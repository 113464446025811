import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Divider,
  Box
} from '@material-ui/core';
import DiscretionaryBenefitsCloneDetail from './cloneDetail';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import SubmitButton from 'common/SingleClickButton';

const DiscretionaryBenefitsCloneDetailDialog = (props) => {
  const { onClose, open, tenantId } = props;
  const defaultValues = {
    tenantCode: tenantId,
    sourceYear: '',
    targetYear: ''
  };
  const methods = useForm({
    defaultValues: { ...defaultValues },
    mode: 'onBlur'
  });

  const { reset } = methods;

  const handleClose = () => {
    reset(defaultValues);
    onClose(null);
  };

  const validate = async (data) => {
    if (!data.targetYear || !data.sourceYear) {
      methods.setError('targetYear', {
        type: 'api',
        message: 'Source and Target Year must be different.'
      });
      return;
    }

    return await axios.post(
      AppConfig.API_BASE_URL +
        `v2/discretionary/clone/validate/from/${data.sourceYear.id}/to/${data.targetYear.id}`,
      data
    );
  };

  const onSubmit = async (data) => {
    const validationRes = await validate(data);
    if (validationRes.data.isValid) {
      onClose({
        ahpmIdFrom: data.sourceYear.id,
        ahpmIdTo: data.targetYear.id
      });
    } else {
      methods.setError('targetYear', {
        type: 'api',
        message: `Invalid selection for source and target year.`
      });
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [tenantId, open]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}>
          <DialogTitle id="customized-dialog-title">
            {'Clone Discretionary Benefits By Year'}
          </DialogTitle>
          <Box sx={{ mb: 2 }}>
            <DialogContent dividers>
              <Box sx={{ m: 2 }}>
                <Typography variant="body1" style={{ color: 'red' }}>
                  {
                    'When cloning, only matching plan names between source year and target year will be cloned. If any matching plans already exist in the target year, the system will skip existing plan(s) cloning to avoid overwriting.'
                  }
                </Typography>
              </Box>
              <DiscretionaryBenefitsCloneDetail tenantId={tenantId} />
            </DialogContent>
            <DialogActions>
              <SubmitButton
                handleSubmit={methods.handleSubmit(onSubmit)}
                isDirty={methods.formState.isDirty}
              />
              <Button onClick={() => handleClose()} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default DiscretionaryBenefitsCloneDetailDialog;
