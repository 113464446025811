import React, { useEffect, useState } from 'react';
import { TextField, Grid } from '@material-ui/core';
import * as Constant from 'common/Constant';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as ValidationUtil from 'utils/ValidationUtil';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

const GroupManagementDialogDetail = (props) => {
  const roleList = useSelector((state) => state.adminRoleManagement.roleList);
  const [defaultRoleList, setDefaultRoleList] = useState([]);

  const { control, getValues } = useFormContext();

  const setRoleList = () => {
    return roleList
      .filter((a) => a.code)
      .map((a) => ({ id: a.id, code: a.code, label: a.description }))
      .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 0));
  };

  useEffect(() => {
    if (roleList?.length) {
      setDefaultRoleList(setRoleList());
    }
  }, [roleList]);

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        <Grid item xs={12} style={{ marginBottom: '10px', marginTop: '20px' }}>
          <Controller
            name="code"
            control={control}
            rules={{
              required: "Group code can't be empty.",
              validate: async (value) => {
                if (value) {
                  if (!/^[A-Z0-9_]*$/.test(value))
                    return 'Only upper case letters, numbers and underscore allowed.';
                  const found = await axios.get(
                    AppConfig.IAM_BACKEND_URL +
                      `/user-group/group-code?code=${value}`
                  );
                  return ValidationUtil.VALIDATION_DUPLICATION(
                    found,
                    parseInt(getValues('id')),
                    'Duplicated Group Code is Found.'
                  );
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                value={field.value ?? ''}
                error={error}
                helperText={error?.message}
                id={'input-role-code'}
                label="Group Code"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: '10px', marginTop: '20px' }}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Group name can't be empty."
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                value={field.value ?? ''}
                error={error}
                helperText={error?.message}
                id={'input-role-description'}
                label="Group Name"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: '10px', marginTop: '20px' }}>
          <Controller
            name="rolesId"
            control={control}
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-rolesId"
                options={defaultRoleList}
                multiple
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.id === value.id}
                filterSelectedOptions
                renderOption={(option) => (
                  <React.Fragment>{option.label}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Roles"
                    error={error}
                    helperText={error?.message}
                  />
                )}
                onChange={(_, data) => field.onChange(data)}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GroupManagementDialogDetail;
