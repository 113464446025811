import React, { useState, useEffect, Fragment } from 'react';
import {
  TextField,
  Grid,
  IconButton,
  Box,
  Chip,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Delete, Add, Edit } from '@material-ui/icons';
import {v4 as uuid} from 'uuid';
import RoleTenantListDialog from './roleTenantListDialog';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const RoleTenantList = ({
  data,
  roleList,
  assignableRoleIdList,
  tenantList,
  appName,
  handleCreationClose,
  handleSingleDelete,
  handleDelete
}) => {
  const classes = useStyles();

  const [dataArray, setDataArray] = useState([]);
  const [creationOpen, setCreationOpen] = useState(false);
  const [unselectedRoleList, setUnselectedRoleList] = useState([]);
  const [unselectedTenantList, setUnselectedTenantList] = useState([]);
  const [cloneData, setCloneData] = useState({});

  /**
   *  Step-1: Filters out already created roles from all assignable roles for the application.
   *  Step-2: Filters in the roles that are assignable to the user's user_group.
   * 
   * @param {*} data 
   * @returns 
   */
  const findUnselectedRoleList = (data) => {
    let roleIdList = [];

    //Step-1
    if (data != null) {
      Object.entries(data).map((item, index) => {
        roleIdList.push(item[1][0].roleId);
      });
      let unselectedRoleList = roleList.filter((item) => !roleIdList.includes(item.id));
      
      //Step-2 
      unselectedRoleList = unselectedRoleList.filter((role) => assignableRoleIdList.includes(role.id));
      return unselectedRoleList;
    } else {
      let filteredRoleList = [];
      filteredRoleList = roleList.filter((role) => assignableRoleIdList.includes(role.id));
      return filteredRoleList;
    }
  };

  const findUnselectedTenantList = ([data]) => {
    let existingMap = {};
    if (Array.isArray(data)) {
      data.forEach((item) => {
        Object.assign(existingMap, { companyId: item.companyId });
      });
      return tenantList
        .filter((item) => item.active)
        .filter((item) => !existingMap.hasOwnProperty(item.id));
    } else {
      return tenantList;
    }
  };

  const handleCreationOpen = (data) => {
    const parsedData = getDefaults(data);
    setCloneData({
      ...data,
      role: parsedData.roleDefaults,
      tenant: parsedData.tenantDefaults
    });
    setCreationOpen(true);
  };

  const handleClose = (data) => {
    setCreationOpen(false);
    handleCreationClose(data);
  };

  const getDefaults = (dataArrayItem) => {
    const defaults = { roleDefaults: [], tenantDefaults: [] };
    if (dataArrayItem?.roleId && dataArrayItem?.tenantIdList) {
      defaults.roleDefaults = roleList.filter(
        (item) => item.id == dataArrayItem.roleId
      )[0];
      defaults.tenantDefaults = tenantList.filter((item) =>
        dataArrayItem.tenantIdList.includes(item.id)
      );
    }
    return defaults;
  };

  useEffect(() => {
    let tempDataArray = [];
    if (data != null) {
      Object.entries(data).map((item, index) => {
        let tenantDisplayText = '';
        let tenantIdList = [];
        let thisRole = roleList.filter((x) => x.id === item[1][0].roleId)[0];

        item[1].map((x) => {
          tenantDisplayText += x.companyName + ', ';
          tenantIdList.push(x.companyId);
        });
        tempDataArray.push({
          roleName: item[1][0].roleName,
          roleId: item[1][0].roleId,
          tenantDisplayText: tenantDisplayText.slice(0, -2),
          tenantIdList: tenantIdList,
          originalData: item[1],
          permissions: thisRole?.permissions
        });
      });
    }
    setDataArray(tempDataArray);
    setUnselectedRoleList(findUnselectedRoleList(data));
    setUnselectedTenantList(findUnselectedTenantList(data));
  }, [data]);

  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        style={{ minWidth: '500px' }}>
        <RoleTenantListDialog
          open={creationOpen}
          onClose={handleClose}
          target={cloneData}
          roleList={roleList}
          unselectedRoleList={unselectedRoleList}
          tenantList={tenantList}
          unselectedTenantList={unselectedTenantList}
          appName={appName}
        />
        {dataArray && Array.isArray(dataArray) && dataArray.length > 0 ? (
          dataArray.map((item, index) => (
            <Fragment key={'key-' + uuid()}>
              <Grid key={'key-' + uuid()} item xs={2}>
                <Tooltip
                  key={'key-' + uuid()}
                  title={
                    <Fragment key={'key-' + uuid()}>
                      <Typography key={'key-' + uuid()} color="inherit">
                        Permission List
                      </Typography>
                      <ul key={'key-' + uuid()} style={{ marginLeft: '10px' }}>
                        {item.permissions && item.permissions.length > 0
                          ? item.permissions.map((permission) => (
                              <li key={'key-' + uuid()}>{permission.code}</li>
                            ))
                          : 'N/A'}
                      </ul>
                    </Fragment>
                  }>
                  <TextField
                    id={'readonly-roleName-' + index}
                    key={'key-' + uuid()}
                    label="Role"
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    fullWidth
                    defaultValue={item.roleName}
                  />
                </Tooltip>
              </Grid>
              <Grid key={'key-' + uuid()} item xs={8}>
                <div className={classes.root} key={'key-' + uuid()}>
                  {Array.isArray(item?.originalData) &&
                    item.originalData.map((data) => (
                      <Chip
                        key={'key-' + uuid()}
                        label={data.companyName}
                        variant={data.companyActive ? 'default' : 'outlined'}
                        onDelete={() => {
                          handleSingleDelete(data.companyId, item.roleId);
                        }}
                      />
                    ))}
                </div>
              </Grid>
              <Grid key={'key-' + uuid()} item xs={2}>
                <Box
                  key={'key-' + uuid()}
                  display="flex"
                  justifyContent="flex-end">
                  {index === dataArray.length - 1 ? (
                    <IconButton
                      id={'action-add'}
                      key={'key-' + uuid()}
                      onClick={() => handleCreationOpen()}
                      title="Add New">
                      <Add key={'key-' + uuid()} />
                    </IconButton>
                  ) : null}
                  <IconButton
                    id={'action-edit-' + index}
                    key={'key-' + uuid()}
                    onClick={() => handleCreationOpen(item)}>
                    <Edit key={'key-' + uuid()} />
                  </IconButton>
                  <IconButton
                    id={'action-delete-' + index}
                    key={'key-' + uuid()}
                    onClick={() => handleDelete(item.roleId)}>
                    <Delete key={'key-' + uuid()} />
                  </IconButton>
                </Box>
              </Grid>
            </Fragment>
          ))
        ) : (
          <>
            <Grid
              key={'key-grid-no-data'}
              container
              justifyContent="center"
              alignItems="center">
              <Typography style={{ fontStyle: 'italic' }}>
                No permission was granted to this user under selected
                application. Please click
              </Typography>
              <IconButton
                id={'action-add-no-data'}
                key={'key-' + uuid()}
                onClick={() => handleCreationOpen()}
                title="Add New">
                <Add />
              </IconButton>
              <Typography style={{ fontStyle: 'italic' }}>
                to create a new one. You can also grant the admin role above.
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default RoleTenantList;
