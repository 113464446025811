export const PREFIX = 'VENDOR_LABELING_';

export const UPLOAD_LOGO_SUCCESS = PREFIX + 'UPLOAD_LOGO_SUCCESS';

export const FETCH_TAG_LIST = PREFIX + 'FETCH_TAG_LIST';

export const FETCH_COMPANY_LIST = PREFIX + 'FETCH_COMPANY_LIST';

export const FETCH_BROKER_LIST = PREFIX + 'FETCH_BROKER_LIST';

export const FETCH_BROKER_COMPANY_MAPPING = PREFIX + 'FETCH_BROKER_COMPANY_MAPPING';