import { useSelector } from 'react-redux';
import * as Constant from 'common/Constant';
import { store } from 'store/store';
import * as Actions from '../actions/action';
import MaterialTable from '@material-table/core';
import React, { useRef, useState, useEffect } from 'react';
import { confirmDialog } from 'common/ComfirmationDialog';
import LookupDetailDialog from './detailDialog';
import SearchIcon from '@material-ui/icons/Search';

const ElectionTierManagmentTable = () => {
  const tableRef = useRef();

  const [open, setOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});

  const data = useSelector((state) => state.MD.lookup.electionTier);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const shouldRefresh = useSelector((state) => state.MD.lookup.shouldRefresh);
  const tenantId = useSelector((state) => state.user.currentTenantCd);

  const handleOpen = (data) => {
    setCloneData(data);
    setOpen(!open);
  };

  const handleClose = (data) => {
    if (data) {
      store.dispatch(Actions.save([data]));
    }
    setOpen(false);
  };

  const deleteById = (id) => {
    confirmDialog(
      () => store.dispatch(Actions.deleteById(id)),
      'Do you really want to remove this election tier?'
    );
  };

  const refresh = () => {
    store.dispatch(Actions.getAllElectionTier());
  };

  useEffect(() => {
    refresh();
  }, [tenantId]);

  useEffect(() => {
    if (shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE) {
      refresh();
    }
  }, [shouldRefresh]);

  return (
    <>
      <MaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Code', field: 'code' }
        ]}
        data={data}
        options={{
          actionsColumnIndex: -1,
          search: true,
          paging: true,
          exportButton: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          {
            position: 'row',
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleOpen({ ...rowData, transientItem: false });
            }
          },
          {
            position: 'row',
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (_, rowData) => deleteById(rowData.id)
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleOpen({ type: 'ELECTION_TIER' });
            }
          }
        ]}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
      />
      <LookupDetailDialog
        open={open}
        onClose={handleClose}
        tenantId={tenantId}
        target={cloneData}
      />
    </>
  );
};

export default ElectionTierManagmentTable;
