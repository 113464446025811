import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';

const TruncatedTextWithTooltip = ({ text }) => (
  <Tooltip title={text || ''}>
    <Typography
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
      {text || ''}
    </Typography>
  </Tooltip>
);

export default React.memo(TruncatedTextWithTooltip);
