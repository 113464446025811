import {
  Box,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as ReportActions from '../actions/actions';
import ReportNavItem from './reportNavItem';
import { store } from 'store/store';
import * as Constant from 'common/Constant';
import * as Action from '../favoriteReport/actions/action';
import { AppConfig } from 'AppConfig';

const BACKGROUND_COLOR = '#4051B5';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  sideNav: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    '&:hover .menuItem': {
      color: BACKGROUND_COLOR
    },
    '&:hover .icon': {
      color: BACKGROUND_COLOR
    },
    '&:hover .typography': {
      color: BACKGROUND_COLOR
    },
    maxWidth: AppConfig.STANDARD_REPORT_SIDE_NAV_WIDTH
  },
  menu: {
    textAlign: 'start',
    maxWidth: AppConfig.STANDARD_REPORT_SIDE_NAV_WIDTH
  },
  header: {
    justifyContent: 'center',
    textAlign: 'start',
    color: BACKGROUND_COLOR
  },
  menuItem: {
    justifyContent: 'start',
    alignContent: 'start',
    alignItems: 'start',
    margin: 0
  },
  icon: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    paddingTop: 3
  },
  count: {
    justifyContent: 'end',
    alignText: 'right'
  },
  listItemText: {
    whiteSpace: 'nowrap',
    maxWidth: AppConfig.STANDARD_REPORT_MENU_ITEM_TEXT_WIDTH,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  menuItemsWrapper: {
    aligntItems: 'center'
  }
}));

const ReportNav = ({ setData, filter }) => {
  const classes = useStyles();

  const legacies = useSelector((state) => state.fo.legacies);
  const reportTags = useSelector((state) => state.fo.reportTags);
  const currentMenu = useSelector((state) => state.favoriteReport.currentMenu);

  const [totals, updateTotals] = useState({});
  const [tags, updateTags] = useState(reportTags);

  useEffect(() => {
    updateTotals(_.groupBy(legacies, (item) => item.reportTag?.code));
  }, [legacies]);

  useEffect(() => {
    updateTags(_.sortBy(reportTags, (tag) => tag.order));
  }, [reportTags]);

  useEffect(() => {
    store.dispatch(ReportActions.fetchReportTags());
  }, []);

  useEffect(() => {
    if (currentMenu) {
      filter(currentMenu.name);
    } else {
      setData(legacies);
    }
  }, [currentMenu, legacies]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.header} variant="h6">
          Filter By
        </Typography>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <MenuList open className={classes.menu}>
          <Box className={classes.sideNav}>
            <MenuItem
              disableGutters={true}
              className={classes.menuItem}
              key={'standard-report-nav-item-all'}
              onClick={() =>
                store.dispatch(Action.setCurrentMenuItem({ name: Constant.ALL }))
              }
              selected={currentMenu?.name === Constant.ALL}>
              <Grid container spacing={2} className={classes.menuItemsWrapper}>
                <Grid item xs={2}>
                  <ListItemIcon className={classes.icon} alignItemsFlexStart>
                    <StarBorderIcon />
                  </ListItemIcon>
                </Grid>
                <Grid item xs={8} className={classes.listItemText}>
                  <ListItemText>{Constant.ALL}</ListItemText>
                </Grid>
                <Grid item xs={2} className={classes.count}>
                  <ListItemText>
                    {Array.isArray(legacies) && legacies.length}
                  </ListItemText>
                </Grid>
              </Grid>
            </MenuItem>
          </Box>

          {Array.isArray(tags) &&
            tags.map((item) => {
              return (
                <ReportNavItem
                  item={item}
                  filter={filter}
                  total={totals[item.code]?.length ?? ''}
                />
              );
            })}
        </MenuList>
      </Grid>
    </Grid>
  );
};
export default ReportNav;
