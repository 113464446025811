import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  makeStyles
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';

import backgroundImage from '../../assets/img/log_bg.jpg';
import bstLogo from '../../assets/img/BST_logo.png';
import { AppConfig } from '../../AppConfig';

const useStyles = makeStyles(() => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw'
  },
  centerCard: {
    backgroundColor: 'transparent',
    maxWidth: '700px',
    minWidth: '400px',
    border: 'solid 1px rgb(201, 205, 209)',
    borderRadius: '4px',
    marginTop: '10rem',
    color: 'white',
    paddingBottom: '2rem'
  },
  header: {
    backgroundColor: 'rgb(201, 205, 209)',
    width: '100%',
    padding: '2rem 0',
    marginBottom: '2rem'
  },
  body: {
    padding: '1rem'
  },
  formInput: {
    backgroundColor: 'white'
  },
  formRow: {
    width: '80%',
    marginBottom: '2rem'
  },
  returnLink: {
    paddingTop: '1rem',
    color: 'yellow',
    textDecoration: 'underline'
  }
}));

export const ResetPassword = () => {
  const styles = useStyles();
  const { control, handleSubmit, getValues } = useForm({ mode: 'onBlur' });

  const history = useHistory();
  const location = useLocation();

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data) => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    axios
      .post(`${AppConfig.IAM_BACKEND_URL}/auth/setpw`, {
        password: data.password,
        token: token
      })
      .then((res) => {
        setSubmitted(true);
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={styles.background}>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="center"
        alignItems="center"
        className={styles.centerCard}>
        <Box display="flex" justifyContent="center" className={styles.header}>
          <Box component="img" src={bstLogo} />
        </Box>
        {!submitted ? (
          <>
            <Controller
              name="password"
              rules={{
                required: 'This field is required.',
                pattern: {
                  value:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#\?!@$%^&*-{}()\[\]_+=.~<>]).{6,100}$/,
                  message:
                    'Password must contain at least one capital, 1 lowercase, 1 special character, 1 number, and be at least 6 characters in length.'
                }
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="password-input"
                  label="New Password"
                  type="password"
                  className={`${styles.formInput} ${styles.formRow}`}
                  variant="filled"
                  required
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message ?? null}
                />
              )}
            />
            <Controller
              name="passwordConfirm"
              rules={{
                required: 'This field is required.',
                validate: {
                  matchesPassword: (val) => {
                    return (
                      getValues('password') === val || 'Passwords do not match.'
                    );
                  }
                }
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="pw-confirm-input"
                  type="password"
                  label="Confirm Password"
                  required
                  className={`${styles.formInput} ${styles.formRow}`}
                  variant="filled"
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message ?? null}
                />
              )}
            />
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleSubmit(onSubmit)}>
              Change Password
            </Button>
          </>
        ) : (
          <Typography variant="body1" className={styles.body}>
            Your password has been changed. Please return to the login page to
            sign into the application.
          </Typography>
        )}
        <Link
          className={styles.returnLink}
          href=""
          variant="body1"
          onClick={() => history.push('/login')}>
          Return to Login
        </Link>
      </Box>
    </Box>
  );
};

export default ResetPassword;
