import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as Types from './types';

export const createBroker = (payload) => {
  return (dispatch) => {
    axios.post(`${AppConfig.FO_INITIAL_URL}/broker`, payload).then((_) => {
      dispatch({
        type: Types.SEARCH_REFRESH
      });
    });
  };
};

export const updateBroker = (payload, brokerId) => {
  return (dispatch) => {
    axios
      .put(`${AppConfig.FO_INITIAL_URL}/broker/${brokerId}`, payload)
      .then((_) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};
