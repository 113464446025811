import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const fetchAdminTenantList = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.IAM_BACKEND_URL + `/tenant-admin/companies`)
      .then((m) => {
        dispatch({
          type: Types.FETCH_ADMIN_TENANT_LIST,
          data: m.data
        });
      });
  };
};

export const fetchAdminRoleList = () => {
  return (dispatch) => {
    axios
      .get(
        AppConfig.IAM_BACKEND_URL + `/user-permission-admin/assignable-roles`
      )
      .then((m) => {
        dispatch({
          type: Types.FETCH_ADMIN_ROLE_LIST,
          data: m.data
        });
      });
  };
};

export const deleteByUserIdRoleIdInTenantAdmin = (userId, roleId) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.IAM_BACKEND_URL + `/tenant-admin/id/${userId}/role/${roleId}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteByUserIdAndCompanyIdAndRoleIdInTenantAdmin = (
  userId,
  companyId,
  roleId
) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.IAM_BACKEND_URL +
          `/tenant-admin/user/${userId}/role/${roleId}/company/${companyId}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const saveTenantApplicationAccesses = (dto) => {
  return (dispatch) => {
    axios
      .post(AppConfig.IAM_BACKEND_URL + `/tenant-admin/all`, dto)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const saveCreatedNewUser = (dto) => {
  return (dispatch) => {
    axios.post(AppConfig.IAM_BACKEND_URL + `/user`, dto).then((m) => {
      dispatch({
        type: Types.SEARCH_REFRESH
      });
    });
  };
};

export const toggleUserActiveStatus = (userId, status) => {
  return (dispatch) => {
    axios
      .post(AppConfig.IAM_BACKEND_URL + `/user/activation/${userId}/${status}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};
