import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Typography,
  AccordionActions,
  IconButton
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { store } from 'store/store';
import * as Actions from '../actions/PlanDesignDataAction';
import { confirmDialog } from 'common/ComfirmationDialog';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from 'common/BstAccordion';

const DentalPlanNetwork = ({ item, handleEdit, handleExpend }) => {
  const [cloned, setCloned] = useState(item);

  const edit = () => {
    if (handleEdit) handleEdit(item);
  };

  const deleteTarget = () => {
    if (item.id && item.id > 0) {
      confirmDialog(() => store.dispatch(Actions.deleteDentalNetwork(item.id)));
    }
  };

  const handleStateChange = (expend) => {
    if (handleExpend) {
      handleExpend(item, expend);
    }
    setCloned({ ...item, default_open: expend });
  };

  return (
    <>
      <Accordion
        defaultExpanded={cloned ? cloned.default_open : false}
        onChange={(event, expend) => handleStateChange(expend)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header">
          <Typography variant="button" display="block" gutterBottom>
            {item.networkType}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container bgcolor="primary.main" spacing={2}>
            <Grid item xs={3}>
              <TextField
                key={'readonly-type' + item.id}
                label="Type"
                value={item.networkType}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-annualMaxBenefits' + item.id}
                label="Annual Max.Benefit"
                value={item.annualMaxBenefits}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-orthoEligibility' + item.id}
                label="Ortho Eligibility"
                value={item.orthoEligibility}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-dedInd' + item.id}
                label="Ded. Individual"
                value={item.dedInd}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-dedFamily' + item.id}
                label="Ded. Family"
                value={item.dedFamily}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-orthoDedInd' + item.id}
                label="Ortho Ded. Individual"
                value={item.orthoDedInd}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-orthoDedFamily' + item.id}
                label="Ortho Ded. Family"
                value={item.orthoDedFamily}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-orthoMax' + item.id}
                label="Ortho Max"
                value={item.orthoMax}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-preventiveCoInsurance' + item.id}
                label="Preventive CoInsurance"
                value={item.preventiveCoInsurance}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-basicDentalCoInsurance' + item.id}
                label="Basic Dental CoInsurance"
                value={item.basicDentalCoInsurance}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-majorDentalCoInsurance' + item.id}
                label="Major Dental CoInsurance"
                value={item.majorDentalCoInsurance}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                key={'readonly-orthoCoInsurance' + item.id}
                label="Ortho CoInsurance"
                value={item.orthoCoInsurance}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <IconButton size="small" onClick={edit}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={deleteTarget}>
            <Delete />
          </IconButton>
        </AccordionActions>
      </Accordion>
    </>
  );
};

export default DentalPlanNetwork;
