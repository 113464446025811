import React, { useState, useRef, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import axios from 'axios';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { fetchData } from 'features/user/userActions';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import * as AppActionTypes from 'store/actions/appstate';
import * as Constant from 'common/Constant';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '2rem'
  }
}));

const TenantTable = () => {
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);

  const styles = useStyles();
  const { deafultUserState, currentTenantCd } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deafultUserState) {
        store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_FO));
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const renderPercentCell = (data, percentDiff) => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography>{`${data} `}</Typography>
        <Box display="flex" flexDirection="row">
          {percentDiff < 0 ? (
            <ArrowDropDownIcon htmlColor="red" />
          ) : (
            <ArrowDropUpIcon htmlColor="green" />
          )}
          <Typography>{`${Math.abs(percentDiff * 100).toFixed(
            1
          )}% Change YTD`}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={styles.container}>
      <MaterialTable
        title="Clients"
        tableRef={tableRef}
        columns={[
          { title: 'Name', field: 'tenantName' },
          {
            title: 'Data Refresh',
            field: 'expectedRefreshDate',
            render: (rowData) => (
              <Typography>
                {moment(rowData.expectedRefreshDate).format('MMM Do YYYY')}
              </Typography>
            )
          },
          {
            title: 'Total Cost',
            field: 'totalPlanCost',
            render: (rowData) =>
              renderPercentCell(
                rowData.totalPlanCost,
                rowData.percentageChangePrevious12Months
              )
          },
          {
            title: 'Member Count',
            field: 'totalMemberCount',
            render: (rowData) =>
              renderPercentCell(
                rowData.totalMemberCount,
                rowData.membershipChangePrevious12Months
              )
          },
          { title: 'Items Needing Attention', field: 'smartCardCount' },
          {
            title: 'Last Accessed',
            field: 'lastAccessedDate',
            render: (rowData) => (
              <Typography>
                {moment(rowData.lastAccessedDate).format('MMM Do YYYY')}
              </Typography>
            )
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.IAM_BACKEND_URL + '/user-tenant/summary', {
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy
                  ? query.orderBy.field
                  : 'tenantName',
                searchTerm: query.search
              })
              .then((res) => {
                const data = res.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                store.dispatch({
                  type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                  payload: `Could not get client info: ${err}`
                });
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        components={{ OverlayLoading: () => <div /> }}
      />
    </Box>
  );
};

export default TenantTable;
