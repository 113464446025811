import { Grid, ListItemText, MenuItem, MenuList } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SideNavItem from './sideNavItem';

const allText = 'All';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  menu: {
    maxWidth: 250,
    textAlign: 'start',
    minWidth: 180
  },
  menuItem: {
    justifyContent: 'start',
    alignContent: 'start',
    alignItems: 'start',
    margin: 0
  }
}));

const SideNav = (props) => {
  const classes = useStyles();
  const filter = (value) => {
    if (props.folders?.size > 0) {
      if (value === allText) {
        props.setData([...props.folders.values()].flatMap((s) => s));
        props.setFolderKey(allText);
      } else {
        props.setFolderKey(value);
      }
    }
  };

  return (
    <Grid container>
      <Grid item>
        <MenuList open className={classes.menu}>
          <MenuItem
            id={'side-nav-menu'}
            disableGutters={true}
            className={classes.menuItem}
            key={'side-nav-item-all'}
            onClick={() => filter(allText)}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.listItemText}>
                <ListItemText>{allText}</ListItemText>
              </Grid>
              <Grid item xs={2} className={classes.count}>
                <ListItemText>{props.allLength}</ListItemText>
              </Grid>
            </Grid>
          </MenuItem>
          {props?.folders?.size > 0 &&
            [...props.folders.values()].map((item) => {
              return (
                <SideNavItem
                  key={'side-nav-item-' + item[0]?.reptSource}
                  item={item}
                  filter={filter}
                  total={item?.length ?? ''}
                />
              );
            })}
        </MenuList>
      </Grid>
    </Grid>
  );
};
export default SideNav;
