import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';

import queryString from 'query-string';
import { fetchData } from 'features/user/userActions';
import axios from 'axios';

import { AppConfig } from 'AppConfig';
import { useDispatch } from 'react-redux';
import LocalStore from 'utils/LocalStore';
import { LOGIN_SUCCESS } from 'features/user/userTypes';
import { setAxiosHeaderToken } from 'networking/NetworkManger';
import { JWT } from '../user/userTypes';
import Auth from 'auth/Auth';

const SSO = () => {
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const searchParams = queryString.parse(search);
  const token = searchParams['otk'];

  const ssoLogin = (otk) => {
    if (token === undefined) {
      history.push(AppConfig.OKTA_URL);
    }

    axios
      .get(AppConfig.FO_INITIAL_URL + '/auth/login/otk?oneTimeKey=' + otk)
      .then((res) => {
        const loginDto = res.data;
        updateDetail(loginDto);
      })
      .catch((err) => {
        history.push(AppConfig.OKTA_URL);
      });
  };

  const updateDetail = (loginDto) => {
    LocalStore.saveLoginUser(loginDto);

    setAxiosHeaderToken(loginDto.jwt);
    LocalStore.saveAccessToken(loginDto.jwt);

    dispatch({ type: LOGIN_SUCCESS, payload: loginDto });
    dispatch({ type: JWT, payload: loginDto.jwt });

    fetchData(loginDto.currentTenantCd, loginDto.currentApp);
    history.push(RouteConfig.newHome.path);
  };

  useEffect(() => {
    if (token) {
      const jwt = LocalStore.getAccessToken();
      if (
        !jwt ||
        Auth.getLoginMode() !== Auth.LOGIN_MODE_HST ||
        Auth.isJwtExpired(jwt)
      ) {
        LocalStore.clear();
        Auth.setLoginMode(Auth.LOGIN_MODE_HST);
        ssoLogin(token);
      } else {
        history.push(RouteConfig.newHome.path);
      }
    }
  }, [token]);

  return <>Loading...</>;
};

export default SSO;
