import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Divider, makeStyles, Typography, FormControlLabel, Switch, FormHelperText } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { store } from 'store/store';
import * as MDActions from '../../../common/actions/CommonActions';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as Constant from 'common/Constant';
import * as ValidationUtil from 'utils/ValidationUtil';
import { PLAN_RATE_STATUS_OPTIONS } from '../../../util/CommonUtil';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'hidden'
  }
}));

const PlanRateCreationDetail = (props) => {
  const ESCAPED_KEYS = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
  const ARROW_GRID_X_MAX = 4;
  const FIXED_ID_ALL = '12igiufhwiyr3w9824u';

  const { control, setValue, getValues, watch } = useFormContext();

  const watchPlanYear = watch('planYears');
  const watchPlanName = watch('planNames');

  const styles = useStyles();

  const tenantId = useSelector((state) => state.user.currentTenantCd);
  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );
  const allElectionTiers = useSelector(
    (state) => state.MD.mdCommon.allElectionTiers
  );
  const employeeClasses = useSelector(
    (state) => state.MD.mdCommon.employeClasses
  );

  const [plansForYears, setPlansForYears] = useState([]);
  const [totalPlanNames, setTotalPlanNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetailMap, setShowDetailMap] = useState([]);
  const [planYearOptions, setPlanYearOptions] = useState([]);

  useEffect(() => {
    getPlanNames();
    store.dispatch(MDActions.fetchAllPlanYears());
    store.dispatch(MDActions.getAllElectionTiers());
    store.dispatch(MDActions.getEmployeeClassTenantId());
    initDatePickers();
  }, [tenantId]);

  const initDatePickers = () => {
    setValue('startDate', null);
    setValue('endDate', null);
  };

  const handlePlanYearChange = (years) => {
    filterPlans(years);
    if (years.length == 1) {
      const planInfo = totalPlanYears.find((py) => py.id === years[0]?.id);
      setValue('startDate', planInfo.startDate);
      setValue('endDate', planInfo.endDate);
    } else {
      setValue('startDate', null);
      setValue('endDate', null);
    }
  };

  const handleTotalRateChange = (rate, index) => {
    const employerShare = getValues(`rate[${index}].employerShare`);
    const employeeShare = getValues(`rate[${index}].employeeShare`);
    if (employerShare) {
      setValue(`rate[${index}].employeeShare`, rate - employerShare);
    } else if (employeeShare) {
      setValue(`rate[${index}].employerShare`, rate - employeeShare);
    } else {
      setValue(`rate[${index}].employeeShare`, rate / 2);
      setValue(`rate[${index}].employerShare`, rate / 2);
    }
  };

  const handleShowDetail = (index) => {
    const newBitmap = [...showDetailMap];
    newBitmap[index] = !newBitmap[index];
    setShowDetailMap(newBitmap);
  };

  const handleArrowNav = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const x = parseInt(e.target.dataset.x);
    const y = parseInt(e.target.dataset.y);

    let { targetX, targetY } = 0;

    switch (e.key) {
      case 'ArrowUp':
        targetX = x;
        targetY = y === 0 ? 0 : y - 1;
        break;
      case 'ArrowDown':
        targetX = x;
        targetY = y >= allElectionTiers.length ? y : y + 1;
        break;
      case 'ArrowLeft':
        targetX = x === 0 ? 0 : x - 1;
        targetY = y;
        break;
      case 'ArrowRight':
        targetX = x >= ARROW_GRID_X_MAX ? x : x + 1;
        targetY = y;
        break;
    }

    const targetField = document.querySelector(
      `[data-x="${targetX}"][data-y="${targetY}"]`
    );
    targetField && targetField.focus();
  };

  const filterPlans = (years) => {
    let filteredList = [];
    const ahpmSet = new Set(years.map((p) => p.id));

    if (ahpmSet === Constant.ALL) {
      return setPlansForYears(setPlanNameSelectOption(totalPlanNames));
    }
    if (totalPlanNames && Array.isArray(totalPlanNames)) {
      filteredList = totalPlanNames.filter((plan) => {
        return ahpmSet.has(plan.ahpmId);
      });
    }

    years.forEach((year) => {
      filteredList
        .filter((plan) => year.id === plan.ahpmId)
        .map((p) => {
          p.year = year.name;
          return p;
        });
    });

    setPlansForYears(setPlanNameSelectOption(filteredList));

    if (watchPlanName.length > 0) {
      const filteredSelectedPlans = watchPlanName.filter((plan) =>
        ahpmSet.has(plan.ahpmId)
      );
      setValue('planNames', filteredSelectedPlans);
    }
  };

  const getPlanNames = async () => {
    setIsLoading(true);
    const planSearch = await axios.get(AppConfig.API_BASE_URL + `v2/plan/all`);

    setTotalPlanNames(planSearch.data);
    setIsLoading(false);
  };

  const setPlanYearSelectOptions = () => {
    const d = totalPlanYears
      .filter((a) => a.planYearName)
      .map((a) => ({
        id: a.id,
        name: a.planYearName,
        label: a.planYearName
      }));
    d.unshift({
      id: FIXED_ID_ALL,
      name: Constant.ALL,
      label: Constant.ALL
    });
    return d;
  };

  const setPlanNameSelectOption = (plans) => {
    const options = plans
      .filter((a) => a.planName)
      .map((a) => ({
        id: a.id,
        ahpmId: a.ahpmId,
        name: a.planName,
        label: a.planName,
        year: a.year
      }));
    if (options.length) {
      options.unshift({
        id: FIXED_ID_ALL,
        name: Constant.ALL,
        label: Constant.ALL
      });
    }

    return options;
  };

  useEffect(() => {
    setPlanYearOptions(setPlanYearSelectOptions);
  }, [totalPlanYears]);

  useEffect(() => {
    handlePlanYearChange(watchPlanYear);
  }, [watchPlanYear]);

  const renderRateDetail = (et, i) => {
    return (
      <Fragment key={`fragment-rate-${i}`}>
        <Grid
          key={`rate-${i}`}
          container
          className={styles.container}
          spacing={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={`readOnly-ET_id-${et.electionTier}`}
                {...field}
                disabled
                hidden
              />
            )}
            name={`rate[${i}].etId`}
            control={control}
            defaultValue={et.id}
          />
          <Grid item xs={1}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id={`readOnly-ET-${et.electionTier}`}
                  fullWidth
                  {...field}
                  label="Election Tier"
                  disabled
                />
              )}
              name={`rate[${i}].electionTier`}
              control={control}
              defaultValue={et.electionTier}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              rules={{
                required: getValues(`rate[${i}].totalRate`)
                  ? Constant.ERROR_MSG_REQUIRED_FIELD
                  : false
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={error}>
                  <InputLabel id={`label_ec_${i}`}>Employee Class</InputLabel>
                  <Select
                    {...field}
                    required
                    fullWidth
                    id={`input_ec_${i}`}
                    key={`input_ec_${i}`}>
                    {employeeClasses &&
                      Array.isArray(employeeClasses) &&
                      employeeClasses.map((item, idx) => (
                        <MenuItem key={`rate[${i}]-ec-${idx}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{error?.message ?? null}</FormHelperText>
                </FormControl>
              )}
              defaultValue={''}
              name={`rate[${i}].employeeClass`}
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              rules={{
                required: getValues(`rate[${i}].employeeClass`)
                  ? Constant.ERROR_MSG_REQUIRED_FIELD
                  : false,
                validate: {
                  rule1: (value) => {
                    if (value && !ValidationUtil.IS_NUMBER(value)) {
                      return 'Input must be a number';
                    }
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id={`monthly-rate-${i}`}
                  fullWidth
                  {...field}
                  inputProps={{ 'data-x': '0', 'data-y': `${i}` }}
                  type="number"
                  onBlur={(e) => {
                    field.onBlur(e);
                    handleTotalRateChange(e.target.value, i);
                  }}
                  onKeyDown={(e) => {
                    if (ESCAPED_KEYS.includes(e.key)) {
                      handleArrowNav(e);
                    }
                  }}
                  error={error}
                  helperText={error?.message ?? null}
                  label="Total Monthly Rate ($)"
                />
              )}
              name={`rate[${i}].totalRate`}
              defaultValue=""
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              rules={{
                required: getValues(`rate[${i}].totalRate`)
                  ? Constant.ERROR_MSG_REQUIRED_FIELD
                  : false,
                validate: {
                  rule1: (value) => {
                    if (value && !ValidationUtil.IS_NUMBER(value)) {
                      return 'Input must be a number';
                    }
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id={`employer-share-${i}`}
                  fullWidth
                  {...field}
                  inputProps={{ 'data-x': '1', 'data-y': `${i}` }}
                  type="number"
                  onBlur={(e) => {
                    field.onBlur(e.target.value);
                    const totalRate = getValues(`rate[${i}].totalRate`);
                    if (totalRate) {
                      setValue(
                        `rate[${i}].employeeShare`,
                        parseFloat(totalRate) - parseFloat(e.target.value)
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (ESCAPED_KEYS.includes(e.key)) {
                      handleArrowNav(e);
                    }
                  }}
                  error={error}
                  helperText={error?.message ?? null}
                  label="Employer Share"
                />
              )}
              name={`rate[${i}].employerShare`}
              defaultValue=""
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              rules={{
                required: getValues(`rate[${i}].totalRate`)
                  ? Constant.ERROR_MSG_REQUIRED_FIELD
                  : false,
                validate: {
                  rule1: (value) => {
                    if (value && !ValidationUtil.IS_NUMBER(value)) {
                      return 'Input must be a number';
                    }
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id={`employee-share-${i}`}
                  {...field}
                  inputProps={{ 'data-x': '2', 'data-y': `${i}` }}
                  onKeyDown={(e) => {
                    if (ESCAPED_KEYS.includes(e.key)) {
                      handleArrowNav(e);
                    }
                  }}
                  fullWidth
                  type="number"
                  onBlur={(e) => {
                    field.onBlur(e.target.value);
                    const totalRate = getValues(`rate[${i}].totalRate`);
                    if (totalRate) {
                      setValue(
                        `rate[${i}].employerShare`,
                        parseFloat(totalRate) - parseFloat(e.target.value)
                      );
                    }
                  }}
                  error={error}
                  helperText={error?.message ?? null}
                  label="Employee Share"
                />
              )}
              name={`rate[${i}].employeeShare`}
              defaultValue=""
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id={`rate-${i}`}
                  fullWidth
                  {...field}
                  inputProps={{ 'data-x': '3', 'data-y': `${i}` }}
                  onKeyDown={(e) => {
                    if (ESCAPED_KEYS.includes(e.key)) {
                      handleArrowNav(e);
                    }
                  }}
                  error={error}
                  helperText={error?.message ?? null}
                  label="Cobra Rate (%)"
                />
              )}
              name={`rate[${i}].cobra`}
              control={control}
              defaultValue="2.0"
            />
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              checked={showDetailMap[i]}
              onChange={(_e) => {
                handleShowDetail(i);
              }}
              control={<Switch />}
              label="Show All"
            />
          </Grid>
          {showDetailMap[i] && (
            <>
              <Grid item xs={2}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id={`tobacco-${i}`}
                      fullWidth
                      type="number"
                      {...field}
                      error={error}
                      helperText={error?.message ?? null}
                      label="Tobacco Surchage"
                    />
                  )}
                  name={`rate[${i}].tobaccoSurcharge`}
                  control={control}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id={`wellness-discount-${i}`}
                      fullWidth
                      type="number"
                      {...field}
                      error={error}
                      helperText={error?.message ?? null}
                      label="Wellness Discount"
                    />
                  )}
                  name={`rate[${i}].wellnessDiscount`}
                  control={control}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id={`spousal-surcharge-${i}`}
                      fullWidth
                      type="number"
                      {...field}
                      error={error}
                      helperText={error?.message ?? null}
                      label="Spousal Surcharge"
                    />
                  )}
                  name={`rate[${i}].spousalSurcharge`}
                  control={control}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id={`ssd-surcharge-${i}`}
                      fullWidth
                      type="number"
                      {...field}
                      error={error}
                      helperText={error?.message ?? null}
                      label="SS Disability Surcharge"
                    />
                  )}
                  name={`rate[${i}].ssdisabilitySurchange`}
                  control={control}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Divider className="mt-4" />
      </Fragment>
    );
  };

  if (isLoading) {
    return <div />;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Controller
            rules={{ required: 'This field is required' }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-planyear"
                multiple
                options={planYearOptions}
                getOptionLabel={(option) => option.label}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Plan Year"
                    inputProps={{ ...params.inputProps }}
                    error={error}
                    helperText={error?.message ?? null}
                  />
                )}
                onChange={(e, data) => {
                  if (data.find((op) => op.label === Constant.ALL)) {
                    e.preventDefault();
                    setValue(
                      'planYears',
                      planYearOptions.filter(
                        (option) => option.label !== Constant.ALL
                      )
                    );
                  } else {
                    field.onChange(data);
                  }
                }}
              />
            )}
            defaultValue={[]}
            name="planYears"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                planStartDate: (value) =>
                  ValidationUtil.START_DATE(
                    null,
                    value,
                    getValues('endDate'),
                    true
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  error={error}
                  helperText={error?.message ?? null}
                  format={Constant.FORMAT_DATE}
                  value={getValues('startDate') ? moment.utc(getValues('startDate')) : null}
                  id="input-startDate"
                  label="Start Date"
                  fullWidth
                  defaultValue=""
                />
              </MuiPickersUtilsProvider>
            )}
            name="startDate"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <InputLabel id="label-status">Status</InputLabel>
                <Select
                  {...field}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  id="input_status"
                  key="input_status">
                  {PLAN_RATE_STATUS_OPTIONS.map((item, i) => (
                    <MenuItem key={`status-${i}`} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            defaultValue={'ACTIVE'}
            name="status"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-planname"
                multiple
                options={plansForYears}
                getOptionLabel={(option) => `${option.label} (${option.year})`}
                renderOption={(option) =>
                  `${option.name} ${option.year ? `(${option.year})` : ''} `
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Plan Name"
                    inputProps={{ ...params.inputProps }}
                    error={error}
                    helperText={error?.message ?? null}
                  />
                )}
                onChange={(e, data) => {
                  if (data.find((op) => op.label === Constant.ALL)) {
                    e.preventDefault();
                    setValue(
                      'planNames',
                      plansForYears.filter(
                        (option) => option.label !== Constant.ALL
                      )
                    );
                  } else {
                    field.onChange(data);
                  }
                }}
              />
            )}
            defaultValue={[]}
            name="planNames"
            control={control}
            rules={{ required: 'This field is required' }}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                planEndDate: (value) =>
                  ValidationUtil.END_DATE(
                    null,
                    getValues('startDate'),
                    value,
                    true
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  error={error}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  helperText={error?.message ?? null}
                  format={Constant.FORMAT_DATE}
                  value={getValues('endDate') ? moment.utc(getValues('endDate')) : null}
                  id="input-endDate"
                  label="End Date"
                  fullWidth
                  defaultValue=""
                />
              </MuiPickersUtilsProvider>
            )}
            name="endDate"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-comment"
                multiline
                fullWidth
                {...field}
                error={error}
                helperText={error?.message ?? null}
                label="Comments"
              />
            )}
            name="comment"
            control={control}
          />
        </Grid>
      </Grid>
      <Typography className="mt-4" variant="subtitle1">
        Rate Details:
      </Typography>
      <Divider />
      {allElectionTiers &&
        allElectionTiers.map((et, i) => renderRateDetail(et, i))}
    </>
  );
};

export default PlanRateCreationDetail;
