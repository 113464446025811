export const AppConfig = {
  /*app level */
  HTTP_STATUS_OK: 200,
  APP_FO: 'FrontOffice',
  APP_MASTER_DATA: 'MasterData',
  APP_RANGER: 'Ranger',
  APP_SECURITY: 'ADMIN',
  APP_CODE_FO: 'FRONT_OFFICE',
  APP_CODE_RANGER: 'RANGER',
  APP_CODE_MASTER_DATA: 'MASTER_DATA',
  APP_CODE_ENIGMA: 'ENIGMA',
  APP_CODE_PLAN_OPTIMIZE: 'PLAN_OPTIMIZE',
  APP_CODE_PREMIUM_OPTIMIZER: 'PREMIUM_OPTIMIZER',
  APP_CODE_CLIENT_PORTAL: 'CLIENT_PORTAL',
  TB_ID: 'bis',
  DEBOUNCE_WAIT_TIME: 500,
  DEBOUNCE_TYPE_TIME: 1000,
  DOWNLOAD_MAX_ROW_COUNT: 15000,
  SCREEN_RIGHT_VIEW_NARROW: 0.99,
  SCREEN_RIGHT_VIEW_WIDE: 0.85,
  RSS_ITEM_COUNT: 5,
  TABLEAU_UPDATE_ALL: 'all',
  TABLEAU_UPDATE_REPLACE: 'replace',
  TABLEAU_UPDATE_ADD: 'add',
  TABLEAU_UPDATE_REMOVE: 'remove',
  TABLEAU_EXPLORER_ROLE: 'ExplorerCanPublish',
  TABLEAU_VIEWER_ROLE: 'Viewer',

  TABLEAU_REPORT_HEIGHT: 2480,

  FO_INITIAL_URL: process.env.REACT_APP_FO_INITIAL_URL,
  FO_SECURITY_URL: process.env.REACT_APP_FO_SECURITY_URL,
  FO_MONITOR_URL: process.env.REACT_APP_FO_MONITOR_URL,
  FO_RANGER_URL: process.env.REACT_APP_FO_RANGER_URL,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  CLIENT_PORTAL_URL: process.env.REACT_APP_CLIENT_PORTAL_URL,
  GEMINI_URL: process.env.REACT_APP_GEMINI_URL,
  IAM_BACKEND_URL: process.env.REACT_APP_IAM_BACKEND_URL,
  TIER_OPTIMIZER_URL: process.env.REACT_APP_TIER_OPTIMIZER_URL,
  PREMIUM_OPTIMIZER_URL: process.env.REACT_APP_PREMIUM_OPTIMIZER_URL,
  VAULT_URL: process.env.REACT_APP_VAULT_URL,
  OKTA_URL: process.env.REACT_APP_OKTA_URL,
  DUMMY_IP: 'dummyip',
  LOCAL_TESTING: 'no',

  /*security and role settings*/
  MASTER_ADMIN_ROLE: 'ADMIN_MasterDataAdmin',
  MAIL_ADMIN_ROLE: 'ADMIN_MailAdmin',
  MENU_ADMIN_ROLE: 'ADMIN_MenuAdmin',
  MESSAGE_ADMIN_ROLE: 'ADMIN_SitewideMessageAdmin',
  RECOMMENDATION_ADMIN_ROLE: 'ADMIN_RecommendationAdmin',
  MONITOR_ADMIN_ROLE: 'ADMIN_MonitorAdmin',
  RANGER_ADMIN_ROLE: 'ADMIN_RangerAdmin',
  ROLE_ADMIN_ROLE: 'ADMIN_RoleAdmin',
  SECURITY_ADMIN_ROLE: 'ADMIN_SecurityAdmin',
  SHARE_ADMIN_ROLE: 'ADMIN_ShareAdmin',
  SITE_ADMIN_ROLE: 'USER_SiteAdmin',
  SYSTEM_ADMIN_ROLE: 'ADMIN_SystemAdmin',
  MASTER_LKP_DI_USER_ROLE: 'USER_MasterData_LKP_DI',
  MASTER_LKP_DI_VAULT_USER_ROLE: 'USER_MasterData_LKP_DI_Vault',
  SYS_ADMIN_ROLES: [
    'ADMIN_MailAdmin',
    'ADMIN_MenuAdmin',
    'ADMIN_SitewideMessageAdmin',
    'ADMIN_RecommendationAdmin',
    'ADMIN_RoleAdmin',
    'ADMIN_SecurityAdmin',
    'ADMIN_ShareAdmin',
    'ADMIN_SystemAdmin'
  ],
  SHOW_TOP_BAR_TPA: 'no',
  USER_ENIGMA_ROLE: 'USER_Enigma',
  USER_TIER_OPTIMIZATION: 'TierOptimization',
  TIER_OPT_MENU: 'Tier-opt',

  /*  Grid consts */
  GRID_NEW_ROW_ID: '-1',
  ROLE_PREFIXES: ['DATA', 'MENU', 'ADMIN', 'USER', 'REPORT'],
  MONITOR_POSTETL_TABLEAU_REPORT: 'https://tb2.benefitsscience.com/#/site/validation/views/Internal_Validation_Dashboard_ALL_GROUPS_V2/ProductionValidationCheck?:iid=1',

  /*home page*/
  FEATURE_REPORT_BAR_WIDTH: 1500,
  HOME_SMART_CARD_WIDTH: 250,

  HOME_CUSTOM_CARD_HEIGHT: 190,
  HOME_CUSTOM_CARD_WIDTH: 300,

  HOME_METRICS_CARD_HEIGHT: 300,

  HOME_RECOMMEND_CARD_HEIGHT: 190,
  HOME_RECOMMEND_CARD_WIDTH: 280,

  HOME_LEFT_SLIDER_LOAD: 'LEFT_SLIDER_LOAD',

  STANDARD_REPORT_SIDE_NAV_WIDTH: 220,
  STANDARD_REPORT_MENU_ITEM_TEXT_WIDTH: 185,

  POST_HEADERS: {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json'
    }
  },
  REPORT_CATEGORY: {
    AD_HOC: 'AD_HOC',
    SMART: 'SMART',
    LEGACY: 'LEGACY',
    METRIX: 'METRIX',
    MINE: 'MINE',
    OFTENTIMES: 'OFTENTIMES',
    SHARE: 'SHARE',
    CUSTOM: 'CUSTOM',
    NEWS: 'NEWS',
    FREQUENCY: 'FREQUENCY',
    EXPLORER: 'EXPLORER'
  },
  REPORT_SUBCATEGORY: {
    PLAN_PERFORMANCE: 'Plan Performance',
    LCARS: 'Large Claimants and Risks',
    DRUGS: 'Drugs',
    PROCEDURES_AND_PROVDIERS: 'Procedures and Providers',
    CLINICAL_MEASURMENTS: 'Clinical Measurements',
    QUALITY_GAPS_IN_CARE: 'Quality/Gaps in Care',
    BENCHMARKS: 'Benchmark Reports'
  },
  AD_HOC_CAROUSEL: 'AD_HOC',
  LEGACY_CAROUSEL: 'LEGACY',
  SMART_CAROUSEL: 'SMART',
  METRIX_CAROUSEL: 'metrix',
  MINE_CAROUSEL: 'mine',
  OFTENTIMES_CAROUSEL: 'oftentimes',
  SHARE_CAROUSEL: 'share',
  CUSTOM_CAROUSEL: 'custom',
  DEFAULT_CAROUSEL_ITEM_ID: '0',
  ELECTION_TIER_TYPE: 'ELECTION_TIER',
  apiUrl: '',
  EXPLORE_CAROUSEL: 'EXPLORER',
  FAVORITE_CAROUSEL: 'FAVORITE_CAROUSEL',
  EXCLUSIVE_CAROUSEL: 'exclusive',

  CAS_END_POING: process.env.REACT_APP_CAS_END_POING,
  CAS_PROXY_CALLBACK: process.env.REACT_APP_CAS_PROXY_CALLBACK,

  GOOGLE_ANALYTICS_ID: 'G-EQEZL62KD7',

  PERMISSION: {
    LOGO_MANAGEMENT: 'LOGO_MANAGEMENT',
    MD_DISCRECTIONARY_BENEFITS: 'MD_DISCRETIONARY_BENEFITS',
    MD_LKP: 'MD_LKP',
    MD_DI: 'MD_DI',
    MD_VAULT: 'MD_VAULT',

    USER_PERMISSION_ADMIN: 'USER_PERMISSION_ADMIN',
    FO_AD_HOC: 'FO_AD_HOC',
    FO_REPORT_EXCEL_EXPORT: 'FO_REPORT_EXCEL_EXPORT',
    FO_REPORT_IMAGE_EXPORT: 'FO_REPORT_IMAGE_EXPORT',
    FO_REPORT_PDF_EXPORT: 'FO_REPORT_PDF_EXPORT',
    FO_SMART_CARD_VIEW: 'FO_SMART_CARD_VIEW',
    FO_SMART_CARD_MODIFY: 'FO_SMART_CARD_MODIFY',
    FO_REPORT_MANAGEMENT: 'FO_REPORT_MANAGEMENT',
    MD_PLAN_RATE_BULK_MODIFICATION: 'MD_PLAN_RATE_BULK_MODIFICATION',
    FO_REPORT_HOME: 'FO_HOME',
    FO_STANDARD_REPORT: 'FO_STANDARD_REPORT',
    FO_EXPLORER_REPORT: 'FO_EXPLORER_REPORT',
    FO_CUSTOM_REPORT: 'FO_CUSTOM_REPORT',
    FO_SMARTCARD_DISMISS: 'FO_SMARTCARD_DISMISS',
    FO_SMARTCARD_RECOVER: 'FO_SMARTCARD_RECOVER',
    FO_LOCAL_TENANT_MANAGEMENT: 'FO_LOCAL_TENANT_MANAGEMENT',
    PLAN_OPT_VIEW: 'PLAN_OPT_VIEW',
    FO_FAVORITE: 'FO_FAVORITE',
    FO_MY_CREATION: 'FO_MY_CREATION',
    SYSTEM_ADMIN_DISCRETIONARY_BENEFITS_IMPORT: 'SYSTEM_ADMIN_DISCRETIONARY_BENEFITS_IMPORT',
    VAULT_ACCESS: 'VAULT_ACCESS',
    FO_EXCLUSIVE_REPORTS: 'FO_EXCLUSIVE_REPORTS',
    FO_COMPANY_MANAGEMENT: 'FO_COMPANY_MANAGEMENT',
    BROKER_MANAGEMENT: 'BROKER_MANAGEMENT',
    PREMIUM_OPTIMIZER_VIEW: 'PREMIUM_OPTIMIZER_VIEW',
    CLIENT_PORTAL: 'CLIENT_PORTAL'
  },

  HEADER_MENU_TYPE: {
    STANDARD: 'STANDARD',
    FO: 'FO',
    NONE: 'NONE',
    RANGER: 'RANGER',
    ENIGMA: 'ENIGMA'
  },

  FRONT_OFFICE_SUB_URL: {
    STANDARD: '/standard',
    MY_REPORT: '/report',
    MY_FAVORITE: '/favorite',
    MY_CUSTOM: '/custom',
    REPORT_MANAGEMENT: '/manage-reports',
    EXISTING_REPORTS: '/manage-existing',
    EXPLORER: '/explorer',
    DEFAULT_SETTING: '/default-setting',
    EXCLUSIVE: '/exclusive'
  },

  ADMIN_SUB_URL: {
    USER_ACCESS: '/user-access',
    ROLE_MANAGEMENT: '/role-management',
    USER_GROUP: '/user-group'
  },

  TENANT_ADMIN_SUB_URL: {
    TENANT_USER_ACCESS: '/tenant-admin'
  },

  WHITE_LABEL_SUB_URL: {
    WHITE_LABEL: '/white-label',
    VENDOR_LABELING: '/vendor-label',
    VENDOR_LABELING_BROKER_VIEW: '/vendor-label-broker',
    TAG_MANAGEMENT: '/tag-management'
  },

  TENANT_MANAGEMENT_SUB_URL: {
    TENANT: '/tenant',
    BROKER: '/broker',
    MD_RENEWAL_MGMT: '/md-renewal-management'
  },

  /**CLIENT PORTAL CONSTANTS */
  CLIENT_PORTAL_SUB_URL: {
    ONBOARDING: '/onboarding',
    ANNUAL_PLAN_RENEWAL: '/annual-plan-renewal',
    USER_AUDIT: '/user-audit',
    CONTACT_SHEET: '/contact-sheet'
  },

  CLIENT_PORTAL_SUBCATEGORY: {
    ONBOARDING: 'Onboarding (Client Docs)',
    MASTER_DATA: 'Annual Renewal',
    ANNUAL_PLAN_RENEWAL: 'Annual Plan Renewal',
    USER_AUDIT: 'User Audit',
    CONTACT_SHEET: 'Contact Sheet'
  },

  MASTER_DATA_SUB_URL: {
    BASE: '/main',
    MASTER_TABLE: '/master',
    ET_SCHEMA: '/election-tier',
    EMPLOYEE_CLASS: '/employee',
    ANNUAL_HEALTH_PLAN: '/annual-health-plan',
    PLAN_DESIGN_DATA: '/plan-design',
    DISCRETIONARY_BENEFITS: '/discretionary-benefits',
    DENTAL_PLAN: '/dental',
    VISION_PLAN: '/vision',
    PLAN_EXPENSE: '/plan-expense',
    PLAN_RATES: '/plan-rates',
    DIVISION: '/division',
    AUDITING: '/auditing',
    VALIDATION: '/validation',
    STOP_LOSS_CONTRACT: '/stop-loss-contract'
  },
  LOOKUP_TABLES_SUB_URL: {
    BASE: '/lookup',
    LOOKUP: '/lookup-table',
    ELECTION_TIER_LIST: '/election-tier-management'
  },
  APP_TENANT_SWITCHING_URL: '/user/tenant/app/switch',
  REFRESH_TOKEN_URL: '/token/refresh',
  ENIGMA_URL: process.env.REACT_APP_ENIGMA_URL,
  REPT_SRC_PREFIX: 'trusted/tableauToken/t',
  PLAN_OPT_URL: 'https://planopt.benefitsscience.com',
  MULTIPLAN_TOS_URL: 'https://www.multiplan.us/terms-of-use/',
  MULTIPLAN_PRIVACY_POLICY_URL: 'https://www.multiplan.us/privacy-policy/',
  TB_DEV_URL: 'tb-dev.benefitsscience.com'
};
