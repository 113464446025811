export function getTypeName(object) {
  const objectToString = Object.prototype.toString.call(object).slice(8, -1);
  if (objectToString === 'Function') {
    const instanceToString = object.toString();
    if (instanceToString.indexOf(' => ') !== -1) return 'ArrowFunction';
    const getFunctionName = /^function ([^(]+)\(/;
    const match = instanceToString.match(getFunctionName);
    if (match === null) return 'AnonymousFunction';
    return 'Function';
  }
  return objectToString;
}

export function getFunctionName(fn) {
  try {
    const instanceToString = fn.toString();
    if (instanceToString.indexOf(' => ') !== -1) return '=>';
    const getFunctionName = /^function ([^(]+)\(/;
    const match = instanceToString.match(getFunctionName);
    if (match === null) {
      const objectToString = Object.prototype.toString.call(fn).slice(8, -1);
      if (objectToString === 'Function') return '';
      throw TypeError('object must be a Function. Actual: ' + getTypeName(fn));
    }
    return match[1];
  } catch (e) {
    throw TypeError('object must be a Function. Actual: ' + getTypeName(fn));
  }
}

export function getObjectClass(object) {
  const getFunctionName = /^function ([^(]+)\(/;
  const result = object.constructor.toString().match(getFunctionName)[1];
  if (result === 'Function') {
    throw TypeError(`object must be an Object! Actual: ${getTypeName(object)}`);
  }
  return result;
}

export function isEmpty(obj) {
  if (!obj || obj.length === 0) {
    return true;
  }
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
