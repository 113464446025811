import { Model, attr } from 'redux-orm';

const defaultAttributes = {
  displayText: 'Default workbook',
  category: 'Dummy catetory'
};

export default class View extends Model {
  static modelName = 'View';
  static fields = {
    id: attr(),
    category: attr(),
    displayText: attr(),
    description: attr(),
    position: attr(),
    rank: attr(),
    recomEndDt: attr(),
    recomStartDt: attr(),
    reptSource: attr(),
    sharedBy: attr(),
    savedBy: attr(),
    thumbnail: attr(),
    weight: attr()
  };

  static parse(viewData) {
    return this.upsert(viewData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherView) {
    this.update(otherView.ref);
  }
}
