import React, { useEffect, useState } from 'react';
import Carousel from 'common/bstCarousel';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import * as UserTypes from 'features/user/userTypes';
import * as Actions from 'features/frontoffice/actions/actions';
import useWindowDimensions from 'common/hooks/useWindowDimensions';

const ExplorerReport = () => {
  const { width } = useWindowDimensions();
  const explorers = useSelector((state) => state.fo.explorers);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.FO
    });
    store.dispatch(Actions.getExplorerReport());
  }, [currentTenantCd]);

  return (
    <>
      <Carousel
        items={explorers}
        type={AppConfig.EXPLORE_CAROUSEL}
        viewWidth={width}
        cardWidth={AppConfig.HOME_RECOMMEND_CARD_WIDTH}
        cardHeight={AppConfig.HOME_RECOMMEND_CARD_HEIGHT}
      />
    </>
  );
};

export default ExplorerReport;
