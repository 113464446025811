import { Model, fk, attr } from 'redux-orm';
import {
  APP_SET,
  BROKER_SET,
  PLAN_YEAR_SET,
  CLAIM_TYPE_SET,
  MESSAGE_SET,
  MESSAGE_OK_SET,
  LEAF_CLICK,
  LEVEL_SET,
  ROLE_SET,
  SHOW_TOP_BAR_BROKER_DROPDOWN,
  SITE_SET,
  LOAD_DATA,
  REC_DISMISSED,
  REC_PINNED,
  USER_SET,
  SYS_ADMIN_USER_SET
} from 'reducers/reducersConstants';

export default class User extends Model {
  static modelName = 'User';

  static fields = {
    id: attr(),
    username: attr(),
    firstname: attr(),
    lastname: attr(),
    lastLoginDt: attr(),
    currentApp: attr(),
    currentRole: attr(),
    currentTenantCd: attr(),
    tableauUsername: attr(),
    tableauRole: attr(),
    message: attr(),
    level: attr(),
    messageOk: attr(),
    sysRoles: fk('SysRole'),
    sites: fk('Site'),
    tenants: fk('Tenants'),
    masterDataSites: fk('MasterDataSite')
  };

  static initial_state = {
    id: null,
    username: null,
    firstname: null,
    lastname: null,
    lastLoginDt: null,
    currentApp: null,
    currentRole: null,
    currentTenantCd: null,
    tableauUsername: null,
    message: null,
    level: null,
    messageOk: null,
    sysRoles: [{}],
    sites: [{}],
    tenants: [{}],
    masterDataSites: [{}]
  };

  static parse(userData) {
    return this.upsert(userData);
  }

  static reducer(action, aUser, session) {
    const { User, View } = session;
    switch (action.type) {
      case LOAD_DATA:
        if (!action.payload) {
          session.state = { ...this.initial_state };
        } else {
          User.parse(action.payload);
        }
        return session.state;
      case APP_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('currentApp', action.payload);
          });
        break;

      case BROKER_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('currentBroker', action.payload);
          });
        break;
      case CLAIM_TYPE_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('claimType', action.payload);
          });
        break;
      case PLAN_YEAR_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('planYear', action.payload);
          });
        break;

      case ROLE_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('currentRole', action.payload);
          });
        break;
      case SHOW_TOP_BAR_BROKER_DROPDOWN:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('showBrokerInsideBar', action.payload);
          });
        break;
      case SITE_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('currentTenantCd', action.payload);
          });
        break;
      case USER_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('username', action.payload);
          });
        break;

      case SYS_ADMIN_USER_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('usernameUnderSysAdmin', action.payload);
          });
        break;

      case MESSAGE_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('message', action.payload);
          });
        break;
      case MESSAGE_OK_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('messageOk', action.payload);
          });
        break;
      case LEVEL_SET:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('level', action.payload);
          });
        break;
      case LEAF_CLICK:
        User.all()
          .toModelArray()
          .forEach((m) => {
            m.set('leaf', action.payload);
          });
        break;
      case REC_DISMISSED:
        View.all()
          .toModelArray()
          .filter((view) => view.id === action.payload)
          .delete();
        break;
      case REC_PINNED:
        View.all()
          .toModelArray()
          .forEach((m) => {
            m.position =
              m.id === action.payload
                ? 0 - Math.abs(m.position)
                : Math.abs(m.position);
          });
        break;
      default:
        return session;
    }
  }
}
