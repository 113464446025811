import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import create from 'zustand';
import { useSelector } from 'react-redux';

const useConfirmDialogStore = create((set) => ({
  title: 'Confirmation',
  messages: [],
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
  cancelText: '',
  confirmText: ''
}));

export const reportDeleteConfirmDialog = (
  onSubmit,
  messages = ['Do you really want to delete all the reports?'],
  title = 'Confirmation',
  cancelText = 'Cancel',
  confirmText = 'Confirm'
) => {
  useConfirmDialogStore.setState({
    messages,
    onSubmit,
    title,
    cancelText,
    confirmText
  });
};

const ReportDeletionConfirmationDialog = () => {
  const { title, messages, onSubmit, close, cancelText, confirmText } =
    useConfirmDialogStore();
  const reportList = useSelector((state) => state.fo.reportsToDelete);
  if (!reportList || reportList.length === 0) {
    messages[0] = "No reports to delete. Please check your input.";
  }
  const formattedReports = reportList.map(report => `${report.tenantCd} / ${report.displayText}`);
  messages.push(...formattedReports);
  return (
    <Dialog open={Boolean(onSubmit)} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        {messages.map((msg, index) => <Typography key={index}>{msg}</Typography>)}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={close}>
          {cancelText}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ReportDeletionConfirmationDialog;
