import React, { useState } from 'react';
import { TextField, Grid, IconButton, Box } from '@material-ui/core';
import { Delete, Add, Edit } from '@material-ui/icons';
import {v4 as uuid} from 'uuid';
import { useFormContext, useFieldArray } from 'react-hook-form';
import IslPlanDialog from './islPlanDialog';
import * as Actions from '../actions/actions';
import { store } from 'store/store';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Constant from 'common/Constant';

const IslPlanList = ({ slcData }) => {
  const [creationOpen, setCreationOpen] = useState(false);
  const { control, setValue, getValues } = useFormContext();
  const { fields, remove } = useFieldArray({
    control,
    keyName: Constant.DEFAULT_REACT_HOOK_FIELD_ID,
    name: 'islPlans'
  });
  const [cloneData, setCloneData] = useState({});

  const handleCreationClose = (data) => {
    if (data) {
      if (data.islAppliedToAll === true) {
        store.dispatch(Actions.applyAllIslPlanToSlc(data.id));
      } else if (
        data.islPlans &&
        Array.isArray(data.islPlans) &&
        data.islPlans.length > 0
      ) {
        store.dispatch(Actions.saveIslPlans(data.islPlans));
      } else {
        store.dispatch(Actions.deleteAllIslPlanBySlcId(data.id));
      }
    }
    setCreationOpen(false);
  };

  const handleDelete = (id) => {
    confirmDialog(() => store.dispatch(Actions.deleteIslPlanById(id)));
  };

  const handleCreationOpen = (data) => {
    setCloneData(data);
    setCreationOpen(true);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        style={{ minWidth: '500px' }}>
        <IslPlanDialog
          open={creationOpen}
          onClose={handleCreationClose}
          slcData={slcData}
        />
        {fields && Array.isArray(fields) && fields.length > 0 ? (
          <>
            <Grid item xs={10} xl={10}>
              <Grid container spacing={2}>
                {fields.map((item, index) => (
                  <Grid item xs={3}>
                    <TextField
                      id={'readonly-plan_name-' + index}
                      key={'key-' + uuid()}
                      label="Plan"
                      InputProps={{ readOnly: true }}
                      multiline
                      defaultValue={item.plan.planName}
                    />
                    <IconButton
                      id={'action-delete-' + index}
                      onClick={() => handleDelete(item.id)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  id={'action-edit'}
                  key={'key-' + uuid()}
                  onClick={() => handleCreationOpen({})}>
                  <Edit />
                </IconButton>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid container justify="center" alignItems="center">
              <i>
                Stop loss contract is currently applied to all plans. If you
                want it to applied to particular plans, please click{' '}
              </i>
              <IconButton
                id={'action-add-no-data'}
                key={'key-' + uuid()}
                onClick={() =>
                  handleCreationOpen({
                    stopLossContractId: getValues('id'),
                    runOutEndDate: null,
                    runOutStartDate: null,
                    runInEndDate: null,
                    runInStartDate: null
                  })
                }
                title="Add New">
                <Add />
              </IconButton>
              <i> to choose plans you want.</i>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default IslPlanList;
