import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  makeStyles,
  FormControl,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import axios from 'axios';
import { debounce } from 'lodash';

import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import * as AppActionTypes from 'store/actions/appstate';
import ManageReportAccordion from './detailAccordion';
import { hasPermission } from 'utils/common';
import * as ReportActions from '../actions/actions';

const useStyles = makeStyles(() => ({
  container: {
    padding: '2rem 0'
  },
  categoryDropdown: {
    paddingLeft: '2rem',
    minWidth: '200px'
  }
}));

const GreenTypography = withStyles({
  root: {
    color: '#46C646'
  }
})(Typography);

const ReportTable = () => {
  const tableRef = useRef();
  const styles = useStyles();
  const history = useHistory();

  const tenantCd = useSelector((state) => state.user.currentTenantCd);
  const permissions = useSelector((state) => state.user.permissions);

  const [category, setCategory] = useState('Smart Reports');
  const [dataMap, setDataMap] = useState({});
  const [allReports, setAllReports] = useState(new Set());

  const categories = [
    { name: 'Smart Reports', value: 'Smart Reports' },
    { name: 'Custom Reports', value: 'Custom Reports' },
    { name: 'Explore Reports', value: 'Explore Reports' },
    { name: 'Legacy Reports', value: 'Legacy Reports' }
  ];

  const fetchAllReports = async () => {
    const reports = await axios.get(AppConfig.FO_INITIAL_URL + '/report/all');
    setAllReports(new Set(reports.data.map((rept) => rept.reptSource)));
    tableRef.current.onQueryChange();
  };

  const groupData = (data) => {
    const dataMap = {};
    Array.isArray(data) &&
      data.forEach((elm) => {
        const el = elm;
        el.inFo = allReports.has(el.reptSource);
        if (!dataMap[el.workbookName]) {
          dataMap[el.workbookName] = [el];
        } else {
          dataMap[el.workbookName].push(el);
        }
      });
    setDataMap(dataMap);
    return dataMap;
  };

  const handleChange = (e) => {
    const target = e.target.value || e.target.textContent;
    setCategory(target);
    tableRef.current.onQueryChange();
  };

  const debounceChangeHandler = useCallback(debounce(handleChange, 500), []);

  useEffect(() => {
    fetchAllReports();
  }, [tenantCd]);

  useEffect(() => {
    if (
      !hasPermission(AppConfig.PERMISSION.FO_REPORT_MANAGEMENT, permissions)
    ) {
      history.push('/fo');
    }
  }, [permissions]);

  useEffect(() => {
    store.dispatch(ReportActions.fetchReportTags());
  }, []);

  return (
    <>
      <Box className={styles.container}>
        <MaterialTable
          title="Reports"
          tableRef={tableRef}
          columns={[
            {
              title: 'Name',
              field: 'displayName',
              render: (rowData) => {
                {
                  return rowData.inFo ? (
                    <GreenTypography>{rowData.displayName}</GreenTypography>
                  ) : (
                    <Typography>{rowData.displayName}</Typography>
                  );
                }
              }
            },
            { title: 'Workbook Name', field: 'workbookName', hidden: true },
            { title: 'In FO', field: 'inFo', hidden: true }
          ]}
          data={(_query) =>
            new Promise((resolve, reject) => {
              axios
                .post(AppConfig.FO_INITIAL_URL + '/init/project-workbooks', {
                  project: category
                })
                .then((res) => {
                  const dataMap = groupData(res.data);
                  resolve({
                    data: Object.keys(dataMap).map((key) => {
                      return {
                        displayName: `${key} (${dataMap[key].length} views)`,
                        workbookName: key,
                        inFo: dataMap[key].some((el) => el.inFo)
                      };
                    })
                  });
                })
                .catch((err) => {
                  resolve({
                    data: []
                  });
                  if (!axios.isCancel(err)) {
                    store.dispatch({
                      type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                      payload: `Could not get workbook info: ${err}`
                    });
                  }
                });
            })
          }
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false
          }}
          onRowClick={(_e, _r, togglePanel) => togglePanel()}
          detailPanel={(detailPanelData) => {
            return (
              <>
                <Box m={2}>
                  <ManageReportAccordion
                    views={dataMap[detailPanelData.rowData.workbookName].sort((a, b) => {
                      return a.order && b.order ? a - b : 0;
                    })}
                    allReports={allReports}
                    onSubmit={() => fetchAllReports()}
                  />
                </Box>
              </>
            );
          }}
          components={{
            OverlayLoading: () => <div />,
            Toolbar: (props) => (
              <Box>
                <MTableToolbar {...props} />
                <FormControl size="medium">
                  <Autocomplete
                    className={styles.categoryDropdown}
                    id="input_manageReport"
                    key="input_manageReport"
                    freeSolo
                    options={categories.map((c) => c.name)}
                    value={category}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={debounceChangeHandler}
                        label="Folder Name"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )
          }}
        />
      </Box>
    </>
  );
};

export default ReportTable;
