import React from 'react';
import { TextField, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { store } from 'store/store';
import * as Actions from '../actions/PlanDesignDataAction';
import { confirmDialog } from 'common/ComfirmationDialog';

const VisionPlanNetwork = ({ item, handleEdit, handleExpend }) => {
  const edit = () => {
    if (handleEdit) handleEdit(item);
  };

  const deleteTarget = () => {
    if (item.id && item.id > 0) {
      confirmDialog(() => store.dispatch(Actions.deleteVisonNetwork(item.id)));
    }
  };

  return (
    <>
      <Grid container bgcolor="primary.main" spacing={2}>
        <Grid item xs={2}>
          <TextField
            key={'readonly-examCopayIn' + item.id}
            label="Exam Copay In"
            value={item.examCopayIn}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-materialsCopayIn' + item.id}
            label="Materials Copay In"
            value={item.materialsCopayIn}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-retinalScreeningDiabeticsIn' + item.id}
            label="Retinal Screening Diabetics In"
            value={item.retinalScreeningDiabeticsIn}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-framesAllowanceIn' + item.id}
            label="Frames Allowance In"
            value={item.framesAllowanceIn}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-electiveContactLenseAllowanceIn' + item.id}
            label="Elective Contact Lense Allowance In"
            value={item.electiveContactLenseAllowanceIn}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-electiveContactLenseFittingAllowanceIn' + item.id}
            label="Elective Contact Lense Fitting Allowance In"
            value={item.electiveContactLenseFittingAllowanceIn}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-necessaryContactLenseAllowanceIn' + item.id}
            label="Necessary Contact Lense Allowance In"
            value={item.necessaryContactLenseAllowanceIn}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-examReImbOut' + item.id}
            label="Exam ReImb Out"
            value={item.examReImbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-framesReImbOut' + item.id}
            label="Frames ReImb Out"
            value={item.framesReImbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-singleVisionLenseReImbOut' + item.id}
            label="Single Vision Lense ReImb Out"
            value={item.singleVisionLenseReImbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-biFocalLenseReimbOut' + item.id}
            label="Bi Focal Lense ReImb Out"
            value={item.biFocalLenseReimbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-triFocallenseReimbOut' + item.id}
            label="Tri Focal Lense ReImb Out"
            value={item.triFocallenseReimbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-lenticularLenseReimbOut' + item.id}
            label="Lenticular Lesnse ReImb Out"
            value={item.lenticularLenseReimbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-electiveContactReimbOut' + item.id}
            label="Elective Contact ReImb Outt"
            value={item.electiveContactReimbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-electiveContactFittingReimbOut' + item.id}
            label="Elective Contact Fitting ReImb Out"
            value={item.electiveContactFittingReimbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            key={'readonly-necessaryContactReimbOut' + item.id}
            label="Necessary Contact ReImb Out"
            value={item.necessaryContactReimbOut}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <IconButton size="small" onClick={edit}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={deleteTarget}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default VisionPlanNetwork;
