import { Box, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const APP_BUTTON_DEFAULT_SIZE = 50;
const APP_CONTAINER_DEFAULT_SIZE = 132;

export const AppButton = withStyles({
  root: {
    '& svg': {
      fontSize: APP_BUTTON_DEFAULT_SIZE
    }
  },

  label: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12
  }
})(IconButton);

export const AppContainer = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: APP_CONTAINER_DEFAULT_SIZE
  }
})(Box);
