import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import PlanExpenseCloneDetail from './PlanExpenseCloneDetail';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import SubmitButton from 'common/SingleClickButton';

const PlanExpenseCloneDetailDialog = (props) => {
  const { open, tenantId, planYears, onClose, onCancel, cloneError } = props;

  const methods = useForm({
    defaultValues: {
      tenantCode: tenantId,
      sourceYear: '',
      targetYear: ''
    },
    mode: 'onBlur'
  });

  const validate = async (data) => {
    if (!data.targetYear || !data.sourceYear) {
      methods.setError('targetYear', {
        type: 'api',
        message: 'Source and Target Year must be different.'
      });
      return;
    }
    return await axios.post(
      AppConfig.API_BASE_URL + 'v2/plan-expense/is-cloneable',
      data
    );
  };

  const onSubmit = async (data) => {
    const validationRes = await validate(data);
    if (validationRes.data) {
      switch (validationRes.data) {
        case 'PREEXISTING_PLAN_RATE':
          methods.setError('targetYear', {
            type: 'api',
            message: 'The target year already has an existing plan expense set.'
          });
          return;
        case 'INVALID_DATE_RANGE':
          methods.setError('targetYear', {
            type: 'api',
            message: 'The source and target year range are incompatible.'
          });
          return;
        case 'VALID_CLONE_TARGET':
          onClose(data);
          return;
        default:
          methods.setError('targetYear', {
            type: 'api',
            message: `An unknown error: ${validationRes.data}} occured. Check the data and try again.`
          });
          return;
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Dialog open={open}>
            <DialogContent dividers>
              <PlanExpenseCloneDetail
                planYears={planYears}
                cloneError={cloneError}
              />
            </DialogContent>
            <DialogActions>
              <SubmitButton
                handleSubmit={methods.handleSubmit(onSubmit)}
                isDirty={methods.formState.isDirty}
              />
              <Button onClick={() => onCancel()} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </FormProvider>
    </>
  );
};

export default PlanExpenseCloneDetailDialog;
