import LocalStore from 'utils/LocalStore';
import {
  APPSTATE_SET_IS_FETCHING_DATA_FROM_SERVER,
  APPSTATE_SET_CURRENT_APP,
  APPSTATE_SET_APP_ERROR,
  APP_REST_CALL_SUCESSED,
  UPDATE_USER_ID,
  OPEN_ALERT_DIALOG,
  SHOW_BST_BAR_TENANT
} from 'store/actions/appstate';

const INITIAL_STATE = {
  isFetchingData: false,
  currentApp: LocalStore.getCurrentApp() ? LocalStore.getCurrentApp() : null,
  appError: null,
  messageSeverity: 'error',
  userId: null,
  isFetchingQueue: 0,
  isOpenAlertDialog: false,
  showBstBarTenant:true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPSTATE_SET_IS_FETCHING_DATA_FROM_SERVER:
      if (action.payload === true) {
        return {
          ...state,
          isFetchingData: action.payload,
          isFetchingQueue: state.isFetchingQueue + 1
        };
      } else {
        return {
          ...state,
          isFetchingData: action.payload,
          isFetchingQueue: state.isFetchingQueue - 1
        };
      }
    case APPSTATE_SET_CURRENT_APP:
      return { ...state, currentApp: action.payload };
    case APPSTATE_SET_APP_ERROR:
      return {
        ...state,
        appError: action.payload,
        messageSeverity: 'error'
      };
    case APP_REST_CALL_SUCESSED:
      return {
        ...state,
        appError: action.payload,
        messageSeverity: 'success'
      };
    case UPDATE_USER_ID:
      return {
        ...state,
        userId: action.payload
      };
    case OPEN_ALERT_DIALOG:
      return {
        ...state,
        isOpenAlertDialog: action.payload
      };
    case  SHOW_BST_BAR_TENANT:
      return {
        ...state,
        showBstBarTenant: action.payload
      };
    default:
      return state;
  }
};
