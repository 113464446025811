import React from 'react';

import { Grid } from '@material-ui/core';
import Card from 'common/bstCard/bstCard';
import {v4 as uuid} from 'uuid';

const BstCarousel = ({ items, type }) => {
  return (
    <Grid container spacing={1}>
      {items &&
        items.map((im, k) => (
          <Grid item key={uuid()}>
            <Card item={im} type={type} key={k} />
          </Grid>
        ))}
    </Grid>
  );
};

export default BstCarousel;
