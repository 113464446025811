import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const save = (data) => {
  return (dispatch) => {
    axios
      .put(AppConfig.API_BASE_URL + `planexpenselookup/updatelist`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
        dispatch({
          type: AppActionTypes.APP_REST_CALL_SUCESSED,
          payload: 'Plan Expense Lookup Saved Successfully.'
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Failed to Save.'
        });
      });
  };
};

export const deleteById = (id) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `planexpenselookup?id=${id}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
        dispatch({
          type: AppActionTypes.APP_REST_CALL_SUCESSED,
          payload: 'Plan Expense Lookup Deleted Successfully.'
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Failed to Delete.'
        });
      });
  };
};

export const getAllPlanExpense = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + `planexpenselookup?type=ExpenseName`)
      .then((m) => {
        dispatch({
          type: Types.GET_ALL_PLAN_EXPENSE_SUCCESS,
          data: m.data ? m.data : []
        });
      })
      .catch((err) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Failed to Fetch Data.'
        });
      });
  };
};

export const getAllElectionTier = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + `planexpenselookup?type=ELECTION_TIER`)
      .then((m) => {
        dispatch({
          type: Types.GET_ALL_ELECTION_TIER_SUCCESS,
          data: m.data ? m.data : []
        });
      })
      .catch((err) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Failed to Save.'
        });
      });
  };
};
