import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import AnnualPlanDetail from './detail';
import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from 'common/SingleClickButton';

const AnnualPlanDetailDialog = (props) => {
  const { onClose, open, tenantId, target, isClone } = props;
  const [cloneEveryThing, setCloneEveryThing] = useState(false);
  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = () => {
    onClose(null, false);
  };

  const onSubmit = (data) => {
    onClose(data, cloneEveryThing);
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}>
        <DialogTitle id="customized-dialog-title">
          {target && target.id > 0
            ? 'Edit'
            : target && target.planSponsorId
            ? 'Clone'
            : 'New'}
        </DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <AnnualPlanDetail
              tenantId={tenantId}
              isModified={target && target.id > 0}
              targetId={target ? target.id : null}
              isClone={isClone}
              original={target}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          {isClone ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    size="medium"
                    onChange={(event) => {
                      setCloneEveryThing(event.target.checked);
                    }}
                  />
                }
                label="Clone Everything Related"
              />
              <div style={{ display: 'flex', flex: 1 }} />
            </>
          ) : null}
          <SubmitButton
            handleSubmit={methods.handleSubmit(onSubmit)}
            isDirty={methods.formState.isDirty}
          />
          <Button onClick={() => handleClose()} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnnualPlanDetailDialog;
