import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import PolicyExclusionList from './policyExclusionList';
import { useForm, FormProvider } from 'react-hook-form';

const PolicyExclusionListDialog = (props) => {
  const { onClose, open, tenantId, target, onCancel } = props;

  const methods = useForm({ defaultValues: target });
  const { reset } = methods;

  const handleClose = (data) => {
    if (onClose) onCancel(data);
  };

  const onSubmit = (data) => {
    if (onClose) onClose(data);
  };

  useEffect(() => {
    if (target) {
      if (target.policyExclusionDTOs) reset(target);
      else reset({ ...target, policyExclusionDTOs: [] });
    } else {
      reset({ policyExclusionDTOs: [] });
    }
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Policy Exclusion
          </DialogTitle>
          <DialogContent dividers>
            <PolicyExclusionList />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary">
              OK
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default PolicyExclusionListDialog;
