import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { store } from 'store/store';

import GrantTenantAdmin from './grantTenantAdmin';
import * as Actions from '../action/action';
import { confirmDialog } from '../../../../common/ComfirmationDialog';

const GrantTenantAdminDialog = ({ onClose, open, target }) => {
  const methods = useForm({
    defaultValues: {},
    mode: 'onSubmit'
  });
  const { reset } = methods;

  const handleClose = (data) => {
    if (data) {
      const requestBody = {
        userId: target.id,
        companyIds: data?.companyIds?.map((el) => el.id),
        brokerIds: data?.brokerIds?.map((el) => el.id),
        userGroupId: data?.userGroupId?.id,
        enigmaTenantAdmin: data?.enigmaTenantAdmin,
        planOptimizeTenantAdmin: data?.planOptimizeTenantAdmin
      };
      store.dispatch(Actions.saveTenantAdminPermissions(requestBody));
    }
    onClose();
  };

  const onSubmit = (data) => {
    handleClose(data);
  };

  const onDeletion = () => {
    confirmDialog(() => handleClose({}));
  };

  useEffect(() => {
    reset({ brokerIds: [], companyIds: [], ...target });
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={(_) => handleClose(null)} maxWidth="xs">
          <DialogTitle id="tenant-admin-grant-dialog">
            Manage Tenant Access
          </DialogTitle>
          <DialogContent dividers>
            <GrantTenantAdmin targetUser={target} />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              disabled={!methods.formState.isDirty}
              color="primary">
              Save
            </Button>
            <Button
              autoFocus
              onClick={() => onDeletion()}
              color="primary"
              disabled={!target?.tenantAdmin}>
              Delete
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default GrantTenantAdminDialog;
