import { Tab, Tabs } from '@material-ui/core';
import { AppConfig } from 'AppConfig';
import { fetchData } from 'features/user/userActions';
import * as UserTypes from 'features/user/userTypes';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import { store } from 'store/store';
import AnnualPlanRenewal from './components/AnnualPlanRenewal';

const ClientPortalNavigator = () => {
  const CLIENT_PORTAL_DASHBOARD_URL = RouteConfig.clientPortal.path;
  const CLIENT_PORTAL_ONBOARDING_URL = CLIENT_PORTAL_DASHBOARD_URL + AppConfig.CLIENT_PORTAL_SUB_URL.ONBOARDING;
  const CLIENT_PORTAL_ANNUALPLANRENEWAL_URL = CLIENT_PORTAL_DASHBOARD_URL + AppConfig.CLIENT_PORTAL_SUB_URL.ANNUAL_PLAN_RENEWAL;
  const CLIENT_PORTAL_USERAUDIT_URL = CLIENT_PORTAL_DASHBOARD_URL + AppConfig.CLIENT_PORTAL_SUB_URL.USER_AUDIT;
  const CLIENT_PORTAL_CONTACT_URL = CLIENT_PORTAL_DASHBOARD_URL + AppConfig.CLIENT_PORTAL_SUB_URL.CONTACT_SHEET;

  const history = useHistory();
  const { currentTenantCd } = useSelector((state) => state.user);

  useEffect(() => {
    const timeout = setTimeout(() => {
      store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_CLIENT_PORTAL));
      store.dispatch({
        type: UserTypes.APP_SWITCHED,
        payload: AppConfig.HEADER_MENU_TYPE.STANDARD
      });
    }, 1000);
    return () => clearTimeout(timeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Tabs value={history.location.pathname} indicatorColor="primary" textColor="primary">
        <Tab
          label={AppConfig.CLIENT_PORTAL_SUBCATEGORY.ANNUAL_PLAN_RENEWAL}
          value={CLIENT_PORTAL_ANNUALPLANRENEWAL_URL}
          component={Link}
          to={CLIENT_PORTAL_ANNUALPLANRENEWAL_URL}
        />
      </Tabs>
      {history.location.pathname === CLIENT_PORTAL_ONBOARDING_URL && <div />}
      {history.location.pathname === CLIENT_PORTAL_ANNUALPLANRENEWAL_URL && <AnnualPlanRenewal />}
      {history.location.pathname === CLIENT_PORTAL_USERAUDIT_URL && <div />}
      {history.location.pathname === CLIENT_PORTAL_CONTACT_URL && <div />}
    </>
  );
};

export default ClientPortalNavigator;
