import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const APPSTATE_SET_IS_FETCHING_DATA_FROM_SERVER =
  'appstatesetisfetchingdatafromserver';
export const APPSTATE_SET_CURRENT_APP = 'appstatesetcurrentapp';
export const APPSTATE_SET_APP_ERROR = 'SET_APP_ERROR';
export const APP_REST_CALL_SUCESSED = 'APP_REST_CALL_SUCESSED';
export const UPDATE_USER_ID = 'UPDATE_USER_ID';
export const OPEN_ALERT_DIALOG = 'OPEN_ALERT_DIALOG';
export const SHOW_BST_BAR_TENANT = 'SHOW_BST_BAR_TENANT';

export const setCurrentApp = (app) => ({
  type: APPSTATE_SET_CURRENT_APP,
  payload: app
});

export const setshowBstBarTenant = (value) => ({
  type: SHOW_BST_BAR_TENANT,
  payload: value
});

export const setAppError = (err) => ({
  type: APPSTATE_SET_APP_ERROR,
  payload: err
});

export const setUserId = (userId) => ({
  type: UPDATE_USER_ID,
  payload: userId
});

export const updateAccess = (profile, appCode) => {
  return (dispatch) => {
    axios.post(AppConfig.FO_INITIAL_URL + '/user/access', {
      profile,
      currentApp: appCode
    });
  };
};
