import React, { useEffect } from 'react';
import AppRoute from './routes/AppRoute';
import Spinner from 'common/Spinner';
import './assets/css/custom.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ConfirmDialog from 'common/ComfirmationDialog';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { AppConfig } from './AppConfig';
import ReactGA from 'react-ga4';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { setAppError } from 'store/actions/appstate';
import * as UserTypes from 'features/user/userTypes';
import axios from 'axios';
import { setAxiosHeaderToken } from 'networking/NetworkManger';
import LocalStore from 'utils/LocalStore';

ReactGA.initialize(AppConfig.GOOGLE_ANALYTICS_ID, {
  debug: true
});
ReactGA.send('pageview');

const App = () => {
  const isFetchingQueue = useSelector(
    (state) => state.appState.isFetchingQueue
  );

  const jwtExp = useSelector((state) => state.user.jwtExp);
  
  const messageSeverity = useSelector(
    (state) => state.appState.messageSeverity
  );
  const appError = useSelector((state) => state.appState.appError);

  const dispatch = useDispatch();

  const onCloseError = () => {
    dispatch(setAppError(null));
  };

  const isNonEmptyMessage = (obj) => {
    if (typeof obj === 'string' && obj.trim() !== '') {
      return true;
    }
    return obj && obj.message && obj.message.trim() !== '';
  };

  useEffect(() => {
    moment.tz.setDefault('utc');
  }, []);

  useEffect(() => {
    const timer = setInterval(
      () => {
        // todo: don't refresh JWT in the middle of signout
        // const signout = localStorage.getItem(SIGN_OUT);
        const signout = 'false';
        if (signout !== 'true' && jwtExp) {
          const leftTime = (jwtExp * 1000) - Date.now();
          
          if (leftTime < 0) {
            // redirect to the login page.
            dispatch({ type: UserTypes.LOGOUT });
          }else if (leftTime < 120000) {
            refreshToken(); 
          } 
        }
      },
      30000,
    );
    return () => clearInterval(timer);
  });


  const refreshToken = () => {
  
    console.log("refresh the token.");
    axios
        .get(AppConfig.FO_INITIAL_URL + AppConfig.REFRESH_TOKEN_URL)
        .then((res) => {
          if (res && res.data) {
            // refresh the token.
            const jwt = res.data; 
            //todo: share the code in the next three lines with ssoRedirect.jsx
            setAxiosHeaderToken(jwt);
            LocalStore.saveAccessToken(jwt);
            dispatch({
              type: UserTypes.JWT,
              payload: jwt
            });
            console.log("Refresh token succeessful.")
          } else{
            console.error("Empty result when refreshing token");
          }
        })
        .catch((err) => {
          console.error("Failed to refresh JWT token", err);
          // dispatch({ type: UserTypes.LOGOUT });
        });
  
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <>
          {isNonEmptyMessage(appError) && (
            <Snackbar
              open={true}
              onClose={onCloseError}
              severity={messageSeverity}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              autoHideDuration={15000}>
              <Alert severity={messageSeverity} onClose={onCloseError}>
                {appError}
              </Alert>
            </Snackbar>
          )}
          {<AppRoute />}
          {isFetchingQueue > 0 && (
            <>
              <Spinner />
            </>
          )}
        </>
      </MuiPickersUtilsProvider>
      <ConfirmDialog />
    </>
  );
};

export default App;
