import * as Types from '../actions/types';
import * as Actions from '../actions/actions';

let initialState = {
  isLoading: false,
  shouldRefresh: Actions.SHOULD_REFRESH_INIT_VALUE
};

export default function stopLossContractReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    default:
      return state;
  }
}
