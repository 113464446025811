import React, { useEffect, useState } from 'react';
import Carousel from 'common/bstCarousel';
import { AppConfig } from 'AppConfig';
import { useSelector } from 'react-redux';
import * as AppActionTypes from 'features/frontoffice/actions/actions';
import { store } from 'store/store';
import * as UserTypes from 'features/user/userTypes';
import {
  TextField,
  InputAdornment,
  Box,
  Grid,
  Typography,
  MenuList
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SideNav from './sideNav';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SideNavItem from './sideNavItem';
import _ from 'lodash';

const allText = 'All';
const publicText = 'Public';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  header: {
    justifyContent: 'center',
    textAlign: 'start',
    color: '#4051B5',
    marginBottom: 15
  },
  menu: {
    maxWidth: 200,
    textAlign: 'start',
    minWidth: 180
  }
}));

const SavedReport = () => {
  const classes = useStyles();
  const state = {
    screenWidth:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
  };

  const adHocPersonal = useSelector((state) => state.fo.adHocPersonal);
  const adHocPublic = useSelector((state) => state.fo.adHocPublic);
  const filteredAdHocPersonal = useSelector(
    (state) => state.fo.filteredAdHocPersonal
  );
  const filteredAdHocPublic = useSelector(
    (state) => state.fo.filteredAdHocPublic
  );
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  const carouselStyle = { zIndex: 1000 };
  const [data, setData] = useState(filteredAdHocPublic);
  const [folderKey, setFolderKey] = useState(allText);
  const [isAscFlagPerson, setFlagPerson] = useState(true);
  const [isAscFlagPublic, setFlagPublic] = useState(true);

  const buildFolders = (arr = []) => {
    let myMap = new Map();
    for (let i = 0; i < arr.length; i += 1) {
      let node = arr[i];
      if (node?.reportTag) {
        if (myMap.has(node?.reportTag)) {
          myMap.get(node?.reportTag).push(node);
        } else {
          myMap.set(node?.reportTag, new Array(node));
        }
      }
    }
    return new Map([...myMap.entries()].sort());
  };

  const [folders, updateFolders] = useState(buildFolders(filteredAdHocPublic));

  const changeFlagPerson = () => {
    const newFlag = !isAscFlagPerson;
    setFlagPerson(newFlag);
  };

  const changeFlagPublic = () => {
    const newFlag = !isAscFlagPublic;
    setFlagPublic(newFlag);
  };

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.STANDARD
    });

    if (filteredAdHocPublic) {
      updateFolders(buildFolders(filteredAdHocPublic));
    }
    if (folders.size === 1) {
      setFolderKey(publicText);
    } else {
      setFolderKey(allText);
    }

    let newSortedAll = _.orderBy(
      filteredAdHocPublic,
      ['displayText'],
      [isAscFlagPublic ? 'asc' : 'desc']
    );
    setData(newSortedAll);
  }, []);

  useEffect(() => {
    store.dispatch(AppActionTypes.getSaved());
  }, [currentTenantCd]);

  useEffect(() => {
    if (filteredAdHocPublic) {
      let myFolders = buildFolders(filteredAdHocPublic);
      updateFolders(myFolders);
      if (folderKey === allText) {
        let newSortedAll = _.orderBy(
          filteredAdHocPublic,
          ['displayText'],
          [isAscFlagPublic ? 'asc' : 'desc']
        );
        setData(newSortedAll);
      }
    }
  }, [filteredAdHocPublic]);

  useEffect(() => {
    if (folders?.size === 1) {
      setFolderKey(allText);
    }
  }, [folders]);

  useEffect(() => {
    setData(adHocPublic);
  }, [adHocPublic]);

  return (
    <>
      {Array.isArray(adHocPersonal) && adHocPersonal.length > 0 && (
        <>
          <div className="carousel-title">Ad Hoc Personal</div>
          <div className="has-carousel frequently-run-carousel">
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end">
              <IconButton
                size="small"
                onClick={() => {
                  store.dispatch(
                    AppActionTypes.changeOrder(
                      false,
                      isAscFlagPerson,
                      filteredAdHocPersonal
                    )
                  );
                  changeFlagPerson();
                }}>
                {isAscFlagPerson ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              </IconButton>
              <TextField
                onChange={(event) => {
                  store.dispatch(
                    AppActionTypes.filterList(
                      event.target.value,
                      false,
                      adHocPersonal,
                      isAscFlagPerson
                    )
                  );
                }}
                placeholder="Search for report"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color='primary'/>
                    </InputAdornment>
                  )
                }}></TextField>
            </Box>
            <Grid container>
              {folders?.size > 1 && (
                <Grid item xs={2}>
                  <MenuList open className={classes.menu}>
                    <SideNavItem
                      key={'side-nav-personal'}
                      item={[{ reportTag: 'Personal' }]}
                      filter={() => { }}
                      total={filteredAdHocPersonal.length ?? ''}
                    />
                  </MenuList>
                </Grid>
              )}
              <Grid item xs={folders?.size > 1 ? 10 : 12} style={carouselStyle}>
                {folders?.size > 1 && (
                  <Typography className={classes.header} variant="h6">
                    {'Personal'}
                  </Typography>
                )}
                <Carousel
                  type={AppConfig.AD_HOC_CAROUSEL}
                  items={filteredAdHocPersonal}
                  viewWidth={state.screenWidth}
                  cardWidth={AppConfig.HOME_CUSTOM_CARD_WIDTH}
                  cardHeight={AppConfig.HOME_CUSTOM_CARD_HEIGHT}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
      {Array.isArray(adHocPublic) && (
        <>
          <div className="carousel-title">Ad Hoc Public</div>
          <div className="has-carousel frequently-run-carousel">
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end">
              <IconButton
                size="small"
                onClick={() => {
                  changeFlagPublic();
                  store.dispatch(
                    AppActionTypes.changeOrder(
                      true,
                      isAscFlagPublic,
                      folders.size === 1 ? data : null,
                      folders,
                      folderKey
                    )
                  );
                }}>
                {isAscFlagPublic ? <ArrowDownwardIcon  color='primary'/> : <ArrowUpwardIcon  color='primary'/>}
              </IconButton>
              <TextField
                onChange={(event) => {
                  if (event.target?.value) {
                    store.dispatch(
                      AppActionTypes.filterList(
                        event.target.value,
                        true,
                        folderKey === allText ? adHocPublic : null,
                        isAscFlagPublic,
                        folders,
                        folderKey
                      )
                    );
                  } else {
                    if (folderKey === allText) {
                      let myData = _.orderBy(
                        [...folders.values()].flatMap((s) => s),
                        ['displayText'],
                        [isAscFlagPublic ? 'asc' : 'desc']
                      );
                      setData(myData);
                    } else {
                      let myFolders = buildFolders(
                        folders.size === 1 ? data : adHocPublic
                      );
                      let sortedAtFolder = _.orderBy(
                        myFolders.get(folderKey),
                        ['displayText'],
                        [isAscFlagPublic ? 'asc' : 'desc']
                      );
                      myFolders.set(folderKey, sortedAtFolder);
                      updateFolders(myFolders);
                    }
                  }
                }}
                placeholder="Search for report"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color='primary'/>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Grid container>
              {folders?.size > 1 && (
                <Grid item xs={2}>
                  <SideNav
                    key={'saved-report-nav-' + folderKey}
                    allLength={Array.isArray(data) ? data.length : 0}
                    folders={folders}
                    setData={setData}
                    setFolderKey={setFolderKey}
                  />
                </Grid>
              )}

              <Grid item xs={folders?.size > 1 ? 10 : 12} style={carouselStyle}>
                {folders?.size > 0 && (
                  <>
                    {folders?.size > 1 && (
                      <Typography className={classes.header} variant="h6">
                        {folderKey}
                      </Typography>
                    )}
                    <Carousel
                      key={'saved-report-carousel-' + folderKey}
                      type={AppConfig.AD_HOC_CAROUSEL}
                      items={
                        folders.size === 1 || folderKey === allText
                          ? data
                          : folders.get(folderKey)
                      }
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default SavedReport;