import React from 'react';
import './Spinner.css';
import PropTypes from 'prop-types';
const Spinner = (props) => {
  return (
    <div className="loading">
      <div className="spinner-container">
        <div className="spinner-border fast text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  );
};
Spinner.propTypes = {
  message: PropTypes.string
};
export default Spinner;
