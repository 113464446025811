import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import BulkModificationDetail from './bulkModificationDetail';
import { useEffect, useState } from 'react';
import BulkModificationResponseModal from './bulkModificationResponseModal';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Types from '../actions/types';
import { store } from 'store/store';

const BulkModificationDialog = ({ onClose, open }) => {
  const [bulkModificationTableOpen, setBulkModificationTableOpen] =
    useState(false);
  const [query, updateQuery] = useState({
    search: null,
    page: null,
    size: 1000
  });
  const handleBulkModificationSubmit = (data) => {
    if (data) {
      const value = {
        ...query,
        ...data
      };
      updateQuery(value);
      setBulkModificationTableOpen(true);
    }
  };

  const defaultValues = {
    ahpmId: '',
    planName: '',
    startDateDayChange: 0,
    startDateMonthChange: 0,
    endDateDayChange: 0,
    endDateMonthChange: 0
  };

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues
  });

  const { reset, getValues } = methods;

  const handleClose = () => {
    reset(defaultValues);
    setBulkModificationTableOpen(false);
    onClose();
  };

  const onSubmit = (data) => {
    const newData = { ...data };
    reset(defaultValues);
    setBulkModificationTableOpen(false);
    onClose(newData);
    store.dispatch({ type: Types.SEARCH_REFRESH });
  };

  const handleSubmit = async (data) => {
    confirmDialog(
      async () => await onSubmit(data),
      'The listed plan rates highlighted red will NOT be modified, are you sure you wish to proceed?'
    );
  };

  useEffect(() => {
    reset(defaultValues);
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}>
          <DialogTitle id="plan-rate-bulk-creation">
            Plan Rate Bulk Modification
          </DialogTitle>
          <DialogContent dividers>
            <BulkModificationDetail />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(handleBulkModificationSubmit)}
              color="primary"
              disabled={
                !methods.formState.isDirty &&
                getValues('ahpmId') !== defaultValues.ahpmId
              }>
              Submit
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <BulkModificationResponseModal
          open={bulkModificationTableOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
          query={query}
        />
      </FormProvider>
    </>
  );
};

export default BulkModificationDialog;
