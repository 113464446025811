import React, { useEffect, useState } from 'react';
import {
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions
} from '@material-ui/core';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { GLOBAL_REPORT_MODES } from 'common/Constant';
import { confirmDialog } from 'common/ComfirmationDialog';

const ReportBulkPull = (props) => {
  const { onClose, open, target } = props;

  const methods = useForm({
    defaultValues: target,
    mode: 'onSubmit'
  });

  const globalConfig = useSelector((state) => state.fo.gconfig);
  const { reset, setValue, getValues, watch, control } = methods;
  const watchTenant = watch('companies');
  const watchBroker = watch('broker');

  const ALL_VALUE = 'All';

  const brokerList = useSelector((state) => state.user.allBrokers);

  const [brokerOptions, setBrokerOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedBrokers, setSelectedBrokers] = useState([]);

  const tagRender = (value, getTagProps) => {
    const numTags = value.length;
    const limitTags = 8;
    return (
      <>
        {value.slice(0, limitTags).map((option, index) => (
          <Chip {...getTagProps({ index })} key={index} label={option.name} />
        ))}
        {numTags > limitTags && ` +${numTags - limitTags}`}
      </>
    );
  };

  const filter = createFilterOptions();
  const optionFilter = (options, params) => {
    let filtered = filter(options, params);
    if (filtered.length > 1)
      return [{ id: 0, code: ALL_VALUE, name: ALL_VALUE }, ...filtered];
    else return [...filtered];
  };

  const onRemoveBroker = (brokersToRemove) => {
    const currentSelectedTenenats = getValues('companies');
    brokersToRemove.forEach((r) => {
      companyOptions
        .filter((cmp) => cmp.brokerId === r.id)
        .forEach((e) => {
          const itemToRemove = currentSelectedTenenats.findIndex(
            (item) => item.code === e.code
          );

          if (itemToRemove !== -1)
            currentSelectedTenenats.splice(itemToRemove, 1);
        });
    });
    setValue('companies', currentSelectedTenenats);
  };

  const onChangeBroker = (reason) => {
    const brokers = getValues('broker');
    const currentSelectedBroker =
      reason === 'clear'
        ? brokerOptions.filter((b) => b.name !== ALL_VALUE)
        : selectedBrokers;

    if (Array.isArray(brokers) && !isAllSelected(brokers)) {
      const brokersToAdd = brokers.filter(
        (b) => !currentSelectedBroker.some((item) => item.code === b.code)
      );
      const brokersToRemove = currentSelectedBroker.filter(
        (s) => !brokers.some((item) => item.code === s.code)
      );
      const currentSelectedTenenats = getValues('companies');

      if (brokersToAdd && Array.isArray(brokersToAdd) && brokersToAdd.length) {
        brokersToAdd.forEach((broker) => {
          companyOptions
            .filter((cmp) => cmp.brokerId === broker.id)
            .forEach((e) => {
              if (
                currentSelectedTenenats.some((item) => item.code === e.code)
              ) {
                // tenant is already selected
                return;
              } else currentSelectedTenenats.push(e);
            });
        });
        setValue('companies', currentSelectedTenenats);
      }

      if (
        brokersToRemove &&
        Array.isArray(brokersToRemove) &&
        brokersToRemove.length
      ) {
        confirmDialog(
          () => onRemoveBroker(brokersToRemove),
          'Do you want to remove all the companies under the broker in this application?'
        );
      }
    }
  };

  const handleClose = () => {
    onClose(null);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  const isAllSelected = (options) => {
    return Boolean(options.find((o) => o.name === ALL_VALUE));
  };

  useEffect(() => {
    reset(
      target
        ? target
        : {
            companies: [],
            mode: GLOBAL_REPORT_MODES.OVERWRITE_BY_DISPLAY_TEXT,
            broker: []
          }
    );
    setSelectedBrokers([]);
  }, [open, target]);

  useEffect(() => {
    if (Array.isArray(watchTenant) && isAllSelected(watchTenant)) {
      setValue(
        'companies',
        companyOptions.filter((c) => c.name !== ALL_VALUE)
      );
    }
  }, [watchTenant]);

  useEffect(() => {
    if (Array.isArray(watchBroker) && isAllSelected(watchBroker)) {
      setValue(
        'broker',
        brokerOptions.filter((b) => b.code !== ALL_VALUE)
      );
      onChangeBroker();
    }
  }, [watchBroker]);

  useEffect(() => {
    if (globalConfig && Array.isArray(globalConfig.allCompanies)) {
      setCompanyOptions(globalConfig.allCompanies);
    }

    if (brokerList && Array.isArray(brokerList)) {
      setBrokerOptions(
        brokerList.sort((a, b) =>
          a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 0
        )
      );
    }
  }, [brokerList, globalConfig]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
          <DialogTitle id="dialog-planDesignDataDetail">
            {target && target.id > 0
              ? 'Edit'
              : 'Select Tableau server sites to pull all reports'}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} bgcolor="primary.main">
              <Grid item xs={12}>
                <Controller
                  name="broker"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      multiple
                      id="broker"
                      value={watchBroker}
                      options={brokerOptions}
                      getOptionLabel={(option) =>
                        option.name ? option.name : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      filterOptions={optionFilter}
                      onChange={(_, data, reason) => {
                        field.onChange(data);
                        onChangeBroker(reason);
                        setSelectedBrokers(
                          data.filter((b) => b.name !== ALL_VALUE)
                        );
                      }}
                      filterSelectedOptions
                      defaultValue={getValues('broker')}
                      renderTags={tagRender}
                      renderInput={(field) => (
                        <TextField
                          {...field}
                          id={'input-broker'}
                          label="broker"
                          placeholder="Chosen Broker"
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      fullWidth
                      id="tenants"
                      options={companyOptions}
                      getOptionLabel={(option) =>
                        option.name ? option.name : ''
                      }
                      value={watchTenant}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      filterOptions={optionFilter}
                      onChange={(_, data) => field.onChange(data)}
                      filterSelectedOptions
                      defaultValue={getValues('companies')}
                      renderTags={tagRender}
                      renderInput={(field) => (
                        <TextField
                          {...field}
                          id={'input-tenant'}
                          label="Companies"
                          placeholder="Chosen Companies"
                        />
                      )}
                    />
                  )}
                  name="companies"
                  control={control}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={GLOBAL_REPORT_MODES.OVERWRITE_BY_DISPLAY_TEXT}
                          control={<Radio color="primary" />}
                          label="Overwrite Reports by Display Text"
                        />
                        <FormControlLabel
                          value={GLOBAL_REPORT_MODES.OVERWRITE_BY_URL}
                          control={<Radio color="primary" />}
                          label="Overwrite Reports by Report URL"
                        />
                        <FormControlLabel
                          value={GLOBAL_REPORT_MODES.NEW_REPORT}
                          control={<Radio color="primary" />}
                          label="Add Reports as New"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  name="mode"
                  control={control}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    onClick={methods.handleSubmit(onSubmit)}
                    color="primary"
                    disabled={watchTenant?.length === 0}>
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default ReportBulkPull;
