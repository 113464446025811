import React from 'react';
import { makeStyles,TextField, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ContactInfo from './contactInfo';

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: '25px', 
    },
    width: '150px'
  },
  label: { 
    fontWeight: "500",
    marginLeft: theme.spacing(1.2),
    marginRight: theme.spacing(0.6),
    marginTop: theme.spacing(1),
    alignSelf: 'center'
  },
  leftFill: {marginLeft: '18px'}
}));

const MedicalChart = ({ prefix }) => {
  const styles = useStyles();
  const { control } = useFormContext();

  return (
    <>
    <div>
      <Typography color="primary">
        <strong style={{ fontSize: '1.15em' }}>{prefix.charAt(0).toUpperCase() + prefix.slice(1)} Feed:</strong>
      </Typography>
      <div className={styles.leftFill}>
        <label htmlFor={`${prefix}CarrierName`} className={styles.label}>
          Carrier Name&nbsp;&nbsp;
        </label>
        <Controller
          name={`${prefix}CarrierName`}
          control={control}
          defaultValue=""
          rules={{
            required: true
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="text"
              className={styles.inputField}
              variant="outlined"
              id={`${prefix}CarrierName`}
              error={!!fieldState.error}
              helperText={fieldState.error ? 'Carrier Name is required' : ''}
              maxLength="500"
              size="25"
            />
          )}
        />
      </div>
      <ContactInfo prefix={`${prefix}`} />
    </div>
  </>
  );
};

export default MedicalChart;