import React, { useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import * as Constant from 'common/Constant';

const AddPoCDialogDetail = ({}) => {
  const { control, getValues, setValue } = useFormContext();

  const INPUT_MAX_LENGTH = 50;

  const validateInputLength = (value) => {
    if (value?.length > INPUT_MAX_LENGTH) {
      return `Input cannot exceed ${INPUT_MAX_LENGTH} characters`;
    }
  };

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  return validateInputLength(value);
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField id="input-firstName" required fullWidth {...field} error={error} helperText={error ? error.message : null} label="First Name" />
            )}
            name="firstName"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  return validateInputLength(value);
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField id="input-lastName" required fullWidth {...field} error={error} helperText={error ? error.message : null} label="Last Name" />
            )}
            name="lastName"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  var email = value;
                  const regEx = /^\S+@\S+\.\S+$/;
                  if (!regEx.test(email)) {
                    return 'Invalid email address';
                  }
                },
                rule2: (value) => {
                  return validateInputLength(value);
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField id="input-emailAddress" required fullWidth {...field} error={error} helperText={error ? error.message : null} label="Email Address" />
            )}
            name="email"
            control={control}
          />
        </Grid>
        <Grid style={{ marginBottom: '10px' }} xs={12}>
          <Alert severity="warning">
            The adding point of contact for current tenant request will be sent to <a href="mailto: insights-access@multiplan.com"> Insights Access </a> by email. If no existing
            user matches the information provided, a new user will be created.
          </Alert>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPoCDialogDetail;
