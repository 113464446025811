import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import PlanSponsor from './PlanSponsor';
import TPA from './TPA/tpa.js';
import PBM from './pbm/PBM';
import StopLossCarrier from './stopLossCarrier/StopLossCarrier';
import StopLossMGU from './stopLossMGU/stopLossMGU';

class MastersTableComponent extends Component {
  render = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Plan Sponsor"></CardHeader>
              <CardContent>
                <PlanSponsor tenantId={this.props.site} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <TPA tenantId={this.props.site} />
          </Grid>
          <Grid item xs={3}>
            <PBM tenantId={this.props.site} />
          </Grid>
          <Grid item xs={3}>
            <StopLossCarrier tenantId={this.props.site} />
          </Grid>
          <Grid item xs={3}>
            <StopLossMGU tenantId={this.props.site} />
          </Grid>
        </Grid>
      </>
    );
  };
}

const mapStateToPropsSelector = createStructuredSelector({
  site: (state) => state.user.currentTenantCd
});
export const MastersTable = connect(mapStateToPropsSelector)(
  MastersTableComponent
);
