import { MDBContainer, MDBIframe } from 'mdbreact';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

class IframeComponent extends PureComponent {
  render = () => {
    const [, link] = this.props.location.search.split('?');

    return (
      <MDBContainer fluid>
        <MDBIframe src={link} />
      </MDBContainer>
    );
  };
}

export default withRouter(IframeComponent);
