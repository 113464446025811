import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Grid, Switch, FormControlLabel, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Constant from 'common/Constant';
import { store } from 'store/store';
import * as MDActions from '../../../common/actions/CommonActions';
import { Controller, useFormContext } from 'react-hook-form';
import * as ValidationUtil from 'utils/ValidationUtil';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as CommonUtil from 'features/masterData/util/CommonUtil';
import moment from 'moment-timezone';

const StopLossContractModification = (props) => {
  const DUPLICATION_TIME_RANGE_ERROR_MSG =
    'Existing records found in same time range';

  const domesticReImbursementOptions = [];
  for (let i = 0; i <= 100; i += 5) {
    let obj = {
      label: i,
      id: i
    };
    domesticReImbursementOptions.push(obj);
  }

  const { tenantId, hasAsl } = props;

  const { control, watch, setValue, getValues } = useFormContext();

  const watchPlanEndDate = watch('endDate', null);
  const watchPlanStartDate = watch('startDate', null);

  const [selectedYear, setSelectedYear] = useState('');

  const getTimeRangeValidationObject = (startDate, endDate) => {
    return {
      annualYearId: getValues('apdId'),
      startDate: startDate,
      endDate: endDate
    };
  };

  const calculateContractBasis = (start_date, end_date, contract_basis) => {
    const map = CommonUtil.CALCULATE_CONTRACT_BASIS_DATE_PERIOD(
      start_date,
      end_date,
      contract_basis
    );

    setValue(
      'runInStartDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_IN_START_DATE]
    );
    setValue('runInEndDate', map[CommonUtil.CONTRACT_BASIS_RUN_IN_END_DATE]);
    setValue(
      'runOutStartDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_OUT_START_DATE]
    );
    setValue('runOutEndDate', map[CommonUtil.CONTRACT_BASIS_RUN_OUT_END_DATE]);
  };

  const calculateContractBasisAsl = (start_date, end_date, contract_basis) => {
    const map = CommonUtil.CALCULATE_CONTRACT_BASIS_DATE_PERIOD(
      start_date,
      end_date,
      contract_basis
    );

    setValue(
      'aslStartDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_IN_START_DATE] ||
        map[CommonUtil.CONTRACT_BASIS_RUN_OUT_START_DATE]
    );
    setValue(
      'aslEndDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_IN_END_DATE] ||
        map[CommonUtil.CONTRACT_BASIS_RUN_OUT_END_DATE]
    );
  };

  const timeRangeValidation = async (startDate, endDate) => {
    try {
      const response = await axios.post(
        AppConfig.API_BASE_URL + `v2/stop-loss-contract/plan-year/time-range`,
        getTimeRangeValidationObject(startDate, endDate)
      );

      let error = null;

      if (
        response &&
        response.data &&
        Array.isArray(response.data) &&
        response.data.length > 0
      ) {
        if (
          response.data.length > 1 ||
          response.data[0].id !== getValues('id')
        ) {
          error = DUPLICATION_TIME_RANGE_ERROR_MSG;
        }
      }
      return error;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanYears(tenantId));
  }, [tenantId]);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-planYearName"
                fullWidth
                {...field}
                disabled
                error={error}
                helperText={error ? error.message : null}
                label="Plan Year"
              />
            )}
            name="planYearName"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-stopLossYear"
                fullWidth
                {...field}
                required
                error={error}
                helperText={error ? error.message : null}
                label="Stop Loss Year"
              />
            )}
            name="stopLossYear"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: (value) => {
                if (value) {
                  return ValidationUtil.VALIDATE_DATE(value);
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  maxDate={watchPlanEndDate ? watchPlanEndDate : undefined}
                  error={error}
                  onChange={(value) => {
                    field.onChange(value);
                    calculateContractBasis(value, getValues('endDate'), getValues('islContractBasis'));
                  }}
                  helperText={error ? error.message : null}
                  format={Constant.FORMAT_DATE}
                  value={getValues('startDate') ? moment.utc(getValues('startDate')) : null}
                  id="input-startDate"
                  label="Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="startDate"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: (value) => {
                if (value) {
                  return ValidationUtil.VALIDATE_DATE(value);
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  minDate={watchPlanStartDate ? watchPlanStartDate : undefined}
                  onChange={(value) => {
                    field.onChange(value);
                    calculateContractBasis(getValues('startDate'), value, getValues('islContractBasis'));
                  }}
                  format={Constant.FORMAT_DATE}
                  value={getValues('endDate') ? moment.utc(getValues('endDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-endDate"
                  label="End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="endDate"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-islDeductible"
                label="ISL Deductible"
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="islDeductible"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{ required: Constant.ERROR_MSG_REQUIRED_FIELD }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-islContractBasis"
                label="ISL Contract Basis"
                fullWidth
                {...field}
                required
                onChange={(e) => {
                  field.onChange(e.target.value);
                  calculateContractBasis(
                    getValues('startDate'),
                    getValues('endDate'),
                    e.target.value
                  );
                }}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="islContractBasis"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="input-aslContractBasis"
                label="ASL Contract Basis"
                fullWidth
                onBlur={(e) => {
                  field.onBlur(e.target.value);
                  calculateContractBasisAsl(
                    getValues('startDate'),
                    getValues('endDate'),
                    e.target.value
                  );
                }}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="aslContractBasis"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-aggSpecDeductible"
                label="Agg Spec Deductible"
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="aggSpecDeductible"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{
              required:
                hasAsl === true ? Constant.ERROR_MSG_REQUIRED_FIELD : null,
              validate: {
                numberValidation: (value) => {
                  if (
                    value &&
                    value !== '' &&
                    !ValidationUtil.IS_NUMBER(value)
                  ) {
                    return 'Input must be a number';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                required={hasAsl}
                fullWidth
                {...field}
                id="input-corridor"
                label="Corridor in % (enter as integer, e.g. 120)"
                defaultValue=""
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="corridor"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                control={<Switch />}
                label="Rx Covered"
              />
            )}
            name="rxCovered"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                control={<Switch />}
                label="Med Covered"
              />
            )}
            name="medCovered"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <InputLabel id="label-domesticReImbursement">
                  Domestic Reimbursement
                </InputLabel>
                <Select
                  fullWidth
                  {...field}
                  labelId="label-domesticReImbursement"
                  id="input-domesticReImbursement">
                  {domesticReImbursementOptions &&
                    domesticReImbursementOptions.map((item) => {
                      return <MenuItem value={item.id}>{item.label}</MenuItem>;
                    })}
                </Select>
                <FormHelperText>{error ? error.message : null}</FormHelperText>
              </FormControl>
            )}
            name="domesticReImbursement"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('runInStartDate') ? moment.utc(getValues('runInStartDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-runInStartDate"
                  label="Run In Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="runInStartDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('runInEndDate') ? moment.utc(getValues('runInEndDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-runInEndDate"
                  label="Run In End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="runInEndDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('runOutStartDate') ? moment.utc(getValues('runOutStartDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-runOutStartDate"
                  label="Run Out Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="runOutStartDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('runOutEndDate') ? moment.utc(getValues('runOutEndDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-runOutEndDate"
                  label="Run Out End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="runOutEndDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('aslStartDate') ? moment.utc(getValues('aslStartDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-aslStartDate"
                  label="ASL Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="aslStartDate"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('aslEndDate') ? moment.utc(getValues('aslEndDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-aslEndDate"
                  label="ASL End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="aslEndDate"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StopLossContractModification;
