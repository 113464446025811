import {
  Box,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReportNavItem from './reportNavItem';
import { store } from 'store/store';
import * as Constant from 'common/Constant';
import * as Action from './actions/action';
import * as Types from './actions/types';
import { AppConfig } from 'AppConfig';

const BACKGROUND_COLOR = '#4051B5';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  sideNav: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    '&:hover .menuItem': {
      color: BACKGROUND_COLOR
    },
    '&:hover .icon': {
      color: BACKGROUND_COLOR
    },
    '&:hover .typography': {
      color: BACKGROUND_COLOR
    },
    maxWidth: AppConfig.STANDARD_REPORT_SIDE_NAV_WIDTH
  },
  menu: {
    textAlign: 'start',
    maxWidth: AppConfig.STANDARD_REPORT_SIDE_NAV_WIDTH
  },
  header: {
    justifyContent: 'center',
    textAlign: 'start',
    color: BACKGROUND_COLOR
  },
  menuItem: {
    justifyContent: 'start',
    alignContent: 'start',
    alignItems: 'start',
    margin: 0,
  },
  icon: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    paddingTop: 3
  },
  count: {
    justifyContent: 'end',
    alignText: 'right'
  },
  listItemText: {
    whiteSpace: 'nowrap',
    maxWidth: AppConfig.STANDARD_REPORT_MENU_ITEM_TEXT_WIDTH,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  menuItemsWrapper: {
    aligntItems: 'center'
  }
}));

const ReportNav = ({ searchKey }) => {
  const classes = useStyles();
  const customs = useSelector((state) => state.fo.customs); // report representation in frontoffice
  const workbooks = useSelector((state) => state.customReport.workbooks); // report representation in tb server
  const currentMenu = useSelector((state) => state.customReport.currentMenu); // current list item {name: str, key: str}
  const projects = useSelector((state) => state.customReport.projects); // project representation in tb server

  useEffect(() => {
    filter(Constant.ALL);
  }, [customs]);

  useEffect(() => {
    filter(currentMenu?.key);
  }, [searchKey]);

  useEffect(() => {
    filter(currentMenu?.key);
  }, [currentMenu]);

  const searchFilter = (value) => {
    if (Array.isArray(value)) {
      const newData = _.filter(value, function (o) {
        return o.displayText
          ? o.displayText.toLowerCase().includes(searchKey?.toLowerCase())
          : false;
      });
      store.dispatch({ type: Types.SET_DATA, data: newData });
    }
  };

  // value: id of subdirectory of 'Custom Reports'
  const filter = (value) => {
    if (value === Constant.ALL && customs.length > 0) {
      let allData = _.uniqBy(customs, 'reptSource');
      allData = _.orderBy(allData, ['displayText'], ['asc']);
      searchFilter(allData);
    } else if (value) {
      // subset of workbooks w/ workbook project id == input id
      const filteredWorkbooks = workbooks.filter((wb) => wb.projectId == value);
      let newData = _.filter(customs, function (report) {
        // if tableau workbook and custom report have equal reptSource
        return filteredWorkbooks.some((wb) => wb.reptSource == report.reptSource);
      });
      newData = _.uniqBy(newData, 'reptSource');
      newData = _.orderBy(newData, ['displayText'], ['asc']);
      searchFilter(newData);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.header} variant="h6">
          Filter By
        </Typography>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <MenuList open className={classes.menu}>
          <Box className={classes.sideNav}>
            <MenuItem
              disableGutters={true}
              className={classes.menuItem}
              key={'report-nav-item-all'}
              onClick={() =>
                store.dispatch(Action.setCurrentMenuItem({ name: Constant.ALL, key: Constant.ALL }))
              }
              selected={currentMenu?.name === Constant.ALL}>
              <Grid container spacing={2} className={classes.menuItemsWrapper}>
                <Grid item xs={2} className={classes.icon}>{/* placeholder for future icon enhancement */}</Grid>
                <Grid item xs={8} className={classes.listItemText}>
                  <ListItemText>{Constant.ALL}</ListItemText>
                </Grid>
                <Grid item xs={2} className={classes.count}>
                  <ListItemText>
                    {Array.isArray(customs) && _.uniqBy(customs, 'reptSource').length}
                  </ListItemText>
                </Grid>
              </Grid>
            </MenuItem>
          </Box>
          {Array.isArray(projects) &&
            projects.map((item, index) => {
              return (
                <ReportNavItem item={item} index={index} filter={filter}/>
              );
            })}
        </MenuList>
      </Grid>
    </Grid>
  );
};
export default ReportNav;
