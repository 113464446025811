import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  TextField,
  FormControl,
  Chip,
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { store } from 'store/store';
import * as Action from '../action/action';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as Constant from 'common/Constant';

const useStyles = makeStyles(() => ({
  autocomplete: {
    minWidth: '400px',
    marginBottom: '1rem'
  }
}));

export const GrantTenantAdmin = ({ targetUser }) => {
  const styles = useStyles();
  const tenantUserSelectedBrokerIds = useSelector(
    (state) => state.adminUserAccess.tenantUserSelectedBrokerIds
  );
  const tenantUserSelectedCompanyIds = useSelector(
    (state) => state.adminUserAccess.tenantUserSelectedCompanyIds
  );
  const tenantAdminUserGroupId = useSelector(
    (state) => state.adminUserAccess.tenantAdminUserGroupId
  );

  const { control, watch, setValue, getValues } = useFormContext();

  const [brokerOptions, setBrokerOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const watchBrokers = watch('brokerIds');
  const watchCompanies = watch('companyIds');

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.displayText}`
  });

  const searchUserPerms = () => {
    store.dispatch(Action.fetchTenantUserPermission(targetUser.id));
  };

  const getCompanyBrokerData = async () => {
    setIsLoading(true);
    const [brokerList, companyList, userGroupLists] = await axios.all([
      axios.get(`${AppConfig.FO_INITIAL_URL}/broker/all`),
      axios.get(`${AppConfig.IAM_BACKEND_URL}/user-access/company-list`),
      axios.get(`${AppConfig.IAM_BACKEND_URL}/user-group`)
    ]);
    const newBrokerCodeIdMap = new Map();
    const newBrokerOptions = [];
    const newCompanyOptions = [];
    const newUserGrupOptions = [];
    Array.isArray(brokerList?.data) &&
      brokerList.data.forEach((el) => {
        newBrokerCodeIdMap[el.code] = el.id;
        newBrokerOptions.push({
          code: el.code,
          id: el.id,
          displayText: el.name
        });
      });
    Array.isArray(companyList?.data) &&
      companyList.data.forEach((el) => {
        newCompanyOptions.push({
          code: el.code,
          id: el.id,
          displayText: el.displayText
        });
      });

    Array.isArray(userGroupLists?.data) &&
      userGroupLists.data.forEach((el) => {
        newUserGrupOptions.push({
          code: el.code,
          id: el.id,
          displayText: el.name
        });
      });

    setBrokerOptions(newBrokerOptions);
    setCompanyOptions(newCompanyOptions);
    setUserGroupOptions(newUserGrupOptions);
    setIsLoading(false);
  };

  useEffect(() => {
    getCompanyBrokerData();
  }, []);

  useEffect(() => {
    if (targetUser?.id) {
      searchUserPerms();
    }
  }, [targetUser]);

  useEffect(() => {
    // TODO update side list with correct values
  }, [watchBrokers, watchCompanies]);

  useEffect(() => {
    let brokerIds = [];

    if (
      tenantUserSelectedBrokerIds &&
      Array.isArray(tenantUserSelectedBrokerIds) &&
      brokerOptions &&
      Array.isArray(brokerOptions)
    ) {
      let tenantUserSelectedMap = {};

      tenantUserSelectedBrokerIds.forEach((item) => {
        tenantUserSelectedMap[item] = item;
      });

      brokerOptions.forEach((item) => {
        if (tenantUserSelectedMap.hasOwnProperty(item.id)) {
          brokerIds.push(item);
        }
      });
    }

    setValue('brokerIds', brokerIds);
  }, [brokerOptions, tenantUserSelectedBrokerIds]);

  useEffect(() => {
    let companyIds = [];

    if (
      tenantUserSelectedCompanyIds &&
      Array.isArray(tenantUserSelectedCompanyIds) &&
      companyOptions &&
      Array.isArray(companyOptions)
    ) {
      let tenantUserSelectedMap = {};

      tenantUserSelectedCompanyIds.forEach((item) => {
        tenantUserSelectedMap[item] = item;
      });

      companyOptions.forEach((item) => {
        if (tenantUserSelectedMap.hasOwnProperty(item.id)) {
          companyIds.push(item);
        }
      });
    }

    setValue('companyIds', companyIds);
  }, [companyOptions, tenantUserSelectedCompanyIds]);

  useEffect(() => {
    let selectObj = null;

    if (
      tenantAdminUserGroupId &&
      userGroupOptions &&
      Array.isArray(userGroupOptions)
    ) {
      userGroupOptions.forEach((item) => {
        if (tenantAdminUserGroupId === item.id) {
          selectObj = item;
        }
      });
    }

    setValue('userGroupId', selectObj);
  }, [userGroupOptions, tenantAdminUserGroupId]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {isLoading ? (
          <div />
        ) : (
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center">
            <Grid item lg={12}>
              <Controller
                name="brokerIds"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    id="input_broker"
                    key="broker"
                    options={brokerOptions}
                    getOptionLabel={(option) => option.displayText}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(_e, data) => field.onChange(data)}
                    className={styles.autocomplete}
                    fullWidth
                    filterOptions={filterOptions}
                    filterSelectedOptions
                    renderTags={(value, getTagProps) =>
                      value.map((data, index) => (
                        <Chip
                          key={'key-' + index}
                          {...getTagProps({ index })}
                          label={data.displayText}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Brokers"
                        placeholder="Brokers"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item lg={12}>
              <Controller
                name="companyIds"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    id="input_companies"
                    key="companies"
                    options={companyOptions}
                    getOptionLabel={(option) => option.displayText}
                    onChange={(_e, data) => field.onChange(data)}
                    fullWidth
                    filterOptions={filterOptions}
                    filterSelectedOptions
                    renderTags={(value, getTagProps) =>
                      value.map((data, index) => (
                        <Chip
                          key={'key-' + index}
                          {...getTagProps({ index })}
                          label={data.displayText}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Companies"
                        placeholder="Companies"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item lg={12}>
              <Controller
                name="userGroupId"
                control={control}
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="input_userGroup"
                    options={userGroupOptions}
                    getOptionLabel={(option) => option.displayText}
                    onChange={(_e, data) => field.onChange(data)}
                    fullWidth
                    filterOptions={filterOptions}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={error}
                        helperText={error ? error.message : null}
                        variant="standard"
                        label="User Group"
                        placeholder="User Group"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item lg={12}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center">
                <Grid item lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{
                          'aria-label': 'fo admin checkbox'
                        }}
                        id={'input-adminCheckbox-' + 'FO'}
                        color="primary"
                        defaultChecked
                        disabled
                      />
                    }
                    label="FO Tenant Admin"
                  />
                </Grid>
                <Grid item lg={12}>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            onChange={(_, checked) => {
                              field.onChange(checked);
                            }}
                            inputProps={{
                              'aria-label': 'enigma admin checkbox'
                            }}
                            id={'input-adminCheckbox-' + 'ENIGMA'}
                            color="primary"
                            checked={getValues('enigmaTenantAdmin')}
                          />
                        }
                        label="Enigma Tenant Admin"
                      />
                    )}
                    name="enigmaTenantAdmin"
                    control={control}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            onChange={(_, checked) => {
                              field.onChange(checked);
                            }}
                            inputProps={{
                              'aria-label': 'plan optm admin checkbox'
                            }}
                            id={'input-adminCheckbox-' + 'PLAN_OPTM'}
                            color="primary"
                            checked={getValues('planOptimizeTenantAdmin')}
                          />
                        }
                        label="Plan Optimize Tenant Admin"
                      />
                    )}
                    name="planOptimizeTenantAdmin"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default GrantTenantAdmin;
