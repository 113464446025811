import * as Types from '../modules/types';

const initialState = {
    annualPlanRenewalList : [],
};

const clientPortalReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_ANNUAL_PLAN_RENEWAL_LIST:
            return { ...state, annualPlanRenewalList: action.payload };
    
        default:
            return state;
    }
}

export default clientPortalReducer;