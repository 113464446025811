import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const uploadDefaultLogo = (file) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('logoFile', file);

    axios
      .post(AppConfig.FO_INITIAL_URL + `/tenant-logo/default`, formData)
      .then((m) => {
        dispatch({
          type: Types.UPLOAD_LOGO_SUCCESS
        });
      })
  };
};

export const uploadTenantLogo = (tenantCode, file) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('logoFile', file);

    axios
      .post(
        AppConfig.FO_INITIAL_URL + `/tenant-logo/tenant/${tenantCode}`,
        formData
      )
      .then((m) => {
        dispatch({
          type: Types.UPLOAD_LOGO_SUCCESS
        });
      })
  };
};

export const deleteLogo = (tenantCode) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.FO_INITIAL_URL + `/tenant-logo/tenant/${tenantCode}`)
      .then((m) => {
        if (m.data.logoCode === 'DELETION_SUCCESS') {
          dispatch({
            type: Types.DELETION_SUCCESS
          });
        } else {
          dispatch({
            type: AppActionTypes.APPSTATE_SET_APP_ERROR,
            payload: m.data.msg
          });
        }

      })
      .catch((err) => {
        //send to global logo
        //do nothing for now
      });
  };
};
