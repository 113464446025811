import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, Grid } from '@material-ui/core';
import { savePlanSponsor } from './actions/Actions';
import { store } from 'store/store';
import { fetchPlanSponsor } from './actions/Actions';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import * as Constant from 'common/Constant';

const PlanSponsor = ({ tenantId }) => {
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      sicCode: ''
    }
  });
  const { control, setValue, formState, handleSubmit, reset } = methods;

  const dispatch = useDispatch();

  const planSponsor = useSelector((state) => state.masterTable.planSponsor);

  const onSubmit = (data) => {
    dispatch(savePlanSponsor(data));
  };

  useEffect(() => {
    if (planSponsor && planSponsor.id) {
      reset(planSponsor);
    } else {
      reset({ name: '', sicCode: '' });
    }
  }, [planSponsor]);

  useEffect(() => {
    store.dispatch(fetchPlanSponsor());
  }, [tenantId]);

  return (
    <>
      <FormProvider {...methods}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}>
          <Grid item>
            <Controller
              name="name"
              control={control}
              rules={{
                required: Constant.ERROR_MSG_REQUIRED_FIELD
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="input-name"
                  label="Name"
                  required
                  {...field}
                  error={error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="sicCode"
              control={control}
              rules={{
                required: Constant.ERROR_MSG_REQUIRED_FIELD
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="input-sicCode"
                  label="SIC Code"
                  required
                  {...field}
                  error={error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isDirty || !formState.isValid}>
              Save
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default PlanSponsor;
