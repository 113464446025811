import React, { useEffect, useState } from 'react';
import Carousel from 'common/bstCarousel';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import * as UserTypes from 'features/user/userTypes';
import * as ReportActions from 'features/frontoffice/actions/actions';
import { TextField, InputAdornment, Box, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ReportNav from '../component/reportNav';
import _ from 'lodash';
import * as Constant from 'common/Constant';
import * as Action from '../favoriteReport/actions/action';

// Allows sidebar nav panel to go behind reports on smaller screen width
const carouselStyle = { zIndex: 1000 };

const StandardReport = () => {
  const legacies = useSelector((state) => state.fo.legacies);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  const [data, setData] = useState(legacies);
  const [searchKey, setSearchKey] = useState('');
  const currentMenu = useSelector((state) => state.favoriteReport.currentMenu);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.FO
    });
    store.dispatch(ReportActions.getStandardReport());
  }, [currentTenantCd]);

  const searchFilter = (value) => {
    if (Array.isArray(value)) {
      const newData = _.filter(value, function (o) {
        return o.displayText
          ? o.displayText.toLowerCase().includes(searchKey?.toLowerCase())
          : false;
      });
      setData(newData);
    }
  };

  const filter = (value) => {
    if (Array.isArray(legacies)) {
      if (value === Constant.ALL) {
        searchFilter(legacies);
      } else {
        const newData = _.filter(legacies, function (o) {
          return o.reportTag?.name
            ? o.reportTag?.name.toLowerCase().includes(value.toLowerCase())
            : false;
        });
        searchFilter(newData);
      }
    }
  };
  useEffect(() => {
    store.dispatch(Action.setCurrentMenuItem({ name: Constant.ALL }));
  }, []);

  useEffect(() => {
    // keep state of searched data, while favorite reports on search results
    filter(currentMenu?.name);
  }, [searchKey]);

  useEffect(() => {
    setSearchKey('');
  }, [currentMenu]);

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-end">
        <Grid item>
          <TextField
            onChange={(event) => setSearchKey(event.target.value)}
            placeholder="Search for report"
            value={searchKey}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color='primary'/>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={2} lg={2} sm={4}>
              <ReportNav setData={setData} filter={filter} />
            </Grid>
            <Grid item xs={10} style={carouselStyle} lg={10} sm={8}>
              <Carousel items={data} type={AppConfig.LEGACY_CAROUSEL} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StandardReport;
