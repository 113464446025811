import React from 'react';
import { MDBInput } from 'mdbreact';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  isDisabled: {
    opacity: 0.5
  },
  isEnabled: {
    fontWeight: 'bold'
  },
  img: {
    maxHeight: '70px',
    maxWidth: '150px',
    alt: ''
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around'
  }
}));

const MetricPanelCard = ({
  id,
  img,
  title,
  disabled,
  checked,
  getValue,
  onInput
}) => {
  const classes = useStyles();

  return (
    <MDBInput
      label={
        <div class="card">
          <div class="smart-card">
            <div className={classes.div}>
              <img src={img} className={classes.img} />
            </div>
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              className={disabled ? classes.isDisabled : classes.isEnabled}>
              {title}
            </Typography>
          </div>
        </div>
      }
      filled
      type="checkbox"
      disabled={disabled}
      checked={checked}
      id={id}
      key={title + id}
      getValue={getValue}
      onInput={onInput}
    />
  );
};

export default MetricPanelCard;
