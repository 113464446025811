import MomentUtils from '@date-io/moment';
import { Box, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FORMAT_DATE } from 'common/Constant';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

const EditableTableCell = React.memo(({ rowData, displayData, field, showEdit, isEdited, onCellClick, onChange, editorType, options, minDate, maxDate, error }) => {
  const renderCell = () => {
    if (!showEdit) {
      const style = { fontWeight: 'normal' };
      if (isEdited) {
        style.color = 'green';
        style.fontWeight = 'bold';
      }
      if (error) {
        style.color = 'red';
        style.fontWeight = 'normal';
      }
      let value = displayData || rowData[field];
      if (editorType === 'date' && !value) {
        style.color = '#d3d3d3';
        style.fontWeight = 'normal';
        value = FORMAT_DATE;
      }
      return <Typography style={style}>{value}</Typography>;
    }
    switch (editorType) {
      case 'date':
        return (
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              clearable
              minDate={moment(minDate)}
              maxDate={moment(maxDate)}
              id={field}
              format={FORMAT_DATE}
              fullWidth
              value={rowData[field] ? moment.utc(rowData[field]) : null}
              onChange={(date) => {
                return date ? onChange(rowData.id, field, date.format(FORMAT_DATE)) : null;
              }}
            />
          </MuiPickersUtilsProvider>
        );
      case 'dropdown':
        return (
          <Select
            fullWidth
            value={rowData[field]}
            id={`${rowData?.id}-${field}`}
            onChange={(event) => {
              onChange(rowData.id, field, event.target.value);
            }}>
            {options.map((item) => {
              return (
                <MenuItem key={'key-' + item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        );
      case 'number':
        return (
          <TextField
            id={`${rowData?.id}-${field}`}
            type="number"
            defaultValue={rowData[field]}
            onChange={(event) => {
              onChange(rowData.id, field, event.target.value);
            }}
          />
        );
      default:
        return (
          <TextField
            id={`${rowData?.id}-${field}`}
            defaultValue={rowData[field]}
            onChange={(event) => {
              onChange(rowData.id, field, event.target.value);
            }}
          />
        );
    }
  };
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        paddingTop: showEdit ? '0px' : '10px',
        paddingBottom: showEdit ? '0px' : '10px'
      }}
      onClick={() => {
        onCellClick(rowData.id, field);
      }}>
      {renderCell()}
    </Box>
  );
});

EditableTableCell.propTypes = {
  displayData: PropTypes.string,
  editorType: PropTypes.oneOf(["date", "dropdown", "number"]),
  error: PropTypes.any,
  field: PropTypes.string.isRequired,
  isEdited: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.instanceOf(null), PropTypes.instanceOf(undefined)]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.instanceOf(null), PropTypes.instanceOf(undefined)]),
  onCellClick: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.any.isRequired,
  })),
  rowData: PropTypes.object.isRequired,
  showEdit: PropTypes.bool,
};

export default EditableTableCell;