import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import LaserList from './laserList';
import { useForm, FormProvider } from 'react-hook-form';

const LaserListDialog = (props) => {
  const { onClose, open, target, slcStartDate, slcEndDate } = props;
  const methods = useForm({ defaultValues: target });
  const { reset } = methods;

  const handleClose = (data) => {
    if (onClose) onClose(data);
  };

  const onSubmit = (data) => {
    if (onClose) onClose(data);
  };

  useEffect(() => {
    if (target) {
      if (target.lasers) reset(target);
      else reset({ ...target, lasers: [] });
    } else {
      reset({ lasers: [] });
    }
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog maxWidth="lg" open={open} onClose={handleClose}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Laser List
          </DialogTitle>
          <DialogContent dividers>
            <LaserList slcStartDate={slcStartDate} slcEndDate={slcEndDate} />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary">
              OK
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default LaserListDialog;
