import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Constant from 'common/Constant';
import { store } from 'store/store';
import * as MDActions from '../../common/actions/CommonActions';
import { Controller, useFormContext } from 'react-hook-form';
import * as ValidationUtil from 'utils/ValidationUtil';
import moment from 'moment-timezone';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

const PlanDesignDetail = (props) => {
  const DUPLICATION_ERROR_MSG = 'Same plan name for plan year is found.';

  const { tenantId, isModified, targetId, category, setSaveDisabled } = props;

  const { control, watch, formState, setValue, getValues } = useFormContext();

  const watchYearType = watch('ahpmId', null);

  const watchPlanEndDate = watch('planEndDate', null);
  const watchPlanStartDate = watch('planStartDate', null);

  const years = useSelector((state) => state.MD.mdCommon.allPlanYearsForTenant);
  const types = useSelector((state) => state.MD.mdCommon.allPlanTypes);

  const [selectedYear, setSelectedYear] = useState('');

  const handleYearUpdate = (yearDate) => {
    if (yearDate) {
      setValue(
        'planStartDate',
        yearDate.startDate ? moment(yearDate.startDate) : null
      );
      setValue(
        'planEndDate',
        yearDate.endDate ? moment(yearDate.endDate) : null
      );
    } else {
      setValue('planStartDate', null);
      setValue('planEndDate', null);
    }
  };

  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanYears(tenantId));
  }, [tenantId]);

  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanTypes(category));
  }, [category]);

  useEffect(() => {
    if (years && Array.isArray(years) && watchYearType) {
      const found = years.find((item) => item.id === watchYearType);
      if (found) {
        setSelectedYear(found.planYearName);
      }
    }
  }, [years, watchYearType]);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          {isModified === true ? (
            <TextField
              id="readOnly-year"
              fullWidth
              disabled
              required
              value={selectedYear}
              label="Plan Year"
            />
          ) : (
            <Controller
              name="ahpmId"
              rules={{
                required: Constant.ERROR_MSG_REQUIRED_FIELD,
                validate: async (value) => {
                  let planName = getValues('planName');
                  if (planName) {
                    try {
                      setSaveDisabled(true);
                      const isFound = await axios.get(
                        AppConfig.API_BASE_URL +
                          `v2/plan/validate/duplication/master-id/${value}/name/${planName}`
                      );
                      return ValidationUtil.VALIDATION_DUPLICATION(
                        isFound,
                        targetId,
                        DUPLICATION_ERROR_MSG
                      );
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setSaveDisabled(false);
                    }
                  }
                }
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  id="input-year"
                  required
                  selectOnFocus
                  clearOnBlur
                  autoSelect
                  options={years}
                  onChange={(_, data) => {
                    handleYearUpdate(data);
                    field.onChange(data ? data.id : null);
                  }}
                  getOptionLabel={(option) =>
                    option.planYearName ? option.planYearName : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={error}
                      helperText={error ? error.message : null}
                      label="Plan Year"
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: async (value) => {
                let ahpmId = getValues('ahpmId');
                if (ahpmId) {
                  try {
                    setSaveDisabled(true);
                    const isFound = await axios.get(
                      AppConfig.API_BASE_URL +
                        `v2/plan/validate/duplication/master-id/${ahpmId}/name/${value}`
                    );
                    return ValidationUtil.VALIDATION_DUPLICATION(
                      isFound,
                      targetId,
                      DUPLICATION_ERROR_MSG
                    );
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setSaveDisabled(false);
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-name"
                fullWidth
                {...field}
                required
                error={error}
                helperText={error ? error.message : null}
                label="Plan Name"
              />
            )}
            name="planName"
            control={control}
          />
        </Grid>
        {category !== Constant.PLAN_DESIGN_DATA_TYPE.VISION ? (
          <Grid item xs={6}>
            <Controller
              rules={{ required: Constant.ERROR_MSG_REQUIRED_FIELD }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  id="input-type"
                  options={types}
                  {...field}
                  autoSelect
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Plan Type"
                      error={error}
                      required
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
              name="planType"
              control={control}
            />
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              maxLength: {
                value: 3000,
                message: 'Exceed Max. 3000 Characters.'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-tpaNumber"
                label="TPA Plan Number"
                fullWidth
                {...field}
                required
                error={error}
                multiline
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                helperText={error ? error.message : null}
              />
            )}
            name="planNumber"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                planStartDate: (value) =>
                  ValidationUtil.START_DATE(
                    selectedYear,
                    value,
                    getValues('planEndDate'),
                    true
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  maxDate={watchPlanEndDate ? watchPlanEndDate : undefined}
                  error={error}
                  helperText={error ? error.message : null}
                  format={Constant.FORMAT_DATE}
                  value={getValues('planStartDate') ? moment.utc(getValues('planStartDate')) : null}
                  id="input-startDate"
                  label="Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="planStartDate"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                planEndDate: (value) =>
                  ValidationUtil.END_DATE(
                    selectedYear,
                    getValues('planStartDate'),
                    value,
                    true
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  minDate={watchPlanStartDate ? watchPlanStartDate : undefined}
                  format={Constant.FORMAT_DATE}
                  value={getValues('planEndDate') ? moment.utc(getValues('planEndDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-planEndDate"
                  label="End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="planEndDate"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PlanDesignDetail;
