import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as ValidationUtil from 'utils/ValidationUtil';
import { addMethod } from 'yup';

const PolicyExclusion = (props) => {
  const { control, getValues } = useFormContext();

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        <Grid item xs={12}>
          <Controller
            rules={{
              validate: async (value) => {
                const slcId = getValues('stopLossContractId');

                if (value && slcId) {
                  try {
                    const isFound = await axios.get(
                      AppConfig.API_BASE_URL +
                      `v2/stop-loss-contract/id/${slcId}/policy/exclusion/person/id/${value}`
                    );
                    return ValidationUtil.VALIDATION_DUPLICATION(
                      isFound,
                      value,
                      'Duplicate person id is found.',
                      'personalId'
                    );
                  } catch (error) { }
                }
              }
            }}
            name={`personId`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={'input-personId'}
                label="Person Id"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-lastName"
                label="First Name"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-firstName"
                label="Last Name"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="medId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-medId"
                label="Medical Id"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyExclusion;
