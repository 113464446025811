import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Grid, Divider, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Constant from 'common/Constant';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import * as ValidationUtil from 'utils/ValidationUtil';
import SpecialExpenseForm from './specialExpenseForm';
import { Autocomplete } from '@material-ui/lab';
import { generateRandomString } from 'utils/common';
import moment from 'moment-timezone';

const ModificationDetailDialog = (props) => {
  const { duplicateError, resetDuplicateError } = props;
  const { control, watch, setValue, getValues, trigger } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'planExpenseRates',
    shouldUnregister: true
  });
  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );
  const plansNames = useSelector((state) => state.MD.mdCommon.allPlanNames);
  const expenseCategories = useSelector(
    (state) => state.MD.planExpense.expenseCategory
  );
  const expenseNames = useSelector(
    (state) => state.MD.planExpense.expenseNames
  );
  const rateBasis = useSelector((state) => state.MD.planExpense.rateBasis);

  const allElectionTiers = useSelector(
    (state) => state.MD.mdCommon.allElectionTiers
  );

  const [plansForYears, setPlansForYears] = useState([]);
  const [planYearOptions, setPlanYearOptions] = useState([]);

  const watchPlanEndDate = watch('endDate', null);
  const watchPlanStartDate = watch('startDate', null);
  const watchRateByTier = watch('isRateByTier', false);
  const watchRateBasis = watch('rateBasis', '');
  const watchPlanYear = watch('planYears', []);
  const watchExpenseName = watch('expenseName', '');
  const watchRate = watch('rate', null);
  const watchPlanNames = watch('planName', []);

  const handlePlanYearChange = (years) => {
    if (years.length === 1) {
      const planInfo = totalPlanYears.find((py) => py.id === years[0].id);
      setValue('startDate', planInfo.startDate);
      setValue('endDate', planInfo.endDate);
    } else {
      setValue('startDate', null);
      setValue('endDate', null);
    }
  };

  const filterPlans = (years) => {
    let filteredList = [];
    const ahpmSet = new Set(years.map((p) => p.id));
    if (plansNames && Array.isArray(plansNames) && ahpmSet.size > 0) {
      filteredList = plansNames.filter((plan) => {
        return ahpmSet.has(plan.ahpmId);
      });
      years.forEach((year) => {
        filteredList
          .filter((plan) => year.id === plan.ahpmId)
          .map((p) => {
            p.year = year.name;
            return p;
          });
      });
      filteredList.unshift({
        id: generateRandomString(),
        planName: Constant.ALL
      });
    }
    const sortedYears = filteredList.sort((a, b) => a.year - b.year);
    setPlansForYears(sortedYears);
    if (watchPlanNames?.length > 0 && Array.isArray(watchPlanNames)) {
      const filteredSelectedPlans = watchPlanNames.filter((plan) =>
        ahpmSet.has(plan.ahpmId)
      );
      setValue('planName', filteredSelectedPlans);
    } else {
      const currentPlanName = getValues('planName');
      if (currentPlanName && sortedYears.length) {
        const plans = sortedYears.filter(
          (plan) => plan.planName === currentPlanName
        );
        setValue('planName', plans);
      }
    }
  };

  const createExpenseRates = (rate) => {
    if (getValues('planExpenseRates') && !rate) {
      return;
    }
    const rateValue = {};
    if (rate) {
      const rates = rate.split(',');
      rates.forEach((item) => {
        const data = item.split('=');
        if (data && data.length === 2) {
          rateValue[data[0]] = data[1];
        }
      });
    }
    const electiontiers = allElectionTiers.map((et, i) => ({
      divisionId: '',
      tierId: et.id,
      name: et.electionTier,
      rate: rateValue[et.electionTier] || '',
      startDate: null,
      endDate: null
    }));
    setValue('planExpenseRates', electiontiers);
  };

  useEffect(() => {
    if (
      watchRateByTier &&
      allElectionTiers?.length &&
      Array.isArray(allElectionTiers)
    ) {
      createExpenseRates(null);
    }
  }, [watchRateByTier, allElectionTiers]);

  useEffect(() => {
    if (allElectionTiers?.length && Array.isArray(allElectionTiers)) {
      createExpenseRates(watchRate);
    }
  }, [watchRate, allElectionTiers]);
  useEffect(() => {
    const planYears = setPlanYearSelectOptions();
    const planYear = getValues('planYear');
    if (planYear) {
      const years = planYears.filter((option) => option.name == planYear);
      setValue('planYears', years);
    }
    setPlanYearOptions(planYears);
  }, [totalPlanYears]);

  useEffect(() => {
    if (watchPlanYear) {
      filterPlans(watchPlanYear);
    }
  }, [watchPlanYear]);

  const setPlanYearSelectOptions = () => {
    const d = totalPlanYears
      .filter((a) => a.planYearName)
      .map((a) => ({
        id: a.id,
        name: a.planYearName,
        label: a.planYearName
      }));
    d.unshift({
      id: generateRandomString(),
      name: Constant.ALL,
      label: Constant.ALL
    });
    return d;
  };
  return (
    <>
      <Grid container spacing={3} bgcolor="primary.main">
        <Grid item xs={4}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Controller
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="input-planyear"
                    multiple
                    options={planYearOptions}
                    value={getValues('planYears') || []}
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Plan Year"
                        inputProps={{ ...params.inputProps }}
                        error={error || duplicateError ? true : false}
                        helperText={error?.message || duplicateError || null}
                      />
                    )}
                    onChange={(e, data) => {
                      resetDuplicateError();
                      if (data.find((op) => op.label === Constant.ALL)) {
                        e.preventDefault();
                        const years = planYearOptions.filter(
                          (option) => option.label !== Constant.ALL
                        );
                        setValue('planYears', years);
                        handlePlanYearChange(years);
                      } else {
                        handlePlanYearChange(data);
                        field.onChange(data);
                      }
                    }}
                  />
                )}
                defaultValue={[]}
                name="planYears"
                control={control}
              />
            </Grid>
            <Grid item lg>
              <Controller
                name="planName"
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                control={control}
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="input-plan"
                    multiple
                    options={plansForYears}
                    value={
                      Array.isArray(getValues('planName'))
                        ? getValues('planName')
                        : []
                    }
                    getOptionLabel={(option) =>
                      `${option.planName} (${option.year})`
                    }
                    renderOption={(option) => (
                      <React.Fragment>{`${option.planName} ${
                        option.year ? `(${option.year})` : ''
                      }`}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Plan Name"
                        inputProps={{ ...params.inputProps }}
                        error={error}
                        helperText={error?.message ?? null}
                      />
                    )}
                    onChange={(e, data) => {
                      resetDuplicateError();
                      if (data.find((pn) => pn.planName === Constant.ALL)) {
                        e.preventDefault();
                        setValue(
                          'planName',
                          plansForYears.filter(
                            (option) => option.planName !== Constant.ALL
                          )
                        );
                      } else {
                        field.onChange(data);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {watchPlanYear?.length === 1 && watchRateBasis !== Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL && (
              <>
                <Grid item>
                  <Controller
                    rules={{
                      required: Constant.ERROR_MSG_REQUIRED_FIELD,
                      validate: {
                        planStartDate: (value) => ValidationUtil.START_DATE(null, value, getValues('endDate'), true)
                      }
                    }}
                    defaultValue={null}
                    render={({ field, fieldState: { error } }) => (
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                          clearable
                          {...field}
                          required
                          maxDate={watchPlanEndDate ? watchPlanEndDate : undefined}
                          error={error}
                          helperText={error?.message ?? null}
                          format={Constant.FORMAT_DATE}
                          id="input-startDate"
                          label="Start Date"
                          fullWidth
                          value={moment.utc(getValues('startDate'))}
                          onChange={(date) => {
                            resetDuplicateError();
                            field.onChange(date);
                            trigger('startDate');
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="startDate"
                    control={control}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    rules={{
                      required: Constant.ERROR_MSG_REQUIRED_FIELD,
                      validate: {
                        planEndDate: (value) => ValidationUtil.END_DATE(null, getValues('startDate'), value, true)
                      }
                    }}
                    defaultValue={null}
                    render={({ field, fieldState: { error } }) => (
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                          clearable
                          {...field}
                          required
                          minDate={
                            watchPlanStartDate ? watchPlanStartDate : undefined
                          }
                          format={Constant.FORMAT_DATE}
                          error={error}
                          helperText={error?.message ?? null}
                          id="input-endDate"
                          label="End Date"
                          fullWidth
                          value={moment.utc(getValues('endDate'))}
                          onChange={(date) => {
                            resetDuplicateError();
                            field.onChange(date);
                            trigger('endDate');
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="endDate"
                    control={control}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Controller
                name="expenseCategory"
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                control={control}
                defaultValue={''}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="input-expenseCategory"
                    options={expenseCategories.map((ec) => ec.name)}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=" Expense Category"
                        inputProps={{ ...params.inputProps }}
                        error={error}
                        helperText={error?.message ?? null}
                      />
                    )}
                    onChange={(_, data) => {
                      resetDuplicateError();
                      field.onChange(data);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="expenseName"
                control={control}
                defaultValue={''}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="input-expenseName"
                    options={expenseNames.map((en) => en.name)}
                    getOptionLabel={(option) => option}
                    renderOption={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Expense Name"
                        inputProps={{ ...params.inputProps }}
                        error={error}
                        helperText={error?.message ?? null}
                      />
                    )}
                    onChange={(_, data) => {
                      resetDuplicateError();
                      field.onChange(data);
                      trigger();
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="rateBasis"
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                control={control}
                defaultValue={''}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Autocomplete
                      {...field}
                      id="input-rateBasis"
                      options={rateBasis.map((rb) => rb.name)}
                      getOptionLabel={(option) => option}
                      renderOption={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rate Basis"
                          inputProps={{ ...params.inputProps }}
                          error={error}
                          helperText={error?.message ?? null}
                        />
                      )}
                      onChange={(_, data) => {
                        resetDuplicateError();
                        field.onChange(data);
                      }}
                    />
                    {watchRateBasis ===
                      Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL && (
                      <Typography color="error">
                        *Attention: one-time special expense will result in
                        applying the same amount to each of the plans selected.
                        If the intention is to apply the expense only once for
                        the given period, then select only one plan.
                      </Typography>
                    )}
                  </>
                )}
              />
            </Grid>
            {watchRateBasis === Constant.PLAN_EXPENSE_RATE_BASIS_PER_CLAIM && (
              <>
                <Grid item>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        {...field}
                        control={<Checkbox color="primary" />}
                        label="RX Covered"
                        labelPlacement="end"
                        checked={getValues('rxCovered')}
                      />
                    )}
                    control={control}
                    name="rxCovered"
                  />
                </Grid>
                <Grid item>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        {...field}
                        control={<Checkbox color="primary" />}
                        label="Med Covered"
                        labelPlacement="end"
                        checked={getValues('medCovered')}
                      />
                    )}
                    control={control}
                    name="medCovered"
                  />
                </Grid>
              </>
            )}
            {watchRateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL &&
            watchPlanYear?.length === 1 ? (
              watchExpenseName &&
              watchExpenseName.toLowerCase() ===
                Constant.PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE && (
                <Grid item>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        {...field}
                        control={<Checkbox color="primary" />}
                        label="Apply To All Plans"
                        labelPlacement="end"
                        checked={getValues('applicableToAllPlans')}
                      />
                    )}
                    control={control}
                    name="applicableToAllPlans"
                  />
                </Grid>
              )
            ) : (
              <Grid item>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                      {...field}
                      control={<Checkbox color="primary" />}
                      checked={watchRateByTier}
                      label="Rate By Election Tier"
                      labelPlacement="end"
                    />
                  )}
                  control={control}
                  name="isRateByTier"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item lg={4}>
          <Grid container spacing={2} direction="column">
            {!(
              watchRateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL &&
              watchPlanYear?.length === 1
            ) && (
              <>
                {watchRateByTier ? (
                  Array.isArray(fields) &&
                  fields.map((et, index) => (
                    <Grid item key={`planExpenseRates[${index}].rate`}>
                      <Controller
                        rules={{
                          validate: (value) => {
                            if (
                              [
                                Constant.PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE,
                                Constant.PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE_1,
                                Constant.PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE_2
                              ].includes(watchExpenseName.toLowerCase())
                            ) {
                              if (value > 0) {
                                return 'Rate must be negative value';
                              }
                            }
                            if (!+value) {
                              return 'Rate must be number';
                            }

                            if (
                              getValues('planExpenseRates').filter(
                                (er) => er.rate
                              ).length === 0
                            ) {
                              return 'Atleast one rate against election tier is required.';
                            }
                          }
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            id={`input-et-${index}`}
                            fullWidth
                            type="number"
                            {...field}
                            error={error}
                            helperText={error?.message ?? null}
                            label={et.name}
                          />
                        )}
                        name={`planExpenseRates[${index}].rate`}
                        control={control}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <Controller
                      rules={{
                        required: Constant.ERROR_MSG_REQUIRED_FIELD,
                        validate: (value) => {
                          if (
                            [
                              Constant.PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE,
                              Constant.PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE_1,
                              Constant.PLAN_EXPENSE_EXPENSE_NAME_RX_REBATE_2
                            ].includes(watchExpenseName.toLowerCase())
                          ) {
                            if (value > 0) {
                              return 'Rate must be negative value';
                            }
                          }
                        }
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          id="input-rate"
                          fullWidth
                          type="number"
                          {...field}
                          error={error}
                          helperText={error?.message ?? null}
                          label="Rate"
                        />
                      )}
                      name="rate"
                      control={control}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {watchRateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL &&
            watchPlanYear?.length === 1 && (
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <Typography>Special Expense Rates</Typography>
                </Grid>
                <Grid item lg={12}>
                  <Divider />
                </Grid>
                <Grid item lg={12}>
                  <SpecialExpenseForm
                    control={control}
                    planYears={watchPlanYear}
                    planStartDate={watchPlanStartDate}
                    planEndDate={watchPlanEndDate}
                    rateBasis={watchRateBasis}
                  />
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
    </>
  );
};
export default React.memo(ModificationDetailDialog);
