import { TextField, Grid, InputLabel, MenuItem, Select, FormControl, FormHelperText } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';
import * as ValidationUtil from 'utils/ValidationUtil';
import * as CommonUtil from 'features/masterData/util/CommonUtil';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';

const Laser = ({ slcStartDate, slcEndDate }) => {
  const { control, setValue, getValues } = useFormContext();

  const calculateContractBasis = (contract_basis) => {
    const map = CommonUtil.CALCULATE_CONTRACT_BASIS_DATE_PERIOD(
      slcStartDate,
      slcEndDate,
      contract_basis
    );

    setValue(
      'runInStartDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_IN_START_DATE]
    );
    setValue('runInEndDate', map[CommonUtil.CONTRACT_BASIS_RUN_IN_END_DATE]);
    setValue(
      'runOutStartDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_OUT_START_DATE]
    );
    setValue(
      'runOutEndDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_OUT_START_DATE]
    );
  };

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-personId"
                label="Person Id"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="personId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-personIdentifierValue1"
                label="First Name"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="personIdentifierValue1"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-personIdentifierValue2"
                label="Last Name"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="personIdentifierValue2"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-personIdentifierValue3"
                label="Medical ID"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="personIdentifierValue3"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-contractBasis"
                label="Contract Basis"
                fullWidth
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  calculateContractBasis(e.target.value);
                }}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="contractBasis"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: (value) => {
                return ValidationUtil.IS_NUMBER(value)
                  ? null
                  : 'Please enter a number.';
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-laserAmount"
                label="Laser Amount"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="laserAmount"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('runInStartDate') ? moment.utc(getValues('runInStartDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-runInStartDate"
                  label="Run In Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="runInStartDate"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('runInEndDate') ? moment.utc(getValues('runInEndDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-runInEndDate"
                  label="Run In End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="runInEndDate"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('runOutStartDate') ? moment.utc(getValues('runOutStartDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-runOutStartDate"
                  label="Run Out Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="runOutStartDate"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  disabled
                  format={Constant.FORMAT_DATE}
                  value={getValues('runOutEndDate') ? moment.utc(getValues('runOutEndDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-runOutEndDate"
                  label="Run Out End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="runOutEndDate"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default Laser;
