import { Box, Grid, ListItemText, MenuItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const BACKGROUND_COLOR = '#4051B5';
const useStyles = makeStyles((theme) => ({
  sideNav: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    '&:hover .menuItem': {
      color: BACKGROUND_COLOR
    },
    '&:hover .icon': {
      color: BACKGROUND_COLOR
    },
    '&:hover .typography': {
      color: BACKGROUND_COLOR
    }
  },
  menuItem: {
    justifyContent: 'start',
    alignContent: 'start',
    alignItems: 'start',
    margin: 0,
    minWidth: 220
  },
  icon: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    paddingTop: 3
  },
  listItemText: {
    alignText: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const SideNavItem = ({ item, filter, total }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.sideNav}>
        <MenuItem
          disableGutters={true}
          className={classes.menuItem}
          key={'side-nav-item-' + item[0]?.reportTag}
          onClick={() => filter(item[0]?.reportTag)}>
          <Grid container spacing={2}>
            <Grid item xs={8} className={classes.listItemText}>
              <Tooltip title={item[0]?.reportTag}>
                <ListItemText>{item[0]?.reportTag}</ListItemText>
              </Tooltip>
            </Grid>
            <Grid item xs={2} className={classes.count}>
              <ListItemText>{total}</ListItemText>
            </Grid>
          </Grid>
        </MenuItem>
      </Box>
    </>
  );
};

export default SideNavItem;
