import React, { useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import RouteConfig from 'routes/RouteConfig';
import { Link, useHistory } from 'react-router-dom';
import TenantManagement from '../tenantManagement/tenantManagement';
import { useSelector } from 'react-redux';
import { fetchData } from 'features/user/userActions';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import BrokerManagement from '../brokerManagement/BrokerManagement';
import MdRenewalManagement from '../mdRenewalManagement/MdRenewalManagement';
import { hasPermission } from 'utils/common';

const TenantManagementNavigator = () => {
  const TENANT_MANAGEMENT_URL = RouteConfig.tenantManagement.path;
  const TENANT_ACCESS_URL = TENANT_MANAGEMENT_URL + AppConfig.TENANT_MANAGEMENT_SUB_URL.TENANT;
  const BROKER_ACCESS_URL = TENANT_MANAGEMENT_URL + AppConfig.TENANT_MANAGEMENT_SUB_URL.BROKER;
  const MG_RENEWL_MGMT_ACCESS_URL = TENANT_MANAGEMENT_URL + AppConfig.TENANT_MANAGEMENT_SUB_URL.MD_RENEWAL_MGMT;
  const permissions = useSelector((state) => state.user.permissions);
  const history = useHistory();
  const { deafultUserState, currentTenantCd } = useSelector((state) => state.user);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deafultUserState) {
        store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_FO));
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Tabs value={history.location.pathname} indicatorColor="primary" textColor="primary">
        <Tab label="Tenant Management" value={TENANT_ACCESS_URL} component={Link} to={TENANT_ACCESS_URL} />
        {hasPermission(AppConfig.PERMISSION.BROKER_MANAGEMENT, permissions) && <Tab label="Broker Management" value={BROKER_ACCESS_URL} component={Link} to={BROKER_ACCESS_URL} />}
        <Tab label="MD Renewal Management" value={MG_RENEWL_MGMT_ACCESS_URL} component={Link} to={MG_RENEWL_MGMT_ACCESS_URL} />
      </Tabs>
      {history.location.pathname === TENANT_ACCESS_URL && <TenantManagement />}
      {history.location.pathname === BROKER_ACCESS_URL && <BrokerManagement />}
      {history.location.pathname === MG_RENEWL_MGMT_ACCESS_URL && <MdRenewalManagement />}
    </>
  );
};

export default TenantManagementNavigator;
