import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { useRef, useEffect, useState } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import * as DateUtil from '../../../util/Dateutil';
import * as Actions from '../actions/action';
import * as Constant from 'common/Constant';

const BulkModificationResponseModal = ({ open, onClose, onSubmit, query }) => {
  const tableRef = useRef();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const methods = useFormContext();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      Actions.validateBulkModify(query).then((m) => {
        const res = m.data.planRates;
        setData(res.content);
        setLoading(false);
      });
    }
  }, [query, open]);

  return (
    <FormProvider {...methods}>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}>
        <DialogTitle id="plan-rate-bulk-creation">
          Plan Rate Bulk Modification
        </DialogTitle>
        <DialogContent dividers>
          <MaterialTable
            title=""
            tableRef={tableRef}
            isLoading={loading}
            columns={[
              { title: 'Plan Year', field: 'planYearName' },
              { title: 'Plan', field: 'planName' },
              {
                title: 'Start Date',
                field: 'startDate',
                render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.startDate)
              },
              {
                title: 'End Date',
                field: 'endDate',
                render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.endDate)
              },
              {
                title: 'Election Tier Schema',
                field: 'electionTierSchemaName'
              },
              { title: 'Class Schema', field: 'classSchemaName' },
              { title: 'Status', field: 'status' },
              { title: 'Total Monthly Rate', field: 'rate' },
              { title: 'Employer Share', field: 'employerShare' },
              { title: 'Employee Share', field: 'employeeShare' }
            ]}
            data={data}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
            options={{
              selection: false,
              actionsColumnIndex: -1,
              search: false,
              pageSize: Constant.DEFAULT_SELECT_PAGE_SIZE,
              pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
              rowStyle: (rowData) => {
                return {
                  backgroundColor: rowData.failed ? '#f1828d' : '#03fcba'
                };
              }
            }}
            detailPanel={(rowData) => {
              return (
                <>
                  <Box m={8}>
                    <Grid container spacing={2} bgcolor="primary.main">
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          key={'readonly-rate' + rowData.rowData.id}
                          label="Rate %"
                          defaultValue={rowData.rowData.cobra}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          key={'readonly-tobaccoSurcharge' + rowData.rowData.id}
                          label="Tobacco Surcharge"
                          defaultValue={rowData.rowData.tobaccoSurcharge}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          key={'readonly-wellnessDiscount' + rowData.rowData.id}
                          label="Wellness Discount"
                          defaultValue={rowData.rowData.wellnessDiscount}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          key={'readonly-spousalSurcharge' + rowData.rowData.id}
                          label="Spousal Surcharge"
                          defaultValue={rowData.rowData.spousalSurcharge}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          key={'readonly-ssdisabilitySurcharge' + rowData.rowData.id}
                          label="SS Disability Surcharge"
                          defaultValue={rowData.rowData.ssdisabilitySurcharge}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              );
            }}
            components={{
              OverlayLoading: () => <div />,
              Toolbar: (props) => (
                <Box>
                  <MTableToolbar {...props} />
                </Box>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={methods.handleSubmit(handleSubmit)}
            color="primary">
            Modify
          </Button>
          <Button onClick={() => handleClose(null)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default BulkModificationResponseModal;
