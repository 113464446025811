import React, { useState, useEffect, Fragment } from 'react';
import {
  TextField,
  Grid,
  IconButton,
  Box,
  Chip,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Delete, Add, Edit } from '@material-ui/icons';
import {v4 as uuid} from 'uuid';
import { useFormContext, useFieldArray } from 'react-hook-form';
import * as Action from '../action/action';
import { store } from 'store/store';
import * as Constant from 'common/Constant';
import RoleTenantListDialog from './roleTenantListDialog';
import { confirmDialog } from 'common/ComfirmationDialog';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const RoleTenantList = ({
  data,
  roleList,
  tenantList,
  appName,
  appId,
  userId
}) => {
  const classes = useStyles();

  const [dataArray, setDataArray] = useState([]);
  const [creationOpen, setCreationOpen] = useState(false);
  const [unselectedRoleList, setUnselectedRoleList] = useState([]);
  const [unselectedTenantList, setUnselectedTenantList] = useState([]);
  const [cloneData, setCloneData] = useState({});

  const findUnselectedRoleList = (data) => {
    let roleIdList = [];
    if (data != null) {
      Object.entries(data).map((item, index) => {
        roleIdList.push(item[1][0].roleId);
      });
      return roleList.filter((item) => !roleIdList.includes(item.id));
    } else {
      return roleList;
    }
  };

  const findUnselectedTenantList = (data) => {
    let existingMap = {};
    if (data != null) {
      Object.entries(data).map((item1, index1) => {
        item1[1].map((item2, index2) => {
          existingMap[item2.companyId] = item2.companyId;
        });
      });
      return tenantList
        .filter((item) => item.active)
        .filter((item) => !existingMap.hasOwnProperty(item.id));
    } else {
      return tenantList;
    }
  };

  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    keyName: Constant.DEFAULT_REACT_HOOK_FIELD_ID,
    name: 'userTenantRoleList'
  });

  const handleCreationClose = (data) => {
    //convert data to backend expected object

    if (data) {
      if (
        data.role &&
        data.tenant &&
        Array.isArray(data.tenant) &&
        data.tenant.length > 0
      ) {
        let dto = {
          userId: userId,
          applicationId: appId,
          originalRoleId: data.roleId,
          updateRoleId: data.role.id,
          companyIds: []
        };
        data.tenant.map((item) => {
          return dto.companyIds.push(item.id);
        });
        store.dispatch(Action.saveApplicationAccesses(dto));
      }
    }
    setCreationOpen(false);
  };

  const handleDelete = (roleId) => {
    confirmDialog(
      () =>
        store.dispatch(
          Action.deleteByUserApplicationAndRoleId(userId, appId, roleId)
        ),
      'Do you really want to remove the role with all the tenants under the role in this application?'
    );
  };

  const handleCreationOpen = (data) => {
    const parsedData = getDefaults(data);
    setCloneData({
      ...data,
      role: parsedData.roleDefaults,
      tenant: parsedData.tenantDefaults
    });
    setCreationOpen(true);
  };

  const getDefaults = (dataArrayItem) => {
    const defaults = { roleDefaults: [], tenantDefaults: [] };
    if (dataArrayItem && dataArrayItem.roleId && dataArrayItem.tenantIdList) {
      defaults.roleDefaults = roleList.filter(
        (item) => item.id == dataArrayItem.roleId
      )[0];

      const tempTenantDefaults = tenantList.filter((item) =>
        dataArrayItem.tenantIdList.includes(item.id)
      );
      defaults.tenantDefaults = tempTenantDefaults.map((item) =>
        item.name ? item : { ...item, name: item.displayText }
      );
    }

    return defaults;
  };

  const handleSingleDelete = (companyId, roleId) => {
    confirmDialog(
      () =>
        store.dispatch(
          Action.deleteByUserApplicationAndCompanyIdAndRoleId(
            userId,
            appId,
            companyId,
            roleId
          )
        ),
      'Do you really want to remove this tenant under this role?'
    );
  };

  useEffect(() => {
    let tempDataArray = [];
    if (data != null)
      Object.entries(data).map((item, index) => {
        let tenantDisplayText = '';
        let tenantIdList = [];
        let thisRole = roleList.filter((x) => x.id === item[1][0].roleId)[0];

        item[1].map((x) => {
          tenantDisplayText += x.companyName + ', ';
          tenantIdList.push(x.companyId);
        });
        tempDataArray.push({
          roleName: item[1][0].roleName,
          roleId: item[1][0].roleId,
          tenantDisplayText: tenantDisplayText.slice(0, -2),
          tenantIdList: tenantIdList,
          originalData: item[1],
          permissions: thisRole?.permissions
        });
      });
    setDataArray(tempDataArray);
    setUnselectedRoleList(findUnselectedRoleList(data));
    setUnselectedTenantList(findUnselectedTenantList(data));
  }, [data]);

  let defaultCreationOpen = {};
  if (data != null)
    Object.entries(data).map((item, index) => {
      defaultCreationOpen[item[1][0].roleId] = false;
    });

  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        style={{ minWidth: '500px' }}>
        <RoleTenantListDialog
          open={creationOpen}
          onClose={handleCreationClose}
          target={cloneData}
          roleList={roleList}
          unselectedRoleList={unselectedRoleList}
          tenantList={tenantList}
          unselectedTenantList={unselectedTenantList}
          appName={appName}
        />
        {dataArray && Array.isArray(dataArray) && dataArray.length > 0 ? (
          dataArray.map((item, index) => (
            <Fragment key={'key-' + uuid()}>
              <Grid key={'key-' + uuid()} item xs={2}>
                <Tooltip
                  key={'key-' + uuid()}
                  title={
                    <React.Fragment key={'key-' + uuid()}>
                      <Typography key={'key-' + uuid()} color="inherit">
                        Permission List
                      </Typography>
                      <ul key={'key-' + uuid()} style={{ marginLeft: '10px' }}>
                        {item.permissions && item.permissions.length > 0
                          ? item.permissions.map((permission) => (
                              <li key={'key-' + uuid()}>{permission.code}</li>
                            ))
                          : 'N/A'}
                      </ul>
                    </React.Fragment>
                  }>
                  <TextField
                    id={'readonly-roleName-' + index}
                    key={'key-' + uuid()}
                    label="Role"
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    fullWidth
                    defaultValue={item.roleName}
                  />
                </Tooltip>
              </Grid>
              <Grid key={'key-' + uuid()} item xs={8}>
                <div className={classes.root} key={'key-' + uuid()}>
                  {item &&
                    item.originalData &&
                    Array.isArray(item.originalData) &&
                    item.originalData.map((data) => (
                      <Chip
                        key={'key-' + uuid()}
                        label={data.companyName}
                        variant={data.companyActive ? 'default' : 'outlined'}
                        onDelete={() => {
                          handleSingleDelete(data.companyId, item.roleId);
                        }}
                      />
                    ))}
                </div>
              </Grid>
              <Grid key={'key-' + uuid()} item xs={2}>
                <Box
                  key={'key-' + uuid()}
                  display="flex"
                  justifyContent="flex-end">
                  {index === dataArray.length - 1 ? (
                    <IconButton
                      id={'action-add'}
                      key={'key-' + uuid()}
                      onClick={() => handleCreationOpen()}
                      title="Add New">
                      <Add key={'key-' + uuid()} />
                    </IconButton>
                  ) : null}
                  <IconButton
                    id={'action-edit-' + index}
                    key={'key-' + uuid()}
                    onClick={() => handleCreationOpen(item)}>
                    <Edit key={'key-' + uuid()} />
                  </IconButton>
                  <IconButton
                    id={'action-delete-' + index}
                    key={'key-' + uuid()}
                    onClick={() => handleDelete(item.roleId)}>
                    <Delete key={'key-' + uuid()} />
                  </IconButton>
                </Box>
              </Grid>
            </Fragment>
          ))
        ) : (
          <>
            <Grid
              key={'key-grid-no-data'}
              container
              justifyContent="center"
              alignItems="center">
              <i>
                No permission was granted to this user under selected
                application. Please click{' '}
              </i>
              <IconButton
                id={'action-add-no-data'}
                key={'key-' + uuid()}
                onClick={() => handleCreationOpen()}
                title="Add New">
                <Add />
              </IconButton>
              <i>
                {' '}
                to create a new one. You can also grant the admin role above.
              </i>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default RoleTenantList;
