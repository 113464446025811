import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import GroupManagementDialogDetail from './groupManagementDialogDetail';
import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from 'common/SingleClickButton';

const GroupManagementDialog = (props) => {
  const { onClose, open, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = () => {
    onClose(null);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {target?.id ? 'Edit' : 'New'}
          </DialogTitle>
          <DialogContent dividers>
            <GroupManagementDialogDetail
              isModified={target?.id > 0}
              targetId={target ? target.id : null}
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default GroupManagementDialog;
