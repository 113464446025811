import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React from 'react';
import InitOptions from '../manageReports/initOptions';

function InitOptionDialog(props) {
  const { onClose, open } = props;
  const handleCancel = () => {
    onClose(null);
  };

  const handleSubmit = (data) => {
    onClose(data);
  };
  return (
    <>
      <Dialog open={open} onClose={handleCancel} maxWidth="lg">
        <DialogTitle id="dialog-edit-existing-report" onClose={handleCancel}>
          Init. Report
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Do you want to pull all the reports from same Tableau site?
          </Typography>
          <InitOptions onCancel={handleCancel} onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default InitOptionDialog;
