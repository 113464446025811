import * as AppActionTypes from 'store/actions/appstate';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const syncThunbnail = (tenantCode) => {
  return (dispatch) => {
    axios
      .post(AppConfig.GEMINI_URL + `/thunmbnail/tenant/${tenantCode}`)
      .then((res) => {
        dispatch([
          {
            type: AppActionTypes.APP_REST_CALL_SUCESSED,
            payload: 'Thumbnail synced up!'
          }
        ]);
      });
  };
};

export const initReportForTenant = (tenantCode, mode) => {
  return (dispatch) => {
    axios
      .post(
        AppConfig.GEMINI_URL + `/report/sync/tenants/mode/${mode}`, [tenantCode]
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: AppActionTypes.APP_REST_CALL_SUCESSED,
            payload: 'Succeeded!'
          });
        } else {
          throw new Error('Internal Server Error');
        }
      })
      .catch((error) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: error.error
        });
      });
  };
};
