import React, { useState, useEffect, useRef } from 'react';
import * as Actions from './actions/action';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { confirmDialog } from 'common/ComfirmationDialog';
import _ from 'lodash';
import ElectionTierDetailDialog from './component/detailDialog';
import Alert from '@material-ui/lab/Alert';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FoMaterialTable } from 'common/FoMaterialTable';

const ElectionTier = ({ tenantId }) => {
  const tableRef = useRef();

  const [errorMsg, setErrorMsg] = useState(null);
  const [errorMsgOpen, setErrorMsgOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});

  const data = useSelector((state) => state.MD.electionTier.data);

  const shouldRefresh = useSelector(
    (state) => state.MD.electionTier.shouldRefresh
  );

  const handleOpen = (data) => {
    setCloneData(data);
    setOpen(!open);
  };

  const handleClose = (data) => {
    if (data) {
      data.et.forEach((thisTier) => {
        const { et, ...rest } = data;
        if (thisTier.toAdd) {
          store.dispatch(Actions.saveElectionTiers({ ...rest, ...thisTier }));
        }
      });
    }

    setOpen(false);
  };

  const deleteByIds = (rowData) => {
    const ids = rowData.map((item) => item.id);
    confirmDialog(() => store.dispatch(Actions.deleteByIds(ids)));
  };

  const refresh = () => {
    store.dispatch(Actions.getAllByTenantId(tenantId));
  };
  useEffect(() => {
    refresh();
  }, [tenantId]);

  useEffect(() => {
    if (shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE) {
      refresh();
    }
  }, [shouldRefresh]);

  return (
    <>
      <Collapse in={errorMsgOpen}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorMsgOpen(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {errorMsg}
        </Alert>
      </Collapse>

      <FoMaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Order', field: 'tierOrder' },
          { title: 'Election Tier', field: 'electionTier' },
          { title: 'Election Tier Name', field: 'etName' }
        ]}
        data={data}
        options={{
          selection: true,
          actionsColumnIndex: -1,
          search: false,
          paging: false
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          {
            position: 'row',
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleOpen({ ...rowData, transientItem: false });
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (evt, data) => deleteByIds(data)
          },
          {
            position: 'row',
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (_, rowData) => deleteByIds([rowData])
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleOpen({ tenantId: tenantId, transientItem: true });
            }
          }
        ]}
      />

      <ElectionTierDetailDialog
        open={open}
        onClose={handleClose}
        tenantId={tenantId}
        target={cloneData}
        existingData={data}
      />
    </>
  );
};

export default ElectionTier;
