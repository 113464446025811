import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import getUrlTicket from 'utils/getUrlTicket';
import { catchTableauReqCancel } from 'utils/catchTableauReqCancel';
import { formatReptSource } from 'utils/formatReptSource';
import { useSelector } from 'react-redux';

const BstMetricsCard = ({ item, width, height }) => {
  const [url, setUrl] = useState(null);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);
  const tableauServiceUrl = useSelector((state) => state.fo.tableauServiceUrl);

  useEffect(() => {
    let url = formatReptSource(tableauServiceUrl, currentTenantCd, item.reptSource);
    if (url.indexOf('trusted') > 0) {
      getUrlTicket(url)
        .then((res) => {
          if (res.indexOf('-1') > 0) {
            return null;
          }
          if (res.indexOf('toolbar=no') < 0) {
            res += '?:embed=yes&:toolbar=no';
          }
          setUrl(res);
        })
        .catch((error) => {
          catchTableauReqCancel(error, 'No Content Available');
        });
    }
  }, [item]);

  return (
    <Box
      style={{
        height: height,
        width: width,
        marginLeft: '18px',
        marginRight: '18px',
        borderBottom: '1px solid rgba(230, 234, 239, .8)',
        marginTop: '10px'
      }}>
      <iframe
        className="show-iframe"
        title={url}
        width="100%"
        height={height}
        src={url}
      />
    </Box>
  );
};
export default BstMetricsCard;
