import * as Types from './types';
import { AppConfig } from 'AppConfig';
import axios from 'axios';

export const fetchAllPlanTypes = (category) => {
  return (dispatch) => {
    axios
      .get(
        AppConfig.API_BASE_URL + `plantype/findPlanTypes/category/${category}`
      )
      .then((m) => {
        dispatch({
          type: Types.FETCH_PLAN_TYPES_SUCCESS,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const getAllElectionTiers = () => {
  return (dispatch) => {
    axios.get(AppConfig.API_BASE_URL + `electiontiers`).then((m) => {
      const result = m.data;
      const sortedResult =
        result && Array.isArray(result)
          ? result.sort((f, s) =>
              f.tierOrder > s.tierOrder ? 1 : s.tierOrder > f.tierOrder ? -1 : 0
            )
          : [];
      dispatch({
        type: Types.GET_ALL_ELECTION_TIER_SUCCESS,
        data: sortedResult
      });
    });
  };
};

export const getAllNetworkTypes = (tenantId) => {
  return (dispatch) => {
    dispatch({
      type: Types.GET_ALL_NETWORK_TYPES
    });
    axios
      .post(AppConfig.API_BASE_URL + `networktype/findNetworkTypes`, {
        tenantId: tenantId
      })
      .then((m) => {
        dispatch({
          type: Types.GET_ALL_NETWORK_TYPES_SUCCESS,
          data: m.data
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.GET_ALL_NETWORK_TYPES_FAILURE
        });
      });
  };
};

export const getTpaByTenantId = (tenantId) => {
  return (dispatch) => {
    axios.get(AppConfig.API_BASE_URL + `tpa`).then((m) => {
      dispatch({
        type: Types.GET_TPA_FOR_TENENT_SUCCESS,
        data: m.data
      });
    });
  };
};

export const getAllAPPredefinedValueByTenantId = () => {
  return (dispatch) => {
    axios
      .all([
        axios.get(AppConfig.API_BASE_URL + `tpa`),
        axios.get(AppConfig.API_BASE_URL + `pbm`),
        axios.get(AppConfig.API_BASE_URL + `stoplosscarrier`),
        axios.get(AppConfig.API_BASE_URL + `slmgu`)
      ])
      .then((res) => {
        dispatch({
          type: Types.GET_TPA_FOR_TENENT_SUCCESS,
          data: res[0].data
        });
        dispatch({
          type: Types.GET_PBM_FOR_TENENT_SUCCESS,
          data: res[1].data
        });
        dispatch({
          type: Types.GET_SLC_FOR_TENENT_SUCCESS,
          data: res[2].data
        });
        dispatch({
          type: Types.GET_SLM_FOR_TENENT_SUCCESS,
          data: res[3].data
        });
      })
      .catch((e) => {});
  };
};

export const getEmployeeClassTenantId = () => {
  return (dispatch) => {
    axios.get(AppConfig.API_BASE_URL + `employeeclass`).then((m) => {
      dispatch({
        type: Types.GET_EMPLOYE_CLASS_SUCCESS,
        data: m.data
      });
    });
  };
};

export const getElectionTiersTenantId = () => {
  return (dispatch) => {
    axios.get(AppConfig.API_BASE_URL + `electiontiers`).then((m) => {
      dispatch({
        type: Types.GET_ELECTION_TIER_BY_TENANT_ID_SUCCESS,
        data: m.data
      });
    });
  };
};

export const getPlansByTenantId = (tenantCode) => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + `v2/plan/tenant/code/${tenantCode}`)
      .then((m) => {
        dispatch({
          type: Types.GET_ELECTION_TIER_BY_TENANT_ID_SUCCESS,
          data: m.data
        });
      });
  };
};

export const fetchAllPlanYears = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + `v2/annual-health-plan/all`)
      .then((m) => {
        dispatch({
          type: Types.FETCH_ALL_PLAN_YEARS_BY_TENANT_ID_SUCCESS,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const getPlanByAnnualPlanId = (planId) => {
  return (dispatch) => {
    if (planId) {
      axios
        .get(AppConfig.API_BASE_URL + `v2/plan/annual/id/${planId}`)
        .then((m) => {
          dispatch({
            type: Types.GET_PLANS_BY_ANNUAL_PLAN_ID_SUCCESS,
            data: m.data
          });
        })
        .catch((err) => {});
    }
  };
};

export const setMasterDataPlanYears = (years) => {
  return (dispatch) => {
    dispatch({
      type: Types.SET_MASTERDATA_PLAN_YEARS,
      data: years
    });
  };
};

export const fetchAllUniqueNamePlanDesignData = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + `v2/plan/all/unique/name`)
      .then((m) => {
        dispatch({
          type: Types.FETCH_ALL_UNIQUE_NAME_PLAN_DESIGN_BY_TENANT_ID_SUCCESS,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const getAllMedPlanByAnnualPlanId = (planId) => {
  return (dispatch) => {
    if (planId) {
      axios
        .get(AppConfig.API_BASE_URL + `v2/plan/med/annual/id/${planId}`)
        .then((m) => {
          dispatch({
            type: Types.GET_MED_PLANS_BY_ANNUAL_PLAN_ID_SUCCESS,
            data: m.data
          });
        })
        .catch((err) => {});
    }
  };
};

export const getAllPlanNames = () => {
  return (dispatch) => {
    axios.get(AppConfig.API_BASE_URL + `v2/plan/all`).then((p) => {
      dispatch({
        type: Types.FETCH_ALL_PLAN_NAMES_SUCCESS,
        data: p.data
      });
    });
  };
};
