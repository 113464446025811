import { Grid, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { ERROR_MSG_REQUIRED_FIELD, FORMAT_DATE, MM_DD_YYYY_DATE_REGEX } from 'common/Constant';
import { END_DATE_LATER_THAN_START_DATE, INVALID_DATE, INVALID_DATE_FORMAT } from 'common/error';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const MdRenewalCreationDetails = () => {
  const { control, getValues } = useFormContext();
  const { tenantList } = useSelector((state) => state.mdRenewalManagement);
  const [tenantOptions, setTenantOptions] = useState([]);

  useEffect(() => {
    if (tenantList && Array.isArray(tenantList)) {
      setTenantOptions(
        tenantList
          .filter((tenant) => tenant.name)
          .map((tenant) => ({ id: tenant.id, name: tenant.name, code: tenant.code }))
          .sort((tenant1, tenant2) => (tenant1.name.toLowerCase() < tenant2.name.toLowerCase() ? -1 : 0))
      );
    }
  }, [tenantList]);

  const tenantFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.name}`
  });

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={12}>
          <Controller
            name="tenant"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-tenant"
                options={tenantOptions}
                filterOptions={tenantFilterOptions}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => option.name}
                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps }} label="Tenant" error={!!error} helperText={error?.message || ''} />}
                onChange={(_, data) => {
                  field.onChange(data);
                }}
              />
            )}
            rules={{
              required: ERROR_MSG_REQUIRED_FIELD
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="caseStartDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} label="Plan Year Start" fullWidth placeholder={FORMAT_DATE} onBlur={field.onBlur} error={!!error} helperText={error?.message || ''} />
            )}
            rules={{
              required: ERROR_MSG_REQUIRED_FIELD,
              pattern: {
                value: MM_DD_YYYY_DATE_REGEX,
                message: INVALID_DATE_FORMAT
              },
              validate: (value) => {
                if (!moment(value, FORMAT_DATE).isValid()) {
                  return INVALID_DATE;
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="caseEndDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} label="Plan Year End" fullWidth placeholder={FORMAT_DATE} onBlur={field.onBlur} error={!!error} helperText={error?.message || ''} />
            )}
            rules={{
              required: ERROR_MSG_REQUIRED_FIELD,
              pattern: {
                value: MM_DD_YYYY_DATE_REGEX,
                message: INVALID_DATE_FORMAT
              },
              validate: (value) => {
                if (moment(value, FORMAT_DATE).isValid()) {
                  const startDate = new Date(getValues('caseStartDate'));
                  const endDate = new Date(value);
                  return endDate > startDate || END_DATE_LATER_THAN_START_DATE;
                } else {
                  return INVALID_DATE;
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MdRenewalCreationDetails;
