import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import BulkDeletionDialogDetail from './bulkDeletionDialogDetail';
import { useForm, FormProvider } from 'react-hook-form';



const BulkDeletionDialog = (props) => {
  const {
    onClose,
    open,
  } = props;

  const formDefaults = { broker: [], tenant: [], displayText: [] };

  const methods = useForm({ defaultValues: formDefaults, mode: 'onSubmit' });
  const { reset, setValue } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(formDefaults);
  }, [open]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {'Report Deletion'}
          </DialogTitle>
          <DialogContent dividers>
            <BulkDeletionDialogDetail
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary"
              disabled={!methods.formState.isDirty}>
              Save
            </Button>
            <Button
              autoFocus
              onClick={() => handleClose(null)}
              color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default BulkDeletionDialog;
