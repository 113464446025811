import { Chip, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from 'common/BstAccordion';
import { confirmDialog } from 'common/ComfirmationDialog';
import { ANNUAL_PLAN_RENEWAL_DETAILS } from 'features/clientPortal/modules/constant';
import { useState } from 'react';
import { store } from 'store/store';
import { v4 as uuid } from 'uuid';
import * as Action from '../../modules/action';
import AddPoCDialog from './AddPoCDialog';

const RenewalPointOfContact = ({ rowData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5)
      }
    }
  }));
  const classes = useStyles();
  const [showAddPoCDialog, setShowAddPoCDialog] = useState(false);

  const handlePoCChangeRequest = (fullname, email, tenantCode, operation) => {
    confirmDialog(
      () => store.dispatch(Action.sendPoCOpsRequest(fullname?.split(' ')[0], fullname?.split(' ')[1], email, tenantCode, operation)),
      'By clicking "confirm", you consent to send a request email to remove this user from point of contact list.'
    );
  };

  const handleAddPoCRequestDialogClose = (data) => {
    setShowAddPoCDialog(false);
    if (data) {
      store.dispatch(Action.sendPoCOpsRequest(data?.firstName, data?.lastName, data?.email, rowData?.tenantCode, 'add'));
    }
  };

  const handleAddPoCRequest = () => {
    setShowAddPoCDialog(true);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary id="renewal-point-of-contact-header" expandIcon={<ExpandMoreIcon />}>
          <Typography>{ANNUAL_PLAN_RENEWAL_DETAILS.RENEWAL_POINT_OF_CONTACT.SUMMARY}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography variant="body1">{ANNUAL_PLAN_RENEWAL_DETAILS.RENEWAL_POINT_OF_CONTACT.DETAILS.TENANT_TEXT}</Typography>
            </Grid>
            <Grid item xs={7} className={classes.root}>
              {rowData?.contacts?.map((contact) => (
                <Chip
                  key={'key-' + uuid()}
                  label={contact?.fullName}
                  onDelete={() => {
                    handlePoCChangeRequest(contact?.fullName, contact?.email, rowData?.tenantCode, 'remove');
                  }}
                />
              ))}
            </Grid>
            <Grid item xs={1}>
              <IconButton
                id={'renewal-point-of-contact-add'}
                key={'key-' + uuid()}
                onClick={() => {
                  handleAddPoCRequest();
                }}
                title="Add New">
                <Add style={{ backgroundColor: 'green', fill: 'white' }} />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <div align="center">
                <p style={{ fontSize: '0.8rem' }}>
                  Any changes to Point of Contacts are pending Renewal Team approval. For questions, please reach out to{' '}
                  <a href="mailto: insights-access@multiplan.com"> Insights Access </a>.
                </p>
              </div>
            </Grid>
          </Grid>
          <AddPoCDialog open={showAddPoCDialog} onClose={handleAddPoCRequestDialogClose} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default RenewalPointOfContact;
