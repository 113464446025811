import * as Types from '../action/types';

let initialState = {
  shouldRefresh: false,
};

export default function tagManagementReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };

    case Types.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };

    default:
      return state;
  }
}
