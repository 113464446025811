import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import { AppConfig } from 'AppConfig';
import { checkAuth, dispatchJwt } from './checkAuth';
import Auth from 'auth/Auth';

const PrivateRoute = ({ component: Component, fallback, ...rest }) => {
  useEffect(() => {
    const jwt = checkAuth();
    if (jwt) {
      dispatchJwt(jwt);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (checkAuth()) {
          return <Component />;
        } else {
          /* The Redirect component can't redirect to an external page. As a result, we set  
             window.location.href directly to bypass the limitation. */
          if (Auth.getLoginMode() === Auth.LOGIN_MODE_HST) {
            Auth.removeLoginMode();
            window.location.href = AppConfig.OKTA_URL;
            return null;
          } else {
            return (
              <Redirect
                to={{
                  pathname: `${RouteConfig.login.path}`,
                  state: { from: props.location }
                }}
              />
            );
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
