import * as Types from '../action/types';

let initialState = {
  roleList: [],
  permissionList: [],
  shouldRefresh: false
};

export default function adminRoleManagementReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case Types.FETCH_ROLE_LIST:
      return {
        ...state,
        roleList: action.data
      };

    case Types.FETCH_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.data
      };

    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };

    default:
      return state;
  }
}
