import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import * as Action from '../action/action';
import { store } from 'store/store';
import SubmitButton from 'common/SingleClickButton';
import BrokerCreationDetail from './BrokerCreationDetails';

const BrokerCreationDialog = (props) => {
  const { onClose, open, target } = props;
  const methods = useForm({
    defaultValues: target,
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  const { reset, formState } = methods;

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (data) => {
    const payload = {
      code: data.code,
      name: data.name,
      active: data.active
    };
    if (data.id) {
      store.dispatch(Action.updateBroker(payload, data.id));
    } else {
      store.dispatch(Action.createBroker(payload));
    }

    onClose();
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>{target && target?.id ? 'Edit' : 'New'}</DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <BrokerCreationDetail />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            handleSubmit={methods.handleSubmit(onSubmit)}
            isDirty={formState.isDirty}
          />
          <Button autoFocus onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BrokerCreationDialog;
