import React, { PureComponent } from 'react';
import { MDBIcon, MDBRow } from 'mdbreact';
import axios from 'axios';
import { getRangerTicket } from 'utils/getUrlTicket';
import debounce from 'lodash.debounce';
import { displayIndicatorsAsPercent } from 'utils/common';
import { AppConfig } from 'AppConfig';
import { catchTableauReqCancel } from 'utils/catchTableauReqCancel';
class AccountTable extends PureComponent {
  state = {
    ...this.props.values,
    test: true,
    myBlob: null,
    vizToShow: null,
    vizUrls: [],
    vizContainers: {}
  };

  cards = [
    {
      title: 'Home',
      tableau: [
        'https://tb2.benefitsscience.com/trusted/tableauToken/t/:tenant/views/overview1/overview'
      ]
    },
    {
      title: 'MPR',
      tableau: [
        'https://tb2.benefitsscience.com/trusted/tableauToken/t/:tenant/views/fmr/FMRTable'
      ]
    },
    {
      title: 'Financial Report',
      tableau: [
        'https://tb2.benefitsscience.com/trusted/tableauToken/t/:tenant/views/FAReports/Cover',
        'https://tb2.benefitsscience.com/trusted/tableauToken/t/:tenant/views/FinancialReportPackage/Cover'
      ]
    },
    {
      title: 'Diagnosis',
      tableau: [
        'https://tb2.benefitsscience.com/trusted/tableauToken/t/:tenant/views/DiagnosisAnalysis/Cover'
      ]
    },
    {
      title: 'Risk Score',
      tableau: [
        'https://tb2.benefitsscience.com/trusted/tableauToken/t/:tenant/views/RiskScoreOverTime/RiskOverTime'
      ]
    },
    {
      title: 'Drug Utilization',
      tableau: [
        'https://tb2.benefitsscience.com/trusted/tableauToken/t/:tenant/views/Drugs_Overview/RxCover'
      ]
    },
    {
      title: 'Bst Robot'
    }
  ];

  renderButton = (tableauUrls) => {
    if (this.state.vizUrls.length == 0) {
      return (
        <button
          className="btn btn-primary btn-sm"
          onClick={debounce(
            () => this.detailButtonClick(tableauUrls),
            AppConfig.DEBOUNCE_WAIT_TIME
          )}>
          Go&nbsp; <MDBIcon icon="hand-point-right" />
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-primary btn-sm"
          onClick={debounce(
            () =>
              this.setState({ ...this.state, vizToShow: false, vizUrls: [] }),
            AppConfig.DEBOUNCE_WAIT_TIME
          )}>
          Close
        </button>
      );
    }
  };

  detailButtonClick = (tableauUrls) => {
    const ths = this;
    this.setState({ ...this.state, vizUrls: tableauUrls, vizContainers: {} });
    tableauUrls.forEach((url) => {
      getRangerTicket(
        url.replace(':tenant', this.state.selectedTenants),
        this.state.selectedTenants
      )
        .then((res) => {
          let options = {
            hideTabs: false,
            width: '1800px',
            height: '0px',
            onFirstInteractive: function () {
              ths.setState({ ...ths.state, vizToShow: url });
              ths[url].setFrameSize(1800, 700);
            }
          };
          this[url] = new window.tableau.Viz(
            this.state.vizContainers[url],
            res,
            options
          );
        })
        .catch((error) => {
          catchTableauReqCancel(error, 'No Content Available');
        });
    });
  };

  componentDidMount() {
    if (this.state.test === true && !this.state.myBlob) {
      axios
        .post(AppConfig.FO_RANGER_URL + '/logo/brkIcon', {
          tenantCode: this.state.selectedTenants
        })
        .then((res) => {
          this.setState({
            ...this.state,
            myBlob: 'data:image/jpeg;base64,' + res.data.data
          });
        })
        .catch((err) => console.log(err));
    }
  }

  componentWillUnmount() {
    this.setState({ ...this.state, vizUrls: [] });
  }

  render() {
    return (
      <>
        <MDBRow center>
          <table border="1" width="50%" className="ml-2 mr-2">
            <thead
              style={{
                color: 'blue'
              }}>
              <tr>
                <th
                  style={{
                    width: '200px',
                    height: '60px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}
                  className="text-center">
                  Data Thru Date
                </th>
                <th
                  style={{
                    width: '200px',
                    height: '60px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}
                  className="text-center">
                  Inspection
                </th>
                <th
                  className="text-center"
                  style={{
                    width: '175px',
                    height: '60px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}>
                  Page
                </th>
                <th
                  style={{
                    width: '75px',
                    height: '60px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}
                  className="text-center">
                  FO Report
                </th>
                {this.state.test === true && (
                  <th
                    style={{
                      width: '75px',
                      height: '60px',
                      fontWeight: 'bold',
                      fontSize: 'large'
                    }}
                    className="text-center">
                    Value
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'planLossPercent'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'planLossPercent'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Abnormal plan loss ratio
                </td>
                <td className="pl-2">{this.cards[0].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[0].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {this.state.homeData.planLossPercent}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'memberCount'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'memberCount'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Member count is 0
                </td>
                <td className="pl-2">{this.cards[0].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[0].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">{this.state.homeData.memberCount}</td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'percentInNetwork'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'percentInNetwork'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Abnormal in-network utilization rate
                </td>
                <td className="pl-2">{this.cards[0].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[0].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {this.state.homeData.percentInNetwork}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'employeeEnrollment'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'employeeEnrollment'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Employee enrollment changed over &plusmn;5%
                </td>
                <td className="pl-2">{this.cards[1].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[1].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {(
                      (100 * this.state.homeData.employeeEnrollment) /
                      this.state.homeData.lastEmployeeEnrollment -
                      100
                    ).toFixed(2)}
                    %
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'memberEnrollment'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'memberEnrollment'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Member enrollment changed over &plusmn;5%
                </td>
                <td className="pl-2">{this.cards[1].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[1].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {(
                      (100 * this.state.homeData.memberEnrollment) /
                      this.state.homeData.lastMemberEnrollment -
                      100
                    ).toFixed(2)}
                    %
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'medClaims'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'medClaims'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Medical claims changed over &plusmn;30%
                </td>
                <td className="pl-2">{this.cards[1].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[1].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {(
                      (100 * this.state.homeData.medClaims) /
                      this.state.homeData.lastMedClaims -
                      100
                    ).toFixed(2)}
                    %
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'rxClaims'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'rxClaims'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Rx claims changed over &plusmn;30%
                </td>
                <td className="pl-2">{this.cards[1].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[1].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {(
                      (100 * this.state.homeData.rxClaims) /
                      this.state.homeData.lastRxClaims -
                      100
                    ).toFixed(2)}
                    %
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    (this.props.hasFieldError(
                      this.state.homeData,
                      'medCopay'
                    ) === true &&
                      this.props.hasFieldError(
                        this.state.homeData,
                        'medDeductible'
                      ) === true &&
                      this.props.hasFieldError(
                        this.state.homeData,
                        'medCoinsurance'
                      ) === true) ||
                      this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'medCopay'
                      ) === true &&
                        this.props.hasFieldError(
                          this.state.homeData,
                          'medDeductible'
                        ) === true &&
                        this.props.hasFieldError(
                          this.state.homeData,
                          'medCoinsurance'
                        ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Plan Year Med Copay, Deductible and Coinsurance are $0
                </td>
                <td className="pl-2">
                  {this.cards[2].title}&#8594;Cost Share Summary
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[2].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    $
                    {this.state.homeData.medCopay +
                      this.state.homeData.medDeductible +
                      this.state.homeData.medCoinsurance}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    (this.props.hasFieldError(
                      this.state.homeData,
                      'rxCopay'
                    ) === true &&
                      this.props.hasFieldError(
                        this.state.homeData,
                        'rxDeductible'
                      ) === true &&
                      this.props.hasFieldError(
                        this.state.homeData,
                        'rxCoinsurance'
                      ) === true) ||
                      this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'rxCopay'
                      ) === true &&
                        this.props.hasFieldError(
                          this.state.homeData,
                          'rxDeductible'
                        ) === true &&
                        this.props.hasFieldError(
                          this.state.homeData,
                          'rxCoinsurance'
                        ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Plan Year Rx Copay, Deductible and Coinsurance are $0
                </td>
                <td className="pl-2">
                  {this.cards[2].title}&#8594;Cost Share Summary
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[2].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    $
                    {this.state.homeData.rxCopay +
                      this.state.homeData.rxDeductible +
                      this.state.homeData.rxCoinsurance}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'lastMonthTotalPremium'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'lastMonthTotalPremium'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Last Month Total Premium is $0
                </td>
                <td className="pl-2">{this.cards[1].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[1].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    ${this.state.homeData.lastMonthTotalPremium}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'lastMonthTotalFixedCost'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'lastMonthTotalFixedCost'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Last Month Total Fixed Cost is $0
                </td>
                <td className="pl-2">{this.cards[1].title}</td>
                <td className="text-center">
                  {this.renderButton(this.cards[1].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    ${this.state.homeData.lastMonthTotalFixedCost}
                  </td>
                )}
              </tr>

              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'sumProjectedReimbursements'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'sumProjectedReimbursements'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Sum of projected reimbursement do not match
                </td>
                <td className="pl-2">
                  {this.cards[2].title}&#8594;Stop Loss Policy Performance
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[2].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    ${this.state.homeData.sumProjectedReimbursements}, $
                    {this.state.homeData.islReimbursement}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'icdCategoryNull'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'icdCategoryNull'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  ICD Category Report
                  {!this.state.homeData.icdCategoryNull ||
                    this.state.homeData.icdCategoryNull === false
                    ? ' does not have null'
                    : ' has null'}
                </td>
                <td className="pl-2">
                  {this.cards[3].title}&#8594;ICD Chapter Drill Down
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[3].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {this.state.homeData.icdCategoryNull}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'highestVariation'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'highestVariation'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Risk score variation is out of range
                </td>
                <td className="pl-2">
                  {this.cards[4].title}&#8594;Risk Over Time
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[4].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {this.state.homeData.highestVariation}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'riskscorePeriod'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'riskscorePeriod'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Risk score is not update to date
                </td>
                <td className="pl-2">
                  {this.cards[4].title}&#8594;Risk Over Time
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[4].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {this.state.homeData.riskscorePeriod},{' '}
                    {this.state.homeData.dataThruDate}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'brandCodesNull'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'brandCodesNull'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Top 20 Brand Drugs have null drug class.
                </td>
                <td className="pl-2">
                  {this.cards[5].title}&#8594;Top Brand Drugs
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[5].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">{this.state.homeData.brandCodesNull}</td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'brandNamesNull'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'brandNamesNull'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Top 20 Brand Drugs have null drug name.
                </td>
                <td className="pl-2">
                  {this.cards[5].title}&#8594;Top Brand Drugs
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[5].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">{this.state.homeData.brandNamesNull}</td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'genericCodesNull'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'genericCodesNull'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Top 20 Generic Drugs have null drug class
                </td>
                <td className="pl-2">
                  {this.cards[5].title}&#8594;Top Generic Drugs
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[5].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {this.state.homeData.genericCodesNull}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'genericNamesNull'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'genericNamesNull'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Top 20 Generic Drugs have null drug name
                </td>
                <td className="pl-2">
                  {this.cards[5].title}&#8594;Top Generic Drugs
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[5].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {this.state.homeData.genericNamesNull}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'unexpectedBrandGenericIndicators'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'unexpectedBrandGenericIndicators'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Unexpected Brand/Generic indicator showed up
                </td>
                <td className="pl-2">
                  {this.cards[5].title}&#8594;Utilization Share
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[5].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {displayIndicatorsAsPercent(
                      this.state.homeData.unexpectedBrandGenericIndicators
                    )}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'unexpectedFormularyIndicators'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'unexpectedFormularyIndicators'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Unexpected Formulary indicator showed up
                </td>
                <td className="pl-2">
                  {this.cards[5].title}&#8594;Utilization Share
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[5].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {displayIndicatorsAsPercent(
                      this.state.homeData.unexpectedFormularyIndicators
                    )}
                  </td>
                )}
              </tr>
              <tr
                style={{
                  display:
                    this.props.hasFieldError(
                      this.state.homeData,
                      'unexpectedMailOrderIndicators'
                    ) === true || this.state.showDetailPage === true
                      ? 'table-row'
                      : 'none'
                }}>
                <td className="pl-2">{this.state.homeData.dataThruDate}</td>
                <td
                  className="pl-2"
                  style={{
                    color:
                      this.props.hasFieldError(
                        this.state.homeData,
                        'unexpectedMailOrderIndicators'
                      ) === true
                        ? 'red'
                        : 'green'
                  }}>
                  Unexpected Mail Order indicator showed up
                </td>
                <td className="pl-2">
                  {this.cards[5].title}&#8594;Utilization Share
                </td>
                <td className="text-center">
                  {this.renderButton(this.cards[5].tableau)}
                </td>
                {this.state.test === true && (
                  <td className="pl-2">
                    {displayIndicatorsAsPercent(
                      this.state.homeData.unexpectedMailOrderIndicators
                    )}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </MDBRow>
        {this.state.vizUrls.map((vizUrl) => (
          <MDBRow
            key={vizUrl + '_div_key'}
            className="d-flex justify-content-center align-items-center">
            <div
              id={vizUrl + 'div'}
              ref={(div) => {
                this.state.vizContainers[vizUrl] = div;
              }}></div>
          </MDBRow>
        ))}
      </>
    );
  }
}

export default AccountTable;
