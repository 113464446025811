import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';

const DisableValidationConfirmation = (props) => {
  const { onClose, open, passValidation, validation } = props;

  const handleClose = () => {
    onClose();
  };

  const handleCloseCancel = () => {
    passValidation(!validation);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseCancel();
        }}>
        <DialogTitle>Confirmation</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={handleCloseCancel}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          Are you sure you want to disable validation on data entered?
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={() => handleClose()}>
            Confirm
          </Button>
          <Button color="secondary" onClick={() => handleCloseCancel()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisableValidationConfirmation;
