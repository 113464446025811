import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import RoleTenantListDialogDetail from './roleTenantListDialogDetail';
import { useForm, FormProvider } from 'react-hook-form';

const RoleTenantListDialog = (props) => {
  const {
    onClose,
    open,
    target,
    roleList,
    unselectedRoleList,
    tenantList,
    unselectedTenantList,
    appName
  } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  const addOneToUnselectedRoleList = (inputList, roleId) => {
    let tempRoleList = inputList.map((a) => Object.assign({}, a));
    const selectedRole = roleList.find((item) => item.id === roleId);
    if (selectedRole) tempRoleList.push(selectedRole);

    return tempRoleList;
  };

  const addOneToUnselectedTenantList = (inputList, tenantIdList) => {
    let tempTenantList = inputList.map((a) => Object.assign({}, a));
    let existingMap = {};
    tenantIdList.map((item) => {
      existingMap[item] = item;
    });
    let addArray = tenantList.filter((item) =>
      existingMap.hasOwnProperty(item.id)
    );
    return [...tempTenantList, ...addArray];
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {target && target.id > 0
              ? 'Edit'
              : appName + ': Role Tenant Access'}
          </DialogTitle>
          <DialogContent dividers>
            <RoleTenantListDialogDetail
              isModified={target && target.id > 0}
              targetId={target ? target.id : null}
              roleList={
                target && target.roleId
                  ? addOneToUnselectedRoleList(
                      unselectedRoleList,
                      target.roleId
                    )
                  : unselectedRoleList
              }
              tenantList={
                target && target.tenantIdList && target.tenantIdList.length > 0
                  ? addOneToUnselectedTenantList(
                      unselectedTenantList,
                      target.tenantIdList
                    )
                  : unselectedTenantList
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary"
              disabled={!methods.formState.isDirty}>
              Save
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default RoleTenantListDialog;
