import * as FoActions from 'features/frontoffice/actions/actions';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'store/store';

const notAvailable = 'Data available through: N/A';

const Inspection = () => {
  const inspection = useSelector((state) => state.fo.inspection);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  useEffect(() => {
    store.dispatch(FoActions.getInspectionForTenant(currentTenantCd));
  }, [currentTenantCd, inspection]);

  return (
    <>
      <div className="site-main__top">
        {inspection ? (
          <img
            src={`data:image/jpeg;base64,${inspection}`}
            style={{
              width: '150px',
              height: '20px',
              float: 'right'
            }}
            alt={notAvailable}
          />
        ) : (
          <p style={{fontSize: '10px', float: 'right' }}>
            {notAvailable}
          </p>
        
        )}
      </div>
    </>
  );
};

export default Inspection;
