import { store } from 'store/store';
import axios from 'axios';
import * as AppActionTypes from 'store/actions/appstate';

export const catchTableauReqCancel = (error, msg) => {
  if (axios.isCancel(error) === false) {
    store.dispatch({
      type: AppActionTypes.APPSTATE_SET_APP_ERROR,
      payload: msg
    });
    console.log('Cannot get tableau ticket ', error);
  }
};
