import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import * as FoAction from 'features/frontoffice/actions/actions';
import * as CustomAction from 'features/frontoffice/customReport/actions/action';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const getFavoriteReport = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.FO_INITIAL_URL + `/favorite-report`)
      .then((m) => {
        let response = m.data;

        if (response && Array.isArray(response)) {
          response.map((item) => (item.favorited = true));
        }
        dispatch({
          type: Types.GET_REPORT_BY_USER_ID_SUCCESS,
          data: response
        });
      })
      .catch((err) => {});
  };
};

export const addFavoriteReport = (reportId, refreshReporttype) => {
  return (dispatch) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + `/favorite-report/id/${reportId}`)
      .then((res) => {
        refreshReport(refreshReporttype);
      });
  };
};

export const deleteFavoriteReport = (reportId, refreshReporttype) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.FO_INITIAL_URL + `/favorite-report/report/${reportId}`)
      .then((res) => {
        refreshReport(refreshReporttype);
      });
  };
};

export const setCurrentMenuItem = (item) => {
  return (dispatch) => {
    dispatch({
      type: Types.SET_CURRENT_SELECTED_MENU,
      data: item
    });
  };
};

// Future Optimization: grab just the selected report type
const refreshReport = (refreshReporttype) => {
  switch (refreshReporttype) {
    case AppConfig.REPORT_CATEGORY.LEGACY:
      store.dispatch(FoAction.getStandardReport());
      break;
    case AppConfig.REPORT_CATEGORY.CUSTOM:
      store.dispatch(CustomAction.fetchCustomReports());
      break;
    case AppConfig.REPORT_CATEGORY.SMART:
    case AppConfig.REPORT_CATEGORY.EXPLORER:
      store.dispatch(FoAction.getFoDetail());
      break;
    default:
      store.dispatch(getFavoriteReport());
  }
};
