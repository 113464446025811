import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import SubmitButton from 'common/SingleClickButton';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { save } from '../action/action';
import MdRenewalCreationDetails from './MdRenewalCreationDetails';

const MdRenewalCreationDialog = ({ onClose, open, target }) => {
  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { formState, reset, handleSubmit } = methods;
  const dispatch = useDispatch();

  useEffect(() => {
    reset(target);
  }, [open, target]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data) => {
    const payload = {
      tenantCode: data.tenant?.code,
      planStartDate: data.caseStartDate,
      planEndDate: data.caseEndDate
    };
    dispatch(save([payload]));
    onClose();
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>New</DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <MdRenewalCreationDetails />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <SubmitButton handleSubmit={handleSubmit(onSubmit)} isDirty={formState.isDirty} />
          <Button onClick={() => onClose(null)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MdRenewalCreationDialog;
