import React, { useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import RouteConfig from 'routes/RouteConfig';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchData } from 'features/user/userActions';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import VendorLabelingTable from '../vendor-labeling';
import TagManagementTable from '../tagManagement/tagManagementTable';
import WhiteLabelingManagement from '../white-labeling';
import VendorLabelingBrokerView from '../vendor-labeling/brokerView';

const WhiteLabelNavigator = () => {
  const WHITE_LABEL_URL = RouteConfig.whiteLabeling.path;
  const TAG_MANAGEMENT_URL =
    WHITE_LABEL_URL + AppConfig.WHITE_LABEL_SUB_URL.TAG_MANAGEMENT;
  const VENDOR_MANAGEMENT_URL =
    WHITE_LABEL_URL + AppConfig.WHITE_LABEL_SUB_URL.VENDOR_LABELING;
  const VENDOR_MANAGEMENT_BROKER_VIEW_URL =
    WHITE_LABEL_URL + AppConfig.WHITE_LABEL_SUB_URL.VENDOR_LABELING_BROKER_VIEW;
  const WHITE_LABEL_MANAGEMENT_URL =
    WHITE_LABEL_URL + AppConfig.WHITE_LABEL_SUB_URL.WHITE_LABEL;

  const history = useHistory();
  const { deafultUserState, currentTenantCd } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deafultUserState) {
        store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_FO));
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Tabs
        value={history.location.pathname}
        indicatorColor="primary"
        textColor="primary">
        <Tab
          label="White Label"
          value={WHITE_LABEL_MANAGEMENT_URL}
          component={Link}
          to={WHITE_LABEL_MANAGEMENT_URL}
        />
        <Tab
          label="Vendor Labeling: Company"
          value={VENDOR_MANAGEMENT_URL}
          component={Link}
          to={VENDOR_MANAGEMENT_URL}
        />
        <Tab
          label="Vendor Labeling: Broker"
          value={VENDOR_MANAGEMENT_BROKER_VIEW_URL}
          component={Link}
          to={VENDOR_MANAGEMENT_BROKER_VIEW_URL}
        />
        <Tab
          label="Tag Management"
          value={TAG_MANAGEMENT_URL}
          component={Link}
          to={TAG_MANAGEMENT_URL}
        />
      </Tabs>
      {history.location.pathname === VENDOR_MANAGEMENT_URL && (
        <VendorLabelingTable />
      )}
      {history.location.pathname === VENDOR_MANAGEMENT_BROKER_VIEW_URL && (
        <VendorLabelingBrokerView />
      )}
      {history.location.pathname === TAG_MANAGEMENT_URL && (
        <TagManagementTable />
      )}
      {history.location.pathname === WHITE_LABEL_MANAGEMENT_URL && (
        <WhiteLabelingManagement />
      )}
    </>
  );
};

export default WhiteLabelNavigator;
