/**
 * Sorts an array of objects by a given field name in the specified order.
 * This function manipulates the original list.
 * Supports nested fields.
 * 
 * @param {Array} list - The array of objects to be sorted.
 * @param {string} field - The field name to sort by. Use dot notation for nested fields.
 * @param {string} order - The order of sorting ('asc' for ascending, 'desc' for descending).
 * @param {string} sensitivityArg - Option strings that differ in base letters compares. I.e.: "base": "a ≠ b, a = á, a = A"
 */
export function sortListInPlace(list, field, order = 'asc', sensitivityArg = 'base') {
  const getValue = (obj, field) => field.split('.').reduce((o, i) => (o ? o[i] : null), obj);

  list.sort((a, b) => {
    return sortHelperCallback(getValue(a, field), getValue(b, field), order, sensitivityArg)
  });
};

/**
 * Sorts an array of objects by a given field name in the specified order.
 * This function returns a sorted copy of the original list.
 * Supports nested fields.
 * 
 * @param {Array} list - The array of objects to be sorted.
 * @param {string} field - The field name to sort by. Use dot notation for nested fields.
 * @param {string} order - The order of sorting ('asc' for ascending, 'desc' for descending).
 * @param {string} sensitivityArg - Option strings that differ in base letters compares. I.e.: "base": "a ≠ b, a = á, a = A"
 * @returns {Array} - A new sorted array.
 */
export function sortListWithShallowCopy(list, field, order = 'asc', sensitivityArg = 'base') {
  const getValue = (obj, field) => field.split('.').reduce((o, i) => (o ? o[i] : null), obj);

  return list.slice().sort((a, b) => {
    return sortHelperCallback(getValue(a, field), getValue(b, field), order, sensitivityArg)
  });
};

/**
 * Helper callback for code reusability.
 * 
 * @param {*} aValue
 * @param {*} bValue
 * @param {*} order
 * @param {*} sensitivityArg
 * @returns 
 */
function sortHelperCallback(aValue, bValue, order, sensitivityArg) {
  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return order === 'asc' ? aValue - bValue : bValue - aValue;
  } else if (typeof aValue === 'string' && typeof bValue === 'string') {
    return order === "asc"
      ? aValue.localeCompare(bValue, undefined, { sensitivity: sensitivityArg })
      : bValue.localeCompare(aValue, undefined, { sensitivity: sensitivityArg });
  } else {
    return 0; // Default case if values are not comparable
  }
}

/**
 * Sorts an array of objects by a given primary and secondary field name in the specified order.
 * This function manipulates the original list.
 * Supports nested fields.
 * 
 * @param {Array} list - The array of objects to be sorted.
 * @param {string} field - The primary field name to sort by. Use dot notation for nested fields.
 * @param {string} order - The order of primary sorting ('asc' for ascending, 'desc' for descending).
 * @param {string} secondField - The secondary field name to sort by if primary fields are equal. Use dot notation for nested fields.
 * @param {string} secondOrder - The order of secondary sorting ('asc' for ascending, 'desc' for descending).
 * @param {string} sensitivityArg - Option strings that differ in base letters compares. I.e.: "base": "a ≠ b, a = á, a = A"
 */
export function twoLevelsortListInPlace(list, field, order = 'asc', secondField, secondOrder = 'asc', sensitivityArg = 'base') {
  const getValue = (obj, field) => field.split('.').reduce((o, i) => (o ? o[i] : null), obj);

  list.sort((a, b) => {
    const primaryComparison = sortHelperCallback(getValue(a, field), getValue(b, field), order, sensitivityArg);
    if (primaryComparison !== 0) {
      return primaryComparison;
    }
    return sortHelperCallback(getValue(a, secondField), getValue(b, secondField), secondOrder, sensitivityArg);
  });
};