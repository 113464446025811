import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as Actions from './actions/action';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { confirmDialog } from 'common/ComfirmationDialog';
import _ from 'lodash';
import * as DateUtil from '../../util/Dateutil';
import EmployeClassDetailDialog from './component/detailDialog';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Alert from '@material-ui/lab/Alert';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Constant from 'common/Constant';
import { AppConfig } from 'AppConfig';
import moment from 'moment-timezone';
import Papa from 'papaparse';
import {v4 as uuid} from 'uuid';
import { FoMaterialTable } from 'common/FoMaterialTable';

const Division = ({ tenantId }) => {
  const tableRef = useRef();
  const importRef = useRef();

  const [errorMsg, setErrorMsg] = useState(null);
  const [errorMsgOpen, setErrorMsgOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);

  const shouldRefresh = useSelector((state) => state.MD.division.shouldRefresh);

  const handleOpen = (data) => {
    setCloneData(data);
    setOpen(!open);
  };

  const handleClose = (data) => {
    if (data) {
      store.dispatch(Actions.save([data]));
    }

    setOpen(false);
  };

  const handleFiles = (event) => {
    setErrorMsg(null);
    setErrorMsgOpen(false);
    event.preventDefault();
    //read file choosen from local
    const file = event.target.files[0];
    // parsing csv using papaparse library
    Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy', // this is for avoid empty row from read
      complete: (content) => {
        const mappedContent = content.data.map((item) => {
          item.tenantId = tenantId;
          item.startDate = item.startDate ? moment(item.startDate) : '';
          item.endDate = item.endDate ? moment(item.endDate) : '';
          item.transientItem = true;
          return item;
        });

        const groupMap = new Map();
        let lineNumbers = [];
        const conflictLineNumbers = [];
        let cnt = 1;
        mappedContent.forEach((item) => {
          const nameField = item.name;
          const divisionField = item.divisionNumber;
          const startField = item.startDate;
          const endField = item.endDate;
          if (startField || endField) {
            if (!(startField && endField)) {
              lineNumbers.push(cnt);
            }
            const divsForGroup = groupMap.get(divisionField);
            if (!divsForGroup) {
              groupMap.set(divisionField, [
                { start: moment(startField), end: moment(endField) }
              ]);
            } else {
              const startMoment = moment(startField);
              const endMoment = moment(endField);
              divsForGroup.forEach((date) => {
                if (
                  startMoment.isBefore(date.end) &&
                  endMoment.isAfter(date.start)
                ) {
                  conflictLineNumbers.push(cnt);
                }
              });
              divsForGroup.push({ start: startMoment, end: endMoment });
            }
          }
          if (!nameField || nameField.trim() === '') {
            lineNumbers.push(cnt);
          } else if (!divisionField || divisionField.trim() === '') {
            lineNumbers.push(cnt);
          }
          cnt++;
        });
        if (lineNumbers.length > 0) {
          setErrorMsg(
            'Missing required data in following line: ' + lineNumbers.join(',')
          );
          setErrorMsgOpen(true);
        } else if (conflictLineNumbers.length > 0) {
          setErrorMsg(
            'There are start/end date conflicts in the following lines: ' +
              conflictLineNumbers.join(',')
          );
          setErrorMsgOpen(true);
        } else {
          store.dispatch(Actions.save(mappedContent));
        }
      }
    });
  };

  const deleteByIds = (rowData) => {
    const ids = rowData.map((item) => item.id);
    confirmDialog(() => store.dispatch(Actions.deleteByIds(ids)));
  };

  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith('.csv')
      ? fileName
      : `${fileName}.csv`;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    a.setAttribute('download', finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const download = () => {
    store.dispatch(
      Actions.exportCSV({
        tenantId: tenantId,
        size: AppConfig.DOWNLOAD_MAX_ROW_COUNT,
        direction: Constant.DIRECTION_ASC,
        sortProperty: 'name'
      })
    );
  };

  const refresh = () => {
    if (tenantId) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  };

  useEffect(() => {
    refresh();
  }, [tenantId]);

  useEffect(() => {
    if (shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE) {
      refresh();
    }
  }, [shouldRefresh]);

  return (
    <>
      <Collapse in={errorMsgOpen}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorMsgOpen(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {errorMsg}
        </Alert>
      </Collapse>

      <FoMaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Division Number', field: 'divisionNumber' },
          {
            title: 'Start Date',
            field: 'startDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.startDate)
          },
          {
            title: 'End Date',
            field: 'endDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.endDate)
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.API_BASE_URL + `v2/division/search`, {
                tenantId: tenantId,
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'name'
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                console.log('error' + err);
              });
          })
        }
        icons={{
          Export: () => <ImportExportIcon />
        }}
        localization={{
          toolbar: {
            exportTitle: 'Import',
            exportCSVName: 'Download Sample CSV',
            exportPDFName: 'Import From File'
          }
        }}
        onChangeRowsPerPage={setPageSize}
        options={{
          selection: true,
          actionsColumnIndex: -1,
          search: false,
          exportButton: true,
          exportCsv: (columns) => {
            const headerRow = columns.map((col) => {
              return col.field;
            });

            const { exportDelimiter } = tableRef.current.props.options;
            const delimiter = exportDelimiter ? exportDelimiter : ',';
            const csvContent = [headerRow]
              .map((e) => e.join(delimiter))
              .join('\n');

            const csvFileName = 'division';

            // Allow user to download file as .csv
            downloadCsv(csvContent, csvFileName);
          },
          exportPdf: () =>
            importRef && importRef.current ? importRef.current.click() : null,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          {
            icon: 'save_alt',
            tooltip: 'Export',
            isFreeAction: true,
            onClick: () => {
              download();
            }
          },
          {
            position: 'row',
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleOpen({ ...rowData, transientItem: false });
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (evt, data) => deleteByIds(data)
          },
          {
            position: 'row',
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (_, rowData) => deleteByIds([rowData])
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleOpen({
                tenantId: tenantId,
                transientItem: true,
                startDate: null,
                endDate: null
              });
            }
          }
        ]}
      />

      <EmployeClassDetailDialog
        open={open}
        onClose={handleClose}
        tenantId={tenantId}
        target={cloneData}
      />
      <input
        type="file"
        key={uuid()}
        onChange={handleFiles}
        ref={importRef}
        hidden
        accept=".csv"
      />
    </>
  );
};

export default Division;
