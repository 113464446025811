import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import Laser from './laser';
import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from 'common/SingleClickButton';

const LaserDialog = (props) => {
  const { onClose, open, tenantId, target, slcStartDate, slcEndDate } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleClose();
          }}>
          <DialogTitle id="customized-dialog-title">
            {target && target.id > 0 ? 'Edit' : 'New Laser'}
          </DialogTitle>
          <DialogContent dividers>
            <Laser
              tenantId={tenantId}
              isModified={target && target.id > 0}
              targetId={target ? target.id : null}
              slcStartDate={slcStartDate}
              slcEndDate={slcEndDate}
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default LaserDialog;
