import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import PolicyExclusion from './policyExclusion';
import { useForm, FormProvider } from 'react-hook-form';

const PolicyExclusionDialog = (props) => {
  const { onClose, open, tenantId, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            New Policy Exclusion
          </DialogTitle>
          <DialogContent dividers>
            <PolicyExclusion
              tenantId={tenantId}
              isModified={target && target.id > 0}
              targetId={target ? target.id : null}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary"
              disabled={!methods.formState.isDirty}>
              Add
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default PolicyExclusionDialog;
