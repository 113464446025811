import * as Types from '../actions/types';
import * as Actions from '../actions/PlanDesignDataAction';

let initialState = {
  isLoading: false,
  shouldRefresh: Actions.SHOULD_REFRESH_INIT_VALUE,
  availablePlanYearToClone: []
};

export default function plandDesignDataReducer(state = initialState, action) {
  switch (action.type) {
    case Types.DELETE_PLAN_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        allNetworkTypes: action.data,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.CLONE_PLAN_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.FETCH_AVAILABLE_PLAN_YEAR_TO_CLONE_SUCCESS:
      return {
        ...state,
        availablePlanYearToClone: action.data
      };
    case Types.SAVE_PLAN_DESIGN_DATA_SUCCESS:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    default:
      return state;
  }
}
