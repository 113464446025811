import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';
import * as Constant from 'common/Constant';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const deleteById = (id) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `planrates?id=${id}`)
      .then((m) => {
        dispatch({
          type: Types.DELETE_PLAN_RATE_SUCCESS
        });
      })
      .catch((err) => { });
  };
};

export const save = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan-rate`, data)
      .then((m) => {
        dispatch({
          type: Types.SAVE_PLAN_RATE_SUCCESS
        });
      })
      .catch((err) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: err
        });
      });
  };
};

export const update = (data) => {
  return (dispatch) => {
    axios
      .put(AppConfig.API_BASE_URL + `v2/plan-rate`, data)
      .then((m) => {
        dispatch({
          type: Types.SAVE_PLAN_RATE_SUCCESS
        });
      })
      .catch((err) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: err
        });
      });
  };
};

export const deleteByIds = (ids) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `v2/plan-rate/`, { data: ids })
      .then((m) => {
        dispatch({
          type: Types.DELETE_PLAN_RATE_SUCCESS
        });
      })
      .catch((err) => { });
  };
};

export const fetchAllUniqueNamePlanDesignData = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + `v2/plan-rate/all/unique/plan/name`)
      .then((m) => {
        dispatch({
          type: Types.FETCH_ALL_UNIQUE_NAME_PLAN_DESIGN_BY_TENANT_ID_SUCCESS,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const clone = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan-rate/clone`, data)
      .then((m) => {
        dispatch({
          type: Types.SAVE_PLAN_RATE_SUCCESS
        });
      })
      .catch((err) => {
        console.error(`Error during cloning: ${err}`);
      });
  };
};

export const exportCSV = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan-rate/download/csv`, data)
      .then((m) => {
        if (m?.data) {
          const url = window.URL.createObjectURL(new Blob([m.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `planRate.csv`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: err
        });
      });
  };
};

export const validateBulkModify = (query) => {
  return axios.post(AppConfig.API_BASE_URL + `v2/plan-rate/bulk-modify/valid`, {
    ...query,
    direction: query.orderDirection
      ? query.orderDirection.toUpperCase()
      : Constant.DIRECTION_DESC
  });
};

export const bulkModify = (data, refresh) => {
  axios
    .post(AppConfig.API_BASE_URL + `v2/plan-rate/bulk-modify/modify`, data)
    .then((m) => {
      refresh();
    })
    .catch((err) => {
      store.dispatch({
        type: AppActionTypes.APPSTATE_SET_APP_ERROR,
        payload: err
      });
    });
};
