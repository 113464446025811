import React from 'react';
import {
  Grid,
  Select,
  makeStyles,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: '400px'
  },
  helperText: {
    color: 'red'
  },
  select: {
    minWidth: '120px'
  }
}));

const DiscretionaryBenefitsCloneDetail = (props) => {
  const { cloneError } = props;
  const styles = useStyles();
  const { control, watch } = useFormContext();
  const watchSourceYear = watch('sourceYear', '');
  const watchTargetYear = watch('targetYear', '');
  const planYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );

  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        className={styles.dialog}>
        <Grid item xs={6}>
          <Controller
            name="sourceYear"
            rules={{ required: 'This field is required' }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="clone-source-year-input-label">
                    Source Year
                  </InputLabel>
                  <Select
                    {...field}
                    id="input-sourceYear"
                    labelId="clone-source-year-input-label"
                    className={styles.select}
                    value={watchSourceYear}
                    helperText={error ? error.message : null}
                    error={error}
                    fullWidth
                    required
                    autoFocus
                    label="Source Year">
                    {Array.isArray(planYears) &&
                      planYears.map((option) => {
                        return (
                          <MenuItem key={option.planYearName} value={option}>
                            {option.planYearName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="targetYear"
            rules={{ required: 'This field is required' }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="clone-target-year-input-label">
                    Target Year
                  </InputLabel>
                  <Select
                    {...field}
                    id="input-targetYear"
                    labelId="clone-target-year-input-label"
                    className={styles.select}
                    value={watchTargetYear}
                    helperText={error ? error.message : null}
                    error={error}
                    fullWidth
                    required
                    label="Target Year">
                    {Array.isArray(planYears) &&
                      planYears.map((option) => {
                        return (
                          <MenuItem key={option.planYearName} value={option}>
                            {option.planYearName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          />
        </Grid>
        <FormHelperText className={styles.helperText}>
          {cloneError ? cloneError.message : null}
        </FormHelperText>
      </Grid>
    </>
  );
};

export default DiscretionaryBenefitsCloneDetail;
