import * as Types from '../actions/types';

let initialState = {
  allPlanTypes: [],
  allElectionTiers: [],
  allNetworkTypes: [],
  tpas: [],
  pbms: [],
  slcs: [],
  slms: [],
  employeClasses: [],
  electionTiers: [],
  allPlanYearsForTenant: [],
  plansForYear: [],
  planYears: [],
  allPlanDesignDatas: [],
  allPlanDesignDatasInMap: {},
  allMedPlanDesignDatas: [],
  allPlanNames: []
};

export default function mdCommonReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_PLAN_TYPES_SUCCESS:
      return {
        ...state,
        allPlanTypes: action.data
      };
    case Types.GET_ALL_ELECTION_TIER_SUCCESS:
      return {
        ...state,
        allElectionTiers: action.data
      };
    case Types.GET_ALL_NETWORK_TYPES_SUCCESS:
      return {
        ...state,
        allNetworkTypes: action.data
      };
    case Types.GET_TPA_FOR_TENENT_SUCCESS:
      return {
        ...state,
        tpas: action.data
      };
    case Types.GET_PBM_FOR_TENENT_SUCCESS:
      return {
        ...state,
        pbms: action.data
      };
    case Types.GET_SLC_FOR_TENENT_SUCCESS:
      return {
        ...state,
        slcs: action.data
      };
    case Types.GET_SLM_FOR_TENENT_SUCCESS:
      return {
        ...state,
        slms: action.data
      };
    case Types.GET_EMPLOYE_CLASS_SUCCESS:
      return {
        ...state,
        employeClasses: action.data
      };
    case Types.GET_ELECTION_TIER_BY_TENANT_ID_SUCCESS:
      return {
        ...state,
        electionTiers: action.data
      };
    case Types.FETCH_ALL_PLAN_YEARS_BY_TENANT_ID_SUCCESS:
      return {
        ...state,
        allPlanYearsForTenant: action.data
      };
    case Types.GET_PLANS_BY_ANNUAL_PLAN_ID_SUCCESS:
      return {
        ...state,
        plansForYear: action.data
      };
    case Types.SET_MASTERDATA_PLAN_YEARS:
      return {
        ...state,
        planYears: action.data
      };
    case Types.GET_MED_PLANS_BY_ANNUAL_PLAN_ID_SUCCESS:
      return {
        ...state,
        allMedPlanDesignDatas: action.data
      };
    case Types.FETCH_ALL_UNIQUE_NAME_PLAN_DESIGN_BY_TENANT_ID_SUCCESS:
      const allPlanDesignDatasWithMap = {};
      const allPlanDesignDatasWithUniqueMap = {};
      const allPlanDesignDataWithUniqueName = [];
      if (action.data && Array.isArray(action.data)) {
        action.data.map((item) => {
          if (!allPlanDesignDatasWithMap[item.ahpmId]) {
            allPlanDesignDatasWithMap[item.ahpmId] = [];
          }
          allPlanDesignDatasWithMap[item.ahpmId].push(item);
          if (!allPlanDesignDatasWithUniqueMap.hasOwnProperty(item.planName)) {
            allPlanDesignDatasWithUniqueMap[item.planName] = item;
            allPlanDesignDataWithUniqueName.push(item);
          }
        });
      }
      return {
        ...state,
        allPlanDesignDatas: allPlanDesignDataWithUniqueName,
        allPlanDesignDatasInMap: allPlanDesignDatasWithMap
      };
    case Types.FETCH_ALL_PLAN_NAMES_SUCCESS:
      return {
        ...state,
        allPlanNames: action.data
      };
    default:
      return state;
  }
}
