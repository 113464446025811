import * as Types from 'features/frontoffice/actions/types';
import * as CustomTypes from '../actions/types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';

export const fetchCustomReports = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.FO_INITIAL_URL + `/report/custom`)
      .then((m) => {
        const response = m.data;
        dispatch({
          type: Types.FETCH_REPORT_CUSTOM_SUCCESS,
          payload: response || null
        });
      })
      .catch((err) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Could not find custom reports.'
        });
      });
  };
};

export const setCurrentMenuItem = (item) => {
  return (dispatch) => {
    dispatch({
      type: CustomTypes.SET_CURRENT_SELECTED_MENU,
      data: item
    });
  };
};

export const setCurrentTags = (tags) => {
  return (dispatch) => {
    dispatch({
      type: CustomTypes.SET_CURRENT_SELECTED_MENU,
      data: tags
    });
  };
};

export const fetchCustomReportWorkbooks = () => {
  return (dispatch) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + `/tbl/project-name`, {
        projectName: 'Custom Reports'
      })
      .then((m) => {
        const response = m.data;
        dispatch({
          type: CustomTypes.SET_CUSTOM_WORKBOOKS,
          data: response
        })
      })
      .catch((err) => {
        console.log('could not get workbooks: ', err);
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Could not find custom reports.'
        });
      });
  };
};

export const fetchCustomReportProjects = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + `/tbl/custom-projects`)
    .then((m) => {
      dispatch({
        type: CustomTypes.SET_CUSTOM_PROJECTS,
        data: m.data
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }
}