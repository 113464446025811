import { Model, fk, attr } from 'redux-orm';

const defaultAttributes = {
  tenant: 'Default site',
  roles: []
};

export default class MasterDataSite extends Model {
  static modelName = 'MasterDataSite';
  static get fields() {
    return {
      tenant: attr(),
      tenantName: attr(),
      roles: fk('SysRole')
    };
  }

  static parse(data) {
    return this.upsert(data);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherView) {
    this.update(otherView.ref);
  }
}
