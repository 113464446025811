import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const uploadLogoByTagAndCompany = (
  tagIdList,
  brokerIdList,
  companyIdList,
  file,
  tagList
) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('tagIdList', tagIdList);
    formData.append('brokerIdList', brokerIdList);
    formData.append('companyIdList', companyIdList);
    formData.append('logoFile', file);

    axios
      .post(AppConfig.FO_INITIAL_URL + `/vendor-logo/tag-and-company`, formData)
      .then((m) => {
        dispatch({
          type: Types.UPLOAD_LOGO_SUCCESS,
          data: tagList.map((tag) => {
            const result = tagIdList.findIndex((id) => id === tag.id);
            if (result !== -1)
              return { ...tag, updatedDttm: new Date().getTime() };

            return tag;
          })
        });
      })
      .catch((err) => {
        //send to global logo
        //do nothing for now
      });
  };
};

export const deleteLogoByTagIdAndCompanyId = (tagId, companyId, tagList) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.FO_INITIAL_URL +
        `/vendor-logo/tag/${tagId}/company/${companyId}`
      )
      .then((m) => {
        dispatch({
          type: Types.UPLOAD_LOGO_SUCCESS,
          data: tagList.map((tag) => {
            if (tag.id === tagId)
              return { ...tag, updatedDttm: new Date().getTime() };

            return tag;
          })
        });
      })
      .catch((err) => {
        //send to global logo
        //do nothing for now
      });
  };
};

export const deleteLogoByTagIdAndBrokerId = (tagId, brokerId) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.FO_INITIAL_URL +
        `/vendor-logo/tag/${tagId}/broker/${brokerId}`
      )
      .then((m) => {
        dispatch({
          type: Types.UPLOAD_LOGO_SUCCESS
        });
      })
      .catch((err) => {
        //send to global logo
        //do nothing for now
      });
  };
};

export const fetchCompanyList = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.IAM_BACKEND_URL + `/user-access/company-list`)
      .then((m) => {
        dispatch({
          type: Types.FETCH_COMPANY_LIST,
          data: m.data
        });
      });
  };
};

export const fetchBrokerList = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + `/broker/all`).then((m) => {
      dispatch({
        type: Types.FETCH_BROKER_LIST,
        data: m.data
      });
    });
  };
};

export const fetchTagList = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + `/whitelabel-tag/list`).then((m) => {
      dispatch({
        type: Types.FETCH_TAG_LIST,
        data: m.data
      });
    });
  };
};

export const fetchBrokerCompanyMapping = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + `/broker/companies`).then((m) => {
      dispatch({
        type: Types.FETCH_BROKER_COMPANY_MAPPING,
        data: m.data
      });
    });
  };
};
