import React, { useState, useEffect } from 'react';
import {v4 as uuid} from 'uuid';

const LogoImg = ({ data, url }) => {

    const [imgSrc, setImgSrc] = useState('');

    const getImgUrl = (rowData) => {
        /*
        Underlying url for logo does not change even when underlying data changes.
        Adding uuid to query str forces image to reload from source.
        */
        return rowData?.code ? `${url}${rowData?.code}?${uuid()}` : '';
    };

    useEffect(() => {
        if (data) {
            setImgSrc(getImgUrl(data));
        };
    }, [data]);

    return (
        <img
            id={uuid()}
            key={uuid()}
            src={imgSrc}
            alt="logo"
            width="180"
            height="38"
        />
    );
};

export default LogoImg;