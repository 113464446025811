import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MTableToolbar } from '@material-table/core';
import {
  Box,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  MenuItem,
  TablePagination
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import * as Constant from 'common/Constant';
import PlanExpenseDetailDialog from './component/detailFormDailog';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as MDActions from '../../common/actions/CommonActions';
import * as Actions from './actions/action';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import PlanExpenseCloneDetailDialog from './component/PlanExpenseCloneDetailDialog';
import { FoMaterialTable } from 'common/FoMaterialTable';
import downloadCsv from 'utils/downloadCsv';
import ModificationDialog from './component/modificationDialog';
import { PlanExpenseCell } from './component/PlanExpenseCell';
import * as AppActionTypes from 'store/actions/appstate';
import LocalStore from 'utils/LocalStore';

const useStyles = makeStyles(() => ({
  planYearSelect: {
    marginLeft: '1rem',
    minWidth: 150
  }
}));
const PlanExpense = () => {
  const ALL_YEARS = 'All';

  const styles = useStyles();
  const tableRef = useRef({});
  const expenseCategories = useSelector(
    (state) => state.MD.planExpense.expenseCategory
  );
  const expenseNames = useSelector(
    (state) => state.MD.planExpense.expenseNames
  );
  const [planYears, setPlanYears] = useState([ALL_YEARS]);
  const [showNewDialog, setShowNewDialog] = useState(false);
  const [showCloneModal, setshowCloneModal] = useState(false);
  const [showEditDialog, setShowEditDailog] = useState(false);
  const [currentEditingCell, setCurrentEditingCell] = useState({
    id: '',
    field: ''
  });
  const [target, setTarget] = useState({});
  const [updatedRows, setUpdatedRows] = useState({});
  const [duplicateRows, setDuplicateRows] = useState({});
  const [planExpenseData, setPlanExpenseData] = useState([]);
  const planExpensePaginationDetails = useRef({
    page: 0,
    size: 0,
    totalCount: 0
  });

  const [pageSize, setPageSize] = useState(10);
  const { allPlanYearsForTenant: totalPlanYears } = useSelector(
    (state) => state.MD.mdCommon
  );
  const sortProperty = useRef('planYear');
  const sortDirection = useRef('DESC');
  const plansNames = useSelector((state) => state.MD.mdCommon.allPlanNames);
  const rateBasis = useSelector((state) => state.MD.planExpense.rateBasis);
  const site = useSelector((state) => state.user.currentTenantCd);
  const isLoadingData = useRef(false);
  const { shouldRefresh } = useSelector((state) => state.MD.planExpense);

  const loadData = async (page, pageSize) => {
    store.dispatch(MDActions.fetchAllPlanYears());
    isLoadingData.current = true;
    const pYears =
      ALL_YEARS === planYears[0]
        ? totalPlanYears.map((year) => year.planYearName)
        : planYears;

    try {
      const response = await axios.get(
        AppConfig.API_BASE_URL +
          `planexpense/findPlanExpenseWithPagingSorting?planYears=${pYears}&page=${page}&size=${pageSize}&sortProperty=${sortProperty.current}&direction=${sortDirection.current}&responseType=text`,
        {},
        AppConfig.POST_HEADERS
      );
      isLoadingData.current = false;
      if (response.data) {
        return response.data;
      }
      return {
        data: [],
        from: 0,
        total_count: 0
      };
    } catch {
      isLoadingData.current = false;
      return {
        data: [],
        from: 0,
        total_count: 0
      };
    }
  };

  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanYears());
    store.dispatch(MDActions.getAllPlanNames());
    store.dispatch(Actions.fetchExpenseNames());
    store.dispatch(Actions.fetchExpenseCategories());
    store.dispatch(Actions.fetchRateBasis());

    store.dispatch(MDActions.getAllElectionTiers());
    store.dispatch(Actions.fetchPlans());
    store.dispatch(Actions.fetchAllPlansExpenses());
  }, []);

  const onOpenDialog = (rowData) => {
    const { tableData, ...targetData } = rowData;
    setTarget(targetData);
    setShowEditDailog(true);
  };

  /**
   * Refreshes the page after each sort/filter/pagination operation.
   * 
   * @param {*} page 
   * @param {*} pageSizeVal 
   */
  const refresh = async (page, pageSizeVal) => {
    setPageSize(pageSizeVal)

    if (totalPlanYears.length && !isLoadingData.current) {
      tableRef.current?.onAllSelected(false);
      const data = await loadData(page, pageSizeVal);

      setPlanExpenseData(data.data);
      setCurrentEditingCell({ id: '', field: '' });

      planExpensePaginationDetails.current = {
        page: data.from,
        size: data.data.length,
        totalCount: data.total_count
      };
      setUpdatedRows({});
      setDuplicateRows({});
    }
  };

  useEffect(() => {
    refresh(0, pageSize);
  }, [shouldRefresh, totalPlanYears, planYears]);

  const planYearDropdownOptions = useMemo(() => {
    if (!totalPlanYears) return [];

    return totalPlanYears
      .filter((a) => a.planYearName)
      .map((a) => ({
        value: a.planYearName,
        label: a.planYearName
      }));
  }, [totalPlanYears]);

  const expenseCategoriesOptions = useMemo(() => {
    if (!expenseCategories) return [];
    return expenseCategories.map((a) => ({
      value: a.name,
      label: a.name
    }));
  }, [expenseCategories]);

  const expenseNamesOptions = useMemo(() => {
    if (!expenseNames) return [];
    return expenseNames.map((a) => ({
      value: a.name,
      label: a.name
    }));
  }, [expenseNames]);

  const rateBasisOptions = useMemo(() => {
    if (!rateBasis) return [[], []];

    const allbasis = rateBasis.map((a) => ({
      value: a.name,
      label: a.name
    }));

    const onetimeexcludedbasis = rateBasis
      .filter((a) => a.name !== 'one-time special')
      .map((a) => ({
        value: a.name,
        label: a.name
      }));
    return [allbasis, onetimeexcludedbasis];
  }, [rateBasis]);

  const planexpenseFields = useMemo(() => {
    return [
      {
        field: 'planYear',
        title: 'Plan Year'
      },
      {
        field: 'rateStartDate',
        title: 'Rate Start Date'
      },
      {
        field: 'rateEndDate',
        title: 'Rate End Date'
      },
      {
        field: 'planName',
        title: 'Plan'
      },
      {
        field: 'expenseCategory',
        title: 'Expense Category'
      },
      {
        field: 'expenseName',
        title: 'Expense Name'
      },
      {
        field: 'rateBasis',
        title: 'Rate Basis'
      },
      {
        field: 'rate',
        title: 'Rate'
      }
    ];
  }, []);

  const getPlanOptions = (year) => {
    if (!totalPlanYears || !plansNames || !Array.isArray(plansNames)) return [];
    const years = totalPlanYears.filter(
      (a) => a.planYearName && a.planYearName === year
    );
    const ahpmSet = new Set(years.map((p) => p.id));
    let filteredList = [];

    filteredList = plansNames.filter((plan) => {
      return ahpmSet.has(plan.ahpmId);
    });
    years.forEach((year) => {
      filteredList
        .filter((plan) => year.id === plan.ahpmId)
        .map((p) => {
          p.year = year.name;
          return p;
        });
    });

    return filteredList
      .sort((a, b) => a.year - b.year)
      .map((a) => ({ value: a.planName, label: a.planName, id: a.id }));
  };

  const handleDelete = (rowData) => {
    confirmDialog(
      () => store.dispatch(Actions.deleteRecord(rowData)),
      `You're about to delete this record. Any related records will be also deleted. Continue?`
    );
  };

  const handleCreateNew = (data) => {
    setShowNewDialog(false);
    if (data) {
      store.dispatch(Actions.createNewRecord(data));
    }
  };

  const handleEditExpense = (data) => {
    if (data) {
      store.dispatch(Actions.updateRecord(data));
    }
    setShowEditDailog(false);
  };

  const handleClone = async (data) => {
    setshowCloneModal(false);
    if (data) {
      store.dispatch(Actions.cloneRecord(data));
    }
  };

  const handleExportCSV = (data) => {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = planexpenseFields.map((item) => item.title);
    const csv = [
      header.join(','), // header row first
      ...data.map((row) =>
        planexpenseFields
          .map((item) => JSON.stringify(row[item.field], replacer))
          .join(',')
      )
    ].join('\r\n');

    const csvFileName = 'planexpense';
    downloadCsv(csv, csvFileName);
  };

  const handlePlanYearSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_YEARS) {
      setPlanYears([ALL_YEARS]);
    } else {
      setPlanYears(inputVal.filter((yr) => yr !== ALL_YEARS));
    }
  };

  const handleCellValaueChange = (id, field, value) => {
    const index = planExpenseData.findIndex((item) => item.id === id);
    if (index > -1) {
      const tempData = [...planExpenseData];
      tempData[index] = { ...tempData[index], [field]: value };

      if (field === 'rateStartDate') {
        tempData[index].startDate = value;
      }
      if (field === 'rateEndDate') {
        tempData[index].endDate = value;
      }
      if (field === 'planName') {
        const options = getPlanOptions(tempData[index].planYear);
        const selected = options.filter((a) => a.value === value);
        if (selected && selected.length) {
          tempData[index].planId = selected[0].id;
        }
      }

      if (field === 'planYear') {
        tempData[index] = {
          ...tempData[index],
          rateStartDate: null,
          rateEndDate: null,
          planName: null,
        };
      } else {
        checkDuplicate({ ...tempData[index] });
      }
      setPlanExpenseData(tempData);
      const temp = { ...updatedRows };
      if (updatedRows[id]) {
        temp[id].add(field);
      } else {
        temp[id] = new Set([field]);
      }
      setUpdatedRows(temp);
    }
  };

  const checkDuplicate = async (payload) => {
    if (!payload.rateStartDate || !payload.rateEndDate) {
      return;
    }
    payload.planExpenseRates = [
      {
        divisionId: '',
        tierId: '',
        name: '',
        rate: payload.rate,
        startDate: null,
        endDate: null
      }
    ];
    const listId = payload.id;
    payload.id = payload.planExpenseId;
    const response = await fetch(
      AppConfig.API_BASE_URL + `planexpense/checkDuplicate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json',
          Authorization: 'Bearer ' + LocalStore.getAccessToken()
        },
        body: JSON.stringify([payload])
      }
    );
    if (response) {
      const responseData = await response.json();
      if (responseData && responseData.length) {
        setDuplicateRows({ ...duplicateRows, [listId]: 1 });
        store.dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: `Duplicate records found.`
        });
      } else {
        const temp = { ...duplicateRows };
        delete temp[listId];
        setDuplicateRows(temp);
      }
    }
  };

  const handleSave = () => {
    const ids = Object.keys(updatedRows);
    const data = planExpenseData.filter((item) => {
      return ids.some((id) => id === item.id);
    });
    let errorFound = false;
    for (let item of data) {
      if (!item.rateStartDate) {
        errorFound = true;
        store.dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Rate start date cannot be empty'
        });
        break;
      }
      if (!item.rateEndDate) {
        errorFound = true;
        store.dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Rate end date cannot be empty'
        });
        break;
      }
    }
    if (!errorFound) {
      handleEditExpense(data);
    }
  };

  const getMinStartDate = (year) => {
    const planInfo = totalPlanYears.find((py) => py.planYearName === year);
    return planInfo.startDate;
  };

  const getMaxEndDate = (year) => {
    const planInfo = totalPlanYears.find((py) => py.planYearName === year);
    return planInfo.endDate;
  };

  const isAllSelected = planYears.length === 1 && planYears[0] === ALL_YEARS;

  return (
    <>
      <FoMaterialTable
        title=""
        tableRef={tableRef}
        onOrderChange={(columns, direction) => {
          sortProperty.current = planexpenseFields[columns].field;
          sortDirection.current = direction.toUpperCase();
          refresh(0, pageSize);
        }}
        columns={[
          {
            field: 'planYear',
            title: 'Plan Year',
            render: (rowdata, index) => (
              <PlanExpenseCell
                rowData={rowdata}
                field="planYear"
                editorType="dropdown"
                showEdit={
                  rowdata.id === currentEditingCell.id &&
                  currentEditingCell.field === 'planYear'
                }
                isEdited={
                  updatedRows[rowdata.id] &&
                  updatedRows[rowdata.id].has('planYear')
                }
                duplicateError={duplicateRows[rowdata.id]}
                options={planYearDropdownOptions}
                onCellClick={(id, field) =>
                  setCurrentEditingCell({ id, field })
                }
                key={rowdata.id}
                onChange={handleCellValaueChange}
              />
            ),
            customSort: (a, b) => {}
          },
          {
            field: 'rateStartDate',
            title: 'Rate Start Date',
            customSort: (a, b) => {},
            minWidth:
              currentEditingCell.field === 'rateStartDate' ? '170px' : '100px',

            render: (rowdata, index) => (
              <PlanExpenseCell
                rowData={rowdata}
                field="rateStartDate"
                editorType="date"
                showEdit={
                  rowdata.id === currentEditingCell.id &&
                  currentEditingCell.field === 'rateStartDate'
                }
                isEdited={
                  updatedRows[rowdata.id] &&
                  updatedRows[rowdata.id].has('rateStartDate')
                }
                duplicateError={duplicateRows[rowdata.id]}
                minDate={getMinStartDate(rowdata['planYear'])}
                maxDate={rowdata['rateEndDate']}
                onCellClick={(id, field) =>
                  setCurrentEditingCell({ id, field })
                }
                key={rowdata.id}
                onChange={handleCellValaueChange}
              />
            )
          },
          {
            field: 'rateEndDate',
            title: 'Rate End Date',
            customSort: (a, b) => {},
            minWidth:
              currentEditingCell.field === 'rateEndDate' ? '170px' : '100px',
            render: (rowdata, index) => (
              <PlanExpenseCell
                rowData={rowdata}
                field="rateEndDate"
                editorType="date"
                maxDate={getMaxEndDate(rowdata['planYear'])}
                minDate={rowdata['rateStartDate']}
                showEdit={
                  rowdata.id === currentEditingCell.id &&
                  currentEditingCell.field === 'rateEndDate'
                }
                isEdited={
                  updatedRows[rowdata.id] &&
                  updatedRows[rowdata.id].has('rateEndDate')
                }
                duplicateError={duplicateRows[rowdata.id]}
                onCellClick={(id, field) =>
                  setCurrentEditingCell({ id, field })
                }
                key={rowdata.id}
                onChange={handleCellValaueChange}
              />
            )
          },
          {
            field: 'planName',
            title: 'Plan',
            customSort: (a, b) => {},
            render: (rowdata, index) => (
              <PlanExpenseCell
                rowData={rowdata}
                field="planName"
                editorType="dropdown"
                options={getPlanOptions(rowdata.planYear)}
                showEdit={
                  rowdata.id === currentEditingCell.id &&
                  currentEditingCell.field === 'planName'
                }
                isEdited={
                  updatedRows[rowdata.id] &&
                  updatedRows[rowdata.id].has('planName')
                }
                duplicateError={duplicateRows[rowdata.id]}
                onCellClick={(id, field) =>
                  setCurrentEditingCell({ id, field })
                }
                key={rowdata.id}
                onChange={handleCellValaueChange}
              />
            )
          },
          {
            field: 'expenseCategory',
            title: 'Expense Category',
            customSort: (a, b) => {},
            maxWidth: '150px',
            render: (rowdata, index) => (
              <PlanExpenseCell
                rowData={rowdata}
                field="expenseCategory"
                editorType="dropdown"
                options={expenseCategoriesOptions}
                showEdit={
                  rowdata.id === currentEditingCell.id &&
                  currentEditingCell.field === 'expenseCategory'
                }
                isEdited={
                  updatedRows[rowdata.id] &&
                  updatedRows[rowdata.id].has('expenseCategory')
                }
                duplicateError={duplicateRows[rowdata.id]}
                onCellClick={(id, field) =>
                  setCurrentEditingCell({ id, field })
                }
                key={rowdata.id}
                onChange={handleCellValaueChange}
              />
            )
          },
          {
            field: 'expenseName',
            title: 'Expense Name',
            customSort: (a, b) => {},
            maxWidth: '150px',
            render: (rowdata, index) => (
              <PlanExpenseCell
                rowData={rowdata}
                field="expenseName"
                editorType="dropdown"
                options={expenseNamesOptions}
                showEdit={
                  rowdata.id === currentEditingCell.id &&
                  currentEditingCell.field === 'expenseName'
                }
                isEdited={
                  updatedRows[rowdata.id] &&
                  updatedRows[rowdata.id].has('expenseName')
                }
                duplicateError={duplicateRows[rowdata.id]}
                onCellClick={(id, field) =>
                  setCurrentEditingCell({ id, field })
                }
                key={rowdata.id}
                onChange={handleCellValaueChange}
              />
            )
          },
          {
            field: 'rateBasis',
            title: 'Rate Basis',
            customSort: (a, b) => {},
            maxWidth: '150px',
            render: (rowdata, index) => (
              <PlanExpenseCell
                rowData={rowdata}
                field="rateBasis"
                editorType="dropdown"
                options={
                  rowdata.rateBasis === 'one-time special'
                    ? rateBasisOptions[0]
                    : rateBasisOptions[1]
                }
                showEdit={
                  rowdata.id === currentEditingCell.id &&
                  currentEditingCell.field === 'rateBasis'
                }
                isEdited={
                  updatedRows[rowdata.id] &&
                  updatedRows[rowdata.id].has('rateBasis')
                }
                duplicateError={duplicateRows[rowdata.id]}
                onCellClick={(id, field) =>
                  setCurrentEditingCell({ id, field })
                }
                key={rowdata.id}
                onChange={handleCellValaueChange}
              />
            )
          },
          {
            field: 'rate',
            title: 'Rate',
            customSort: (a, b) => {},
            render: (rowdata, index) => (
              <PlanExpenseCell
                rowData={rowdata}
                field="rate"
                editorType="number"
                showEdit={
                  rowdata.id === currentEditingCell.id &&
                  currentEditingCell.field === 'rate'
                }
                isEdited={
                  updatedRows[rowdata.id] && updatedRows[rowdata.id].has('rate')
                }
                duplicateError={duplicateRows[rowdata.id]}
                onCellClick={(id, field) =>
                  setCurrentEditingCell({ id, field })
                }
                key={rowdata.id}
                onChange={handleCellValaueChange}
              />
            )
          }
        ]}
        data={planExpenseData}
        options={{
          actionsColumnIndex: -1,
          search: false,
          pageSize: pageSize == planExpensePaginationDetails.current.size ? pageSize : planExpensePaginationDetails.current.size == 0 
            ? pageSize : planExpensePaginationDetails.current.size,
          totalCount: planExpensePaginationDetails.current.totalCount,
          debounceInterval: 400,
          selection: true,
          rowStyle: {
            minHeight: '60px',
            maxHeight: '60px',
            height: '60px'
          }
        }}
        components={{
          OverlayLoading: () => <div />,
          Toolbar: (props) => (
            <Box>
              <MTableToolbar {...props} />
              <FormControl className={styles.planYearSelect} size="medium">
                <InputLabel id="label_planYear">Plan Year:</InputLabel>
                <Select
                  multiple
                  id="input_planYear"
                  key="input_planYear"
                  onChange={handlePlanYearSelection}
                  value={planYears}
                  renderValue={(planYears) => planYears.join(', ')}>
                  <MenuItem value={ALL_YEARS}>
                    <ListItemIcon>
                      <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary={ALL_YEARS} />
                  </MenuItem>
                  {totalPlanYears &&
                    Array.isArray(totalPlanYears) &&
                    totalPlanYears.map((item) => (
                      <MenuItem
                        value={item.planYearName}
                        key={item.planYearName}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              planYears.indexOf(item.planYearName) > -1 ||
                              isAllSelected
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.planYearName} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          ),
          Pagination: (props) => {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={Constant.DEFAULT_SEARCH_PAGE_SIZE}
                rowsPerPage={pageSize}
                count={planExpensePaginationDetails.current.totalCount}
                page={planExpensePaginationDetails.current.page}
                onRowsPerPageChange={(event) => {
                  if (Object.keys(updatedRows).length) {
                    confirmDialog(
                      () => {
                        handleSave();
                      },
                      `There are unsaved records. Do you want to save?`,
                      'Save Records',
                      'Cancel',
                      'Save',
                      () => {
                        refresh(0, event.target.value);
                      }
                    );
                  } else {
                    refresh(0, event.target.value);
                  }
                }}
                onPageChange={(event, page) => {
                  if (Object.keys(updatedRows).length) {
                    confirmDialog(
                      () => {
                        handleSave();
                      },
                      `There are unsaved records. Do you want to save?`,
                      'Save Records',
                      'Cancel',
                      'Save',
                      () => {
                        refresh(page, pageSize);
                      }
                    );
                  } else {
                    refresh(page, pageSize);
                  }
                }}
              />
            );
          }
        }}
        actions={[
          {
            position: 'row',
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              onOpenDialog(rowData);
            }
          },
          {
            position: 'row',
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (_event, rowData) => {
              handleDelete([rowData]);
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (_, rowData) => {
              handleDelete(rowData);
            }
          },
          {
            icon: 'system_update_alt',
            tooltip: 'Export All Selection',
            onClick: (_, rowData) => {
              handleExportCSV(rowData);
            }
          },
          {
            icon: 'save',
            tooltip: 'Save',
            isFreeAction: true,
            disabled:
              Object.keys(updatedRows).length === 0 ||
              Object.keys(duplicateRows).length,
            onClick: (_event, _rowData) => {
              handleSave();
            }
          },

          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh(0, pageSize);
            },
            isFreeAction: true
          },
          {
            icon: 'save_alt',
            tooltip: 'Download CSV',
            isFreeAction: true,

            onClick: async (_event, _rowData) => {
              const data = await loadData(0, 100000);

              handleExportCSV(data.data);
            }
          },
          {
            icon: 'library_add',
            tooltip: 'Clone',
            isFreeAction: true,
            onClick: (_event, _rowData) => {
              setshowCloneModal(true);
            }
          },
          {
            tooltip: 'Create New',
            icon: 'add',
            onClick: () => {
              setShowNewDialog(true);
            },
            isFreeAction: true
          }
        ]}
      />
      {showNewDialog && (
        <PlanExpenseDetailDialog
          onClose={handleCreateNew}
          open={showNewDialog}
          tenantId={site}
          target={null}
        />
      )}
      {showCloneModal ? (
        <PlanExpenseCloneDetailDialog
          open={showCloneModal}
          tenantId={site}
          planYears={totalPlanYears || []}
          onClose={(data) => handleClone(data)}
          onCancel={() => setshowCloneModal(false)}
          cloneError={''}
        />
      ) : null}
      {showEditDialog ? (
        <ModificationDialog
          onClose={handleEditExpense}
          open={showEditDialog}
          tenantId={site}
          target={target}
        />
      ) : null}
    </>
  );
};

export default React.memo(PlanExpense);
