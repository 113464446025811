import { combineReducers } from 'redux';
import plandDesignDataReducer from '../components/planDesignData/reducer/PlanDesignDataReducer';
import mdCommonReducer from '../common/reducer/CommonReducer';
import annualPlanReducer from '../components/annualPlan/reducer/reducer';
import planRateReducer from '../components/planRate/reducer/planRateReducer';
import dicretionaryBenefitsReducer from '../components/discretionaryBenefits/reducer/reducer';
import stopLossContractReducer from '../components/stopLossContract/reducer/reducer';
import employeClassReducer from '../components/employeeClass/reducer/reducer';
import divisionReducer from '../components/division/reducer/reducer';
import electionTierReducer from '../components/electionTier/reducer/reducer';
import lookupReducer from '../components/lookup/reducer/reducer';
import planExpenseReducer from '../components/planExpense/reducer/planExpenseReducer';

export default combineReducers({
  plandDesignData: plandDesignDataReducer,
  mdCommon: mdCommonReducer,
  annualPlanData: annualPlanReducer,
  planRate: planRateReducer,
  dicretionaryBenefits: dicretionaryBenefitsReducer,
  stopLossContract: stopLossContractReducer,
  employeClass: employeClassReducer,
  division: divisionReducer,
  electionTier: electionTierReducer,
  lookup: lookupReducer,
  planExpense: planExpenseReducer
});
