import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import DefaultSettingDetail from './detail';
import { useForm, FormProvider } from 'react-hook-form';

const DefaultingSettingDetailDialog = React.memo((props) => {
  const { onClose, open, target } = props;

  const methods = useForm({
    defaultValues: target,
    mode: 'onBlur'
  });
  const { reset } = methods;

  const handleClose = () => {
    onClose(null);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
          <DialogTitle id="dialog-planDesignDataDetail">
            {target && target.id > 0 ? 'Edit' : 'New'}
          </DialogTitle>
          <DialogContent dividers>
            <DefaultSettingDetail target={target} />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary"
              disabled={!methods.formState.isDirty || !methods.formState.isValid || methods.formState.isValidating}>
              Save
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
});

export default DefaultingSettingDetailDialog;
