import { Model, attr } from 'redux-orm';

const defaultAttributes = {
  classDesc: 'Default Submenu'
};

export default class SubMenu extends Model {
  static modelName = 'SubMenu';

  static fields = {
    id: attr(),
    parentId: attr(),
    menuId: attr(),
    classDesc: attr(),
    helpVideoId: attr(),
    tabDesc: attr(),
    roleName: attr(),
    name: attr(),
    icon: attr(),
    reptSource: attr()
  };

  static parse(data) {
    return this.upsert(data);
  }
  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherView) {
    this.update(otherView.ref);
  }
}
