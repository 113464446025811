import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  FormHelperText
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

const PolicyExclusion = (props) => {
  const { control } = useFormContext();

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        <Grid item xs={12}>
          <Controller
            name={`personId`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={'input-hsaTiers-tier-amount'}
                label="Person Id"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-hsaIn"
                label="First Name"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-hsaFAM"
                label="Last Name"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="medId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-hsaFAM"
                label="Medical Id"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyExclusion;
