export const PREFIX = 'MD-EMPLOYE-CLASS_';

export const DELETE_PLAN_RATE_SUCCESS =
  PREFIX + 'SAVE_PLAN_DESIGN_DATA_SUCCESS';

export const SAVE_PLAN_RATE_SUCCESS = PREFIX + 'SAVE_PLAN_RATE_SUCCESS';

export const SEARCH_REFRESH = PREFIX + 'SEARCH_REFRESH';

export const GET_ALL_DATA_BY_TENANT_ID_SUCCESS =
  PREFIX + 'GET_ALL_DATA_BY_TENANT_ID_SUCCESS';
