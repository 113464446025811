import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import { MDBRow, MDBIcon, MDBCol } from 'mdbreact';

class BrokerTable extends PureComponent {
  records = {};
  csvData = [];

  captureIssue = (context, tenant, name, dataThruDate) => {
    if (!this.records[context]) {
      this.records[context] = 0;
    }
    this.records[context] = this.records[context] + 1;
    this.csvData.push({
      tenant: tenant,
      name: name,
      dataThruDate: dataThruDate,
      inspection: context
    });
    return null;
  };

  renderBrokerRow(r, robotRow) {
    if (this.props.hasError(r, this.props.hasFieldError) === true) {
      return (
        <>
          <tr>
            <td className="pl-2">{r.tenantCode}</td>
            <td className="pl-2">{r.companyName}</td>
            <td className="pl-2">{r.dataThruDate}</td>
            <td
              className="pl-2"
              style={{
                color: 'red',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word'
              }}>
              {this.props.hasFieldError(r, 'planLossPercent') === true && (
                <p>
                  "Abnormal plan loss ratio"
                  <span>
                    {this.captureIssue(
                      'Abnormal plan loss ratio',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'memberCount') === true && (
                <p>
                  "Member count is 0"
                  <span>
                    {this.captureIssue(
                      'Member count is 0',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'percentInNetwork') === true && (
                <p>
                  "Abnormal in-network utilization rate"
                  <span>
                    {this.captureIssue(
                      'Abnormal in-network utilization rate',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'employeeEnrollment') === true && (
                <p>
                  "Employee enrollment changed over &plusmn; 5%"
                  <span>
                    {this.captureIssue(
                      'Employee enrollment changed over 5%',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'memberEnrollment') === true && (
                <p>
                  "Member enrollment changed over &plusmn;5% "{' '}
                  <span>
                    {this.captureIssue(
                      'Member enrollment changed over 5%',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'medClaims') === true && (
                <p>
                  "Medical claims changed over &plusmn;30%"{' '}
                  <span>
                    {this.captureIssue(
                      'Medical claims changed over 30%',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'rxClaims') === true && (
                <p>
                  "Rx claims changed over &plusmn; 30%"
                  <span>
                    {this.captureIssue(
                      'Rx claims changed over 30%',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'medCopay') === true &&
                this.props.hasFieldError(r, 'medDeductible') === true &&
                this.props.hasFieldError(r, 'medCoinsurance') === true && (
                  <p>
                    "Plan Year Med Copay, Deductible and Coinsurance are $0"{' '}
                    <span>
                      {this.captureIssue(
                        'Plan Year Med Copay, Deductible and Coinsurance are $0',
                        r.tenantCode,
                        r.companyName,
                        r.dataThruDate
                      )}
                    </span>
                  </p>
                )}
              {this.props.hasFieldError(r, 'rxCopay') === true &&
                this.props.hasFieldError(r, 'rxDeductible') === true &&
                this.props.hasFieldError(r, 'rxCoinsurance') === true && (
                  <p>
                    "Plan Year Rx Copay, Deductible and Coinsurance are $0"
                    <span>
                      {this.captureIssue(
                        'Plan Year Rx Copay, Deductible and Coinsurance are $0',
                        r.tenantCode,
                        r.companyName,
                        r.dataThruDate
                      )}
                    </span>
                  </p>
                )}
              {this.props.hasFieldError(r, 'sumProjectedReimbursements') ===
                true && (
                  <p>
                    "Sum of projected reimbursement do not match"
                    <span>
                      {this.captureIssue(
                        'Sum of projected reimbursement do not match',
                        r.tenantCode,
                        r.companyName,
                        r.dataThruDate
                      )}
                    </span>
                  </p>
                )}
              {this.props.hasFieldError(r, 'icdCategoryNull') === true && (
                <p>
                  "ICD Category Report"
                  <span>
                    {this.captureIssue(
                      'ICD Category Report',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'highestVariation') === true && (
                <p>
                  "Risk score variation is out of range"
                  <span>
                    {this.captureIssue(
                      'Risk score variation is out of range',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'riskscorePeriod') === true && (
                <p>
                  "Risk score is not update to date"
                  <span>
                    {this.captureIssue(
                      'Risk score is not update to date',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'brandCodesNull') === true && (
                <p>
                  "Top 20 Brand Drugs have null drug class"
                  <span>
                    {this.captureIssue(
                      'Top 20 Brand Drugs have null drug class',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}
              {this.props.hasFieldError(r, 'brandNamesNull') === true && (
                <p>
                  "Top 20 Brand Drugs have null drug name"
                  <span>
                    {this.captureIssue(
                      'Top 20 Brand Drugs have null drug name',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}

              {this.props.hasFieldError(r, 'genericCodesNull') === true && (
                <p>
                  "Top 20 Generic Drugs have null drug class"
                  <span>
                    {this.captureIssue(
                      'Top 20 Generic Drugs have null drug class',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}

              {this.props.hasFieldError(r, 'genericNamesNull') === true && (
                <p>
                  "Top 20 Generic Drugs have null drug name"
                  <span>
                    {this.captureIssue(
                      'Top 20 Generic Drugs have null drug name',
                      r.tenantCode,
                      r.companyName,
                      r.dataThruDate
                    )}
                  </span>
                </p>
              )}

              {this.props.hasFieldError(
                r,
                'unexpectedBrandGenericIndicators'
              ) === true && (
                  <p>
                    "Unexpected Brand/Generic indicator showed up"
                    <span>
                      {this.captureIssue(
                        'Unexpected Brand/Generic indicator showed up',
                        r.tenantCode,
                        r.companyName,
                        r.dataThruDate
                      )}
                    </span>
                  </p>
                )}

              {this.props.hasFieldError(r, 'unexpectedFormularyIndicators') ===
                true && (
                  <p>
                    "Unexpected Formulary indicator showed up"
                    <span>
                      {this.captureIssue(
                        'Unexpected Formulary indicator showed up',
                        r.tenantCode,
                        r.companyName,
                        r.dataThruDate
                      )}
                    </span>
                  </p>
                )}

              {this.props.hasFieldError(r, 'unexpectedMailOrderIndicators') ===
                true && (
                  <p>
                    "Unexpected Mail Order indicator showed up"
                    <span>
                      {this.captureIssue(
                        'Unexpected Mail Order indicator showed up',
                        r.tenantCode,
                        r.companyName,
                        r.dataThruDate
                      )}
                    </span>
                  </p>
                )}
            </td>
          </tr>
        </>
      );
    }
  }

  render() {
    const columns = [
      {
        id: 'tenant',
        displayName: 'Tenant Code'
      },
      {
        id: 'name',
        displayName: 'Tenant Name'
      },
      {
        id: 'dataThruDate',
        displayName: 'Date Thru Date'
      },
      {
        id: 'inspection',
        displayName: 'Inspections'
      }
    ];
    return (
      <>
        <MDBRow center>
          <table border="1" width="50%">
            <thead
              style={{
                color: 'blue'
              }}>
              <tr>
                <th
                  style={{
                    width: '80px',
                    height: '60px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}
                  className="text-center">
                  Tenant Code
                </th>
                <th
                  className="text-center"
                  style={{
                    width: '100px',
                    height: '60px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}>
                  Tenant Name
                </th>
                <th
                  className="text-center"
                  style={{
                    width: '100px',
                    height: '60px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}>
                  Data Thru Date
                </th>
                <th
                  style={{
                    width: '200px',
                    height: '60px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}>
                  <MDBCol className="row justify-content-center ">
                    Inspections &nbsp;&nbsp;
                    <CsvDownloader
                      filename="brokerInspections"
                      separator=";"
                      columns={columns}
                      datas={this.csvData}>
                      <MDBIcon icon="cloud-download-alt" />{' '}
                    </CsvDownloader>
                  </MDBCol>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.brokerData.map((r, key) =>
                this.renderBrokerRow(
                  r,
                  this.props.robotBrokerData.find(
                    (rb) => rb.tenantCode === r.tenantCode
                  )
                )
              )}
            </tbody>
          </table>
        </MDBRow>
        <span>{this.props.getStatus(this.records)}</span>
      </>
    );
  }
}

export default withRouter(BrokerTable);
