import React from 'react';
import { Box, TextField, Select, MenuItem, Typography } from '@material-ui/core';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import * as Constant from 'common/Constant';
const PCell = ({
  rowData,
  field,
  showEdit,
  isEdited,
  onCellClick,
  onChange,
  editorType,
  options,
  minDate,
  maxDate,
  duplicateError
}) => {
  const renderCell = () => {
    if (!showEdit) {
      const style = { color: 'black', fontWeight: 'normal' };
      if (isEdited) {
        style.color = 'green';
        style.fontWeight = 'bold';
      }
      if (duplicateError) {
        style.color = 'red';
        style.fontWeight = 'normal';
      }
      let value = rowData[field];
      if (editorType === 'date' && !value) {
        style.color = '#d3d3d3';
        style.fontWeight = 'normal';
        value = 'MM/DD/YYYY';
      }
      return <Typography style={style}>{value}</Typography>;
    }
    switch (editorType) {
      case 'date':
        return (
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              clearable
              minDate={moment(minDate)}
              maxDate={moment(maxDate)}
              id={field}
              format={Constant.FORMAT_DATE}
              fullWidth
              value={rowData[field] ? moment.utc(rowData[field]) : null}
              onChange={(date) => {
                return date ? onChange(rowData.id, field, date.format(Constant.FORMAT_DATE)) : null;
              }}
            />
          </MuiPickersUtilsProvider>
        );
      case 'dropdown':
        return (
          <Select
            fullWidth
            value={rowData[field]}
            labelId="label-application"
            id="input-application"
            onChange={(event) => {
              onChange(rowData.id, field, event.target.value);
            }}>
            {options.map((item) => {
              return (
                <MenuItem key={'key-' + item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        );
      case 'number':
        return (
          <TextField
            type="number"
            defaultValue={rowData[field]}
            onChange={(event) => {
              onChange(rowData.id, field, event.target.value);
            }}
          />
        );
      default:
        return (
          <TextField
            defaultValue={rowData[field]}
            onChange={(event) => {
              onChange(rowData.id, field, event.target.value);
            }}
          />
        );
    }
  };
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        paddingTop: showEdit ? '0px' : '10px',
        paddingBottom: showEdit ? '0px' : '10px'
      }}
      onClick={() => {
        onCellClick(rowData.id, field);
      }}>
      {renderCell()}
    </Box>
  );
};
export const PlanExpenseCell = React.memo(PCell);
