import * as Types from '../actions/types';

let initialState = {
  shouldRefresh: false,
  expenseCategory: [],
  expenseNames: [],
  rateBasis: [],
  simplePlans: [],
  allExpenseData: [],
  allRateBasis: []
};

export default function planExpenseReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_EXPENSE_CATEGORY_SUCCESS:
      return {
        ...state,
        expenseCategory: action.payload
      };

    case Types.FETCH_EXPENSE_NAMES_SUCCESS:
      return {
        ...state,
        expenseNames: action.payload
      };

    case Types.FETCH_RATE_BASIS_SUCCESS:
      return {
        ...state,
        rateBasis: action.payload
      };
    case Types.FETCH_ALL_RATE_BASIS_SUCCESS:
      return {
        ...state,
        allRateBasis: action.payload
      };
    case Types.FETCH_SIMPLE_PLANS_SUCCESS:
      return {
        ...state,
        simplePlans: action.payload
      };
    case Types.CREATION_SUCCESS:
    case Types.UPDATION_SUCCESS:
    case Types.DELETE_RECORD_SUCCESS:
    case Types.CLONE_SUCCESS:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.FETCH_SIMPLE_PLANS_EXPENSE_SUCCESS:
      return {
        ...state,
        allExpenseData: action.payload
      };
    default:
      return state;
  }
}
