export const ANNUAL_PLAN_RENEWAL_DETAILS = {
  RENEWAL_POINT_OF_CONTACT: {
    SUMMARY: 'Renewal Point of Contacts',
    DETAILS: {
      TENANT_TEXT: 'Users who are able to provide required data for the tenant:',
    }
  },
  UPLOADED_FILE_DETAILS: {
    SUMMARY: 'Uploaded Files',
    DETAILS: {
      TITLE_TEXT: 'Annual Renewal Optional Template - download'
    }
  }
};
