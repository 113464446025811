import moment from 'moment';
import CasClient, { constant } from 'react-cas-client';
import { AppConfig } from '../../../AppConfig';
import RouteConfig from '../../../routes/RouteConfig';
import LocalStore from '../../../utils/LocalStore';
import * as ValidationUtil from 'utils/ValidationUtil';

export const CONTRACT_BASIS_RUN_IN_START_DATE = 'RUN_IN_START_DATE';
export const CONTRACT_BASIS_RUN_IN_END_DATE = 'RUN_IN_END_DATE';
export const CONTRACT_BASIS_RUN_OUT_START_DATE = 'RUN_OUT_START_DATE';
export const CONTRACT_BASIS_RUN_OUT_END_DATE = 'RUN_OUT_END_DATE';

//get run start date, enddate
export const CALCULATE_CONTRACT_BASIS_DATE_PERIOD = (
  start_date,
  end_date,
  contract_basis
) => {
  let result = {
    [CONTRACT_BASIS_RUN_IN_START_DATE]: null,
    [CONTRACT_BASIS_RUN_IN_END_DATE]: null,
    [CONTRACT_BASIS_RUN_OUT_START_DATE]: null,
    [CONTRACT_BASIS_RUN_OUT_END_DATE]: null
  };

  if (start_date && end_date && contract_basis) {
    const startDate = moment(start_date);
    const endDate = moment(end_date);
    if (endDate < startDate) {
      console.log('Invalid start/end date');
    }

    const [parsedIn, parsedOut] = contract_basis.split('/');

    let inMonths = parseFloat(parsedIn);
    let outMonths = parseFloat(parsedOut);

    if (
      !isNaN(inMonths) &&
      typeof inMonths === 'number' &&
      !isNaN(outMonths) &&
      typeof outMonths === 'number'
    ) {
      const contractPeriod = endDate.diff(startDate, 'months') + 1;
      const extraInwardMonths = inMonths - contractPeriod;

      const extraOutwardMonths = outMonths - contractPeriod;
      if (inMonths > outMonths) {
        if (extraInwardMonths === 0) {
          result[CONTRACT_BASIS_RUN_IN_START_DATE] = null;
          result[CONTRACT_BASIS_RUN_IN_END_DATE] = null;
        } else {
          const run_in_end_date = moment(startDate).subtract(1, 'd');
          const run_in_start_date = moment(run_in_end_date).subtract(
            extraInwardMonths - 1,
            'M'
          );
          run_in_start_date.startOf('month');
          run_in_end_date.endOf('month').startOf('day');
          result[CONTRACT_BASIS_RUN_IN_START_DATE] = run_in_start_date;
          result[CONTRACT_BASIS_RUN_IN_END_DATE] = run_in_end_date;
        }
      }

      if (inMonths < outMonths) {
        if (extraOutwardMonths === 0) {
          result[CONTRACT_BASIS_RUN_OUT_START_DATE] = null;
          result[CONTRACT_BASIS_RUN_OUT_END_DATE] = null;
        } else {
          const run_out_start_date = moment(endDate).add(1, 'd');
          const run_out_end_date = moment(run_out_start_date).add(
            extraOutwardMonths - 1,
            'M'
          );
          run_out_start_date.startOf('month');
          run_out_end_date.endOf('month').startOf('day');

          result[CONTRACT_BASIS_RUN_OUT_START_DATE] = run_out_start_date;
          result[CONTRACT_BASIS_RUN_OUT_END_DATE] = run_out_end_date;
        }
      }
    }
  }

  return result;
};

export const LOGOUT_CAS = () => {
  CLEAN_SESSION();
  const casEndpoint = AppConfig.CAS_END_POING;
  const casOptions = {
    version: constant.CAS_VERSION_3_0,
    path: '/',
    validation_proxy_path: '/cas_proxy',
    pgtUrl: AppConfig.CAS_PROXY_CALLBACK
  };
  const casClient = new CasClient(casEndpoint, casOptions);

  casClient.logout(RouteConfig.login.path);
};

export const CLEAN_SESSION = () => {
  sessionStorage.clear();
  LocalStore.clear();
};

export const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const PLAN_RATE_STATUS_MAP = {
  ACTIVE: 'Active',
  COBRA: 'COBRA',
  RETIREE: 'Retired'
};

export const PLAN_RATE_STATUS_OPTIONS = [
  { id: 'COBRA', label: PLAN_RATE_STATUS_MAP['COBRA'] },
  { id: 'RETIREE', label: PLAN_RATE_STATUS_MAP['RETIREE'] },
  { id: 'ACTIVE', label: PLAN_RATE_STATUS_MAP['ACTIVE'] }
];

export const FORMAT_DATE = (date, format) => {
  return date
    ? ValidationUtil.VALIDATE_DATE(date)
      ? moment(date, 'MM/DD/YYYYTHH:mm:ss.SSSZ').format(format)
      : moment(date).format(format)
    : date;
};
