import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';
import { Autocomplete } from '@material-ui/lab';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { AppConfig } from '../../../../AppConfig';
const UserAccessManagementDialogDetail = ({ external, isEdit, assignedCompanyId }) => {
  const { control, getValues, setValue } = useFormContext();

  const { sysAdmin, foAdmin, mdAdmin, rangerAdmin } = useSelector((state) => state.user);
  const tenantList = useSelector((state) => state.adminUserAccess.tenantList);
  const applications = useSelector(
    (state) => state.adminUserAccess.applicationList
  );
  const [defaultTenantList, setDefaultTenantList] = useState([]);
  const [defaultRoleList, setDefaultRoleList] = useState([]);
  const [showExternalUserWarning, setShowExternalUserWarning] = useState(false);

  const externalUserMessage = 'User without Site Administrator role'

  const setTenantList = () => {
    let d = [];
    d = tenantList
      .filter((a) => a.displayText)
      .map((a) => ({
        id: a.id,
        code: a.code,
        label: a.displayText
      }));
    return d;
  };
  const setRoleList = (applicationId, external) => {
    const application = applications.filter((a) => a.id === applicationId);
    // if user is internal, filter external roles out
    if (application.length === 0) return;
    let appRoles = application[0].roles;
    if (external === false) {
      appRoles = appRoles.filter((role) => role.isExternalOnlyRole === false);
    }
    const roles = appRoles.map((a) => ({
      id: a.id,
      code: a.code,
      label: a.description
    }));
    setDefaultRoleList(roles);
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.label} ${option.code}`
  });


  const handleExternalChange = (event) => {
    (isEdit && (event.target.value === 'false')) ?
      setShowExternalUserWarning(true) :
      setShowExternalUserWarning(false);

    // reset role list on external status change
    setRoleList(getValues('applicationId'), event.target.value === 'true' ? false : true);

  };

  useEffect(() => {
    if (tenantList && tenantList.length) {
      const tlist = setTenantList();
      setDefaultTenantList(tlist);
      if (isEdit) {
        const company = tlist.filter((t) => t.id === assignedCompanyId);
        setValue('assignedCompanyId', company.length ? company[0] : null);
      }
    }
  }, [tenantList]);



  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-firstName"
                required
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="First Name"
              />
            )}
            name="firstName"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-lastName"
                required
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="Last Name"
              />
            )}
            name="lastName"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  if (!isEdit && value.includes(' ')) {
                    return 'Username cannot have white space!';
                  }
                },
                rule2: async (value) => {
                  if (!isEdit) {
                    const found = await axios.get(
                      AppConfig.IAM_BACKEND_URL + `/user/username/${value}/`
                    );
                    if (found && found.data && found.data.userId) {
                      return 'Already exists!';
                    }
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-username"
                disabled={isEdit}
                required
                fullWidth
                inputProps={{ maxLength: 20 }}
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="Username"
              />
            )}
            name="username"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  var email = value;
                  const regEx = /^\S+@\S+\.\S+$/;
                  if (!regEx.test(email)) {
                    return 'Invalid email address';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-emailAddress"
                required
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="Email Address"
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-assignedCompanyId"
                options={defaultTenantList}
                filterOptions={filterOptions}
                filterSelectedOptions
                getOptionLabel={(option) => option.label}
                renderOption={(option) => (
                  <React.Fragment>{option.label}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company"
                    inputProps={{ ...params.inputProps }}
                  />
                )}
                onChange={(_, data) => {
                  field.onChange(data);
                }}
              />
            )}
            name="assignedCompanyId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        {!isEdit && (
          <>
            <Grid item xs={12}>
              <Typography className="mt-4" variant="subtitle1">
                Accessibility
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="applicationId"
                control={control}
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <FormControl fullWidth error={error}>
                      <InputLabel id="label-application-input">
                        Application
                      </InputLabel>
                      <Select
                        fullWidth
                        {...field}
                        name="applicationId"
                        labelId="label-applicationId"
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          setValue('roleId', null);
                          setRoleList(event.target.value, getValues('external'));
                        }}
                        id="input-applicationId">
                        {applications &&
                          Array.isArray(applications) &&
                          applications.map((item) => {
                            return (
                              <MenuItem key={'key-' + uuid()} value={item?.id}>
                                {item.description}
                              </MenuItem>
                            );
                          })}
                      </Select>

                      <FormHelperText>
                        {error ? error.message : null}
                      </FormHelperText>
                    </FormControl>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="input-roleId"
                    options={defaultRoleList}
                    getOptionLabel={(option) => option.label}
                    value={
                      defaultRoleList.find(
                        (option) => option.id === field.value
                      ) || null
                    }
                    renderOption={(option) => (
                      <React.Fragment>{option.label}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Role"
                        required
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    onChange={(_, data) => field.onChange(data?.id)}
                  />
                )}
                name="roleId"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    id="input-accessibleCompanyIds"
                    filterOptions={filterOptions}
                    filterSelectedOptions
                    options={defaultTenantList}
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => (
                      <React.Fragment>{option.label}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Accessible Company(s)"
                        inputProps={{ ...params.inputProps }}
                        required
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    onChange={(_, data) => {
                      field.onChange(data);
                    }}
                  />
                )}
                name="accessibleCompanyIds"
                control={control}
              />
            </Grid>
          </>
        )}
        {(sysAdmin || foAdmin) && (
          <Grid item lg={6}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Tooltip title={externalUserMessage} followCursor>
                  <FormControlLabel
                    {...field}
                    control={
                      <Checkbox
                        onClick={(event) => handleExternalChange(event)}
                        color="primary"
                        defaultChecked={external != null ? external : true}
                      />
                    }
                    label="External User"
                    labelPlacement="end"
                  />
                </Tooltip>
              )}
              defaultValue={true}
              control={control}
              name="external"
            />
          </Grid>)}
        {(isEdit && (showExternalUserWarning)) ?
          <Grid style={{ marginBottom: "10px" }} xs={12}>
            <Alert severity="warning">Selecting 'External User' will revoke this user's all administrator roles.</Alert>
          </Grid> :
          null
        }
        {(isEdit && (showExternalUserWarning === false)) ?
          <Grid style={{ marginBottom: "10px" }} xs={12}>
            <Alert severity="warning">Unselecting 'External User' will revoke this user's all external only roles.</Alert>
          </Grid> :
          null
        }
      </Grid>
    </>
  );
};

export default UserAccessManagementDialogDetail;
