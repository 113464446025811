import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import * as Actions from '../actions/Actions';
import { AppConfig } from 'AppConfig';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';
import * as Constant from 'common/Constant';
import { FoMaterialTable } from 'common/FoMaterialTable';

const TPA = (props) => {
  const { onClose, onSave, open } = props;
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.masterTable.systemTpas);
  const isLoading = useSelector(
    (state) => state.masterTable.tpaSelectionLoading
  );

  const resetSelection = () => {
    setSelected([]);
  };
  const handleSave = () => {
    onSave(selected);
    resetSelection();
  };

  const handleClose = () => {
    onClose();
    resetSelection();
  };

  const handleSelectionChanged = (rows) => {
    setSelected([...rows]);
  };

  const deleteSelected = (row) => {
    let ids = [];

    row.map((item) => {
      ids.push(item.id);
    });
    if (ids && Array.isArray(ids) && ids.length > 0) {
      axios
        .delete(AppConfig.API_BASE_URL + `tpa/new`, { data: ids })
        .then((m) => {
          refresh();
        })
        .catch((err) =>
          dispatch({
            type: Actions.MASTER_TABLE_DELETE_TPA_FAILURE
          })
        );
    }
  };

  const handleSaveSelection = (row) => {
    dispatch({
      type: Actions.MASTER_TABLE_SAVE_TPA
    });

    axios
      .post(AppConfig.API_BASE_URL + `tpa/save`, row)
      .then((m) => {
        dispatch({
          type: Actions.MASTER_TABLE_SAVE_TPA_SUCCESS
        });
        refresh();
      })
      .catch((error) => {
        dispatch({
          type: Actions.MASTER_TABLE_SAVE_TPA_FAILURE
        });
        handleClose();
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.status === 409
        ) {
          store.dispatch({
            type: AppActionTypes.APPSTATE_SET_APP_ERROR,
            payload: 'Save Failed. ' + error.response.data.message
          });
        } else {
          store.dispatch({
            type: AppActionTypes.APPSTATE_SET_APP_ERROR,
            payload: 'Save Failed.'
          });
        }
      });
  };

  const refresh = () => {
    dispatch({
      type: Actions.MASTER_TABLE_GET_ALL_TPA
    });
    axios
      .get(AppConfig.API_BASE_URL + `tpa/all`)
      .then((m) => {
        if (m.data && Array.isArray(m.data)) {
          dispatch({
            type: Actions.MASTER_TABLE_GET_ALL_TPA_SUCCESS,
            data: m.data
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: Actions.MASTER_TABLE_GET_ALL_TPA_FAILURE
        });
      });
  };

  useEffect(() => {
    if (open) {
      refresh();
      resetSelection();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') handleClose();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <DialogTitle id="customized-dialog-title">TPA Selection</DialogTitle>
        <DialogContent dividers>
          <FoMaterialTable
            isLoading={isLoading}
            title=""
            columns={[
              {
                title: 'Name',
                field: 'name',
                validate: (row) =>
                  (row.name || '').length === 0
                    ? {
                        isValid: false,
                        helperText: Constant.ERROR_MSG_REQUIRED_FIELD
                      }
                    : Array.isArray(data) &&
                      data.find(
                        (item) =>
                          item.name.toLowerCase() === row.name.toLowerCase()
                      )
                    ? {
                        isValid: false,
                        helperText: Constant.ERROR_MSG_DUPLICATE_FIELD
                      }
                    : true
              }
            ]}
            data={data}
            options={{
              selection: true,
              actionsColumnIndex: -1
            }}
            components={{ OverlayLoading: () => <div /> }}
            onSelectionChange={(rows) => handleSelectionChanged(rows)}
            actions={[
              {
                tooltip: 'Remove All Selected TPA(s)',
                icon: 'delete',
                onClick: (evt, data) => {
                  deleteSelected(data);
                  resetSelection();
                }
              }
            ]}
            editable={{
              onRowAdd: true
                ? (newData) =>
                    new Promise((resolve, reject) => {
                      resolve();
                      handleSaveSelection(newData);
                    })
                : null
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSave}
            color="primary"
            disabled={isLoading || !selected.length}>
            Save
          </Button>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TPA;
