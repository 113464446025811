import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FeatureReport from './featureReport';
import { makeStyles } from '@material-ui/core/styles';
import { AppConfig } from 'AppConfig';

const useStyles = makeStyles((theme) => ({
  bar: {
    justifyContent: 'flex-start',
    maxWidth: AppConfig.FEATURE_REPORT_BAR_WIDTH,
    minWidth: AppConfig.FEATURE_REPORT_BAR_WIDTH
  }
}));

const FeatureBar = () => {
  const classes = useStyles();

  const featureReports = useSelector((state) => state.defaultSettings.featureReports);

  return (
    <>
      {featureReports.length > 0 && (
        <>
          <Tooltip title="Highlighting recently released or updated reports as well as high traffic topics." placement="top-start">
            <h6 className="section-title" style={{display: 'flex', width: 'fit-content'}}>Featured Reports</h6>
          </Tooltip>
          <Grid container spacing={6} bgcolor="primary.main" direction="row" className={classes.bar}>
            {featureReports &&
              Array.isArray(featureReports) &&
              featureReports.map((f, i) => {
                return <FeatureReport item={f} key={`feature-bar-${i}`} />;
              })}
          </Grid>
        </>
      )}
    </>
  );
};

export default FeatureBar;
