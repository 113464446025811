import * as Types from '../actions/types';
import * as Actions from '../actions/action';

let initialState = {
  shouldRefresh: Actions.SHOULD_REFRESH_INIT_VALUE,
  expenseName: [],
  electionTier: []
};

export default function lookupReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ALL_PLAN_EXPENSE_SUCCESS:
      return {
        ...state,
        expenseName: action.data
      };
    case Types.GET_ALL_ELECTION_TIER_SUCCESS:
      return {
        ...state,
        electionTier: action.data
      };
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    default:
      return state;
  }
}
