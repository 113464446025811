import React, { useState } from 'react';
import { TextField, Grid, IconButton } from '@material-ui/core';
import { Delete, Add } from '@material-ui/icons';
import {v4 as uuid} from 'uuid';
import { useFormContext, useFieldArray } from 'react-hook-form';
import PolicyExclusionDialog from './policyExclusionDialog';
import * as Constant from 'common/Constant';

const PolicyExclusionList = (props) => {
  const [creationOpen, setCreationOpen] = useState(false);
  const { control, setValue, getValues } = useFormContext();
  const { fields, remove } = useFieldArray({
    control,
    keyName: Constant.DEFAULT_REACT_HOOK_FIELD_ID,
    name: 'policyExclusionDTOs'
  });

  const handleCreationClose = (data) => {
    if (data) {
      let existing = getValues('policyExclusionDTOs');
      if (!existing) {
        existing = [];
      }
      existing.push(data);
      setValue('policyExclusionDTOs', existing);
    }
    setCreationOpen(false);
  };

  const handleCreationOpen = () => {
    setCreationOpen(true);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        style={{ minWidth: '500px' }}>
        <PolicyExclusionDialog
          open={creationOpen}
          onClose={handleCreationClose}
        />

        {fields && Array.isArray(fields) && fields.length > 0 ? (
          fields.map((item, index) => (
            <>
              <Grid item xs={3}>
                <TextField
                  id={'readonly-policyExclusionPersonalId-' + index}
                  key={'key-' + uuid()}
                  label="Personal Id"
                  InputProps={{ readOnly: true }}
                  defaultValue={item.personId}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id={'readonly-policyExclusionFirstName-' + index}
                  key={'key-' + uuid()}
                  label="First Name"
                  InputProps={{ readOnly: true }}
                  defaultValue={item.firstName}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id={'readonly-policyExclusionLastName-' + index}
                  key={'key-' + uuid()}
                  label="Last Name"
                  InputProps={{ readOnly: true }}
                  defaultValue={item.lastName}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id={'readonly-policyExclusionMedId-' + index}
                  key={'key-' + uuid()}
                  label="Medical Id"
                  InputProps={{ readOnly: true }}
                  defaultValue={item.medId}
                />
              </Grid>
              <Grid item xs={1}>
                {index === fields.length - 1 && (
                  <IconButton
                    id={'action-add'}
                    onClick={() => handleCreationOpen()}
                    title="Add New">
                    <Add />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  id={'action-delete-' + index}
                  onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </>
          ))
        ) : (
          <>
            <Grid container justify="center" alignItems="center">
              <i>No data was found. Please click </i>
              <IconButton
                id={'action-add-no-data'}
                key={'key-' + uuid()}
                onClick={() =>
                  handleCreationOpen({
                    runOutEndDate: null,
                    runOutStartDate: null,
                    runInEndDate: null,
                    runInStartDate: null
                  })
                }
                title="Add New">
                <Add />
              </IconButton>
              <i> to create a new one</i>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PolicyExclusionList;
