import React from 'react';
import { Grid, makeStyles,TextField, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ContactInfo from './contactInfo';

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: '25px', 
    },
    width: '150px'
  },
  label: { 
    fontWeight: "500",
    marginRight: theme.spacing(1),
    alignSelf: 'center'
  },
  leftFill: {marginLeft: '18px'}
}));

const AdminChart = ({ prefix }) => {
  const styles = useStyles();
  const { control, getValues,methods, formState, reset } = useFormContext();

  return (
    <>
    <br />
    <div>
      <Typography color="primary">
        <strong style={{ fontSize: '1.15em' }}>{prefix.charAt(0).toUpperCase() + prefix.slice(1)} Feed:</strong>
      </Typography>
      <Grid container spacing={2} className={styles.leftFill}>
        <Grid item xs={3}>
          <Grid container alignItems="center">
            <Grid item>
              <label htmlFor={`${prefix}CarrierName`} className={styles.label}>
                Carrier Name
              </label>
            </Grid>
            <Grid item xs>
              <Controller
                name={`${prefix}CarrierName`}
                control={control}
                defaultValue=""
                rules={{
                  required: "Carrier Name is required"
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    size="small"
                    id={`${prefix}-carrier-name`}
                    className={styles.inputField}
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? 'Carrier Name is required' : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={3}>
          <Grid container alignItems="center">
            <Grid item>
              <label htmlFor={`${prefix}StartDate`} className={styles.label} style={{ marginRight: '24px' }}>
                Start Date
              </label>
            </Grid>
            <Grid item xs>
              <Controller
                name={`${prefix}StartDate`}
                control={control}
                defaultValue=""
                rules={{
                  required: "Start Date can't be empty",
                  pattern: {
                    value: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
                    message: 'Invalid format, should be MM/DD/YYYY'
                  }
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    size="small"
                    placeholder="MM/DD/YYYY"
                    className={styles.inputField}
                    variant="outlined"
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={4}>
          <Grid container alignItems="center">
            <Grid item>
              <label htmlFor={`${prefix}EndDate`} className={styles.label}>
                End Date{' '}
                <span className={styles.smallFont} style={{ marginRight: '32px' }}>
                  - optional
                </span>
              </label>
            </Grid>
            <Grid item xs>
              <Controller
                name={`${prefix}EndDate`}
                control={control}
                defaultValue=""
                rules={{
                  validate: (value) => {
                    if (!value) return true;
                    const startDate = new Date(getValues(`${prefix}StartDate`));
                    const endDate = new Date(value);
                    return endDate > startDate || 'End Date must be later than Start Date';
                  },
                  pattern: {
                    value: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
                    message: 'Invalid format, should be MM/DD/YYYY'
                  }
                }}
                placeholder="MM/DD/YYYY"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    size="small"
                    placeholder="MM/DD/YYYY"
                    className={styles.inputField}
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ContactInfo prefix={`${prefix}`} />
      <br />
    </div>
  </>
  );
};

export default AdminChart;