import { alpha, Chip, makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { confirmDialog } from "common/ComfirmationDialog";
import { updateRenewalCaseStatus } from "features/clientPortal/modules/action";
import { useDispatch } from "react-redux";
import { v4 as uuid } from 'uuid';

const UploadedFileSubmit = ({ caseId, fileListSize }) => {
    const dispatch = useDispatch();
    const useStyles = makeStyles(() => ({
        root: {
          backgroundColor: alpha(blue[800], 0.7),
          width: '160px'
        }
      }));
    const classes = useStyles();

    const showConfirmationDialog = () => {
        confirmDialog(
            () => {
              dispatch(updateRenewalCaseStatus(caseId));
            },
            `Once you submit, you will not be able to modify the file list. Are you sure you want to proceed?`,
            'Confirm',
            'No',
            'Yes'
          );
    }

  return (
    <Chip key={'key-' + uuid()} label="Submit For Processing" className={classes.root} disabled={fileListSize === 0} onClick={showConfirmationDialog}/>
  )
}

export default UploadedFileSubmit;