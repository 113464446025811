import { AppConfig } from 'AppConfig';
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import axios from 'axios';
import * as Constant from 'common/Constant';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { confirmDialog } from 'common/ComfirmationDialog';
import TruncatedTextWithTooltip from 'common/TruncatedTextWithTooltip';
import * as Actions from './actions/action';
import * as MDActions from '../../common/actions/CommonActions';
import DiscretionaryBenefitsDetailDialog from './component/detailDialog';
import DiscretionaryBenefitsCloneDetailDialog from './component/cloneDetailDialog';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FileUploadDetail from './component/fileUploadDetail';
import { hasPermission } from 'utils/common';

const useStyles = makeStyles(() => ({
  planYearSelect: {
    marginLeft: '1rem',
    minWidth: 150
  }
}));

const DiscretionaryBenefits = ({ tenantId }) => {
  const ALL_YEARS = 'All';

  const styles = useStyles();

  const tableRef = useRef();

  const [cloneData, setCloneData] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
  const [cloneDetailOpen, setCloneDetailOpen] = useState(false);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [planYears, setPlanYears] = useState([ALL_YEARS]);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  const shouldRefresh = useSelector(
    (state) => state.MD.dicretionaryBenefits.shouldRefresh
  );
  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );
  const permissions = useSelector((state) => state.user.permissions);

  const isAllSelected = planYears.length === 1 && planYears[0] === ALL_YEARS;

  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanYears(tenantId));
  }, [tenantId]);

  const handleDetailOpen = (rowData) => {
    setCloneData(rowData);
    setDetailOpen(true);
  };

  const handleDetailClose = (rowData) => {
    if (rowData) {
      store.dispatch(Actions.save({ ...rowData, tenantId: tenantId }));
    }

    setDetailOpen(false);
  };

  const handleCloneDetailClose = (data) => {
    if (data) {
      confirmDialog(
        () => store.dispatch(Actions.cloneByYear(data)),
        'Are you sure you wish to clone?'
      );
    }
    setCloneDetailOpen(false);
  };

  const deletePlan = (rowData) => {
    confirmDialog(() => store.dispatch(Actions.deleteById(rowData.id)));
  };

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const handlePlanYearSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_YEARS) {
      setPlanYears([ALL_YEARS]);
    } else {
      setPlanYears(inputVal.filter((yr) => yr !== ALL_YEARS));
    }
  };

  const handleFileUploadDetailOpen = () => {
    setFileUploadOpen(true);
  };

  const handleFileUploadDetailClose = (data) => {
    setFileUploadOpen(false);
  };

  useEffect(() => {
    refresh();
  }, [tenantId, planYears]);

  useEffect(() => {
    if (
      tableRef.current &&
      shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE
    ) {
      refresh();
    }
  }, [shouldRefresh]);

  return (
    <>
      <MaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Plan Year', field: 'planYearName' },
          { title: 'Plan', field: 'planName' },
          { title: 'Coverage Category', field: 'coverageCategoryText' },
          { title: 'Coverage Name', field: 'coverageNameText' },
          { title: 'Coverage Indicator', field: 'coverageIndicator' },
          {
            title: 'BDC Benefits Differentials Indicator',
            field: 'differentialsIndicator'
          },
          {
            title: 'In-Network Coinsurance',
            field: 'inNetworkCoinsurance'
          },
          {
            title: 'In-Network Copay',
            field: 'inNetworkCopay',
            maxWidth: '200px',
            render: (data) => (
              <TruncatedTextWithTooltip text={data.inNetworkCopay} />
            )
          },
          {
            title: 'Coverage Specification',
            field: 'coverageSpecification',
            maxWidth: '200px',
            render: (data) => (
              <TruncatedTextWithTooltip text={data.coverageSpecification} />
            )
          },
          {
            title: 'Coverage Limit',
            field: 'coverageLimit',
            maxWidth: '200px',
            render: (data) => (
              <TruncatedTextWithTooltip text={data.coverageLimit} />
            )
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.API_BASE_URL + `v2/discretionary/search`, {
                tenantId: tenantId,
                years: ALL_YEARS === planYears[0] ? [] : planYears,
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_DESC,
                sortProperty: query.orderBy
                  ? query.orderBy.field
                  : 'planYearName'
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.szie,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                console.log('error' + err);
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          selection: false,
          actionsColumnIndex: -1,
          search: false,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        actions={[
          {
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleDetailOpen(rowData);
            }
          },
          {
            icon: 'library_add',
            tooltip: 'Clone',
            onClick: (event, rowData) => {
              handleDetailOpen({ ...rowData, id: null, planId: null });
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete User',
            onClick: (_, rowData) => deletePlan(rowData)
          },
          hasPermission(
            AppConfig.PERMISSION.SYSTEM_ADMIN_DISCRETIONARY_BENEFITS_IMPORT,
            permissions
          )
            ? {
                icon: ImportExportIcon,
                tooltip: 'Import',
                isFreeAction: true,
                onClick: () => handleFileUploadDetailOpen()
              }
            : null,
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleDetailOpen({
                startDate: null,
                endDate: null,
                dedEndDate: null,
                dedStartDate: null
              });
            }
          },
          {
            icon: 'library_add',
            tooltip: 'Clone Discretionary Benefits By Year',
            isFreeAction: true,
            onClick: () => {
              setCloneDetailOpen(true);
            }
          }
        ]}
        components={{
          OverlayLoading: () => <div />,
          Toolbar: (props) => (
            <Box>
              <MTableToolbar {...props} />
              <FormControl className={styles.planYearSelect} size="medium">
                <InputLabel id="label_planYear">Plan Year:</InputLabel>
                <Select
                  multiple
                  id="input_planYear"
                  key="input_planYear"
                  onChange={handlePlanYearSelection}
                  value={planYears}
                  renderValue={(planYears) => planYears.join(', ')}>
                  <MenuItem value={ALL_YEARS}>
                    <ListItemIcon>
                      <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary={ALL_YEARS} />
                  </MenuItem>
                  {totalPlanYears &&
                    Array.isArray(totalPlanYears) &&
                    totalPlanYears.map((item) => (
                      <MenuItem value={item.planYearName}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              planYears.indexOf(item.planYearName) > -1 ||
                              isAllSelected
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.planYearName} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )
        }}
      />
      <DiscretionaryBenefitsDetailDialog
        open={detailOpen}
        onClose={handleDetailClose}
        tenantId={tenantId}
        target={cloneData}
      />
      <DiscretionaryBenefitsCloneDetailDialog
        open={cloneDetailOpen}
        onClose={handleCloneDetailClose}
        tenantId={tenantId}
      />
      <FileUploadDetail
        open={fileUploadOpen}
        onClose={handleFileUploadDetailClose}
      />
    </>
  );
};

export default DiscretionaryBenefits;
