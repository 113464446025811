import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import LocalStore from 'utils/LocalStore';
import * as AppActionTypes from 'store/actions/appstate';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const save = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/division`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const deleteByIds = (ids) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `division/delete`, ids)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const getAllByTenantId = () => {
  return (dispatch) => {
    axios
      .get(
        AppConfig.API_BASE_URL +
        `division/findDivisionsWithPaging?&t=${LocalStore.getAccessToken()}&from=0&limit=30&responseType=text`
      )
      .then((m) => {
        dispatch({
          type: Types.GET_ALL_DATA_BY_TENANT_ID_SUCCESS,
          data: m.data ? m.data.data : []
        });
      })
      .catch((err) => { });
  };
};

export const exportCSV = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/division/download/csv`, data)
      .then((m) => {
        if (m?.data) {
          const url = window.URL.createObjectURL(new Blob([m.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `division.csv`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};