import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import Inspection from './inspection';
import Report from './report';

const ReportViewerDialog = ({
  open,
  title,
  reportSource,
  category,
  onClose
}) => {
  const handleClose = () => {
    onClose();
  };
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    onClose();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        handleClose();
      }}>
      <DialogTitle id="reportViewerTitle" style={{ padding: 0 }}>
        <Grid container>
          <Grid item xs="5">
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>

            {title}
          </Grid>
          <Grid item xs="6">
            <Inspection />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Report reportSource={reportSource} category={category} />
      </DialogContent>
    </Dialog>
  );
};

export default ReportViewerDialog;
