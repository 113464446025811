import React, { useEffect } from 'react';
import { TextField, Grid, Checkbox, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

const EmployeClassDetail = (props) => {
  const DUPLICATION_ERROR_MSG = 'Duplicated Name is Found.';

  const { control, getValues } = useFormContext();

  useEffect(() => { }, []);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                control={<Switch />}
                label="Active"
              />
            )}
            name="active"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: async (value) => {
                try {
                  const isFound = await axios.get(
                    AppConfig.API_BASE_URL + `employeeclass/name/${value}`
                  );
                  if (
                    isFound &&
                    isFound.data &&
                    isFound.data.id != getValues('id')
                  ) {
                    return DUPLICATION_ERROR_MSG;
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-name"
                fullWidth
                {...field}
                required
                error={error}
                helperText={error ? error.message : null}
                label="Name"
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="tpaClassName"
            rules={{ required: Constant.ERROR_MSG_REQUIRED_FIELD }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                {...field}
                required
                error={error}
                helperText={error ? error.message : null}
                label="TPA Class Name"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default EmployeClassDetail;
