import { ORM } from 'redux-orm';

import User from 'model/user';
import Site from 'model/site';
import View from 'model/view';
import Role from 'model/role';
import SysRole from 'model/sysRole';
import Trait from 'model/trait';
import Tenants from 'model/tenants';
import Menu from 'model/menu';
import SubMenu from 'model/subMenu';
import MasterDataSite from 'model/masterDataSite';
export const orm = new ORM();
orm.register(
  User,
  Site,
  View,
  Role,
  SysRole,
  Trait,
  Tenants,
  Menu,
  MasterDataSite,
  SubMenu
);
