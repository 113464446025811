import React, { useState } from 'react';
import { TextField, Grid, makeStyles } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  passwordField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },

  passwordIcon: {
    position: 'relative',
    top: 5
  }
}));

const UpdateDialogDetail = () => {

  const classes = useStyles();

  const { control, getValues } = useFormContext();
  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  return (
    <>
      <Grid container spacing={4} bgcolor='primary.main'>
        <Grid item xs={12}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              minLength: { value: 8, message: "Minimum 8 characters" },
              validate: {
                rule1: (value) => {
                  var pwd = value;
                  const upper = /[A-Z]/;
                  const lower = /[a-z]/;
                  const chars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
                  if (!upper.test(pwd)) {
                    return "At least 1 uppercase letter"
                  }
                  if (!lower.test(pwd)) {
                    return "At least 1 lowercase letter"
                  }
                  if (!chars.test(pwd)) {
                    return "At least 1 special chars"
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <div className={classes.passwordField}>
                  <TextField
                    id='input-pwd'
                    fullWidth
                    required
                    error={error}
                    helperText={error ? error.message : null}
                    label='New Password'
                    type={visible ? 'text' : 'password'}
                    {...field}
                  />
                  {visible ?
                    <VisibilityOff
                      className={classes.passwordIcon}
                      onClick={() => {
                        setVisible(!visible);
                      }}
                    /> :
                    <Visibility
                      className={classes.passwordIcon}
                      onClick={() => {
                        setVisible(!visible)
                      }}
                    />
                  }
                </div>
              );
            }}
            name="newPwd"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  var pwd = getValues('newPwd');
                  var confirmPwd = value;
                  if (pwd != confirmPwd) {
                    return "Passwords does not match"
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <div className={classes.passwordField}>
                  <TextField
                    id='input-confirmPwd'
                    required
                    fullWidth
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                    label='Confirm New Password'
                    type={visibleConfirm ? 'text' : 'password'}
                  />
                  {visibleConfirm ?
                    <VisibilityOff
                      className={classes.passwordIcon}
                      onClick={() => {
                        setVisibleConfirm(!visibleConfirm);
                      }}
                    /> :
                    <Visibility
                      className={classes.passwordIcon}
                      onClick={() => {
                        setVisibleConfirm(!visibleConfirm)
                      }}
                    />
                  }
                </div>
              );
            }}
            name='confirmNewPwd'
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateDialogDetail;
