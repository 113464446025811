import React, { useState, useEffect, useRef } from 'react';
import * as Constant from 'common/Constant';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as UserTypes from 'features/user/userTypes';
import { AppConfig } from 'AppConfig';
import * as Action from './action/action';
import axios from 'axios';
import MaterialTable from '@material-table/core';
import { confirmDialog } from 'common/ComfirmationDialog';
import TagManagementDialog from './components/tagManagementDialog';
import LogoImg from '../white-labeling/components/logoImg';
import SearchIcon from '@material-ui/icons/Search';

const TagManagementTable = () => {
  const tableRef = useRef();

  const shouldRefresh = useSelector(
    (state) => state.tagManagement.shouldRefresh
  );

  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [detailpanelState, setdetailpanelState] = useState({});
  const [interalDetailPanelState, setInteralDetailPanelState] = useState({});
  const [creationOpen, setCreationOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});
  const refresh = () => {
    tableRef.current?.onQueryChange();
  };

  const handleDelete = (code) => {
    confirmDialog(
      () => store.dispatch(Action.deleteLogo(code)),
      'Do you really want to delete this Tag? This will also delete all the areas that used this tag.'
    );
  };

  const handleCreationOpen = (data) => {
    setCloneData(data);
    setCreationOpen(true);
  };

  const handleCreationClose = (data) => {
    if (data && data.code && data.description) {
      let dto = {
        id: data.id ? data.id : null,
        code: data.code,
        description: data.description
      };
      let logoFile = null;
      if (data.logoFile instanceof FileList && data.logoFile.length > 0) {
        logoFile = data.logoFile[0];
      }
      store.dispatch(Action.saveTag(dto, logoFile));
    }
    setCreationOpen(false);
  };

  useEffect(() => {
    refresh();
  }, [shouldRefresh]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
  }, []);

  return (
    <>
      <TagManagementDialog
        open={creationOpen}
        onClose={handleCreationClose}
        target={cloneData}
      />
      <MaterialTable
        title=""
        style={{ width: '100%' }}
        tableRef={tableRef}
        columns={[
          { title: 'Code', field: 'code', editable: 'never' },
          { title: 'Description', field: 'description', editable: 'never' },
          {
            title: 'Default Logo',
            field: 'logo',
            render: (rowData) => <LogoImg data={rowData} url={AppConfig.FO_INITIAL_URL + '/open-api/vendor-logo/tag-code/'}/>
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let pState = {};

            tableRef.current.state.data.map((data) => {
              pState[data.id] = data.tableData.showDetailPanel;
            });
            setdetailpanelState(pState);

            axios
              .post(AppConfig.FO_INITIAL_URL + `/whitelabel-tag/search`, {
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'code',
                searchTerm: query.search
              })
              .then((m) => {
                let data = m.data;
                data.content.forEach((item) => {
                  if (pState[item.id]) {
                    if (item.networkValues) {
                      item.networkValues.forEach((mi) => {
                        const found = interalDetailPanelState[item.id];
                        if (found) {
                          mi.default_open = found[mi.id];
                        }
                      });
                    }
                    item.tableData = {
                      showDetailPanel: tableRef.current.props.detailPanel
                    };
                  }
                });

                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize,
          debounceInterval: 400
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          (data) => ({
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (event, rowData) => {
              handleCreationOpen(rowData);
            },
            disabled: data.isDefault
          }),
          (data) => ({
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (event, rowData) => {
              handleDelete(rowData.code);
            },
            disabled: data.isDefault
          }),
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Create A New Tag',
            onClick: () => {
              handleCreationOpen({});
            },
            isFreeAction: true
          }
        ]}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
      />
    </>
  );
};

export default TagManagementTable;
