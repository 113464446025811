import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import PlanRateCreationDetail from './creationDetail';
import { useForm, FormProvider } from 'react-hook-form';
import { store } from 'store/store';
import * as Actions from '../actions/action';
import * as AppActionTypes from 'store/actions/appstate';
import * as Constant from 'common/Constant';
import { AppConfig } from '../../../../../AppConfig';
import axios from 'axios';
import SubmitButton from 'common/SingleClickButton';

const PlanRateCreationDialog = (props) => {
  const { onClose, open } = props;

  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;

  const tenantId = useSelector((state) => state.user.currentTenantCd);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data) => {
    const createRequests = [];
    const conflictRequests = [];

    const valid = data.rate.some(r => r.employeeClass);
    if (!valid){
        store.dispatch({
            type: AppActionTypes.APPSTATE_SET_APP_ERROR,
            payload:
              'Must include at least 1 Plan Rate. Add 1 or more rows under Rate Details.'
          });
          return;
    }

    const planNames = data.planNames
      .filter((pln) => pln !== Constant.ALL)
      .map(({ id }) => id);

    for (const id of planNames) {
      const rateRequestBodies = data.rate.map((rate) => {
        if (
          !rate.employeeClass ||
          Number.isNaN(rate.totalRate) ||
          Number.isNaN(rate.employerShare) ||
          Number.isNaN(rate.employeeShare)
        ) {
          return;
        }

        conflictRequests.push(
          axios.get(
            `${AppConfig.API_BASE_URL}v2/plan-rate/plan-id/${id}/election-id/${rate.etId}/status/${data.status}/class-id/${rate.employeeClass}`
          )
        );

        return {
          comments: data.comments,
          planId: id,
          electionTierSchemaId: rate.etId,
          status: data.status,
          rate: rate.totalRate,
          employerShare: rate.employerShare,
          employeeShare: rate.employeeShare,
          tobaccoSurcharge: rate.tobaccoSurcharge,
          wellnessDiscount: rate.wellnessDiscount,
          spousalSurcharge: rate.spousalSurcharge,
          ssdisabilitySurcharge: rate.ssdisabilitySurcharge,
          cobra: rate.cobra,
          startDate: data.startDate,
          endDate: data.endDate,
          classSchemaId: rate.employeeClass
        };
      });
      createRequests.push(
        ...rateRequestBodies.filter((bdy) => bdy !== undefined)
      );
    }
    const conflictResults = await axios.all(conflictRequests);

    if (
      conflictResults.filter(
        (el) => el.data !== null && typeof el.data === 'object'
      ).length > 0
    ) {
      const originalStartDate = data.startDate;
      const originalEndDate = data.endDate;

      const result = conflictResults.filter(
        (item) =>
          (item.data.startDate >= originalStartDate &&
            item.data.startDate <= originalEndDate) ||
          (item.data.endDate >= originalStartDate &&
            item.data.endDate <= originalEndDate)
      );

      if (result && result.length > 0) {
        return store.dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload:
            'Found an existing Rate for the given plan, election tier, employee class, date range and status, please check and try again.'
        });
      }
    }

    createRequests.forEach((rq) => {
      store.dispatch(Actions.save(rq));
    });

    onClose();
  };

  useEffect(() => {
    reset({ planYears: [], planNames: [] });
  }, [tenantId, open]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          maxWidth="lg"
          fullWidth
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}>
          <DialogTitle id="plan-rate-create">Add Plan Rates</DialogTitle>
          <DialogContent dividers>
            <PlanRateCreationDetail />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default PlanRateCreationDialog;
