import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const saveTag = (dto, file) => {
  let formData = new FormData();
  formData.append('id', Number(dto.id));
  formData.append('code', dto.code);
  formData.append('description', dto.description);
  if (file !== null) {
    formData.append('logoFile', file);
  }
  
  return (dispatch) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + `/vendor-logo/tag/save`, formData)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteById = (id) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.FO_INITIAL_URL + `/whitelabel-tag/tag/${id}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteLogo = (code) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.FO_INITIAL_URL + `/vendor-logo/tag-code/${code}`)
      .then((m) => {
        dispatch({
          type: Types.UPLOAD_LOGO_SUCCESS
        });
      })
      .catch((err) => {
        //send to global logo
        //do nothing for now
      });
  };
};