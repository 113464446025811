import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkAuth } from './checkAuth';

const ControlledRoute = ({ component: Component, fallback, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !checkAuth() ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: fallback,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default ControlledRoute;
