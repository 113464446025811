import * as Types from '../action/types';

const initialState = {
  planYears: [],
  shouldRefresh: false,
  tenantList: [],
  fileList: []
};

export default function mdRenewalManagementReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };

    case Types.FETCH_ALL_MD_RENEWAL_YEARS_SUCCESS:
      return {
        ...state,
        planYears: action.data.data
      };

    case Types.FETCH_TENANT_LIST:
      return {
        ...state,
        tenantList: action.data
      };

    case Types.FETCH_FILE_LIST:
      return {
        ...state,
        fileList: action.data
      };

    case Types.CLEAR_FILE_LIST:
      return {
        ...state,
        fileList: []
      };

    default:
      return state;
  }
}
