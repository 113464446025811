import { AppConfig } from 'AppConfig';
import React, { useState, useEffect, useRef } from 'react';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import {
  Box,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import DentalPlanNetwork from './components/dentalPlanNetwork';
import DentalClone from './components/dentalClone';
import * as Constant from 'common/Constant';
import * as Actions from './actions/PlanDesignDataAction';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import * as DateUtil from '../../util/Dateutil';
import PlanDesignDataDetailDialog from './detailDialog';
import DentalPlanNetworkDetailDialog from './components/dentalPlanNetworkDetailDialog';
import * as MDActions from '../../common/actions/CommonActions';
import { confirmDialog } from 'common/ComfirmationDialog';
import _ from 'lodash';
import { update } from '../stopLossContract/actions/actions';

const useStyles = makeStyles(() => ({
  planYearSelect: {
    marginLeft: '1rem',
    minWidth: 150
  }
}));

const DentalPlan = ({ tenantId }) => {
  const ALL_YEARS = 'All';

  const styles = useStyles();

  const tableRef = useRef();

  const [open, setOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});
  const [detailpanelState, setdetailpanelState] = useState({});
  const [interalDetailPanelState, setInteralDetailPanelState] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
  const [networkDetailOpen, setNetworkDetailOpen] = useState(false);
  const [networkDetail, setNetworkDetail] = useState({});
  const [existingNetworks, setExistingNetworks] = useState([]);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [planYears, setPlanYears] = useState([ALL_YEARS]);

  const allNetworkTypes = useSelector(
    (state) => state.MD.mdCommon.allNetworkTypes
  );
  const shouldRefresh = useSelector(
    (state) => state.MD.plandDesignData.shouldRefresh
  );
  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );

  const isAllSelected = planYears.length === 1 && planYears[0] == ALL_YEARS;

  const handleClone = (rowData) => {
    setCloneData({ ...rowData, type: Constant.PLAN_DESIGN_DATA_TYPE.DENTAL });
    setOpen(true);
  };

  const handleDetailClone = (rowData) => {
    setCloneData(rowData);
    setDetailOpen(true);
  };

  const handleNetworkOpen = (data, existingNetworks) => {
    setNetworkDetail(data);
    setExistingNetworks(existingNetworks);
    setNetworkDetailOpen(true);
  };

  const handleClose = (data) => {
    if (data) {
      store.dispatch(
        Actions.cloneDental({
          targetId: data.targetId,
          annualPlanId: data.ahpmId,
          startDate: data.planStartDate,
          endDate: data.planEndDate,
          networkIds: data.networkIds,
          planName: data.planName
        })
      );

      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const handleDetailClose = (data) => {
    if (data) {
      store.dispatch(
        Actions.save({
          ...data,
          tenantId: tenantId
        })
      );
    }

    setDetailOpen(false);
  };

  const handleNetworkDetailClose = (data) => {
    if (data) {
      store.dispatch(
        Actions.saveDentalNetwork({
          ...data,
          tenantId: tenantId
        })
      );
    }

    setNetworkDetailOpen(false);
  };

  const deletePlan = (rowData) => {
    confirmDialog(() => store.dispatch(Actions.deletePDA(rowData.id)));
  };

  const refresh = () => {
    refreshNetworkTypes();
    tableRef.current.onQueryChange();
  };

  //function to remembe which detail is expend
  const handleDetailExpend = (item, expend) => {
    setInteralDetailPanelState({
      ...interalDetailPanelState,
      [item.planId]: {
        [item.id]: expend
      }
    });
  };

  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanYears(tenantId));
  }, [tenantId]);

  useEffect(() => {
    refreshNetworkTypes();
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [planYears]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
    store.dispatch(MDActions.getAllElectionTiers());
  }, [tenantId]);

  const refreshNetworkTypes = () => {
    store.dispatch(MDActions.getAllNetworkTypes());
  };

  useEffect(() => {
    if (
      tableRef.current &&
      shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE
    ) {
      refreshNetworkTypes();
      tableRef.current.onQueryChange();
    }
  }, [shouldRefresh]);

  const handlePlanYearSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_YEARS) {
      setPlanYears([ALL_YEARS]);
    } else {
      setPlanYears(inputVal.filter((yr) => yr !== ALL_YEARS));
    }
  };

  return (
    <>
      <MaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Plan Year', field: 'planYearName' },
          { title: 'Plan Name', field: 'planName' },
          {
            title: 'Start Date',
            field: 'planStartDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.planStartDate)
          },
          {
            title: 'End Date',
            field: 'planEndDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.planEndDate)
          },
          { title: 'Plan Type', field: 'planType' },
          {
            title: 'TPA Plan Number',
            field: 'planNumber',
            cellStyle: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: 500
            }
          },
          {
            title: 'Network',
            sorting: false,
            render: (rowData) =>
              rowData.dentalNetworkValues
                ? _.map(rowData.dentalNetworkValues, function (item) {
                    return item.networkType;
                  }).join(', ')
                : 0
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            //remember the existing state before refresh
            let pState = {};

            tableRef.current.state.data.map((data) => {
              pState[data.id] = data.tableData.showDetailPanel;
            });
            setdetailpanelState(pState);

            //end of remember state
            axios
              .post(AppConfig.API_BASE_URL + `v2/plan/dental/search`, {
                tenantId: tenantId,
                years: ALL_YEARS === planYears[0] ? [] : planYears,
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_DESC,
                sortProperty: query.orderBy
                  ? query.orderBy.field
                  : 'planYearName'
              })
              .then((m) => {
                let data = m.data;
                data.content.forEach((item) => {
                  if (pState[item.id]) {
                    if (item.dentalNetworkValues) {
                      item.dentalNetworkValues.forEach((mi) => {
                        const found = interalDetailPanelState[item.id];
                        if (found) {
                          mi.default_open = found[mi.id];
                        }
                      });
                    }
                    item.tableData = {
                      showDetailPanel: tableRef.current.props.detailPanel
                    };
                  }
                });
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.szie,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                console.log('error' + err);
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          selection: false,
          actionsColumnIndex: -1,
          search: false,
          exportButton: {
            csv: true,
            pdf: false
          },
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        actions={[
          (rowData) => ({
            icon: 'playlist_add_circle',
            tooltip:
              rowData &&
              allNetworkTypes &&
              rowData.dentalNetworkValues &&
              rowData.dentalNetworkValues.length < allNetworkTypes.length
                ? 'Add Network'
                : 'N/A',
            disabled: !(
              rowData &&
              allNetworkTypes &&
              rowData.dentalNetworkValues &&
              rowData.dentalNetworkValues.length < allNetworkTypes.length
            ),
            onClick: (_, rowData) => {
              handleNetworkOpen(
                {
                  planId: rowData.id,
                  hsaType: Constant.KEY_HSA_TYPE_IN_FAMILY
                },
                rowData.dentalNetworkValues
              );
            }
          }),
          {
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleDetailClone(rowData);
            }
          },
          {
            icon: 'library_add',
            tooltip: 'Clone',
            onClick: (event, rowData) => {
              handleClone(rowData);
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete User',
            onClick: (_, rowData) => deletePlan(rowData)
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleDetailClone({
                planStartDate: null,
                planEndDate: null,
                type: Constant.PLAN_DESIGN_DATA_TYPE.DENTAL
              });
            }
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={(rowData) => {
          return (
            <>
              <Box m={2}>
                {rowData.rowData &&
                rowData.rowData.dentalNetworkValues &&
                Array.isArray(rowData.rowData.dentalNetworkValues)
                  ? rowData.rowData.dentalNetworkValues.map((item) => {
                      return (
                        <DentalPlanNetwork
                          key={'parent_network' + item.id}
                          item={item}
                          handleEdit={(data) => handleNetworkOpen(data)}
                          handleExpend={(data, expend) =>
                            handleDetailExpend(data, expend)
                          }
                        />
                      );
                    })
                  : null}
              </Box>
            </>
          );
        }}
        components={{
          OverlayLoading: () => <div />,
          Toolbar: (props) => (
            <Box>
              <MTableToolbar {...props} />
              <FormControl className={styles.planYearSelect} size="medium">
                <InputLabel id="label_planYear">Plan Year:</InputLabel>
                <Select
                  multiple
                  id="input_planYear"
                  key="input_planYear"
                  onChange={handlePlanYearSelection}
                  value={planYears}
                  renderValue={(planYears) => planYears.join(', ')}>
                  <MenuItem value={ALL_YEARS}>
                    <ListItemIcon>
                      <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary={ALL_YEARS} />
                  </MenuItem>
                  {totalPlanYears &&
                    Array.isArray(totalPlanYears) &&
                    totalPlanYears.map((item) => (
                      <MenuItem value={item.planYearName}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              planYears.indexOf(item.planYearName) > -1 ||
                              isAllSelected
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.planYearName} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )
        }}
      />
      <DentalClone
        open={open}
        onSubmit={handleClone}
        onClose={handleClose}
        tenantId={tenantId}
        target={cloneData}
      />
      <PlanDesignDataDetailDialog
        open={detailOpen}
        onClose={handleDetailClose}
        tenantId={tenantId}
        target={cloneData}
        category={Constant.PLAN_DESIGN_DATA_TYPE.DENTAL}
      />
      <DentalPlanNetworkDetailDialog
        open={networkDetailOpen}
        onClose={handleNetworkDetailClose}
        tenantId={tenantId}
        target={networkDetail}
        existingNetworks={existingNetworks}
      />
    </>
  );
};

export default DentalPlan;
