import React, { useState, useEffect, Fragment } from 'react';
import { TextField, Grid, IconButton } from '@material-ui/core';
import { Delete, Add } from '@material-ui/icons';
import {v4 as uuid} from 'uuid';
import MonthlyAggDialog from '../monthlyAggDialog';
import * as Constant from 'common/Constant';
import { useFieldArray, useFormContext } from 'react-hook-form';

const MonthlyAggList = React.memo((props) => {
  const { tenantId, annualPlanId, currentPlanNames } = props;
  const { control, getValues, setValue, watch } = useFormContext();
  const { fields, remove } = useFieldArray({
    control,
    keyName: Constant.DEFAULT_REACT_HOOK_FIELD_ID,
    name: 'aslDTOs'
  });

  const watchSlcEndDate = watch('aslDTOs');

  const DEAFULT_CRUD_OBJ = {
    sLCMonthlyAggType: Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE
  };

  const [dataGroupByPlanMap, setDataGroupByPlanMap] = useState({});
  const [planKeyValue, setPlanKeyValue] = useState({});

  const [creationOpen, setCreationOpen] = useState(false);

  const [crudObj, setCrudObj] = useState(DEAFULT_CRUD_OBJ);

  //quick way to fix that when dialog shows up and inside the dialog it called use store.dispatcher, the aslDto will be reset
  const [currentList, setCurrentList] = useState([]);

  const handleCreationClose = (data) => {
    if (data) {
      let existing = currentList;
      if (!existing) {
        existing = [];
      }
      existing.push(data);
      setValue('aslDTOs', existing);
      calculateMaps(existing);
    } else {
      setValue('aslDTOs', currentList);
    }
    setCreationOpen(false);
  };

  const handleCreationOpen = (data) => {
    const eList = getValues('aslDTOs');
    if (eList) {
      setCurrentList(eList);
    } else {
      setCurrentList([]);
    }
    setCrudObj(data);
    setCreationOpen(true);
  };

  const calculateMaps = (data) => {
    if (
      data &&
      Array.isArray(data) &&
      currentPlanNames &&
      Array.isArray(currentPlanNames)
    ) {
      const newMap = {};
      const map = {};
      data.map((key) => {
        const keyId = key.planId;
        if (!newMap.hasOwnProperty(keyId)) {
          newMap[keyId] = [];
        }

        newMap[keyId].push(key);
        const found = currentPlanNames.find((pln) => pln.id === keyId);
        if (found) map[keyId] = found.planName;
      });
      setPlanKeyValue(map);
      setDataGroupByPlanMap(newMap);
    }
  };

  useEffect(() => {
    calculateMaps(getValues('aslDTOs'));
  }, [fields, currentPlanNames]);

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        <MonthlyAggDialog
          open={creationOpen}
          onClose={handleCreationClose}
          tenantId={tenantId}
          target={crudObj}
          annualPlanId={annualPlanId}
          excludedPlans={currentList}
        />
        {Object.keys(dataGroupByPlanMap).length > 0 ? (
          Object.keys(dataGroupByPlanMap).map((key, index) => (
            <Fragment key={uuid()}>
              <Grid item xs={3}>
                <TextField
                  id={'readonly-planId-' + index}
                  key={'key-' + uuid()}
                  label="Plan"
                  InputProps={{ readOnly: true }}
                  multiline
                  defaultValue={planKeyValue[key]}
                />
              </Grid>
              {Array.isArray(dataGroupByPlanMap[key]) &&
                dataGroupByPlanMap[key].length > 0
                ? dataGroupByPlanMap[key].map((item, indx) => {
                  return item.sLCMonthlyAggType ===
                    Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE ? (
                    <Fragment key={uuid()}>
                      {indx > 0 ? <Grid item xs={3}></Grid> : null}
                      <Grid item xs={3}>
                        <TextField
                          id={'readonly-aggDedComp-' + indx}
                          fullWidth
                          key={'key-' + uuid()}
                          label="Agg Factor Composite"
                          InputProps={{ readOnly: true }}
                          defaultValue={item.aggDedComp}
                        />
                      </Grid>
                      <Grid item xs={5}></Grid>
                    </Fragment>
                  ) : item.sLCMonthlyAggType ===
                    Constant.SLC_MONTHLY_AGG_TYPE.ELECTION_TIER ? (
                    <Fragment key={uuid()}>
                      {item.aggRates &&
                        Array.isArray(item.aggRates) &&
                        item.aggRates.map((rate, eIndx) => {
                          return (
                            <Fragment key={uuid()}>
                              {eIndx > 0 ? <Grid item xs={3}></Grid> : null}
                              <Grid item xs={3}>
                                <TextField
                                  id={
                                    'readonly-electiontierId-' +
                                    indx +
                                    '-' +
                                    eIndx
                                  }
                                  key={'key-' + uuid()}
                                  label="Election Tier"
                                  InputProps={{ readOnly: true }}
                                  defaultValue={rate.meta.electiontierName}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  id={
                                    'readonly-electiontierRate-' +
                                    indx +
                                    '-' +
                                    eIndx
                                  }
                                  key={'key-' + uuid()}
                                  label="Rate"
                                  InputProps={{ readOnly: true }}
                                  defaultValue={rate.electiontierRate}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={2}></Grid>
                            </Fragment>
                          );
                        })}
                    </Fragment>
                  ) : item.sLCMonthlyAggType ===
                    Constant.SLC_MONTHLY_AGG_TYPE.IND_FAM ? (
                    <Fragment key={uuid()}>
                      {indx > 0 ? <Grid item xs={3}></Grid> : null}
                      <Grid item xs={3}>
                        <TextField
                          id={'readonly-aggFactInd-' + indx}
                          key={'key-' + uuid()}
                          label="IND"
                          InputProps={{ readOnly: true }}
                          defaultValue={item.aggFactInd}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id={'readonly-aggFactFam-' + index}
                          key={'key-' + uuid()}
                          label="FAM"
                          InputProps={{ readOnly: true }}
                          defaultValue={item.aggFactFam}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Fragment>
                  ) : null;
                })
                : null}
              <Grid item xs={1}>
                <IconButton
                  id={'action-delete-' + index}
                  onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
                {index === Object.keys(dataGroupByPlanMap).length - 1 && (
                  <IconButton
                    id={'action-add'}
                    onClick={() => handleCreationOpen(DEAFULT_CRUD_OBJ)}
                    title="Add New">
                    <Add />
                  </IconButton>
                )}
              </Grid>
            </Fragment>
          ))
        ) : (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <i>No data was found. Please click </i>
              <IconButton
                id={'action-add-no-data'}
                onClick={() => handleCreationOpen(DEAFULT_CRUD_OBJ)}
                title="Add New">
                <Add />
              </IconButton>
              <i> to create a new one</i>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
});

export default MonthlyAggList;
