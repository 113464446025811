import * as Types from '../actions/types';
import * as Actions from '../actions/action';

let initialState = {
  shouldRefresh: Actions.SHOULD_REFRESH_INIT_VALUE,
  coverageCategories: [],
  coverageNames: [],
  coverageSpecifications: [],
  records: [],
  errorLogFile: null
};

export default function dicretionaryBenefitsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case Types.DELETE_SUCCESS:
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.GET_COVERAGE_NAMES:
      return {
        ...state,
        coverageNames: action.payload
      };
    case Types.GET_COVERAGE_CATEGORIES:
      return {
        ...state,
        coverageCategories: action.payload
      };
    case Types.CLEAR_COVERAGE_NAMES:
      return {
        ...state,
        coverageNames: []
      };
    case Types.VALIDATE_SUCCESS:
      return {
        ...state,
        records: action.payload
      };
    case Types.LOAD_SUCCESS:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.DOWNLOAD_ERROR_LOGS:
      return {
        ...state,
        errorLogFile: action.payload
      };
    default:
      return state;
  }
}
