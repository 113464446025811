import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import RouteConfig from 'routes/RouteConfig';
import { Link, useHistory } from 'react-router-dom';
import { AppConfig } from 'AppConfig';
import { useSelector } from 'react-redux';
import { fetchData } from 'features/user/userActions';
import { store } from 'store/store';
import TenantAdminTable from '../tenantAdmin/tenantAdminTable';

const TenantAdminNavigator = () => {
  const TENANT_USER_ACCESS_URL = RouteConfig.tenantAdmin.path;
  const history = useHistory();
  const { deafultUserState, currentTenantCd } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deafultUserState) {
        store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_FO));
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Tabs
        value={history.location.pathname}
        indicatorColor="primary"
        textColor="primary">
        <Tab
          label="Tenant User Access"
          value={TENANT_USER_ACCESS_URL}
          component={Link}
          to={TENANT_USER_ACCESS_URL}
        />
      </Tabs>
      {history.location.pathname === TENANT_USER_ACCESS_URL && (
        <TenantAdminTable />
      )}
    </>
  );
};

export default TenantAdminNavigator;
