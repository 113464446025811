export const PREFIX = 'MD-PLAN-EXPENSE_';

export const FETCH_EXPENSE_CATEGORY_SUCCESS =
  PREFIX + 'FETCH_EXPENSE_CATEGORY_SUCCESS';

export const FETCH_EXPENSE_NAMES_SUCCESS =
  PREFIX + 'FETCH_EXPENSE_NAMES_SUCCESS';

export const FETCH_RATE_BASIS_SUCCESS = PREFIX + 'FETCH_RATE_BASIS_SUCCESS';
export const FETCH_ALL_RATE_BASIS_SUCCESS =
  PREFIX + 'FETCH_ALL_RATE_BASIS_SUCCESS';
export const FETCH_SIMPLE_PLANS_SUCCESS = PREFIX + 'FETCH_SIMPLE_PLANS_SUCCESS';
export const CREATION_SUCCESS = PREFIX + 'CREATION_SUCCESS';
export const UPDATION_SUCCESS = PREFIX + 'UPDATION_SUCCESS';
export const CLONE_SUCCESS = PREFIX + 'CLONE_SUCCESS';

export const CREATION_SPECIAL_EXPENSE_VALIDATION_FAILED =
  PREFIX + 'CREATION_SPECIAL_EXPENSE_VALIDATION_FAILED';
export const FETCH_SIMPLE_PLANS_EXPENSE_SUCCESS =
  PREFIX + 'FETCH_SIMPLE_PLANS_EXPENSE_SUCCESS';

export const DELETE_RECORD_SUCCESS = PREFIX + 'DELETE_RECORD_SUCCESS';
