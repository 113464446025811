import { AppConfig } from 'AppConfig';
import React, { useState, useEffect, useRef } from 'react';
import { MTableToolbar } from '@material-table/core';
import {
  Box,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import StopLossContractDetailView from './detailView';
import * as Constant from 'common/Constant';
import * as Actions from './actions/actions';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import * as DateUtil from '../../util/Dateutil';
import { confirmDialog } from 'common/ComfirmationDialog';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import StopLossContractDetailDialog from './components/detailDialog';
import * as MDActions from '../../common/actions/CommonActions';
import * as ValidationUtil from 'utils/ValidationUtil';
import { FoMaterialTable } from 'common/FoMaterialTable';

const useStyles = makeStyles(() => ({
  planYearSelect: {
    marginLeft: '1rem',
    minWidth: 150
  }
}));

const StopLossContract = ({ tenantId }) => {
  const ALL_YEARS = 'All';

  const styles = useStyles();

  const tableRef = useRef();

  const [cloneData, setCloneData] = useState({});
  const [detailpanelState, setdetailpanelState] = useState({});
  const [interalDetailPanelState, setInteralDetailPanelState] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [planYears, setPlanYears] = useState([ALL_YEARS]);

  const shouldRefresh = useSelector(
    (state) => state.MD.stopLossContract.shouldRefresh
  );
  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );

  const isAllSelected = planYears.length === 1 && planYears[0] == ALL_YEARS;

  const handleDetailOpen = (rowData) => {
    setCloneData(rowData);
    setDetailOpen(true);
  };

  const updateData = (data) => {
    store.dispatch(Actions.update(data));
    setDetailOpen(false);
  };

  const saveData = (data) => {
    store.dispatch(Actions.save(data));
    setDetailOpen(false);
  };

  const handleDetailCloseUtil = (data) => {
    if (
      ValidationUtil.IN_RANGE(
        data._planYearStartDate,
        data._planYearEndDate,
        data.startDate
      ) ||
      ValidationUtil.IN_RANGE(
        data._planYearEndDate,
        data._planYearEndDate,
        data.endDate
      )
    ) {
      const warningMesage =
        'The start/end date is NOT within selected plan year. Are you sure you want to save it?';
      if (data.id == null) {
        confirmDialog(() => saveData(data), warningMesage, 'Confirmation');
      } else {
        confirmDialog(() => updateData(data), warningMesage, 'Confirmation');
      }
      return true;
    } else if (data.id == null) {
      saveData(data);
    } else {
      updateData(data);
    }
    return false;
  };

  const handleDetailClose = (data) => {
    let confirmationDetailOpen = false;
    if (data) {
      if (
        data.shareExpectedClaims === true &&
        data.aslPlanName &&
        Array.isArray(data.aslPlanName) &&
        data.aslPlanName.length
      ) {
        if (
          data.sLCMonthlyAggType === Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE
        ) {
          const rates = data.aslPlanName.map((plnId) => {
            return { planId: plnId, aggDedComp: data.aggDedComp };
          });
          data.aggRates = rates;
        } else if (
          data.sLCMonthlyAggType === Constant.SLC_MONTHLY_AGG_TYPE.IND_FAM
        ) {
          const rates = data.aslPlanName.map((plnId) => {
            return {
              planId: plnId,
              aggFactInd: data.aggFactInd,
              aggFactFam: data.aggFactFam
            };
          });
          data.aggRates = rates;
        } else if (
          data.sLCMonthlyAggType === Constant.SLC_MONTHLY_AGG_TYPE.ELECTION_TIER
        ) {
          const rates = [];

          data.aslPlanName.map((plnId) => {
            data.aggRates.map((eRate) =>
              rates.push({
                planId: plnId,
                electiontierId: eRate.electiontierId,
                electiontierRate: eRate.electiontierRate
              })
            );
          });

          data.aggRates = rates;
        }
      } else if (data.aggRates && Array.isArray(data.aggRates)) {
        const convertedAggRate = [];

        data.aggRates.forEach((rate) => {
          if (
            rate.sLCMonthlyAggType ===
              Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE ||
            rate.sLCMonthlyAggType === Constant.SLC_MONTHLY_AGG_TYPE.IND_FAM
          ) {
            convertedAggRate.push(rate);
          } else if (
            rate.sLCMonthlyAggType ===
            Constant.SLC_MONTHLY_AGG_TYPE.ELECTION_TIER
          ) {
            rate.aggRates.map((rt) => {
              convertedAggRate.push({
                planId: rate.planId,
                electiontierId: rt.electiontierId,
                electiontierRate: rt.electiontierRate
              });
            });
          }
        });
        data.aggRates = convertedAggRate;
      }
      confirmationDetailOpen = handleDetailCloseUtil(data);
    }
    if (!confirmationDetailOpen) {
      setDetailOpen(false);
    }
  };

  const deleteById = (rowData) => {
    const ids = rowData.map((item) => item.id);
    confirmDialog(
      () => store.dispatch(Actions.deleteByIds(ids)),
      'Are you sure you want to delete this?'
    );
  };

  const refresh = () => {
    tableRef.current.onQueryChange();
  };

  //function to remembe which detail is expend
  const handleDetailExpend = (item, expend) => {
    setInteralDetailPanelState({
      ...interalDetailPanelState,
      [item.planId]: {
        [item.id]: expend
      }
    });
  };

  const handlePlanYearSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_YEARS) {
      setPlanYears([ALL_YEARS]);
    } else {
      setPlanYears(inputVal.filter((yr) => yr !== ALL_YEARS));
    }
  };

  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanYears(tenantId));
  }, [tenantId]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [tenantId, planYears]);

  useEffect(() => {
    if (
      tableRef.current &&
      shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE
    ) {
      tableRef.current.onQueryChange();
    }
  }, [shouldRefresh]);

  return (
    <>
      <FoMaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Plan Year', field: 'planYearName' },
          { title: 'Stop Loss Year', field: 'stopLossYear' },
          {
            title: 'Start Date',
            field: 'startDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.startDate)
          },
          {
            title: 'End Date',
            field: 'endDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.endDate)
          },
          { title: 'ISL Contract Basis', field: 'islContractBasis' },
          { title: 'ASL Contract Basis', field: 'aslContractBasis' },
          { title: 'ISL Deductible', field: 'islDeductible' },
          { title: 'Agg Spec Deductible', field: 'aggSpecDeductible' },
          { title: 'Corridor', field: 'corridor' },
          {
            title: 'Rx Covered',
            field: 'rxCovered',
            render: (rowData) =>
              rowData.rxCovered === true ? <CheckIcon /> : <ClearIcon />
          },
          {
            title: 'Med Covered',
            field: 'medCovered',
            render: (rowData) =>
              rowData.medCovered === true ? <CheckIcon /> : <ClearIcon />
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            //remember the existing state before refresh
            let pState = {};

            tableRef.current.state.data.map((data) => {
              pState[data.id] = data.tableData.showDetailPanel;
            });
            setdetailpanelState(pState);

            //end of remember state
            axios
              .post(AppConfig.API_BASE_URL + `v2/stop-loss-contract/search`, {
                tenantId: tenantId,
                years: ALL_YEARS === planYears[0] ? [] : planYears,
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_DESC,
                sortProperty: query.orderBy
                  ? query.orderBy.field
                  : 'planYearName'
              })
              .then((m) => {
                let data = m.data;
                data.content.forEach((item) => {
                  if (pState[item.id]) {
                    if (item.networkValues) {
                      item.networkValues.forEach((mi) => {
                        const found = interalDetailPanelState[item.id];
                        if (found) {
                          mi.default_open = found[mi.id];
                        }
                      });
                    }
                    item.tableData = {
                      showDetailPanel: tableRef.current.props.detailPanel
                    };
                  }
                });
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.szie,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                console.log('error' + err);
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          rowStyle: (rowData) => {
            return {
              color:
                rowData &&
                (rowData.completedLaser === false ||
                  rowData.completedPolicyExclusion === false)
                  ? 'Red'
                  : ''
            };
          },
          selection: true,
          actionsColumnIndex: -1,
          search: false,
          exportButton: {
            csv: true,
            pdf: false
          },
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        actions={[
          {
            icon: 'mode_edit',
            tooltip: 'Edit',
            position: 'row',
            onClick: (_, rowData) => {
              handleDetailOpen({
                ...rowData,
                _planYearStartDate: rowData.planYearNameStartDate,
                _planYearEndDate: rowData.planYearNameEndDate
              });
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete SLC',
            onClick: (_, rowData) => deleteById(rowData)
          },
          {
            position: 'row',
            icon: 'delete',
            tooltip: 'Delete SLC',
            onClick: (_, rowData) => deleteById([rowData])
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleDetailOpen({
                tenantId: tenantId,
                startDate: null,
                endDate: null,
                islPlanName: [],
                aslPlanName: [],
                sLCMonthlyAggType: Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE
              });
            }
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={(rowData) => {
          return (
            <>
              <Box m={2}>
                <StopLossContractDetailView
                  item={rowData.rowData}
                  policyExclusions={rowData.rowData.policyExclusions}
                  tenantId={tenantId}
                />
              </Box>
            </>
          );
        }}
        components={{
          OverlayLoading: () => <div />,
          Toolbar: (props) => (
            <Box>
              <MTableToolbar {...props} />
              <FormControl className={styles.planYearSelect} size="medium">
                <InputLabel id="label_planYear">Plan Year:</InputLabel>
                <Select
                  multiple
                  id="input_planYear"
                  key="input_planYear"
                  onChange={handlePlanYearSelection}
                  value={planYears}
                  renderValue={(planYears) => planYears.join(', ')}>
                  <MenuItem value={ALL_YEARS}>
                    <ListItemIcon>
                      <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary={ALL_YEARS} />
                  </MenuItem>
                  {totalPlanYears &&
                    Array.isArray(totalPlanYears) &&
                    totalPlanYears.map((item) => (
                      <MenuItem value={item.planYearName} key={`stop-loss-plan-year-name-${item.planYearName}`}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              planYears.indexOf(item.planYearName) > -1 ||
                              isAllSelected
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.planYearName} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )
        }}
      />

      <StopLossContractDetailDialog
        open={detailOpen}
        onClose={handleDetailClose}
        tenantId={tenantId}
        target={cloneData}
      />
    </>
  );
};

export default StopLossContract;
