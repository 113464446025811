import MaterialTable from '@material-table/core';
import { useRef, useState } from 'react';
import * as Constant from 'common/Constant';
import axios from 'axios';
import GrainIcon from '@material-ui/icons/Grain';
import InputIcon from '@material-ui/icons/Input';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import {
  deleteTenantReports,
  fetchTenantReports,
  syncReportsWithDefaultingSetting,
  saveReportefaultingSetting,
  deleteDefaultingSettingById,
  addReportToTenants,
  initTenantReports,
  fetchAllDisplayText
} from '../actions/actions';
import { confirmDialog } from '../../../common/ComfirmationDialog';
import DefaultingSettingDetailDialog from './component/detailDialog';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchData } from 'features/user/userActions';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import * as Actions from '../actions/actions';
import { fetchTenantList } from '../../admin/userAccess/action/action';
import { getAllBrokers } from 'features/user/userActions';
import { fetchBrokerCompanyMapping } from '../../admin/vendor-labeling/actions/action';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import * as UserTypes from 'features/user/userTypes';
import ReportBulkCreation from './component/bulkModification';
import ReportBulkPull from './component/bulkPull';
import ReportDeletionConfirmationDialog, {
  reportDeleteConfirmDialog
} from './component/ReportDeletionConfirmationDialog';
import BulkDeletionDialog from './component/bulkDeletionDialog';
import DefaultSettingHelpDialog from './component/helpInfo';
import ReorderIcon from '@material-ui/icons/Reorder';
import SearchIcon from '@material-ui/icons/Search';

export default function ReportDefaultSetting() {
  const tableRef = useRef();
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [open, setOpen] = useState(false);
  const [bulkOpen, setBulkOpen] = useState(false);
  const [pullOpen, setPullOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [editTarget, setEditTarget] = useState({});
  const shouldRefresh = useSelector((state) => state.fo.defaultSettingRefresh);
  const { deafultUserState, currentTenantCd } = useSelector(
    (state) => state.user
  );

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const applySettingToAll = (data = { description: true, order: false }) => {
    confirmDialog(
      () => store.dispatch(syncReportsWithDefaultingSetting(data)),
      data.description === true && data.order === true
        ? 'Are you sure you want to overwrite order and description for all the reports in system?'
        : data.description === true
        ? 'Are you sure you want to overwrite description for all the reports in system?'
        : 'Are you sure you want to overwrite order for all the reports in system?',
      'Caution!'
    );
  };

  const handleDelete = (id) => {
    confirmDialog(
      () => store.dispatch(deleteDefaultingSettingById(id)),
      'Do you really want to delete this? This will impact the report initialization'
    );
  };

  const handleClose = (data) => {
    if (data) {
      store.dispatch(saveReportefaultingSetting(data));
    }
    setOpen(false);
  };

  const handleBulkClose = (data) => {
    if (data) {
      confirmDialog(
        () => store.dispatch(addReportToTenants(data)),
        'This will added report to all tenants even there is similiar one exists, are you sure you want to contiune?',
        'Caution!'
      );
    }
    setBulkOpen(false);
  };

  const handlePullClose = (data) => {
    if (data) {
      confirmDialog(
        () => store.dispatch(initTenantReports(data)),
        'This will add tableau reports to selected tenants, are you sure you want to contiune?',
        'Caution!'
      );
    }
    setPullOpen(false);
  };

  const handleDeleteClose = (data) => {
    if (data) {
      if (
        data.displayTextList &&
        data.displayTextList.length &&
        data.tenant &&
        data.tenant.length > 0
      ) {
        let dto = {
          displayTexts: data.displayTextList,
          tenantCds: []
        };
        data.tenant.map((item) => {
          return dto.tenantCds.push(item.code);
        });
        store.dispatch(fetchTenantReports(dto)).then(() => {
          reportDeleteConfirmDialog(
            () => store.dispatch(deleteTenantReports(dto)),
            [
              'This will delete following reports, are you sure you want to contiune?'
            ],
            'Caution!'
          );
        });
      }
    }

    setDeleteOpen(false);
  };

  const handHelpOpen = (flag) => {
    setHelpOpen(flag);
  };

  useEffect(() => {
    refresh();
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    store.dispatch(fetchAllDisplayText());
    store.dispatch(fetchTenantList());
    store.dispatch(getAllBrokers());
    store.dispatch(fetchBrokerCompanyMapping());
    const timeout = setTimeout(() => {
      if (deafultUserState) {
        store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_FO));
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (
      shouldRefresh !== Actions.SHOULD_REFRESH_REPORT_DEFAULT_SETTING_INIT_VALUE
    ) {
      refresh();
    }
  }, [shouldRefresh]);

  return (
    <>
      <MaterialTable
        title={
          <>
            <Typography variant="h6">
              Global Report Configuration{' '}
              <IconButton color="primary" onClick={() => handHelpOpen(true)}>
                <HelpIcon />
              </IconButton>
            </Typography>{' '}
          </>
        }
        tableRef={tableRef}
        columns={[
          { title: 'Display Text', field: 'displayText', type: 'string' },
          { title: 'Order', field: 'order', type: 'numeric' },
          { title: 'Description', field: 'description', type: 'string' },
          { title: 'SC Id', field: 'scId', type: 'string'},
          { title: 'Feature', field: 'feature', type: 'numeric'}
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.FO_INITIAL_URL + '/report-default-setting/search', {
                page: query.page,
                size: query.pageSize,
                searchTerm: query.search,
                direction: query.orderDirection ? query.orderDirection.toUpperCase() : Constant.DIRECTION_DESC,
                sortProperty: query.orderBy ? query.orderBy.field : 'displayText'
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                console.log('error' + err);
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          debounceInterval: 300,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        components={{ OverlayLoading: () => <div /> }}
        localization={{
          toolbar: { searchPlaceholder: 'Search for Display Text' }
        }}
        actions={[
          {
            position: 'row',
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_e, rowData) => {
              setOpen(true);
              setEditTarget(rowData);
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (_e, rowData) => handleDelete(rowData.id)
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              setEditTarget({});
              setOpen(true);
            }
          },
          {
            icon: 'library_add',
            tooltip: 'Add report to all tenants',
            isFreeAction: true,
            onClick: () => {
              setBulkOpen(true);
            }
          },
          {
            icon: () => <GrainIcon />,
            tooltip: 'Apply Description to All Tenants',
            onClick: () => {
              applySettingToAll();
            },
            isFreeAction: true
          },
          {
            icon: () => <ReorderIcon />,
            tooltip: 'Apply Order to All Tenants',
            onClick: () => {
              applySettingToAll({ description: false, order: true });
            },
            isFreeAction: true
          },
          {
            icon: () => <InputIcon />,
            tooltip: 'Pull Reports to Tenants',
            onClick: () => {
              setPullOpen(true);
            },
            isFreeAction: true
          },
          {
            icon: () => <DeleteSweepIcon />,
            tooltip: 'Delete Report from Selected Tenants',
            onClick: () => {
              setDeleteOpen(true);
            },
            isFreeAction: true
          }
        ]}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
      />
      <DefaultingSettingDetailDialog
        open={open}
        onClose={handleClose}
        target={editTarget}
      />
      <ReportBulkPull open={pullOpen} onClose={handlePullClose} />
      <ReportBulkCreation open={bulkOpen} onClose={handleBulkClose} />
      <BulkDeletionDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        setOpen={setOpen}
      />
      <DefaultSettingHelpDialog open={helpOpen} onClose={handHelpOpen} />
      <ReportDeletionConfirmationDialog />
    </>
  );
}
