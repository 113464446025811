import { AppConfig } from 'AppConfig';
import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from '@material-table/core';
import axios from 'axios';
import * as Constant from 'common/Constant';
import * as Actions from './actions/action';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import * as UserTypes from 'features/user/userTypes';
import TagLabelingList from './components/tagLabelingList';
import TagLabelingDialog from './components/tagLabelingDialog';
import * as Action from './actions/action';
import SearchIcon from '@material-ui/icons/Search';

const VendorLabelingTable = () => {
  const tableRef = useRef();
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [cloneData, setCloneData] = useState({});
  const [detailpanelState, setdetailpanelState] = useState({});
  const [interalDetailPanelState, setInteralDetailPanelState] = useState({});

  const shouldRefresh = useSelector(
    (state) => state.vendorLabeling.shouldRefresh
  );

  const refresh = () => {
    tableRef.current?.onQueryChange();
  };

  const handleSave = (data, tagList) => {
    if (data) {
      const brokerIdList = [];
      const companyIdList = [];
      const tagIdList = [];

      if (
        data.selectedBrokerList &&
        Array.isArray(data.selectedBrokerList) &&
        data.selectedBrokerList.length > 0
      ) {
        data.selectedBrokerList.forEach((broker) => {
          brokerIdList.push(broker.id);
        });
      }

      if (
        data.selectedCompanyList &&
        Array.isArray(data.selectedCompanyList) &&
        data.selectedCompanyList.length > 0
      ) {
        data.selectedCompanyList.forEach((company) => {
          companyIdList.push(company.id);
        });
      }

      if (
        data.selectedTagList &&
        Array.isArray(data.selectedTagList) &&
        data.selectedTagList.length > 0
      ) {
        data.selectedTagList.forEach((tag) => {
          tagIdList.push(tag.id);
        });
      }

      if (
        (brokerIdList.length > 0 || companyIdList.length > 0) &&
        tagIdList.length > 0 &&
        data.logoFile instanceof FileList &&
        data.logoFile.length > 0
      ) {
        const file = data.logoFile[0];
        store.dispatch(
          Action.uploadLogoByTagAndCompany(
            tagIdList,
            brokerIdList,
            companyIdList,
            file,
            tagList
          )
        );
      }
    }
  };

  const handleCreationClose = (data) => {
    handleSave(data);
    setOpen(false);
  };

  useEffect(() => {
    store.dispatch(Actions.fetchBrokerCompanyMapping());
    store.dispatch(Actions.fetchBrokerList());
    store.dispatch(Actions.fetchCompanyList());
    store.dispatch(Actions.fetchTagList());
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    refresh();
  }, []);

  useEffect(() => {
    if (shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE) {
      refresh();
    }
  }, [shouldRefresh]);

  return (
    <>
      <MaterialTable
        title="Vendor Label Management - Company View"
        tableRef={tableRef}
        localization={{
          toolbar: { searchPlaceholder: "Search for Company's Name or Code" }
        }}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Code', field: 'code' },
          { title: 'Broker Name', field: 'brokerName' }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let pState = {};

            tableRef.current.state.data.map((data) => {
              pState[data.id] = data.tableData.showDetailPanel;
            });
            setdetailpanelState(pState);

            axios
              .post(AppConfig.FO_INITIAL_URL + '/company-admin/v2/search', {
                search: query.search,
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'name'
              })
              .then((m) => {
                let data = m.data;
                data.content.forEach((item) => {
                  if (pState[item.id]) {
                    if (item.networkValues) {
                      item.networkValues.forEach((mi) => {
                        const found = interalDetailPanelState[item.id];
                        if (found) {
                          mi.default_open = found[mi.id];
                        }
                      });
                    }
                    item.tableData = {
                      showDetailPanel: tableRef.current.props.detailPanel
                    };
                  }
                });

                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          selection: false,
          actionsColumnIndex: -1,
          search: true,
          exportButton: false,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          debounceInterval: 400,
          pageSize
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            position: 'row',
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              setOpen(true);
            }
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel();
        }}
        detailPanel={(rowData) => {
          return <TagLabelingList data={rowData.rowData} handleSave={handleSave} />;
        }}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
      />
      <TagLabelingDialog
        open={open}
        onClose={handleCreationClose}
        target={cloneData}
      />
    </>
  );
};

export default VendorLabelingTable;
