export const ADMIN_USER_ACCESS_PREFIX = 'ADMIN-USER-ACCESS-PREFIX_';

export const FETCH_APPLICATION_LIST =
  ADMIN_USER_ACCESS_PREFIX + 'FETCH_APPLICATION_LIST';

export const FETCH_TENANT_LIST = ADMIN_USER_ACCESS_PREFIX + 'FETCH_TENANT_LIST';

export const FETCH_USER_TENANT_ROLE_DATA =
  ADMIN_USER_ACCESS_PREFIX + 'FETCH_USER_TENANT_ROLE_DATA';

export const SEARCH_REFRESH = ADMIN_USER_ACCESS_PREFIX + 'SEARCH_REFRESH';

export const FETCH_TENANT_USER_PERMISSION =
  ADMIN_USER_ACCESS_PREFIX + 'FETCH_TENANT_USER_PERMISSION';

export const USER_ACCESS_CREATE_SUCCESS =
  ADMIN_USER_ACCESS_PREFIX + 'USER_ACCESS_CREATE_SUCCESS';
export const USER_UPDATE_SUCCESS =
  ADMIN_USER_ACCESS_PREFIX + 'USER_UPDATE_SUCCESS';
