import { AppConfig } from 'AppConfig';
import { fetchData } from 'features/user/userActions';
import * as UserTypes from 'features/user/userTypes';
import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import RouteConfig from 'routes/RouteConfig';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';
import { hasPermission } from 'utils/common';
import LocalStore from 'utils/LocalStore';
import { DiJobs } from './DIJobs/DIJobs';
import MasterNav from './masterNav';
import { fetchPlanSponsor } from './MastersTable/actions/Actions';

class Master extends Component {
  state = {
    onInitialLoad: true,
    refreshKey: 0,
    currentMDItem: null
  };

  componentDidMount() {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.STANDARD
    });
    //reload the user info for the app
    store.dispatch(
      fetchData(this.props.currentTenant, AppConfig.APP_CODE_MASTER_DATA)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentTenant !== this.props.currentTenant) {
      this.props.history.push(
        RouteConfig.master.path + AppConfig.MASTER_DATA_SUB_URL.MASTER_TABLE
      );
      if (this.props.currentTenant) {
        store.dispatch(fetchPlanSponsor());
      }
    }
  }

  onMarkAsCompleted = async () => {
    try {
      const response = await fetch(
        AppConfig.API_BASE_URL + `mail/sendMDCompleteMail`,
        {
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + LocalStore.getAccessToken()
          }
        }
      );
      if (!response.ok) {
        throw new Error('Network Error');
      }
      store.dispatch({
        type: AppActionTypes.APP_REST_CALL_SUCESSED,
        payload: 'Master data notification email send successfully'
      });
    } catch (error) {
      store.dispatch({
        type: AppActionTypes.APPSTATE_SET_APP_ERROR,
        payload: 'Failed to send master data notification email: Unknown Error'
      });
    }
  };

  renderMasterHead = () => {
    return (
      <div key={this.state.refreshKey + this.props.lastReturnTime}>
        <div className="master-office">
          <div className="master-office__top">
            <div className="row pb-4">
              <div className="col-4"></div>
              <div className="col-8 text-right">
                {this.state.currentMDItem ? (
                  <Button
                    variant="contained"
                    disableElevation
                    style={{
                      backgroundColor: '#377DC7',
                      height: '36px',
                      marginTop: '5px',
                      color: 'white',
                      marginLeft: '5px',
                      marginRight: '5px',
                      textTransform: 'none'
                    }}
                    onClick={() => {
                      this.setState({ currentMDItem: null });
                    }}>
                    Back
                  </Button>
                ) : null}
                {hasPermission(
                  AppConfig.PERMISSION.MD_DI,
                  this.props.permissions
                ) ? (
                  <Button
                    variant="contained"
                    disableElevation
                    style={{
                      backgroundColor: '#377DC7',
                      height: '36px',
                      marginTop: '5px',
                      color: 'white',
                      marginLeft: '5px',
                      marginRight: '5px',
                      textTransform: 'none'
                    }}
                    onClick={() => {
                      this.setState({ currentMDItem: 'dijob' });
                    }}>
                    DI Jobs
                  </Button>
                ) : null}
                {hasPermission(
                  AppConfig.PERMISSION.MD_VAULT,
                  this.props.permissions
                ) ? (
                  <Button
                    variant="contained"
                    disableElevation
                    style={{
                      backgroundColor: '#377DC7',
                      height: '36px',
                      marginTop: '5px',
                      color: 'white',
                      marginLeft: '5px',
                      marginRight: '5px',
                      textTransform: 'none'
                    }}
                    onClick={() => {
                      this.onMarkAsCompleted();
                    }}>
                    Mark as Completed
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          <section>
            {this.state.currentMDItem === 'dijob' ? (
              <div className="DiJobs">
                <DiJobs />
              </div>
            ) : (
              <MasterNav {...this.props} />
            )}
          </section>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <Beforeunload
          onBeforeunload={(event) => {
            if (
              window.location.pathname &&
              window.location.pathname === RouteConfig.master.path
            ) {
              if (this.state.onInitialLoad === true) {
                this.setState({ ...this.state, onInitialLoad: false });
              } else {
                this.setState({
                  ...this.state,
                  refreshKey: this.state.refreshKey + 1
                });
              }
            }
          }}></Beforeunload>
        {this.renderMasterHead()}
      </>
    );
  }
}

const mapStateToPropsSelector = createStructuredSelector({
  currentTenant: (state) => state.user.currentTenantCd,
  permissions: (state) => state.user.permissions,
  planSponsor: (state) => state.masterTable.planSponsor,
  planSponsorLoading: (state) => state.masterTable.planSponsorLoading
});

export default connect(mapStateToPropsSelector)(withRouter(Master));
