import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from 'common/SingleClickButton';
import * as MDActions from '../../../common/actions/CommonActions';
import { store } from 'store/store';

import { useSelector } from 'react-redux';
import * as Constant from 'common/Constant';
import moment from 'moment-timezone';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import * as AppActionTypes from 'store/actions/appstate';
import ModificationDetailDialog from './modificationDailogDetails';
import { DEFAULT_FORMAT } from '../../../util/Dateutil';

const ModificationDialog = (props) => {
  const DUPLICATION_ERROR_MSG = 'Duplicated entry found for the same plan';
  const { onClose, open, tenantId, target } = props;
  const methods = useForm({
    defaultValues: {
      ...target,
      planExpenseRates: null,
      specialExpense: []
    },
    mode: 'onBlur'
  });
  const { reset, register } = methods;
  const [duplocateErrorMessage, setDuplocateErrorMessage] = useState('');
  // register conditional rendering fields

  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );
  const simplePlans = useSelector((state) => state.MD.planExpense.simplePlans);

  const handleClose = (data) => {
    onClose(data);
  };
  const resetDuplicateErrorMessage = () => {
    setDuplocateErrorMessage('');
  };
  const onSubmit = async (data) => {
    const payload = [];

    if (data) {
      // build payload as backend expected format
      const planExpenseRates =
        data.rateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL &&
        data.planYears.length === 1
          ? data.specialExpense
              .filter((ex) => ex.rate)
              .map((sp) => {
                sp.startDate = moment.isMoment(sp.startDate)
                  ? moment(sp.startDate).format(Constant.FORMAT_DATE)
                  : DEFAULT_FORMAT(sp.startDate);
                sp.endDate = moment.isMoment(sp.endDate)
                  ? moment(sp.endDate).format(Constant.FORMAT_DATE)
                  : DEFAULT_FORMAT(sp.endDate);
                return sp;
              })
          : data.planExpenseRates.filter((expense) => expense.rate);

      data.planYears.forEach((planyear) => {
        data.planName.forEach((plan) => {
          const foundPlan = simplePlans.find(
            (sp) => sp.ahpmId === planyear.id && sp.planName === plan.planName
          );

          if (!foundPlan) {
            return;
          }
          const endDate =
            data.planYears.length > 1
              ? totalPlanYears.find((y) => y.id === planyear.id)?.endDate
              : moment.isMoment(data.endDate)
              ? moment(data.endDate).format(Constant.FORMAT_DATE)
              : DEFAULT_FORMAT(data.endDate);

          const startDate =
            data.planYears.length > 1
              ? totalPlanYears.find((y) => y.id === planyear.id)?.startDate
              : moment.isMoment(data.startDate)
              ? moment(data.startDate).format(Constant.FORMAT_DATE)
              : DEFAULT_FORMAT(data.startDate);
          const payloadObject = {
            ...data,
            planId: foundPlan?.id,
            planName: plan.planName,
            startDate: startDate,
            endDate: endDate,
            rateStartDate: startDate,
            rateEndDate: endDate,
            planExpenseRates: planExpenseRates,
            planYear: moment(startDate).year(),
            rxCovered:
              data.rateBasis === Constant.PLAN_EXPENSE_RATE_BASIS_PER_CLAIM
                ? data.rxCovered
                : false
          };

          payload.push(payloadObject);
        });
      });

      // cleanup payload by removing unwanted properties
      const payloadForValidation = payload.map(
        ({ planYears, specialExpense, ...rest }) => {
          if (
            data.rateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL &&
            data.planYears.length === 1
          ) {
            rest.rate = planExpenseRates[0].rate;
            rest.rateStartDate = planExpenseRates[0].startDate;
            rest.rateEndDate = planExpenseRates[0].endDate;
            rest.rateComments = planExpenseRates[0].comments;
          }

          if (data.isRateByTier) {
            rest.rate = planExpenseRates
              .map((exp) => `${exp.name}=${exp.rate}`)
              .join(',');
          }

          return rest;
        }
      );
      const payloadData = payloadForValidation.map(
        ({ planExpenseRates, ...rest }) => rest
      );

      if (
        data.rateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL &&
        data.planYears.length === 1
      ) {
        const validationResult = await validateSpecialExpense(
          payloadForValidation
        ); //validate special expense data
        if (
          validationResult.length &&
          validationResult.id !== payloadForValidation.planExpenseRateId
        ) {
          store.dispatch({
            type: AppActionTypes.APPSTATE_SET_APP_ERROR,
            payload: `${DUPLICATION_ERROR_MSG} special expense, ${JSON.stringify(
              validationResult.map(({ startDate, endDate, rate }) => {
                return { startDate, endDate, rate };
              })
            )}`
          });
        } else {
          checkDuplicateAndSubmit(data, payloadData);
        }
      } else {
        checkDuplicateAndSubmit(data, payloadData);
      }
    } else {
      onClose(null);
    }
  };

  const checkDuplicateAndSubmit = async (formData, payload) => {
    const duplicateData = payload.map((item) => ({
      ...item,
      id: item.planExpenseId,
      planExpenseRates: [
        {
          divisionId: '',
          tierId: '',
          name: '',
          rate: formData.rate,
          startDate: null,
          endDate: null
        }
      ]
    }));

    const duplicateResponse = await checkDuplicate(duplicateData);

    if (!duplicateResponse.length) {
      onClose(payload);
    } else {
      const duplicatePlanNames = formData.planName.filter((name) =>
        duplicateResponse.some((duplicate) => duplicate.planId === name.id)
      );

      const planNames = formData.planYears.map((year) => {
        const name = duplicatePlanNames.filter(
          (duplicate) => duplicate.ahpmId === year.id
        );
        return `${name[0].planName} (${year.label})`;
      });

      const name = planNames.length > 1 ? planNames.join(', ') : planNames[0];

      const errorMessage = `${DUPLICATION_ERROR_MSG} for Plan (${name})
      with Start Date(${moment(formData.startDate).format(
        Constant.FORMAT_DATE
      )}) and End Date(${moment(formData.endDate).format(
        Constant.FORMAT_DATE
      )}),
       Expense Catogory (${formData.expenseCategory}), Expense Name (${
        formData.expenseName
      }) and Rate Basis (${formData.rateBasis}) and Rate (${formData.rate}).`;

      setDuplocateErrorMessage(errorMessage);
    }
  };
  const checkDuplicate = async (data) => {
    try {
      const response = await axios.post(
        AppConfig.API_BASE_URL + `planexpense/checkDuplicate`,
        data,
        AppConfig.POST_HEADERS
      );

      if (response.data && response.data.length) {
        return response.data;
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  const validateSpecialExpense = async (data) => {
    try {
      const result = await Promise.all(
        data.map(async (row) => {
          const found = await axios.post(
            AppConfig.API_BASE_URL + 'planexpense/special-rate/verify',
            row
          );
          return found.data;
        })
      );
      return result.flat();
    } catch (error) {
      console.warn('validation failed : ', error);
    }
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') handleClose();
        }}
        maxWidth="lg"
        fullWidth>
        <DialogTitle id="dialog-planExpenseDetail">Edit</DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <ModificationDetailDialog
              tenantId={tenantId}
              isNew={!(target && target.id > 0)}
              duplicateError={duplocateErrorMessage}
              resetDuplicateError={resetDuplicateErrorMessage}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            handleSubmit={methods.handleSubmit(onSubmit)}
            isDirty={methods.formState.isDirty}
          />
          <Button onClick={() => handleClose(null)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(ModificationDialog);
