import React, { useState, useEffect } from 'react';
import { AppConfig } from 'AppConfig';
import { Tab, Tabs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ElectionTierManagmentTable from './component/electionTierManagementTable';
import Lookup from './component/lookupTables';
import { store } from 'store/store';
import * as UserTypes from 'features/user/userTypes';
import { fetchData } from 'features/user/userActions';
import { useSelector } from 'react-redux';

const LookupTableNav = () => {
  const location = useLocation();
  const [currentTab, updateCurrentTab] = useState(
    AppConfig.LOOKUP_TABLES_SUB_URL.LOOKUP
  );

  const currentApp = useSelector((state) => state.user.currentApp);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    const tab = '/' + location.pathname.split('/').slice(-1).pop();
    tab === AppConfig.MASTER_DATA_SUB_URL.MASTER_TABLE
      ? updateCurrentTab(AppConfig.LOOKUP_TABLES_SUB_URL.LOOKUP)
      : updateCurrentTab(tab);
  }, [location]);

  useEffect(() => {
    //fetch the data again
    if (currentApp !== AppConfig.APP_CODE_MASTER_DATA) {
      store.dispatch(
        fetchData(currentTenantCd, AppConfig.APP_CODE_MASTER_DATA)
      );
    }
  }, []);

  return (
    <>
      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable">
        <Tab
          label="Expense Name"
          value={AppConfig.LOOKUP_TABLES_SUB_URL.LOOKUP}
          component={Link}
          to={'.' + AppConfig.LOOKUP_TABLES_SUB_URL.LOOKUP}
        />
        <Tab
          label="Election Tier"
          value={AppConfig.LOOKUP_TABLES_SUB_URL.ELECTION_TIER_LIST}
          component={Link}
          to={'.' + AppConfig.LOOKUP_TABLES_SUB_URL.ELECTION_TIER_LIST}
        />
      </Tabs>
      {currentTab === AppConfig.LOOKUP_TABLES_SUB_URL.LOOKUP && <Lookup />}
      {currentTab === AppConfig.LOOKUP_TABLES_SUB_URL.ELECTION_TIER_LIST && (
        <ElectionTierManagmentTable />
      )}
    </>
  );
};

export default LookupTableNav;
