import { Tab, Tabs } from '@material-ui/core';
import { AppConfig } from 'AppConfig';
import * as ReportTypes from 'features/frontoffice/actions/types';
import ReportViewerDialog from 'features/frontoffice/component/reportViewerDialog';
import FavoriteReport from 'features/frontoffice/favoriteReport/favoriteReport';
import SavedReport from 'features/frontoffice/saved/savedReport';
import StandardReport from 'features/frontoffice/standardReport/index';
import { fetchData } from 'features/user/userActions';
import * as UserTypes from 'features/user/userTypes';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import { store } from 'store/store';
import { hasPermission } from 'utils/common';
import Inspection from './component/inspection';
import CustomReport from './customReport/customReport';
import ExclusiveReports from './exclusiveReport';
import ExplorerReport from './explorerReport/explorerReport';
import ExistingReportTable from './manageExisting/existingReportTable';
import ReportTable from './manageReports/reportTable';
import Reports from './reports';

const FrontOffice = ({ location }) => {
  const STANDARD_URL =
    RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.STANDARD;
  const MY_REPORT_URL =
    RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.MY_REPORT;
  const MY_FAVORITE_URL =
    RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.MY_FAVORITE;
  const MY_CUSTOM_URL =
    RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.MY_CUSTOM;
  const EXPLORER_URL =
    RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.EXPLORER;
  const REPORT_MGMT_URL =
    RouteConfig.frontOffice.path +
    AppConfig.FRONT_OFFICE_SUB_URL.REPORT_MANAGEMENT;
  const EXISTING_MGMT_URL =
    RouteConfig.frontOffice.path +
    AppConfig.FRONT_OFFICE_SUB_URL.EXISTING_REPORTS;
  const EXCLUSIVE_URL =
    RouteConfig.frontOffice.path + AppConfig.FRONT_OFFICE_SUB_URL.EXCLUSIVE;

  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  const openReportViewDialog = useSelector(
    (state) => state.fo.openReportViewDialog
  );
  const reportUrl = useSelector((state) => state.fo.reportUrl);
  const category = useSelector((state) => state.fo.currentReportCategory);
  const reportTitle = useSelector((state) => state.fo.currentReportTitle);
  const permissions = useSelector((state) => state.user.permissions);

  const closeReportView = () => {
    store.dispatch({
      type: ReportTypes.CLOSE_REPORT_VIEWER_REQUEST
    });
  };

  useEffect(() => {
    store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_FO));
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.FO
    });
  }, []);

  useEffect(() => {
    if (!openReportViewDialog){
        closeReportView();
    }
  }, [openReportViewDialog]);

  return (
    <>
      <Inspection />
      <Tabs
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary">
        {hasPermission(AppConfig.PERMISSION.FO_REPORT_HOME, permissions) && (
          <Tab
            label="Home"
            value={RouteConfig.frontOffice.path}
            component={Link}
            to={RouteConfig.frontOffice.path}
          />
        )}
        {hasPermission(
          AppConfig.PERMISSION.FO_STANDARD_REPORT,
          permissions
        ) && (
            <Tab
              label="Standard Reports"
              value={STANDARD_URL}
              component={Link}
              to={STANDARD_URL}
            />
          )}
        {hasPermission(
          AppConfig.PERMISSION.FO_EXPLORER_REPORT,
          permissions
        ) && (
            <Tab
              label="Explorer Reports"
              value={EXPLORER_URL}
              component={Link}
              to={EXPLORER_URL}
            />
          )}
        {hasPermission(AppConfig.PERMISSION.FO_MY_CREATION, permissions) && (
          <Tab
            label="My Creations"
            value={MY_REPORT_URL}
            component={Link}
            to={MY_REPORT_URL}
          />
        )}
        {hasPermission(
          AppConfig.PERMISSION.FO_EXCLUSIVE_REPORTS,
          permissions
        ) && (
          <Tab
            label="Exclusive Reports"
            value={EXCLUSIVE_URL}
            component={Link}
            to={EXCLUSIVE_URL}
          />
        )}
        {hasPermission(AppConfig.PERMISSION.FO_CUSTOM_REPORT, permissions) && (
          <Tab
            label="Custom Reports"
            value={MY_CUSTOM_URL}
            component={Link}
            to={MY_CUSTOM_URL}
          />
        )}
        {hasPermission(AppConfig.PERMISSION.FO_FAVORITE, permissions) && (
          <Tab
            label="Favorites"
            value={MY_FAVORITE_URL}
            component={Link}
            to={MY_FAVORITE_URL}
          />
        )}

        {hasPermission(
          AppConfig.PERMISSION.FO_REPORT_MANAGEMENT,
          permissions
        ) && (
            <Tab
              label="Add Reports"
              value={REPORT_MGMT_URL}
              component={Link}
              to={REPORT_MGMT_URL}
            />
          )}
        {hasPermission(
          AppConfig.PERMISSION.FO_REPORT_MANAGEMENT,
          permissions
        ) && (
            <Tab
              label="Manage Reports"
              value={EXISTING_MGMT_URL}
              component={Link}
              to={EXISTING_MGMT_URL}
            />
          )}
      </Tabs>
      {location.pathname === RouteConfig.frontOffice.path &&
        hasPermission(AppConfig.PERMISSION.FO_REPORT_HOME, permissions) && (
          <Reports />
        )}
      {location.pathname === STANDARD_URL &&
        hasPermission(AppConfig.PERMISSION.FO_STANDARD_REPORT, permissions) && (
          <StandardReport />
        )}
      {location.pathname === MY_REPORT_URL && <SavedReport />}
      {location.pathname === MY_FAVORITE_URL && <FavoriteReport />}
      {location.pathname === MY_CUSTOM_URL &&
        hasPermission(AppConfig.PERMISSION.FO_CUSTOM_REPORT, permissions) && (
          <CustomReport />
        )}
      {location.pathname === REPORT_MGMT_URL &&
        hasPermission(
          AppConfig.PERMISSION.FO_REPORT_MANAGEMENT,
          permissions
        ) && <ReportTable />}
      {location.pathname === EXISTING_MGMT_URL &&
        hasPermission(
          AppConfig.PERMISSION.FO_REPORT_MANAGEMENT,
          permissions
        ) && <ExistingReportTable />}
      {location.pathname === EXPLORER_URL &&
        hasPermission(AppConfig.PERMISSION.FO_EXPLORER_REPORT, permissions) && (
          <ExplorerReport />
        )}
      {location.pathname === EXCLUSIVE_URL &&
        hasPermission(
          AppConfig.PERMISSION.FO_EXCLUSIVE_REPORTS,
          permissions
        ) && <ExclusiveReports />}
      <ReportViewerDialog
        open={openReportViewDialog}
        reportSource={reportUrl}
        category={category}
        title={reportTitle}
        onClose={() => closeReportView()}
      />
    </>
  );
};

export default FrontOffice;
