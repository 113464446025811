const USER_PREFIX = 'FO_';

export const FETCH_FO_DETAIL_SUCCESS = USER_PREFIX + 'FETCH_FO_DETAIL_SUCCESS';
export const FETCH_SAVED_REPORT_SUCCESS =
  USER_PREFIX + 'FETCH_SAVED_REPORT_SUCCESS';
export const FETCH_DEFAULT_METRIC_SUCCESS =
  USER_PREFIX + 'FETCH_DEFAULT_METRIC_SUCCESS';

export const SAVE_MY_METRIC_SUCCESS = USER_PREFIX + 'SAVE_MY_METRIC_SUCCESS';
export const FETCH_MY_METRIC_SUCCESS = USER_PREFIX + 'FETCH_MY_METRIC_SUCCESS';

export const FETCH_REPORT_STANDARD_SUCCESS =
  USER_PREFIX + 'FETCH_REPORT_STANDARD_SUCCESS';
export const FETCH_REPORT_SMART_SUCCESS =
  USER_PREFIX + 'FETCH_REPORT_SMART_SUCCESS';
export const FETCH_REPORT_CUSTOM_SUCCESS =
  USER_PREFIX + 'FETCH_REPORT_CUSTOM_SUCCESS';
export const FETCH_REPORT_EXPLORER_SUCCESS =
  USER_PREFIX + 'FETCH_REPORT_EXPLORER_SUCCESS';
export const FETCH_REPORT_EXCLUSIVE_SUCCESS =
  USER_PREFIX + 'FETCH_REPORT_EXCLUSIVE_SUCCESS';

export const FETCH_INSPECTION_SUCCESS =
  USER_PREFIX + 'FETCH_INSPECTION_SUCCESS';

export const FETCH_ALL_DISPLAY_TEXT_SUCCESS =
  USER_PREFIX + 'FETCH_ALL_DISPLAY_TEXT_SUCCESS';

export const FETCH_REPORT_TO_DELETE_SUCCESS = USER_PREFIX + 'FETCH_REPORT_TO_DELETE_SUCCESS';

export const OPEN_REPORT_VIEWER_REQUEST =
  USER_PREFIX + 'OPEN_REPORT_VIEWER_REQUEST';
export const CLOSE_REPORT_VIEWER_REQUEST =
  USER_PREFIX + 'CLOSE_REPORT_VIEWER_REQUEST';

export const FILTER_SUCCESS = USER_PREFIX + 'FILTER_SUCCESS';
export const SORT_SUCCESS = USER_PREFIX + 'SORT_SUCCESS';

export const FETCH_REPORT_TAGS = USER_PREFIX + 'FETCH_REPORT_TAGS';

export const REPORT_DEFAULT_SETTING_REFRESH =
  USER_PREFIX + 'REPORT_DEFAULT_SETTING_REFRESH';

export const OPEN_GLOBAL_CONFIG_REQUEST =
  USER_PREFIX + 'OPEN_GLOBAL_CONFIG_REQUEST';