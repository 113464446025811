import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import UpdateDialogDetail from './updateDialogDetail';
import * as Action from '../action/action';
import { store } from 'store/store';

const UpdateDialog = (props) => {
  const { onClose, open, target } = props;
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    let dto = {
      password: data.newPwd
    };
    store.dispatch(Action.updateUserPassword(dto));
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
          }}>
          <DialogTitle>Change Your Login Password</DialogTitle>
          <DialogContent dividers>
            <UpdateDialogDetail />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="primary"
              onClick={methods.handleSubmit(onSubmit)}>
              Save
            </Button>
            <Button color="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default UpdateDialog;
