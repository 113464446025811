import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { store } from 'store/store';
import { Switch, FormControlLabel } from '@material-ui/core';
import * as MDActions from '../../../common/actions/CommonActions';
import { FoMaterialTable } from 'common/FoMaterialTable';

const IslPlan = React.memo((props) => {
  const { existingPlans, annualPlanId, slcId } = props;

  const {
    control,
    formState: { errors },
    setValue,
    watch
  } = useFormContext();

  const plansForYear = useSelector(
    (state) => state.MD.mdCommon.allMedPlanDesignDatas
  );
  const [data, setData] = useState([]);

  const watchIslAppliedToAll = watch('islAppliedToAll', false);

  const handleSelectionChanged = (rows) => {
    if (rows.length === plansForYear.length) {
      setValue('islPlans', []);
      setValue('islAppliedToAll', true);
    } else {
      setValue('islAppliedToAll', false);
      let islPlans = [];
      if (rows && Array.isArray(rows)) {
        rows.map((item) => {
          islPlans.push({
            planId: item.id,
            stopLossContractId: slcId
          });
        });
      }
      setValue('islPlans', islPlans);
    }
  };

  useEffect(() => {
    if (annualPlanId) {
      store.dispatch(MDActions.getAllMedPlanByAnnualPlanId(annualPlanId));
    }
  }, [annualPlanId]);

  useEffect(() => {
    if (
      plansForYear &&
      Array.isArray(plansForYear) &&
      existingPlans &&
      Array.isArray(existingPlans)
    ) {
      const planMap = new Map();

      existingPlans.map((item) => {
        planMap.set(item.planId, item);
      });

      plansForYear.map((item) => {
        let found = planMap.get(item.id);
        item.tableData = {
          id: item.id,
          checked: found ? true : false
        };
      });
    }
    setData(plansForYear);
  }, [existingPlans, plansForYear]);

  return (
    <>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <FormControlLabel
            checked={field.value}
            onChange={(e) => field.onChange(e.target.checked)}
            control={<Switch />}
            label="Apply to All Plans"
          />
        )}
        name="islAppliedToAll"
        control={control}
        defaultValue=""
      />
      {!watchIslAppliedToAll && (
        <FoMaterialTable
          columns={[
            {
              title: 'Plan Name',
              field: 'planName'
            }
          ]}
          onSelectionChange={(rows) => handleSelectionChanged(rows)}
          data={plansForYear}
          options={{
            selection: true
          }}
          components={{ OverlayLoading: () => <div /> }}
        />
      )}
    </>
  );
});

export default IslPlan;
