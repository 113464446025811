import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as Actions from '../actions/action';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadFile } from '../../../util/CommonUtil';

const FileUploadDetail = (props) => {
  const { open, onClose } = props;

  const methods = useForm({ defaultValues: null, mode: 'onBlur' });
  const { reset } = methods;

  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const records = useSelector((state) => state.MD.dicretionaryBenefits.records);
  const errorLogFile = useSelector(
    (state) => state.MD.dicretionaryBenefits.errorLogFile
  );
  const [totalRecords, setTotalRecords] = useState(records?.totalRecords);
  const [processedRecords, setProcessedRecords] = useState(
    records?.processedRecords
  );
  const [brokenRecords, setBrokenRecords] = useState(
    records?.brokenRecordsSize
  );
  const [errorLogFileURL, setErrorLogFileURL] = useState(errorLogFile);

  useEffect(() => {
    setTotalRecords(records?.totalRecords);
    setProcessedRecords(records?.processedRecords);
    setBrokenRecords(records?.brokenRecordsSize);
  }, [records]);

  useEffect(() => {
    setErrorLogFileURL(errorLogFile);
    setDownloading(false);
  }, [errorLogFile]);

  const handleClose = () => {
    setFormData(null);
    setTotalRecords(null);
    setProcessedRecords(null);
    setBrokenRecords(null);
    setLoading(false);
    onClose(null);
  };

  const handleValidate = () => {
    store.dispatch(Actions.validateFile(formData));
  };

  const handleLoad = () => {
    store.dispatch(Actions.loadFile(formData));
    onClose(null);
  };

  const handleDownloadErrorLogs = () => {
    if (formData) {
      store.dispatch(Actions.downloadErrorLogs(formData));
    } else {
      downloadFile(errorLogFileURL, 'ErrorLogs.txt');
      setDownloading(false);
    }
  };

  const resetRecords = () => {
    setFormData(null);
    setTotalRecords(null);
    setProcessedRecords(null);
    setBrokenRecords(null);
    setErrorLogFileURL(null);
  };
  useEffect(() => {
    setLoading(false);
  }, [records]);

  useEffect(() => {
    reset();
  }, [open, onClose]);

  useEffect(() => {
    resetRecords();
  }, [open]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleClose();
          }}>
          <DialogTitle>Import CSV File</DialogTitle>
          <DialogContent dividers>
            <Grid>
              <input
                type="file"
                accept=".csv"
                onChange={(event) => {
                  resetRecords();
                  setFormData(event.target.files[0]);
                }}
              />
            </Grid>
            <Grid>
              {loading ? (
                <Typography>Validating your file data...</Typography>
              ) : null}
            </Grid>
            <Grid>
              {totalRecords !== null ? (
                <Grid>
                  <Typography>
                    {'Processed records: ' + processedRecords}
                  </Typography>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start">
                    <Grid item>
                      <Typography>
                        {'Broken records: ' + brokenRecords}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        onClick={() => {
                          setDownloading(true);
                          handleDownloadErrorLogs();
                        }}
                        color="primary"
                        startIcon={
                          isDownloading ? (
                            <CircularProgress size={12} />
                          ) : (
                            <GetAppIcon />
                          )
                        }>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography>{'Total records: ' + totalRecords}</Typography>
                </Grid>
              ) : null}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setLoading(true);
                handleValidate();
              }}
              color="primary"
              disabled={!formData}>
              Validate
            </Button>
            <Button
              onClick={methods.handleSubmit(handleLoad)}
              color="primary"
              disabled={!formData}>
              Load to DB
            </Button>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default FileUploadDetail;
