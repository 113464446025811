import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import IslPlan from './islPlan';
import { useForm, FormProvider } from 'react-hook-form';

const IslPlanDialog = React.memo((props) => {
  const { onClose, open, slcData } = props;

  const methods = useForm({ defaultValues: slcData, mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(slcData);
  }, [open, slcData]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleClose();
          }}>
          <DialogTitle id="customized-dialog-title">Plans</DialogTitle>
          <DialogContent dividers>
            <IslPlan
              existingPlans={slcData.islPlans}
              isModified={slcData && slcData.id > 0}
              annualPlanId={slcData.ahpmId}
              slcId={slcData.id}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary">
              Save
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
});

export default IslPlanDialog;
