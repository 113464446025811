import * as Types from '../actions/types';
import * as Actions from '../actions/action';

let initialState = {
  shouldRefresh: Actions.SHOULD_REFRESH_INIT_VALUE,
  plansForYear: [],
  allPlanDesignDatasWithMap: {},
  allPlanDesignDatas: []
};

export default function planRateReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEARCH_REFRESH:
    case Types.DELETE_PLAN_RATE_SUCCESS:
    case Types.SAVE_PLAN_RATE_SUCCESS:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.FETCH_ALL_UNIQUE_NAME_PLAN_DESIGN_BY_TENANT_ID_SUCCESS:
      const allPlanDesignDatasWithMap = {};
      const allPlanDesignDatasWithUniqueMap = {};
      const allPlanDesignDataWithUniqueName = [];
      if (action.data && Array.isArray(action.data)) {
        action.data.map((item) => {
          if (!allPlanDesignDatasWithMap[item.planYearName]) {
            allPlanDesignDatasWithMap[item.planYearName] = [];
          }
          allPlanDesignDatasWithMap[item.planYearName].push(item);
          if (!allPlanDesignDatasWithUniqueMap.hasOwnProperty(item.planName)) {
            allPlanDesignDatasWithUniqueMap[item.planName] = item;
            allPlanDesignDataWithUniqueName.push(item);
          }
        });
      }
      return {
        ...state,
        allPlanDesignDatas: allPlanDesignDataWithUniqueName,
        allPlanDesignDatasInMap: allPlanDesignDatasWithMap
      };
    default:
      return state;
  }
}
