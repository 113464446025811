import { MDBContainer, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const columns = [
  {
    label: 'Job ID',
    field: 'jobId',
    sort: 'asc'
  },
  {
    label: 'Job Name',
    field: 'jobName',
    sort: 'asc'
  },
  {
    label: 'Process',
    field: 'process',
    sort: 'asc'
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'asc'
  }
];
class DIJobTableComponent extends Component {
  render = () => {
    if (!this.props.rows) {
      return null;
    }
    return (
      <MDBContainer>
        <MDBTable scrollY maxHeight="30em" bordered responsive>
          <MDBTableHead columns={columns} color="primary-color" textWhite />
          <MDBTableBody rows={this.props.rows} />
        </MDBTable>
      </MDBContainer>
    );
  };
}

const mapStateToPropsSelector = createStructuredSelector({});

export const DIJobsTable = connect(mapStateToPropsSelector)(
  DIJobTableComponent
);
