import React, { useEffect, useState } from 'react';
import Carousel from 'common/bstCarousel';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import * as Actions from './actions/action';
import { useSelector } from 'react-redux';
import * as UserTypes from 'features/user/userTypes';
import { TextField, InputAdornment, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import _ from 'lodash';

const FavoriteReport = () => {
  const { width } = useWindowDimensions();
  const favorites = useSelector(
    (state) => state.favoriteReport.favoriteReports
  );
  const [data, setData] = useState(favorites);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  const filterList = (value) => {
    if (favorites && Array.isArray(favorites)) {
      const newData = _.filter(favorites, function (report) {
        return report.displayText
          ? report.displayText.toLowerCase().includes(value.toLowerCase())
          : false;
      });
      setData(newData);
    }
  };

  useEffect(() => {
    setData(favorites);
  }, [favorites]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.FO
    });
    store.dispatch(Actions.getFavoriteReport());
  }, [currentTenantCd]);

  return (
    <>
      <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
        <TextField
          onChange={(event) => filterList(event.target.value)}
          placeholder="Search for report"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color='primary'/>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Carousel
        items={data}
        type={AppConfig.FAVORITE_CAROUSEL}
        viewWidth={width}
        cardWidth={AppConfig.HOME_RECOMMEND_CARD_WIDTH}
        cardHeight={AppConfig.HOME_RECOMMEND_CARD_HEIGHT}
      />
    </>
  );
};

export default FavoriteReport;
