import LocalStore from 'utils/LocalStore';
import jwt from 'jwt-decode';

/* encapsulates the business logic of login method. */
class Auth {
  LOGIN_MODE_BST = 'BST';
  LOGIN_MODE_HST = 'HST';

  setLoginMode(value) {
    LocalStore.setLoginMode(value);
  }
  removeLoginMode() {
    LocalStore.removeLoginMode();
  }
  getLoginMode() {
    return LocalStore.getLoginMode();
  }

  isJwtExpired(token) {
    const jwtObj = jwt(token);

    return jwtObj.exp < Date.now() / 1000;
  }
}

export default new Auth();
