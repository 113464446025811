import {
  Box,
  FormControl,
  IconButton,
  makeStyles,
  TextField
} from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import SyncAltSharpIcon from '@material-ui/icons/SyncAltSharp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Autocomplete } from '@material-ui/lab';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Constant from 'common/Constant';
import * as ReportTypes from 'features/frontoffice/actions/types';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';
import { hasPermission } from 'utils/common';
import { formatReptSource } from 'utils/formatReptSource';
import * as Actions from './actions/actions';
import EditDialog from './editDialog';
import { CATEGORY_VIEW_MAPPING, CATEGORY_OPTIONS } from 'common/Constant';
import InitOptionDialog from './initOptionDialog';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() => ({
  container: {
    padding: '2rem 0'
  },
  categoryDropdown: {
    paddingLeft: '2rem',
    minWidth: '200px'
  }
}));

const ExistingReportTable = () => {
  const tableRef = useRef();
  const styles = useStyles();
  const history = useHistory();

  const tenantCd = useSelector((state) => state.user.currentTenantCd);
  const permissions = useSelector((state) => state.user.permissions);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);
  const tableauServiceUrl = useSelector((state) => state.user.tableauServiceUrl);

  const [category, setCategory] = useState(CATEGORY_OPTIONS[0]);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editTarget, setEditTarget] = useState(undefined);
  const [initDialogOpen, setInitDialogOpen] = useState(false);
  const [data, setData] = useState([]);

  const handleDelete = (rowData) => {
    confirmDialog(
      () => {
        axios
          .delete(AppConfig.FO_INITIAL_URL + '/report/' + rowData.id)
          .then((_res) => refresh())
          .catch((err) => {
            store.dispatch({
              type: AppActionTypes.APPSTATE_SET_APP_ERROR,
              payload: `Could not get save report: ${err}`
            });
          });
      },
      'Deleting this report will also delete any associated reports, such as favorites. Are you sure you want to continue?',
      'Delete Report'
    );
  };

  const handleThunbmailSyncup = () => {
    confirmDialog(
      () => {
        store.dispatch(Actions.syncThunbnail(tenantCd));
      },
      'Do you want to sync up all thumbnails for current tenant?',
      'Sync Thumbnail'
    );
  };

  const handleInitReport = () => {
    setInitDialogOpen(true);
  };

  const handleDialogClose = (data) => {
    if (data) {
      axios
        .post(AppConfig.FO_INITIAL_URL + '/report', data)
        .then((_res) => {
          refresh();
        })
        .catch((err) => {
          store.dispatch({
            type: AppActionTypes.APPSTATE_SET_APP_ERROR,
            payload: `Could not save report: ${err}`
          });
        });
    }
    setDialogOpen(false);
    setEditTarget(undefined);
  };

  const handleInitDialogClose = (payload) => {
    if (payload) {
      store.dispatch(Actions.initReportForTenant(tenantCd, payload.mode));
    }

    setInitDialogOpen(false);
  };
  const refresh = () => {
    tableRef.current.onQueryChange();
  };

  const handleChange = (e, v) => {
    setCategory(v);
    refresh();
  };

  const getCurrentCategory = () => {
    if (category?.value === 'ALL') {
      return undefined;
    } else {
      // Free typed entries will not have value property
      return category?.value ?? category;
    }
  };

  useEffect(() => {
    refresh();
  }, [tenantCd]);

  useEffect(() => {
    if (
      !hasPermission(AppConfig.PERMISSION.FO_REPORT_MANAGEMENT, permissions)
    ) {
      history.push('/fo');
    }
  }, [permissions]);

  const renderPreview = (view) => {
    return (
      <IconButton
        className="p-1"
        onClick={(e) => {
          e.stopPropagation();
          store.dispatch({
            type: ReportTypes.OPEN_REPORT_VIEWER_REQUEST,
            reportUrl: formatReptSource(tableauServiceUrl, currentTenantCd, view.reptSource),
            category: view.category,
            title: view.displayText
          });
        }}>
        <VisibilityIcon />
      </IconButton>
    );
  };

  return (
    <>
      <Box className={styles.container}>
        <MaterialTable
          title="Existing Reports"
          tableRef={tableRef}
          columns={[
            { title: 'Display Text', field: 'displayText', width: '35%' },
            {
              title: 'Category',
              field: 'category',
              render: (data) =>
                CATEGORY_VIEW_MAPPING[data.category] || 'UNKNOWN'
            },
            { title: 'Description', field: 'description' },
            { title: 'Order', field: 'order' },
            {
              title: 'Preview',
              field: 'reptSource',
              render: (rowData) => {
                return renderPreview(rowData);
              }
            },
            {
              title: 'Last Updated',
              field: 'updateDt',
              render: (rowData) => {
                return moment(rowData.updateDt).format('MMM Do YY, h:mm:ss a');
              }
            }
          ]}
          onChangeRowsPerPage={setPageSize}
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .post(AppConfig.FO_INITIAL_URL + '/report/search', {
                  page: query.page,
                  size: query.pageSize,
                  direction: query.orderDirection
                    ? query.orderDirection.toUpperCase()
                    : Constant.DIRECTION_ASC,
                  category: getCurrentCategory(),
                  searchTerm: query.search,
                  sortProperty: query.orderBy
                    ? query.orderBy.field
                    : 'displayText'
                })
                .then((res) => {
                  const data = res.data;
                  setData(data?.content);
                  resolve({
                    data: data.content,
                    page: data.number,
                    size: data.size,
                    totalCount: data.totalElements
                  });
                })
                .catch((err) => {
                  resolve({
                    data: [],
                    page: 0,
                    totalCount: 0
                  });
                  if (!axios.isCancel(err)) {
                    if (err?.response.status === 400) {
                      store.dispatch({
                        type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                        payload: `Could not find reports for subcategory: ${category}`
                      });
                    } else {
                      store.dispatch({
                        type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                        payload: `Could not get report info: ${err}`
                      });
                    }
                  }
                });
            })
          }
          options={{
            actionsColumnIndex: -1,
            search: true,
            paging: true,
            debounceInterval: 1500,
            pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
            pageSize: pageSize
          }}
          actions={[
            {
              position: 'row',
              icon: 'mode_edit',
              tooltip: 'Edit',
              onClick: (_e, rowData) => {
                setDialogOpen(true);
                setEditTarget(rowData);
              }
            },
            (data) => ({
              icon: 'delete',
              tooltip: 'Delete',
              onClick: (_e, rowData) => handleDelete(rowData)
            }),
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              onClick: () => {
                refresh();
              },
              isFreeAction: true
            },
            {
              icon: () => <SyncAltSharpIcon />,
              tooltip: 'Init. Report',
              isFreeAction: true,
              onClick: (event) => handleInitReport()
            },
            {
              icon: () => <SyncIcon />,
              tooltip: 'Sync thumbnails',
              isFreeAction: true,
              onClick: (event) => handleThunbmailSyncup()
            }
          ]}
          components={{
            OverlayLoading: () => <div />,
            Toolbar: (props) => (
              <Box className={styles.categoryDropdown}>
                <MTableToolbar {...props} />
                <FormControl size="medium">
                  <Autocomplete
                    className={styles.categoryDropdown}
                    id="input_manageReport"
                    key="input_manageReport"
                    freeSolo
                    includeInputInList
                    options={CATEGORY_OPTIONS}
                    getOptionLabel={(e) => e.name ?? ''}
                    getOptionSelected={(option, value) =>
                      option.value === value
                    }
                    value={category}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Category" />
                    )}
                  />
                </FormControl>
              </Box>
            )
          }}
          icons={{
            Search: () => <SearchIcon color="primary"/>,
          }}
        />
        <EditDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          target={editTarget}
        />
        <InitOptionDialog
          open={initDialogOpen}
          onClose={handleInitDialogClose}
          target={data}
        />
      </Box>
    </>
  );
};

export default ExistingReportTable;
