import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Tooltip,
  Typography,
  List,
  ListSubheader,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import * as Constant from 'common/Constant';
import { useSelector } from 'react-redux';
import { confirmDialog } from 'common/ComfirmationDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    marginTop: '-10px',
    marginLeft: '20px',
    backgroundColor: 'inherit',
    padding: 0
  }
}));

const RoleTenantListDialogDetail = ({
  roleList,
  tenantList,
  showPermission = true
}) => {
  const classes = useStyles();
  const [roleOptions, setRoleOptions] = useState([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [selectedBrokers, setSelectedBrokers] = useState([]);

  const { control, getValues, watch, setValue } = useFormContext();

  const brokerList = useSelector((state) => state.user.allBrokers);
  const brokerCompanyMapping = useSelector(
    (state) => state.vendorLabeling.brokerCompanyMapping
  );

  const watchRole = watch('role', null);
  const watchTenant = watch('tenant', []);
  const roleFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.description}`
  });

  const tenantFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.displayText}`
  });

  const brokerFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.name}`
  });

  useEffect(() => {
    if (roleList && Array.isArray(roleList)) {
      setRoleOptions(
        roleList.sort((a, b) =>
          a.code?.toLowerCase() < b.code?.toLowerCase() ? -1 : 0
        )
      );
    }

    if (tenantList && Array.isArray(tenantList)) {
      setTenantOptions(
        tenantList.sort((a, b) =>
          a.displayText?.toLowerCase() < b.displayText?.toLowerCase() ? -1 : 0
        )
      );
    }

    if (brokerList && Array.isArray(brokerList)) {
      setBrokerOptions(
        brokerList.sort((a, b) =>
          a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 0
        )
      );
    }
  }, [roleList, brokerList, tenantList]);

  useEffect(() => {
    if (watchTenant.length > 0 && selectedBrokers.length === 0) {
      const initialBroker = brokerOptions.filter((broker) =>
        watchTenant.some((tenant) => tenant.brokerId == broker.id)
      );
      setSelectedBrokers(initialBroker);
    }
  }, [watchTenant]);

  const onChangeBroker = () => {
    const brokers = getValues('broker');
    const brokersToAdd = brokers.filter(
      (b) => !selectedBrokers.some((item) => item.code === b.code)
    );
    const brokersToRemove = selectedBrokers.filter(
      (s) => !brokers.some((item) => item.code === s.code)
    );
    const currentSelectedTenenats = getValues('tenant');
    if (brokersToAdd && Array.isArray(brokersToAdd) && brokersToAdd.length) {
      brokersToAdd.forEach((broker) => {
        brokerCompanyMapping[broker.code].forEach((e) => {
          if (currentSelectedTenenats.some((item) => item.code === e.code)) {
            // tenant is already selected
            return;
          } else currentSelectedTenenats.push(e);
        });
      });
      setValue('tenant', currentSelectedTenenats);
    }

    if (
      brokersToRemove &&
      Array.isArray(brokersToRemove) &&
      brokersToRemove.length
    ) {
      confirmDialog(
        () => onRemoveBroker(brokersToRemove),
        'Do you want to remove all the tenants under the broker in this application?'
      );
    }
  };

  const onRemoveBroker = (brokersToRemove) => {
    const currentSelectedTenenats = getValues('tenant');
    brokersToRemove.forEach((r) => {
      brokerCompanyMapping[r.code].forEach((e) => {
        const itemToRemove = currentSelectedTenenats.findIndex(
          (item) => item.code === e.code
        );

        if (itemToRemove !== -1)
          currentSelectedTenenats.splice(itemToRemove, 1);
      });
    });
    setValue('tenant', currentSelectedTenenats);
  };

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        <Grid item xs={showPermission === true ? 6 : 12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="role"
                control={control}
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    id="input-role"
                    options={roleOptions}
                    getOptionLabel={(option) =>
                      option.description ? option.description : ''
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={roleFilterOptions}
                    renderOption={({ id, ...props }) => {
                      let thisRole = roleList.find((x) => x.id === id);
                      return (
                        <Tooltip
                          key={'key-' + uuid()}
                          title={
                            showPermission === true ? (
                              <React.Fragment key={'key-' + uuid()}>
                                <Typography
                                  key={'key-' + uuid()}
                                  color="inherit">
                                  Permission List
                                </Typography>
                                {thisRole.permissions &&
                                thisRole.permissions.length > 0 ? (
                                  <ul
                                    key={'key-' + uuid()}
                                    style={{ marginLeft: '10px' }}>
                                    {thisRole.permissions.map((permission) => (
                                      <li key={'key-' + uuid()}>
                                        {permission.code}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  'N/A'
                                )}
                              </React.Fragment>
                            ) : (
                              ''
                            )
                          }>
                          <TextField
                            InputProps={{
                              readOnly: true,
                              disableUnderline: true
                            }}
                            fullWidth
                            defaultValue={thisRole.description}
                          />
                        </Tooltip>
                      );
                    }}
                    onChange={(_, data) => field.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={'input-role'}
                        label="Role"
                        placeholder="Chosen Role"
                        required
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="broker"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    multiple
                    id="broker"
                    value={selectedBrokers}
                    options={brokerOptions}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ''
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={brokerFilterOptions}
                    onChange={(_, data) => {
                      field.onChange(data);
                      onChangeBroker();
                      setSelectedBrokers(data);
                    }}
                    filterSelectedOptions
                    PopperComponent={'bottom'}
                    renderTags={(value, getTagProps) =>
                      value.map((data, index) => (
                        <Chip
                          key={'key-' + uuid()}
                          {...getTagProps({ index })}
                          label={data.name}
                          variant={data?.active ? 'default' : 'outlined'}
                        />
                      ))
                    }
                    renderInput={(field) => (
                      <TextField
                        {...field}
                        id={'input-tenant'}
                        label="Broker"
                        placeholder="Chosen Broker"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="tenant"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    fullWidth
                    id="tenants"
                    options={tenantOptions}
                    getOptionLabel={(option) =>
                      option.displayText ? option.displayText : ''
                    }
                    value={watchTenant}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={tenantFilterOptions}
                    onChange={(_, data) => field.onChange(data)}
                    filterSelectedOptions
                    PopperComponent={'bottom'}
                    defaultValue={getValues('tenant')}
                    renderTags={(value, getTagProps) =>
                      value.map((data, index) => (
                        <Chip
                          key={'key-' + uuid()}
                          {...getTagProps({ index })}
                          label={data.displayText || data.name}
                          variant={data?.active ? 'default' : 'outlined'}
                        />
                      ))
                    }
                    renderInput={(field) => (
                      <TextField
                        {...field}
                        id={'input-tenant'}
                        label="Tenants"
                        placeholder="Chosen Tenants"
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {showPermission === true && (
          <Grid item xs={6}>
            <List>
              <ListSubheader component="div" id="permission-list-subheader">
                Permissions
              </ListSubheader>
              <ul className={classes.ul} type="none">
                {watchRole &&
                watchRole.permissions &&
                watchRole.permissions.length > 0
                  ? watchRole.permissions.map((permission) => (
                      <li key={'key-' + uuid()}>
                        <b>{permission.code}</b> {': ' + permission.description}
                      </li>
                    ))
                  : 'No permission was found here.'}
              </ul>
            </List>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RoleTenantListDialogDetail;
