import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { GLOBAL_REPORT_MODES } from 'common/Constant';

const ReportBulkCreation = React.memo((props) => {
  const { onClose, open, target, control } = props;

  const methods = useForm({
    defaultValues: target,
    mode: 'onSubmit'
  });
  const { reset } = methods;

  const handleClose = () => {
    onClose(null);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(
      target ? target : { mode: GLOBAL_REPORT_MODES.OVERWRITE_BY_DISPLAY_TEXT }
    );
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
          <DialogTitle id="dialog-planDesignDataDetail">
            {target && target.id > 0 ? 'Edit' : 'New'}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4} bgcolor="primary.main">
              <Grid item xs={1}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        id="input-defaultOrder"
                        fullWidth
                        type="number"
                        {...field}
                        label="Order"
                        error={error}
                        helperText={error ? error.message : null}
                        labelId="label-defaultOrder"
                      />
                    </>
                  )}
                  name="defaultOrder"
                  defaultValue=""
                  control={control}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  rules={{ required: 'This field is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        id="input-projectName"
                        fullWidth
                        required
                        {...field}
                        label="Project Name"
                        error={error}
                        helperText={error ? error.message : null}
                        labelId="label-projectName"
                      />
                    </>
                  )}
                  name="projectName"
                  defaultValue="Smart Reports"
                  control={control}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  rules={{ required: 'This field is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        id="input-wbName"
                        fullWidth
                        required
                        {...field}
                        label="Workbook Name"
                        labelId="label-wbName"
                        error={error}
                        helperText={error ? error.message : null}
                        defaultValue=""
                      />
                    </>
                  )}
                  name="workbookName"
                  control={control}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  rules={{ required: 'This field is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        id="input-sheetName"
                        fullWidth
                        required
                        {...field}
                        label="Sheet Name"
                        multiline
                        labelId="label-sheetName"
                        error={error}
                        helperText={error ? error.message : null}
                        defaultValue=""
                      />
                    </>
                  )}
                  name="sheetName"
                  control={control}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={GLOBAL_REPORT_MODES.OVERWRITE_BY_DISPLAY_TEXT}
                          control={<Radio color="primary" />}
                          label="Overwrite Reports by Display Text"
                        />
                        <FormControlLabel
                          value={GLOBAL_REPORT_MODES.OVERWRITE_BY_URL}
                          control={<Radio color="primary" />}
                          label="Overwrite Reports by Report URL"
                        />
                        <FormControlLabel
                          value={GLOBAL_REPORT_MODES.NEW_REPORT}
                          control={<Radio color="primary" />}
                          label="Add Reports as New"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  name="mode"
                  control={control}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary"
              disabled={!methods.formState.isDirty}>
              Save
            </Button>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
});

export default ReportBulkCreation;
