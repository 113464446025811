import React, { useState, useEffect, useRef } from 'react';
import * as Actions from './actions/action';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { confirmDialog } from 'common/ComfirmationDialog';
import EmployeClassDetailDialog from './component/detailDialog';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { FoMaterialTable } from 'common/FoMaterialTable';

const EmployeeClass = ({ tenantId }) => {
  const tableRef = useRef();

  const [open, setOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});

  const data = useSelector((state) => state.MD.employeClass.data);

  const shouldRefresh = useSelector(
    (state) => state.MD.employeClass.shouldRefresh
  );

  const handleOpen = (data) => {
    setCloneData(data);
    setOpen(!open);
  };

  const handleClose = (data) => {
    if (data) {
      store.dispatch(Actions.save([data]));
    }

    setOpen(false);
  };

  const deleteByIds = (rowData) => {
    const ids = rowData.map((item) => item.id);
    confirmDialog(() => store.dispatch(Actions.deleteByIds(ids)));
  };

  const refresh = () => {
    store.dispatch(Actions.getAllByTenantId());
  };
  useEffect(() => {
    refresh();
  }, [tenantId]);

  useEffect(() => {
    if (shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE) {
      refresh();
    }
  }, [shouldRefresh]);

  return (
    <>
      <FoMaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'TPA Class Name', field: 'tpaClassName' },
          {
            title: 'Active',
            field: 'active',
            render: (rowData) =>
              rowData.active === true ? <CheckIcon /> : <ClearIcon />
          }
        ]}
        data={data}
        options={{
          selection: true,
          actionsColumnIndex: -1,
          search: false,
          paging: false
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          {
            position: 'row',
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleOpen({ ...rowData, transientItem: false });
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (evt, data) => deleteByIds(data)
          },
          {
            position: 'row',
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (_, rowData) => deleteByIds([rowData])
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleOpen({ transientItem: true, active: true });
            }
          }
        ]}
      />
      <EmployeClassDetailDialog
        open={open}
        onClose={handleClose}
        tenantId={tenantId}
        target={cloneData}
      />
    </>
  );
};

export default EmployeeClass;
