import { Box, Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppConfig } from 'AppConfig';
import * as ReportTypes from 'features/frontoffice/actions/types';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import { formatReptSource } from 'utils/formatReptSource';
import { trackClick, trackingActions, trackingTypes } from 'utils/tracking';
import HCCExplIcon from '../../../..//assets/img/hcc-expl-icon.png';
import ClinicalP2GIcon from '../../../../assets/img/clinical-p2g-icon.png';
import ExecutiveOverviewIcon from '../../../../assets/img/executive-overview-icon.png';
import GLPDrugsIcon from '../../../../assets/img/glp-1-drugs-icon.png';
import TrendExplorerIcon from '../../../../assets/img/trend-explorer-icon.png';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginRight: 8,
    marginTop: 5,
    marginBottom: 5
  },
  card: {
    paddingTop: 5,
    display: 'block',
    minHeight: '80px',
    maxHeight: '80px',
    textAlign: 'center',
    minWidth: AppConfig.HOME_SMART_CARD_WIDTH,
    maxWidth: AppConfig.HOME_SMART_CARD_WIDTH,
    transition: 'background 250ms color 250ms border 250ms',
    '&:hover': {
      cursor: 'pointer',
      color: '#3f51b5',
      border: '1px solid #3f51b5',
      backgroundColor: 'transparent'
    }
  },
  icon: {
    height: '50px',
    width: '50px',
    marginLeft: '15px',
    marginTop: '10px'
  },
  typographyIcon: {
    fontSize: '17.5px',
    fontWeight: 'bold',
    marginTop: '10px'
  },
  typography: {
    fontSize: '17.5px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  container: {
    alignItems: 'center',
    textWrap: 'pretty',
    wordBreak: 'break-word'
  }
}));

// hardcoded feature report icons
const getFeatureReportIcon = (displayText) => {
  switch (displayText) {
    case 'Print-to-Go Clinical Report':
      return ClinicalP2GIcon;
    case 'Weight Loss Drugs Opportunity':
      return GLPDrugsIcon;
    case 'High Cost Claimant Explorer':
      return HCCExplIcon;
    case 'Trend Explorer':
      return TrendExplorerIcon;
    case 'Executive Overview':
      return ExecutiveOverviewIcon;
    default:
      return null;
  }
};

const FeatureReport = ({ item }) => {
  const classes = useStyles();

  const tableauServiceUrl = useSelector((state) => state.user.tableauServiceUrl);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  const reportIcon = getFeatureReportIcon(item?.displayText);

  const handleFeatureClick = () => {
    if (item?.id) {
      trackClick(trackingTypes.REPORT, trackingActions.READ, item.id, item.displayText);
    }
    store.dispatch({
      type: ReportTypes.OPEN_REPORT_VIEWER_REQUEST,
      reportUrl: item.reportSource ? item.reportSource : formatReptSource(tableauServiceUrl, currentTenantCd, item.reptSource),
      title: item.displayText
    });
  };

  return (
    <>
      <Grid item xs={2} className={classes.gridItem}>
        <Card className={classes.card} onClick={handleFeatureClick}>
          {reportIcon ? (
            <Grid container xs={12} className={classes.container}>
              <Grid item xs={3}>
                <Box component="img" src={reportIcon} className={classes.icon} />
              </Grid>
              <Grid item xs={9}>
                <Typography className={classes.typographyIcon}>{item.displayText}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.container}>
              <Grid item xs={12}>
                <Typography className={classes.typography}>{item.displayText}</Typography>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default FeatureReport;
