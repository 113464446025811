import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as Types from './types';

export const fetchStateList = () => {
  return (dispatch) => {
    axios.get(`${AppConfig.FO_INITIAL_URL}/state/all`).then((res) => {
      dispatch({
        type: Types.FETCH_STATE_LIST,
        data: res.data
      });
    });
  };
};

export const createNewTenant = (data) => {
  return (dispatch) => {
    axios
      .post(`${AppConfig.FO_INITIAL_URL}/company-admin`, data)
      .then((_) => dispatch({ type: Types.SEARCH_REFRESH }));
  };
};

export const updateTenantDetails = (data) => {
  return (dispatch) => {
    axios
      .post(`${AppConfig.FO_INITIAL_URL}/company-admin/update`, data)
      .then((_) => dispatch({ type: Types.SEARCH_REFRESH }));
  };
};

export const activateCompany = (id) => {
  return (dispatch) =>
    axios
      .put(`${AppConfig.FO_INITIAL_URL}/company-admin/activate/${id}`)
      .then((_) => dispatch({ type: Types.SEARCH_REFRESH }));
};

export const deactivateCompany = (id) => {
  return (dispatch) =>
    axios
      .put(`${AppConfig.FO_INITIAL_URL}/company-admin/deactivate/${id}`)
      .then((_) => dispatch({ type: Types.SEARCH_REFRESH }));
};
