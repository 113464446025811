export const PLAN_DESIGN_DATA_PREFIX = 'MD-PLAN-DESIGN-DATA_';

export const DELETE_PLAN_DESIGN_DATA =
  PLAN_DESIGN_DATA_PREFIX + 'DELETE_PLAN_DESIGN_DATA';
export const DELETE_PLAN_DESIGN_DATA_SUCCESS =
  PLAN_DESIGN_DATA_PREFIX + 'DELETE_PLAN_DESIGN_DATA_SUCCESS';
export const DELETE_PLAN_DESIGN_DATA_FAILURE =
  PLAN_DESIGN_DATA_PREFIX + 'DELETE_PLAN_DESIGN_DATA_FAILURE';

export const MATERIAL_TABLE_PLAN_DESIGN_DATA =
  PLAN_DESIGN_DATA_PREFIX + 'MATERIAL_TABLE_PLAN_DESIGN_DATA';

export const CLONE_PLAN_DESIGN_DATA_SUCCESS =
  PLAN_DESIGN_DATA_PREFIX + 'CLONE_PLAN_DESIGN_DATA_SUCCESS';

export const FETCH_AVAILABLE_PLAN_YEAR_TO_CLONE_SUCCESS =
  PLAN_DESIGN_DATA_PREFIX + 'FETCH_AVAILABLE_PLAN_YEAR_TO_CLONE_SUCCESS';

export const SAVE_PLAN_DESIGN_DATA_SUCCESS =
  PLAN_DESIGN_DATA_PREFIX + 'SAVE_PLAN_DESIGN_DATA_SUCCESS';

export const SEARCH_REFRESH = PLAN_DESIGN_DATA_PREFIX + 'SEARCH_REFRESH';
