import React, { useState } from 'react';
import { TextField, Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { store } from 'store/store';
import { fetchDefaultSettings } from '../actions/action';

const DefaultingSettingDetail = ({ target }) => {
  const { control } = useFormContext();

  const defaultSettings = useSelector((state) => state.defaultSettings.defaultSettings);

  useState(() => {
    store.dispatch(fetchDefaultSettings());
  }, [target]);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={4}>
          <Controller
            rules={{
              validate: {
                validDisplayText: (newDisplayText) => {
                  try {
                    if (
                      newDisplayText &&
                      Array.isArray(defaultSettings) &&
                      defaultSettings.some((setting) => setting.displayText === newDisplayText) &&
                      defaultSettings.find((setting) => setting.displayText === newDisplayText).id !== target.id
                    ) {
                      return 'Existing default report with display text found, please choose unique display text.';
                    }
                  } catch (err) {
                    console.log(err);
                    return 'Failed to validate display text';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <TextField id="input-displayText" fullWidth {...field} multiline label="Display Text" error={error ? true : false} helperText={error ? error.message : null} />
              </>
            )}
            name="displayText"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <TextField id="input-description" fullWidth {...field} label="Description" multiline />
              </>
            )}
            name="description"
            control={control}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            rules={{
              validate: {
                validScId: (newScId) => {
                  try {
                    if ((target.scId === null || _.isEmpty(target)) && newScId) {
                      const scIdRegExp = new RegExp('^sc[1-9][0-9]*$');
                      if (!scIdRegExp.test(newScId)) {
                        return 'Sc Id must begin with sc and end with an integer. Ex: sc1';
                      }
                      if (newScId && Array.isArray(defaultSettings) && defaultSettings.some((setting) => setting.scId === newScId)) {
                        return 'Existing SC Id found, please choose unique Sc Id.';
                      }
                    }
                  } catch (err) {
                    console.log(err);
                    return 'Failed to validate Sc Id';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <TextField
                  id="input-sc-id"
                  disabled={target.scId && target.id}
                  fullWidth
                  {...field}
                  label="SC Id"
                  error={error ? true : false}
                  helperText={error ? error.message : null}
                />
              </>
            )}
            name="scId"
            control={control}
          />
        </Grid>
        <Grid item xs={1}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <TextField id="input-order" fullWidth {...field} label="Order" type="number" error={error} helperText={error ? error.message : null} />
              </>
            )}
            name="order"
            control={control}
          />
        </Grid>
        <Grid item xs={1}>
          <Controller
            rules={{
              validate: {
                validateFeature: (newFeature) => {
                  try {
                    if (newFeature && (newFeature < 1 || newFeature > 4)) {
                      return 'Feature must be between 1 - 4';
                    }
                  } catch (err) {
                    console.log(err);
                    return 'Failed to validate Feature';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <TextField id="input-feature" {...field} label="Feature" error={error ? true : false} helperText={error ? error.message : null} />
              </>
            )}
            name="feature"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default DefaultingSettingDetail;
