import React, { useState, useEffect, useRef } from 'react';
import { Box, Checkbox } from '@material-ui/core';
import * as Constant from 'common/Constant';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as UserTypes from 'features/user/userTypes';
import { AppConfig } from 'AppConfig';
import * as Action from './action/action';
import axios from 'axios';
import MaterialTable from '@material-table/core';
import RoleTenantDetail from './components/roleTenantDetail';
import UserCreationDialog from './components/userCreationDialog';
import { getAllBrokers, fetchData } from 'features/user/userActions';
import { fetchBrokerCompanyMapping } from '../vendor-labeling/actions/action';
import SearchIcon from '@material-ui/icons/Search';

// prepare object for applicaiton listing order based on app code
const appOrder = {
  [AppConfig.APP_CODE_FO]: 1,
  [AppConfig.APP_CODE_ENIGMA]: 2,
  [AppConfig.APP_CODE_PLAN_OPTIMIZE]: 3
};

const TenantAdminTable = () => {
  const tableRef = useRef();

  const shouldRefresh = useSelector((state) => state.tenantAdmin.shouldRefresh);
  const tenantList = useSelector((state) => state.tenantAdmin.tenantList);
  const appRoleList = useSelector((state) => state.tenantAdmin.roleList);
  const isEnigmaTenantAdmin = useSelector(
    (state) => state.user.enigmaTenantAdmin
  );
  const isPlanOptmTenantAdmin = useSelector(
    (state) => state.user.planOptimizeTenantAdmin
  );
  const { currentTenantCd } = useSelector((state) => state.user);

  useEffect(() => {
    const timeout = setTimeout(() => {
      store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_FO));
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const [creationOpen, setCreationOpen] = useState(false);

  const handleCreationOpen = () => {
    setCreationOpen(true);
  };

  const handleCreationClose = () => {
    setCreationOpen(false);
  };

  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [detailpanelState, setdetailpanelState] = useState({});
  const [interalDetailPanelState, setInteralDetailPanelState] = useState({});
  const [applicationList, setAppList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  useEffect(() => {
    refresh();
  }, [shouldRefresh]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    store.dispatch(getAllBrokers());
    store.dispatch(fetchBrokerCompanyMapping());
    store.dispatch(Action.fetchAdminTenantList());
    store.dispatch(Action.fetchAdminRoleList());
  }, []);

  useEffect(() => {
    const apps = appRoleList
      .filter((item) => {
        switch (item.code) {
          case AppConfig.APP_CODE_FO:
            return true;
          case AppConfig.APP_CODE_PLAN_OPTIMIZE:
            return isPlanOptmTenantAdmin;
          case AppConfig.APP_CODE_ENIGMA:
            return isEnigmaTenantAdmin;
          default:
            return false;
        }
      })
      .map((app) => {
        app.order = appOrder[app.code];
        if (app.code === AppConfig.APP_CODE_FO) {
          app.description = 'BenInsight';
        }
        return app;
      });
    const sortedApplList = apps.sort((a, b) => a.order - b.order);
    setAppList(sortedApplList);
    setRoleList(appRoleList.map((item) => item.roles).flat());
  }, [isEnigmaTenantAdmin, isPlanOptmTenantAdmin, appRoleList]);

  const handleActiveCheckboxChange = (data) => {
    let { rowdata, isActive } = data;
    confirmDialog(
      () =>
        store.dispatch(
          Action.toggleUserActiveStatus(rowdata?.userId, isActive)
        ),
      `Are you sure you want to change the status of "${rowdata?.firstName} ${
        rowdata?.lastName
      }" to ${isActive ? 'active' : 'inactive'}`
    );
  };

  return (
    <>
      <MaterialTable
        title=""
        style={{ width: '100%' }}
        tableRef={tableRef}
        columns={[
          { title: 'First', field: 'firstName', editable: 'never' },
          { title: 'Last', field: 'lastName', editable: 'never' },
          { title: 'Username', field: 'username', editable: 'never' },
          { title: 'Email', field: 'email', editable: 'never' },
          {
            title: 'Active',
            field: 'active',
            cellStyle: { textAlign: 'start' },
            headerStyle: { textAlign: 'center' },
            render: (rowdata) => (
              <Checkbox
                checked={rowdata.active === 'A'}
                onChange={() =>
                  handleActiveCheckboxChange({
                    rowdata: rowdata,
                    isActive: rowdata.active === 'I'
                  })
                }
                inputProps={{ 'aria-label': 'fo active checkbox' }}
                id={'input-activeCheckbox-' + rowdata.id}
                color="primary"
              />
            )
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let pState = {};
            tableRef.current.state.data.map((data) => {
              pState[data.id] = data.tableData.showDetailPanel;
            });
            setdetailpanelState(pState);

            axios
              .post(AppConfig.IAM_BACKEND_URL + `/tenant-admin/search`, {
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'username',
                searchTerm: query.search
              })
              .then((m) => {
                let data = m.data;
                data.content.forEach((item) => {
                  if (pState[item.id]) {
                    if (item.networkValues) {
                      item.networkValues.forEach((mi) => {
                        const found = interalDetailPanelState[item.id];
                        if (found) {
                          mi.default_open = found[mi.id];
                        }
                      });
                    }
                    item.tableData = {
                      showDetailPanel: tableRef.current.props.detailPanel
                    };
                  }
                });

                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize,
          debounceInterval: 400
        }}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            onClick: () => {
              handleCreationOpen();
            },
            isFreeAction: true
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel();
        }}
        detailPanel={(rowData) => {
          return (
            <>
              <Box m={2}>
                <RoleTenantDetail
                  data={rowData}
                  tenantList={tenantList}
                  roleList={roleList}
                  appList={applicationList}
                />
              </Box>
            </>
          );
        }}
      />
      <UserCreationDialog
        open={creationOpen}
        onClose={handleCreationClose}
        appList={applicationList}
      />
    </>
  );
};

export default TenantAdminTable;
