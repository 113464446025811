import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import UserAccessManagementDialogDetail from './userAccessManagementDialogDetail';

const UserAccessManagementDialog = (props) => {
  const { onClose, open, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = () => {
    onClose(null);
  };

  const onSubmit = (data) => {
    const params = {
      ...data,
      assignedCompanyId: data.assignedCompanyId?.id,
      accessibleCompanyIds: !target.id
        ? data.accessibleCompanyIds.map((item) => item.id)
        : null
    };
    onClose(params, target.id ? true : false);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {target.id ? 'Edit User' : 'New User'}
        </DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <UserAccessManagementDialogDetail
              external={target.external}
              isEdit={target.id ? true : false}
              assignedCompanyId={target.assignedCompanyId}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={methods.handleSubmit(onSubmit)}
            color="primary"
            disabled={!methods.formState.isDirty}>
            Save
          </Button>
          <Button onClick={() => handleClose(null)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserAccessManagementDialog;
