import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { validate } from '@material-ui/pickers';
import * as Constant from 'common/Constant';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as ValidationUtil from 'utils/ValidationUtil';
import * as MDActions from '../../../common/actions/CommonActions';
import DisableValidationConfirmation from './disableValidationConfirmation';

const PlanDesignNetworkDetail = (props) => {
  const { tenantId, isNew, existingNetworks } = props;

  const {
    register,
    control,
    watch,
    formState: { errors },
    getValues
  } = useFormContext();
  const { fields } = useFieldArray(
    {
      control,
      keyName: Constant.DEFAULT_REACT_HOOK_FIELD_ID,
      name: 'hsaTiers'
    },
    {
      control,
      keyName: Constant.DEFAULT_REACT_HOOK_FIELD_ID,
      name: 'hraTiers'
    }
  );

  const watchHsaType = watch('hsaType', Constant.KEY_HSA_TYPE_IN_FAMILY);
  const watchHraType = watch('hraType', Constant.KEY_HSA_TYPE_IN_FAMILY);

  const allNetworkTypes = useSelector(
    (state) => state.MD.mdCommon.allNetworkTypes
  );

  const allElectionTiers = useSelector(
    (state) => state.MD.mdCommon.allElectionTiers
  );

  const [filteredNetworkTypes, setFilteredNetworkTypes] =
    useState(allNetworkTypes);

  const [validation, setValidation] = useState(true);
  const [validationConfirmation, setValidationConfirmation] = useState(false);

  const handleValidation = () => {
    if (validation) {
      handleValidationConfirmationOpen();
      setValidation(!validation);
    } else {
      setValidation(!validation);
    }
  };

  const handleValidationConfirmationOpen = () => {
    setValidationConfirmation(true);
  };

  const handleValidationConfirmationClose = () => {
    setValidationConfirmation(false);
  };

  useEffect(() => {
    store.dispatch(MDActions.getAllElectionTiers());
  }, [tenantId]);

  useEffect(() => {
    if (
      isNew &&
      allNetworkTypes &&
      existingNetworks &&
      Array.isArray(allNetworkTypes) &&
      Array.isArray(existingNetworks) &&
      existingNetworks.length > 0
    ) {
      let filtered = allNetworkTypes.filter(function (item) {
        return (
          _.findIndex(existingNetworks, function (o) {
            return o.networkType === item;
          }) < 0
        );
      });
      setFilteredNetworkTypes(filtered);
    }
  }, [existingNetworks, allNetworkTypes]);

  return (
    <>
      <Grid container bgcolor="primary.main" spacing={2}>
        <Grid item xs={5}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="networkType"
                rules={{ required: 'This field is required' }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="input-allNetworkTypes"
                    required
                    selectOnFocus
                    clearOnBlur
                    autoSelect
                    options={filteredNetworkTypes}
                    disabled={!isNew}
                    autoFocus
                    onChange={(_, data) => {
                      field.onChange(data);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={error}
                        helperText={error ? error.message : null}
                        label="Network Type"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="deductibleIn"
                control={control}
                rules={{
                  validate: {
                    deductibleIn: (value) =>
                      ValidationUtil.validateDedIn(
                        value,
                        getValues('deductibleFAM'),
                        getValues('maxOutOfPocketIn'),
                        validation
                      )
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-deductibleIn"
                    label="Deductible IND"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="deductibleFAM"
                control={control}
                rules={{
                  validate: {
                    deductibleFAM: (value) =>
                      ValidationUtil.validateDedFam(
                        getValues('deductibleIn'),
                        value,
                        validation
                      )
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-deductibleFAM"
                    label="Deductible FAM"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="maxOutOfPocketIn"
                rules={{
                  validate: {
                    maxOutOfPocketIn: (value) =>
                      ValidationUtil.validateMaxOutOfPocketIn(
                        value,
                        getValues('maxOutOfPocketFAM'),
                        getValues('deductibleIn'),
                        validation
                      )
                  }
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-maxOutOfPocketIn"
                    label="Max OOP IND"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="maxOutOfPocketFAM"
                rules={{
                  validate: {
                    maxOutOfPocketIn: (value) =>
                      ValidationUtil.validateMaxOutOfPocketFam(
                        getValues('maxOutOfPocketIn'),
                        value,
                        validation
                      )
                  }
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-maxOutOfPocketFAM"
                    label="Max OOP FAM"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                rules={{
                  validate: {
                    coInsurance: (value) =>
                      ValidationUtil.validateTheStringOrNumberInput(value)
                  }
                }}
                name="coInsurance"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-coInsurance"
                    label="Co-Insurance"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                rules={{
                  validate: {
                    pcpCopay: (value) =>
                      ValidationUtil.validateTheStringOrNumberInput(value)
                  }
                }}
                name="pcpCopay"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-pcpCopay"
                    label="PCP Copay"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                rules={{
                  validate: {
                    rule1: (value) =>
                      ValidationUtil.validateTheStringOrNumberInput(value)
                  }
                }}
                name="scpCopay"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-spcCopay"
                    label="SPC Copay"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                rules={{
                  validate: {
                    urgentCareCopay: (value) =>
                      ValidationUtil.validateTheStringOrNumberInput(value)
                  }
                }}
                name="urgentCareCopay"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-urgentCareCopay"
                    label="Urgent Care Copay"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="emergencyRoomCopay"
                rules={{
                  validate: {
                    emergencyRoomCopay: (value) =>
                      ValidationUtil.validateTheStringOrNumberInput(value)
                  }
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-emergencyRoomCopay"
                    label="ER Copay"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="rxCopay1"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-rxCopay1"
                    label="RX Copay 1"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="rxCopay2"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-rxCopay2"
                    label="RX Copay 2"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="rxCopay3"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-rxCopay3"
                    label="RX Copay 3"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="rxCopay4"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-rxCopay4"
                    label="RX Copay 4"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="teleMedCoPay"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-teleMedCoPay"
                    label="Telemedicine Copay"
                    {...field}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                id="checkbox-disableValidation"
                control={<Checkbox checked={!validation} />}
                label="Disable Validation"
                onChange={() => {
                  handleValidation();
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          HSA Contribution Basis
                        </FormLabel>
                        <RadioGroup {...field}>
                          <FormControlLabel
                            value={Constant.KEY_HSA_TYPE_IN_FAMILY}
                            control={<Radio color="primary" />}
                            label="Individual and family"
                          />
                          <FormControlLabel
                            value={Constant.KEY_HSA_TYPE_ELECTION_TIER}
                            control={<Radio color="primary" />}
                            label="Election tier"
                          />
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                      </FormControl>
                    )}
                    name="hsaType"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row">
                {watchHsaType === Constant.KEY_HSA_TYPE_IN_FAMILY && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name="hsaIn"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            id="input-hsaIn"
                            label="HSA In"
                            {...field}
                            error={error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="hsaFam"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            id="input-hsaFAM"
                            label="HSA FAM"
                            {...field}
                            error={error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {watchHsaType === Constant.KEY_HSA_TYPE_ELECTION_TIER && (
                  <>
                    {allElectionTiers.map((item, index) => (
                      <Fragment key={`key-hsaTiers_parent_${index}`}>
                        <input
                          type="hidden"
                          name={`hsaTiers[${index}].tierId`}
                          defaultValue={item.id}
                          {...register(`hsaTiers[${index}].tierId`)}
                        />
                        <input
                          type="hidden"
                          name={`hsaTiers[${index}].id`}
                          defaultValue={item.id}
                          {...register(`hsaTiers[${index}].id`)}
                        />
                        <input
                          type="hidden"
                          name={`hsaTiers[${index}].planDesingNetworkId`}
                          defaultValue={item.planDesingNetworkId}
                          {...register(
                            `hsaTiers[${index}].planDesingNetworkId`
                          )}
                        />
                        <Grid item xs={12}>
                          <Controller
                            name={`hsaTiers.${index}.amount`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                id={'input-hsaTiers-tier-amount' + item.id}
                                key={field.id}
                                label={item.etName}
                                {...field}
                                error={error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Fragment>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          HRA Contribution Basis
                        </FormLabel>
                        <RadioGroup {...field}>
                          <FormControlLabel
                            value={Constant.KEY_HSA_TYPE_IN_FAMILY}
                            control={<Radio color="primary" />}
                            label="Individual and family"
                          />
                          <FormControlLabel
                            value={Constant.KEY_HSA_TYPE_ELECTION_TIER}
                            control={<Radio color="primary" />}
                            label="Election tier"
                          />
                        </RadioGroup>
                        <FormHelperText></FormHelperText>
                      </FormControl>
                    )}
                    name="hraType"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row">
                {watchHraType === Constant.KEY_HSA_TYPE_IN_FAMILY && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name="hraIn"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            id="input-hraIn"
                            label="HRA In"
                            {...field}
                            error={error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="hraFam"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            id="input-hraFAM"
                            label="HRA FAM"
                            {...field}
                            error={error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {watchHraType === Constant.KEY_HSA_TYPE_ELECTION_TIER && (
                  <>
                    {allElectionTiers.map((item, index) => (
                      <Fragment key={`key-hraTiers_parent_${index}`}>
                        <input
                          type="hidden"
                          name={`hraTiers[${index}].tierId`}
                          defaultValue={item.id}
                          {...register(`hraTiers[${index}].tierId`)}
                        />
                        <input
                          type="hidden"
                          name={`hraTiers[${index}].id`}
                          defaultValue={item.id}
                          {...register(`hraTiers[${index}].id`)}
                        />
                        <input
                          type="hidden"
                          name={`hraTiers[${index}].planDesingNetworkId`}
                          defaultValue={item.planDesingNetworkId}
                          {...register(
                            `hraTiers[${index}].planDesingNetworkId`
                          )}
                        />
                        <Grid item xs={12}>
                          <Controller
                            name={`hraTiers.${index}.amount`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                id={'input-hraTiers-tier-amount' + item.id}
                                key={field.id}
                                label={item.etName}
                                {...field}
                                error={error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Fragment>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DisableValidationConfirmation
        open={validationConfirmation}
        onClose={handleValidationConfirmationClose}
        passValidation={setValidation}
        validation={validation}
      />
    </>
  );
};

export default PlanDesignNetworkDetail;
