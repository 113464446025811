import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import RouteConfig from './RouteConfig';
import PrivateRoute from './PrivateRoute';
import ControlledRoute from './ControlledRoute';
import AppView from './AppView';

const AppRoute = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={RouteConfig.login.path}
          component={RouteConfig.login.component}
        />
        <ControlledRoute
          path={RouteConfig.reset.path}
          component={RouteConfig.reset.component}
          fallback={RouteConfig.newHome.path}
        />
        <ControlledRoute
          path={RouteConfig.forgot.path}
          component={RouteConfig.forgot.component}
          fallback={RouteConfig.newHome.path}
        />
        <Route
          path={RouteConfig.oktaSso.path}
          component={RouteConfig.oktaSso.component}
        />
        {/* The private route has different fall backs based on the login method, so we removed the fallback property 
            from the route definition here and handle the fall back logic inside the PrivateRoute code */}
        <PrivateRoute path="/" component={AppView} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoute;
