import {
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {v4 as uuid} from 'uuid';

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: '400px'
  }
}));

const CloneDetail = (props) => {
  const { control, register, getValues } = useFormContext();
  const { planYears, tenantId } = props;
  const styles = useStyles();

  return (
    <>
      <input
        key={uuid()}
        type="hidden"
        name={`tenantCode`}
        defaultValue={tenantId}
        {...register('tenantCode')}
      />
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        className={styles.dialog}>
        <Grid item xs={6}>
          <Controller
            rules={{ required: 'Source year is required' }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <InputLabel id="label-sourceYear">Source Year</InputLabel>
                <Select
                  id="input-sourceYear"
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error ? error.message : null}>
                  {planYears &&
                    Array.isArray(planYears) &&
                    planYears.map((item) => (
                      <MenuItem key={uuid()} value={item.planYearName}>
                        {item.planYearName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            name="sourceYear"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{ required: 'Destination year is required' }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <InputLabel id="label-targetYear">Destination Year</InputLabel>
                <Select
                  id="input-targetYear"
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error ? error.message : null}>
                  {planYears &&
                    Array.isArray(planYears) &&
                    planYears
                      .filter(
                        (item) => item.planYearName !== getValues('sourceYear')
                      )
                      .map((item) => (
                        <MenuItem key={uuid()} value={item.planYearName}>
                          {item.planYearName}
                        </MenuItem>
                      ))}
                </Select>
                {error && error.message && (
                  <FormHelperText>{error.message}</FormHelperText>
                )}
              </FormControl>
            )}
            name="targetYear"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default CloneDetail;
