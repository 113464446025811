import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

const InitOptions = ({ onCancel, onSubmit }) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { mode: 'overwrite-by-displaytext' },
    mode: 'onBlur'
  });

  const handleCancel = () => {
    reset();
    onCancel();
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        style={{ paddingTop: '1em' }}>
        <Grid item xs={12}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <RadioGroup aria-label="mode" name="mode" {...field}>
                <FormControlLabel
                  value="overwrite-by-displaytext"
                  control={<Radio color="primary" />}
                  label='Overwrite reports by "Display Text"'
                />

                <FormControlLabel
                  value="overwrite-by-url"
                  control={<Radio color="primary" />}
                  label='Overwrite reports by "Report URL"'
                />

                <FormControlLabel
                  value="new-report"
                  control={<Radio color="primary" />}
                  label="Add all reports as New"
                />
              </RadioGroup>
            )}
            name="mode"
            control={control}
          />
        </Grid>
        <Grid item xl={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}>
            <Grid item>
              <Button onClick={handleSubmit(onSubmit)} color="primary">
                Confirm
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handleCancel()} color="secondary">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InitOptions;
