import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import LogoUploader from './logoUploader';
import * as Constant from 'common/Constant';
import SubmitButton from 'common/SingleClickButton';

const LogoUploaderDialog = (props) => {
  const { onClose, open, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset, formState, getValues } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);
  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}>
          <DialogTitle id="customized-dialog-title">
            {target && target.TYPE === Constant.LOGO_DEFAULT_TYPE
              ? 'Default Logo'
              : 'Tenant Logo'}
          </DialogTitle>
          <DialogContent dividers>
            <LogoUploader />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={formState.isDirty && getValues('logoFile')}
            />
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default LogoUploaderDialog;
