import React, { useState, Fragment } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from 'common/BstAccordion';
import { v4 as uuid } from 'uuid';
import * as Action from '../action/action';
import { store } from 'store/store';

import { confirmDialog } from 'common/ComfirmationDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoleTenantList from './roleTenantList';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const RoleTenantDetail = ({ data, tenantList, appList, roleList }) => {
  const [creationOpen, setCreationOpen] = useState(false);

  const { userId } = data.rowData;

  const handleDelete = (roleId) => {
    confirmDialog(
      () =>
        store.dispatch(
          Action.deleteByUserIdRoleIdInTenantAdmin(userId, roleId)
        ),
      'Do you really want to remove the role with all the tenants under the role in this application?'
    );
  };

  /**
   * Extracts roleId list from role Object list.
   *
   * @param {*} roleObjectList
   */
  const extractAssignableRoleIdList = (roleObjectList) => {
    let assignableRoleIdList = [];
    if (roleObjectList) {
      assignableRoleIdList = roleObjectList.map((role) => role.id);
    }
    return assignableRoleIdList;
  };

  const handleSingleDelete = (companyId, roleId) => {
    confirmDialog(
      () =>
        store.dispatch(
          Action.deleteByUserIdAndCompanyIdAndRoleIdInTenantAdmin(
            userId,
            companyId,
            roleId
          )
        ),
      'Do you really want to remove this tenant under this role?'
    );
  };

  const handleCreationClose = (data) => {
    //convert data to backend expected object
    if (data) {
      if (
        data.role &&
        data.tenant &&
        Array.isArray(data.tenant) &&
        data.tenant.length > 0
      ) {
        let dto = {
          userId: userId,
          originalRoleId: data.roleId,
          updateRoleId: data.role.id,
          companyIds: []
        };
        data.tenant.map((item) => {
          return dto.companyIds.push(item.id);
        });
        store.dispatch(Action.saveTenantApplicationAccesses(dto));
      }
    }
    setCreationOpen(false);
  };

  const getAppRoleData = (roles) => {
    const appRoles = [];
    roles.forEach((role) => {
      let roleToPush = data.rowData.userRoleTenantDetail[String(role.id)];
      if (roleToPush) {
        appRoles.push(roleToPush);
      }
    });
    return appRoles;
  };

  return (
    <>
      <Fragment key={uuid()}>
        {Array.isArray(appList) &&
          appList.map((app, index) => (
            <Fragment key={'key-' + index}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                key={'key-' + uuid()}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls={app.code + '-content'}
                  id={app.code + '-header'}
                  key={'key-' + uuid()}>
                  {getAppRoleData(app.roles).length ? (
                    <CheckIcon color="primary" />
                  ) : (
                    <ClearIcon color="secondary" />
                  )}
                  {app.description}
                </AccordionSummary>
                <AccordionDetails>
                  <RoleTenantList
                    data={getAppRoleData(app.roles)}
                    assignableRoleIdList={extractAssignableRoleIdList(roleList)}
                    roleList={app.roles}
                    tenantList={tenantList}
                    appName={app.description}
                    appId={app.id}
                    userId={data.id}
                    handleCreationClose={handleCreationClose}
                    handleDelete={handleDelete}
                    handleSingleDelete={handleSingleDelete}
                    creationOpen={creationOpen}
                  />
                </AccordionDetails>
              </Accordion>
            </Fragment>
          ))}
      </Fragment>
    </>
  );
};

export default RoleTenantDetail;
