import * as Types from '../actions/types';
import _ from 'lodash';
import { formatReptSource } from 'utils/formatReptSource';
import * as Actions from '../actions/actions';

const initialState = {
  legacies: [],
  explorers: [],
  smarts: [],
  adHocs: [],
  customs: [],
  oftentimes: [],
  metrics: [],
  menuSearchOptions: [],
  reportMenus: [],
  defaultMetrics: [],
  openReportViewDialog: false,
  reportUrl: null,
  inspection: {},
  reportTags: [],
  currentReportCategory: '',
  currentReportTitle: '',
  adHocPublic: [],
  adHocPersonal: [],
  filteredAdHocPersonal: [],
  filteredAdHocPublic: [],
  displayTexts: [],
  reportsToDelete: [],
  defaultSettingRefresh:
    Actions.SHOULD_REFRESH_REPORT_DEFAULT_SETTING_INIT_VALUE,
  exclusive: [],
  tableauServiceUrl: null
};

export default function foReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_MY_METRIC_SUCCESS:
      const sortedMetrics = _.orderBy(
        action.payload,
        [(item) => parseInt(item.order)],
        ['asc']
      );
      return {
        ...state,
        metrics: sortedMetrics
      };
    case Types.FETCH_DEFAULT_METRIC_SUCCESS:
      return {
        ...state,
        defaultMetrics: action.payload
      };
    case Types.FETCH_SAVED_REPORT_SUCCESS:
      let initPublic = _.orderBy(
        action.payload?.adHocPublic,
        'displayText',
        'asc'
      );
      let initPersonal = _.orderBy(
        action.payload?.adHocPersonal,
        'displayText',
        'asc'
      );
      return {
        ...state,
        adHocPublic: initPublic,
        adHocPersonal: initPersonal,
        filteredAdHocPersonal: initPersonal,
        filteredAdHocPublic: initPublic
      };
    case Types.SORT_SUCCESS:
      if (
        action.payload.isPublic &&
        action.payload.folders &&
        action.payload.folderKey
      ) {
        if (!action.payload.data) {
          let newMap = new Map(action.payload.folders);
          let newData = [];
          if (action.payload.flag) {
            newData = _.orderBy(
              action.payload.folders.get(action.payload.folderKey),
              ['displayText'],
              ['desc']
            );
          } else {
            newData = _.orderBy(
              action.payload.folders.get(action.payload.folderKey),
              ['displayText'],
              ['asc']
            );
          }
          newMap.set(action.payload.folderKey, newData);
          return {
            ...state,
            filteredAdHocPublic: Array.from(newMap.values()).flat(1)
          };
        } else {
          let newData = [];
          if (action.payload.flag) {
            newData = _.orderBy(action.payload.data, 'displayText', 'desc');
          } else {
            newData = _.orderBy(action.payload.data, 'displayText', 'asc');
          }
          return { ...state, adHocPublic: newData };
        }
      } else {
        if (!action.payload.isPublic) {
          if (action.payload.flag) {
            const newData = _.orderBy(
              action.payload.data,
              'displayText',
              'desc'
            );
            return { ...state, filteredAdHocPersonal: newData };
          } else {
            const newData = _.orderBy(
              action.payload.data,
              'displayText',
              'asc'
            );
            return { ...state, filteredAdHocPersonal: newData };
          }
        }
      }
      break;
    case Types.FILTER_SUCCESS:
      if (
        action.payload.isPublic &&
        action.payload.folders &&
        action.payload.folderKey
      ) {
        if (!action.payload.data) {
          let newMap = new Map(action.payload.folders);
          let newData = _.filter(
            action.payload.folders.get(action.payload.folderKey),
            function (o) {
              return o.displayText
                ? o.displayText
                  .toLowerCase()
                  .includes(action.payload.searchValue.toLowerCase())
                : false;
            }
          );

          if (Array.isArray(newData) && newData.length > 0) {
            newData = _.orderBy(newData, 'displayText', action.payload.order);
          }
          newMap.set(action.payload.folderKey, newData);
          return {
            ...state,
            filteredAdHocPublic: Array.from(newMap.values()).flat(1)
          };
        } else {
          let newData = _.filter(
            [...action.payload.folders.values()].flatMap((s) => s),
            function (o) {
              return o.displayText
                ? o.displayText
                  .toLowerCase()
                  .includes(action.payload.searchValue.toLowerCase())
                : false;
            }
          );
          if (Array.isArray(newData) && newData.length > 0) {
            newData = _.orderBy(newData, 'displayText', action.payload.order);
          }
          return { ...state, adHocPublic: newData };
        }
      }

      if (
        !action.payload.isPublic &&
        action.payload.data &&
        Array.isArray(action.payload.data)
      ) {
        let newData = _.filter(action.payload.data, function (o) {
          return o.displayText
            ? o.displayText
              .toLowerCase()
              .includes(action.payload.searchValue.toLowerCase())
            : false;
        });
        newData = _.orderBy(newData, 'displayText', action.payload.order);
        return { ...state, filteredAdHocPersonal: newData };
      }
      break;
    case Types.FETCH_REPORT_CUSTOM_SUCCESS:
      return {
        ...state,
        customs: _.orderBy(action.payload, ['updateDt'], ['desc'])
      };
    case Types.FETCH_FO_DETAIL_SUCCESS:
      let searchOptions = [];
      let reportMenus = [];

      if (action.payload?.legacies && Array.isArray(action.payload.legacies)) {
        Array.prototype.push.apply(searchOptions, action.payload.legacies);
        Array.prototype.push.apply(reportMenus, action.payload.legacies);
      }

      if (
        action.payload?.explorers &&
        Array.isArray(action.payload.explorers)
      ) {
        Array.prototype.push.apply(searchOptions, action.payload.explorers);
        Array.prototype.push.apply(reportMenus, action.payload.explorers);
      }

      if (
        action.payload?.smarts &&
        Array.isArray(action.payload.smarts)
      ) {
        Array.prototype.push.apply(searchOptions, action.payload.smarts);
      }

      searchOptions = searchOptions
        .map((opt) => {
          return {
            displayText: opt.displayText,
            category: opt.category,
            reptSource: formatReptSource(
              action.payload.tbUrl,
              action.payload.tenantCd,
              opt.reptSource
            ),
            description: opt.description,
            searchTerm: opt.displayText
          };
        })
        .sort(function (a, b) {
          return a.displayText < b.displayText ? -1 : 1;
        });

      return {
        ...state,
        legacies: action.payload?.legacies,
        explorers: action.payload?.explorers,
        adHocs: action.payload?.adHocs,
        metrics: action.payload?.metrics,
        oftentimes: action.payload?.oftentimes,
        smarts: action.payload?.smarts,
        menuSearchOptions: searchOptions,
        reportMenus: reportMenus,
        tableauServiceUrl: action.payload?.tbUrl
      };
    case Types.FETCH_REPORT_STANDARD_SUCCESS:
      return {
        ...state,
        legacies: action.payload
      };
    case Types.OPEN_REPORT_VIEWER_REQUEST:
      return {
        ...state,
        openReportViewDialog: true,
        reportUrl: action.reportUrl,
        currentReportCategory: action.category,
        currentReportTitle: action.title
      };
    case Types.CLOSE_REPORT_VIEWER_REQUEST:
      return {
        ...state,
        openReportViewDialog: false
      };
    case Types.FETCH_INSPECTION_SUCCESS:
      return {
        ...state,
        inspection: action.payload
      };
    case Types.FETCH_REPORT_EXPLORER_SUCCESS:
      return {
        ...state,
        explorers: action.payload
      };
    case Types.FETCH_REPORT_TAGS:
      return {
        ...state,
        reportTags: action.payload
      };
    case Types.FETCH_ALL_DISPLAY_TEXT_SUCCESS:
      return {
        ...state,
        displayTexts: action.payload
      };
    case Types.FETCH_REPORT_TO_DELETE_SUCCESS:
      return {
        ...state,
        reportsToDelete: action.payload
      };
    case Types.REPORT_DEFAULT_SETTING_REFRESH:
      return {
        ...state,
        defaultSettingRefresh: state.defaultSettingRefresh + 1
      };
    case Types.FETCH_REPORT_EXCLUSIVE_SUCCESS:
      return {
        ...state,
        exclusive: action.payload
      };
      case Types.OPEN_GLOBAL_CONFIG_REQUEST:
        return {
          ...state,
          gconfig: action.payload
        };
    default:
      return state;
  }
}
