import React from 'react';
import { TextField, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';
import * as ValidationUtil from 'utils/ValidationUtil';
import { useSelector } from 'react-redux';

const BrokerCreationDetail = () => {
  const { control, getValues, setValue } = useFormContext();
  const currentId = getValues('id');
  const brokerList = useSelector((state) => state.user.allBrokers);

  const onInputChange = (inputValue) => {
    const code = generateValidCode(inputValue);
    setValue('code', code);
  };
  const generateValidCode = (code) => {
    // Replace all uppercase characters with lowercase and whitespace and symbols with ''
    return code.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  };

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-name"
                required
                fullWidth
                {...field}
                error={error}
                helperText={error?.message ?? null}
                label="Name"
                onChange={(event) => {
                  const value = event.target.value;
                  field.onChange(value);
                  if (!currentId) {
                    onInputChange(value);
                  }
                }}
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  if (!currentId && value.includes(' ')) {
                    return 'Code cannot have white space!';
                  }
                },
                rule2: (value) => {
                  if (!currentId) {
                    const found = {
                      data: brokerList.find((broker) => broker.code === value)
                    };
                    return ValidationUtil.VALIDATION_DUPLICATION(
                      found,
                      parseInt(getValues('id')),
                      'Duplicated broker code is found.'
                    );
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-code"
                required
                fullWidth
                {...field}
                disabled={currentId}
                error={error}
                value={field.value || ''}
                helperText={error?.message ?? null}
                label="Code"
                onChange={(e) => onInputChange(e.target.value, field)}
              />
            )}
            name="code"
            control={control}
          />
        </Grid>

        {!currentId && (
          <>
            <Grid item lg={6}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControlLabel
                    {...field}
                    control={<Checkbox color="primary" defaultChecked />}
                    label="Active"
                    labelPlacement="end"
                  />
                )}
                defaultValue={true}
                control={control}
                name="active"
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default BrokerCreationDetail;
