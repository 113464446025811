import CasClient, { constant } from 'react-cas-client';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LocalStore from 'utils/LocalStore';
import { AppConfig } from 'AppConfig';
import { LOAD_DATA } from 'reducers/reducersConstants';
import RouteConfig from '../../routes/RouteConfig';
import { LOGOUT } from 'features/user/userTypes';
import Auth from 'auth/Auth';

const Logout = ({ }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const casEndpoint = AppConfig.CAS_END_POING;
  const casOptions = {
    version: constant.CAS_VERSION_3_0,
    path: '/',
    validation_proxy_path: '/cas_proxy',
    pgtUrl: AppConfig.CAS_PROXY_CALLBACK
  };
  const casClient = new CasClient(casEndpoint, casOptions);

  const handleClearup = () => {
    sessionStorage.clear();
    LocalStore.clear();
    let allCookies = document.cookie.split(';');
    for (var i = 0; i < allCookies.length; i++) {
      let cookie = allCookies[i];
      let cookieName = decodeURIComponent(cookie.split("=")[0]).trim();
      if (!LocalStore.isLoginModeToken(cookieName)) {
        document.cookie =
          cookie + '=;expires=' + new Date(0).toUTCString();
      }
    }
    dispatch({
      type: LOAD_DATA,
      payload: {
        user: {
          id: null,
          username: null,
          firstname: null,
          lastname: null,
          lastLoginDt: null,
          currentApp: null,
          currentRole: null,
          currentTenantCd: null
        }
      }
    });
    dispatch({ type: LOGOUT });
  };

  useEffect(() => {
    handleClearup();

    if (Auth.getLoginMode() === Auth.LOGIN_MODE_BST) {
      casClient.logout(RouteConfig.login.path);
    }
  });

  return <>Logging out</>;
};

export default Logout;
