import React, { useState, useEffect } from 'react';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemIcon, Checkbox, ListItemText, ListSubheader, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Constant from 'common/Constant';
import * as MDActions from '../../../common/actions/CommonActions';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import * as ValidationUtil from 'utils/ValidationUtil';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import moment from 'moment-timezone';
import SubmitButton from 'common/SingleClickButton';

let defaultData = {
  yearId: null,
  startDate: null,
  endDate: null,
  networkIds: []
};

const DentalClone = (props) => {
  const { onClose, open, tenantId, target } = props;
  const years = useSelector((state) => state.MD.mdCommon.allPlanYearsForTenant);
  const [data, setData] = useState(defaultData);

  const {
    handleSubmit,
    reset,
    formState,
    setValue,
    getValues,
    control,
    watch
  } = useForm({ defaultValues: { planName: target.planName }, mode: 'onBlur' });
  const [selectedYear, setSelectedYear] = useState('');

  const watchYearType = watch('ahpmId', null);

  const watchPlanEndDate = watch('planEndDate', null);
  const watchPlanStartDate = watch('planStartDate', null);

  const handleClose = (updateData) => {
    if (updateData) {
      onClose({
        ...updateData,
        networkIds: data ? data.networkIds : [],
        targetId: target ? target.id : null
      });
    } else {
      onClose(null);
    }
  };

  const handleYearUpdate = (yearDate) => {
    if (yearDate) {
      setValue(
        'planStartDate',
        yearDate.startDate ? moment(yearDate.startDate) : null
      );
      setValue(
        'planEndDate',
        yearDate.endDate ? moment(yearDate.endDate) : null
      );
    } else {
      setValue('planStartDate', null);
      setValue('planEndDate', null);
    }
  };

  const handleNetworkSelectionChange = (value) => {
    let selected = data.networkIds;
    if (selected) {
      const currentIndex = selected.indexOf(value);
      const newChecked = [...selected];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setData({
        ...data,
        networkIds: newChecked
      });
    }
  };

  const updateDefaultDate = () => {
    let defaultSelectedNetworkIds = [];

    if (target && target.dentalNetworkValues) {
      target.dentalNetworkValues.map((item) => {
        defaultSelectedNetworkIds.push(item.id);
      });
    }

    setData({
      ...defaultData,
      networkIds: defaultSelectedNetworkIds,
      targetId: target.id
    });
  };
  useEffect(() => {
    reset({ planName: target.planName });
    if (tenantId && target && open === true) {
      updateDefaultDate();
      store.dispatch(MDActions.fetchAllPlanYears(tenantId));
    }
  }, [tenantId, open, target]);

  useEffect(() => {
    if (years && Array.isArray(years) && watchYearType) {
      const found = years.find((item) => item.id === watchYearType);
      if (found) {
        setSelectedYear(found.planYearName);
      }
    }
  }, [years, watchYearType]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') handleClose();
        }}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Clone ({target ? target.planYearName + ' - ' + target.planName : ''})
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="ahpmId"
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD,
                  validate: async (value) => {
                    let planName = getValues('planName');
                    if (planName) {
                      try {
                        const isFound = await axios.get(
                          AppConfig.API_BASE_URL +
                            `v2/plan/validate/duplication/master-id/${value}/name/${planName}`
                        );
                        return isFound && isFound.data && isFound.data.id > 0
                          ? ValidationUtil.DUPLICATION_ERROR_MSG
                          : null;
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="input-year"
                    required
                    selectOnFocus
                    clearOnBlur
                    autoSelect
                    options={years}
                    onChange={(_, data) => {
                      handleYearUpdate(data);
                      field.onChange(data ? data.id : null);
                    }}
                    getOptionLabel={(option) =>
                      option.planYearName ? option.planYearName : ''
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={error}
                        helperText={error ? error.message : null}
                        label="Plan Year"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD,
                  validate: async (value) => {
                    let ahpmId = getValues('ahpmId');
                    if (ahpmId) {
                      try {
                        const isFound = await axios.get(
                          AppConfig.API_BASE_URL +
                            `v2/plan/validate/duplication/master-id/${ahpmId}/name/${value}`
                        );
                        return isFound && isFound.data && isFound.data.id > 0
                          ? ValidationUtil.DUPLICATION_ERROR_MSG
                          : null;
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="input-name"
                    fullWidth
                    {...field}
                    required
                    error={error}
                    helperText={error ? error.message : null}
                    label="Plan Name"
                  />
                )}
                name="planName"
                control={control}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD,
                  validate: {
                    planStartDate: (value) =>
                      ValidationUtil.START_DATE(
                        selectedYear,
                        value,
                        getValues('planEndDate'),
                        true
                      )
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      clearable
                      {...field}
                      required
                      maxDate={watchPlanEndDate ? watchPlanEndDate : undefined}
                      error={error}
                      helperText={error ? error.message : null}
                      format={Constant.FORMAT_DATE}
                      value={getValues('planStartDate') ? moment.utc(getValues('planStartDate')) : null}
                      id="input-startDate"
                      label="Start Date"
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                )}
                name="planStartDate"
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD,
                  validate: {
                    planEndDate: (value) =>
                      ValidationUtil.END_DATE(
                        selectedYear,
                        getValues('planStartDate'),
                        value,
                        true
                      )
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      clearable
                      {...field}
                      required
                      minDate={watchPlanStartDate ? watchPlanStartDate : undefined}
                      format={Constant.FORMAT_DATE}
                      value={getValues('planEndDate') ? moment.utc(getValues('planEndDate')) : null}
                      error={error}
                      helperText={error ? error.message : null}
                      id="input-endDate"
                      label="End Date"
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                )}
                name="planEndDate"
                control={control}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <List>
              {target &&
              Array.isArray(target.dentalNetworkValues) &&
              target.dentalNetworkValues.length > 0 ? (
                <>
                  <ListSubheader>{`Select Network Type to clone`}</ListSubheader>
                  {target.dentalNetworkValues.map((item) => {
                    return (
                      <ListItem
                        key={item.id}
                        dense
                        button
                        onClick={() => handleNetworkSelectionChange(item.id)}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              data && data.networkIds
                                ? data.networkIds.indexOf(item.id) !== -1
                                : false
                            }
                            edge="start"
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={'email_sub_label' + item.id}
                          primary={item.networkType}
                        />
                      </ListItem>
                    );
                  })}
                </>
              ) : (
                <ListSubheader>{`No available network type to clone`}</ListSubheader>
              )}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            handleSubmit={handleSubmit(handleClose)}
            isDirty={formState.isDirty}
          />
          <Button onClick={() => handleClose(null)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DentalClone;
