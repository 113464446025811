import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as Constant from 'common/Constant';
import { useEffect, useState } from 'react';



const ManageReportDetail = ({ report, onCancel, onSubmit, isEdit }) => {
  const [categories, setCategories] = useState([
    { name: 'Smart Reports', value: 'SMART' },
    { name: 'Exclusive Reports', value: 'EXCLUSIVE' },
    { name: 'Custom Reports', value: 'CUSTOM' },
    { name: 'Explorer Reports', value: 'EXPLORER' },
    { name: 'Standard Reports', value: 'LEGACY' }
  ]);

  useEffect(() => {
    if (isEdit) {
      setCategories([...categories, { name: 'Metrics', value: 'METRICS' }]);
    }
  }, []);

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: report,
    mode: 'onBlur'
  });

  const [smartCategory, setSmartCategory] = useState(false);
  const watchCategory = watch(['category']);

  useEffect(() => {
    if (Array.isArray(watchCategory) && watchCategory == 'SMART') {
      setSmartCategory(true);
    } else {
      setSmartCategory(false);
    }
  }, [watchCategory]);

  const reportTags = useSelector((state) => state.fo.reportTags);

  const handleCancel = () => {
    reset(report);
    onCancel();
  };

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={3}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-displayText"
                fullWidth
                {...field}
                label="Display Text"
                error={error ? true : false}
                helperText={error ? error.message : null}
                labelId="label-displayText"
              />
            )}
            name="displayText"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-description"
                fullWidth
                {...field}
                label="Description"
                error={error ? true : false}
                helperText={error ? error.message : null}
                labelId="label-description"
              />
            )}
            name="description"
            control={control}
          />
        </Grid>
        {smartCategory === true ? (
          <Grid item xs={3}></Grid>
        ) : (
          <Grid item xs={3}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="input-order"
                  fullWidth
                  {...field}
                  label="Order"
                  error={error ? true : false}
                  helperText={error ? error.message : null}
                  labelId="label-order"
                  type="number"
                />
              )}
              name="order"
              control={control}
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl size="medium" error={error ? true : false}>
                <InputLabel id="label_category">Category:</InputLabel>
                <Select 
                   id="input_manageReport" 
                   key="input_manageReports" 
                   {...field}>
                  {categories.map((c) => {
                    return <MenuItem value={c.value}>{c.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText>{error ? error.message : null}</FormHelperText>
              </FormControl>
            )}
            name="category"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl size="medium">
                <InputLabel id="label_sub_category">Tag:</InputLabel>
                <Select 
                   style={{ minWidth: 120 }} 
                   fullWidth 
                   defaultValue={''} 
                   id="input_manageReportSubCategory" 
                   key="input_manageReportSubCategory" 
                   {...field}>
                  {reportTags &&
                    Array.isArray(reportTags) &&
                    reportTags.map((item, idx) => (
                      <MenuItem key={`reportTab-${idx}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            name="tagId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField 
                 id="input-src" 
                 fullWidth {...field} 
                 label="Report Source" 
                 error={error ? true : false} 
                 helperText={error ? error.message : null} 
                 labelId="label-src" />
            )}
            name="reptSource"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField 
                id="input-s3" 
                fullWidth 
                {...field} 
                label="S3 File Name" 
                error={error ? true : false} 
                helperText={error ? error.message : null} 
                labelId="label-s3" />
            )}
            name="s3Url"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={2}>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            Save
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={() => handleCancel()} color="secondary">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ManageReportDetail;
