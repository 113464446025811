import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import * as Constant from 'common/Constant';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as MDActions from '../../../common/actions/CommonActions';

const MonthlyAgg = React.memo((props) => {
  const DUPLICATION_ERROR_MSG = 'Duplicated plan is found.';

  const {
    tenantId,
    annualPlanId,
    showPlanSelection = true,
    excludedPlans
  } = props;
  const {
    register,
    control,
    watch,
    formState: { errors },
    getValues
  } = useFormContext();

  const plansForYear = useSelector(
    (state) => state.MD.mdCommon.allMedPlanDesignDatas
  );

  const [filteredPlans, setFilterPlans] = useState(plansForYear);

  const watchMonthlyAggType = watch(
    'sLCMonthlyAggType',
    Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE
  );
  const allElectionTiers = useSelector(
    (state) => state.MD.mdCommon.allElectionTiers
  );

  useEffect(() => {
    store.dispatch(MDActions.getAllElectionTiers());
  }, [tenantId]);

  useEffect(() => {
    if (annualPlanId) {
      store.dispatch(MDActions.getAllMedPlanByAnnualPlanId(annualPlanId));
    }
  }, [annualPlanId]);

  useEffect(() => {
    if (
      plansForYear &&
      excludedPlans &&
      Array.isArray(excludedPlans) &&
      Array.isArray(plansForYear) &&
      excludedPlans.length > 0
    ) {
      let filtered = plansForYear.filter(function (item) {
        return (
          _.findIndex(excludedPlans, function (o) {
            return o.planId === item.id;
          }) < 0
        );
      });
      setFilterPlans(filtered);
    } else {
      setFilterPlans(plansForYear);
    }
  }, [excludedPlans, plansForYear]);

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        {showPlanSelection && (
          <Grid item xs={12}>
            <Controller
              name="planId"
              rules={{
                required: 'This field is required',
                validate: async (value) => {
                  const slcId = getValues('stopLossContractId');
                  if (slcId) {
                    try {
                      const isFound = await axios.get(
                        AppConfig.API_BASE_URL +
                          `v2/stop-loss-contract/monthly-agg/slc/id/${slcId}/plan/id/${value}`
                      );

                      if (isFound && isFound.data === true) {
                        return DUPLICATION_ERROR_MSG;
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <FormControl fullWidth error={error}>
                    <InputLabel id="label-planName">Plan Name</InputLabel>
                    <Select
                      fullWidth
                      {...field}
                      labelId="label-planName"
                      id="input-planName"
                      defaultValue="">
                      {filteredPlans &&
                        filteredPlans.map((item) => {
                          return (
                            <MenuItem value={item.id}>{item.planName}</MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>
                      {error ? error.message : null}
                    </FormHelperText>
                  </FormControl>
                </>
              )}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <Controller
            rules={{
              required: 'This field is required'
            }}
            render={({ field }) => (
              <FormControl component="fieldset">
                <FormLabel component="legend">Agg Factor</FormLabel>
                <RadioGroup {...field}>
                  <FormControlLabel
                    value={Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE}
                    control={<Radio color="primary" />}
                    label="Composite"
                  />
                  <FormControlLabel
                    value={Constant.SLC_MONTHLY_AGG_TYPE.ELECTION_TIER}
                    control={<Radio color="primary" />}
                    label="Election tier"
                  />
                  <FormControlLabel
                    value={Constant.SLC_MONTHLY_AGG_TYPE.IND_FAM}
                    control={<Radio color="primary" />}
                    label="IND / FAM"
                  />
                </RadioGroup>
                <FormHelperText></FormHelperText>
              </FormControl>
            )}
            name="sLCMonthlyAggType"
            control={control}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row">
            {watchMonthlyAggType ===
              Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE && (
              <Fragment key="monthly_agg_composite">
                <Grid item xs={12}>
                  <Controller
                    rules={{
                      validate: (value) => {
                        return !value &&
                          getValues('sLCMonthlyAggType') ===
                            Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE
                          ? 'This field is required'
                          : null;
                      }
                    }}
                    name="aggDedComp"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="input-aggDedComp"
                        label="Composite Agg Factor"
                        type="number"
                        {...field}
                        fullWidth
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
              </Fragment>
            )}
            {watchMonthlyAggType ===
              Constant.SLC_MONTHLY_AGG_TYPE.ELECTION_TIER && (
              <Fragment key="monthly_agg_election_tier">
                {allElectionTiers.map((item, index) => (
                  <Fragment key={'monthly_agg_election_tier'}>
                    <input
                      key="monthly_agg_election_tier_id"
                      type="hidden"
                      name={`aggRates[${index}].electiontierId`}
                      defaultValue={item.id}
                      {...register(`aggRates[${index}].electiontierId`)}
                    />
                    <input
                      key="monthly_agg_election_tier_name"
                      type="hidden"
                      name={`aggRates[${index}].meta.electiontierName`}
                      defaultValue={item.etName}
                      {...register(`aggRates[${index}].meta.electiontierName`)}
                    />
                    <Grid item xs={12}>
                      <Controller
                        rules={{
                          validate: (value) => {
                            return !value &&
                              getValues('sLCMonthlyAggType') ===
                                Constant.SLC_MONTHLY_AGG_TYPE.ELECTION_TIER
                              ? 'This field is required'
                              : null;
                          }
                        }}
                        name={`aggRates.${index}.electiontierRate`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            id={'input-aggRates-tier-amount' + item.id}
                            key={index}
                            type="number"
                            label={item.etName}
                            {...field}
                            fullWidth
                            error={error}
                            helperText={error ? error.message : null}
                            defaultValue=""
                          />
                        )}
                      />
                    </Grid>
                  </Fragment>
                ))}
              </Fragment>
            )}
            {watchMonthlyAggType === Constant.SLC_MONTHLY_AGG_TYPE.IND_FAM && (
              <>
                <Grid item xs={12}>
                  <Controller
                    rules={{
                      validate: (value) => {
                        return !value &&
                          !getValues('aggFactFam') &&
                          getValues('sLCMonthlyAggType') ===
                            Constant.SLC_MONTHLY_AGG_TYPE.IND_FAM
                          ? 'One of IND/FAM is required'
                          : null;
                      }
                    }}
                    name="aggFactInd"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="input-aggFactInd"
                        label="IND"
                        type="number"
                        {...field}
                        fullWidth
                        error={error}
                        helperText={error ? error.message : null}
                        defaultValue=""
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    rules={{
                      validate: (value) => {
                        return !value &&
                          !getValues('aggFactInd') &&
                          getValues('sLCMonthlyAggType') ===
                            Constant.SLC_MONTHLY_AGG_TYPE.IND_FAM
                          ? 'One of IND/FAM is required'
                          : null;
                      }
                    }}
                    name="aggFactFam"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id="input-aggFactFam"
                        label="FAM"
                        type="number"
                        {...field}
                        fullWidth
                        error={error}
                        helperText={error ? error.message : null}
                        defaultValue=""
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

export default MonthlyAgg;
