import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const deletePdaFail = () => {
  return {
    type: Types.DELETE_PLAN_DESIGN_DATA_FAILURE
  };
};

export const deletePdaSuccess = () => {
  return {
    type: Types.DELETE_PLAN_DESIGN_DATA_SUCCESS
  };
};

export const deletePDA = (id) => {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_PLAN_DESIGN_DATA
    });
    axios
      .delete(AppConfig.API_BASE_URL + `v2/plan/id/${id}`)
      .then((m) => {
        dispatch({
          type: Types.DELETE_PLAN_DESIGN_DATA_SUCCESS
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.DELETE_PLAN_DESIGN_DATA_FAILURE
        });
      });
  };
};

export const clone = (cloneTarget) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan/clone`, cloneTarget)
      .then((m) => {
        dispatch({
          type: Types.CLONE_PLAN_DESIGN_DATA_SUCCESS
        });
      })
      .catch((err) => { });
  };
};

export const fetchAvailablePlanToClone = (tenantId, planName) => {
  return (dispatch) => {
    axios
      .get(
        AppConfig.API_BASE_URL +
        `v2/plan/annual-health-plan/available?planName=${planName}`
      )
      .then((m) => {
        dispatch({
          type: Types.FETCH_AVAILABLE_PLAN_YEAR_TO_CLONE_SUCCESS,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const save = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan`, data)
      .then((m) => {
        dispatch({
          type: Types.SAVE_PLAN_DESIGN_DATA_SUCCESS,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const saveNetwork = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan/network`, data)
      .then((m) => {
        dispatch({
          type: Types.SAVE_PLAN_DESIGN_DATA_SUCCESS,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const deleteNetwork = (networkId) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `v2/plan/network/${networkId}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const saveDentalNetwork = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/dental/network`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const cloneDental = (cloneTarget) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan/dental/clone`, cloneTarget)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const deleteDentalNetwork = (networkId) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `v2/dental/network/id/${networkId}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const deleteVisonNetwork = (networkId) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `v2/vision/network/id/${networkId}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const saveVisionNetwork = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/vision/network`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH,
          data: m.data
        });
      })
      .catch((err) => {
        console.log('error' + err);
      });
  };
};

export const cloneVision = (cloneTarget) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan/vision/clone`, cloneTarget)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const deleteByIds = (ids) => {
  return (dispatch) => {
    dispatch({
      type: Types.DELETE_PLAN_DESIGN_DATA
    });
    axios
      .post(AppConfig.API_BASE_URL + `v2/plan/delete`, ids)
      .then((m) => {
        dispatch([
          {
            type: Types.DELETE_PLAN_DESIGN_DATA_SUCCESS
          },
          {
            type: AppActionTypes.APP_REST_CALL_SUCESSED,
            payload: 'Deleted Successfully!'
          }
        ]);
      })
      .catch((err) => {
        dispatch({
          type: Types.DELETE_PLAN_DESIGN_DATA_FAILURE
        });
      });
  };
};
