import { Model, attr } from 'redux-orm';

const defaultAttributes = {
  name: 'Dummy Role'
};

export default class Role extends Model {
  static modelName = 'Role';

  static fields = {
    id: attr(),
    name: attr()
  };

  static parse(data) {
    return this.upsert(data);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherView) {
    this.update(otherView.ref);
  }
}
