import RenewalPointOfContact from './RenewalPointOfContact';
import UploadedFileDetail from './UploadedFileDetail';

const AnnualPlanRenewalDetailPanel = ({ rowData }) => {
  return (
    <>
      <RenewalPointOfContact rowData={rowData} />
      <UploadedFileDetail rowData={rowData} />
    </>
  );
};

export default AnnualPlanRenewalDetailPanel;
