import {
  Box,
  Grid,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as Action from './actions/action';
import { useEffect, useState } from 'react';
import { AppConfig } from 'AppConfig';

const BACKGROUND_COLOR = '#4051B5';

const useStyles = makeStyles((theme) => ({
  sideNav: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    '&:hover .menuItem': {
      color: BACKGROUND_COLOR
    },
    '&:hover .icon': {
      color: BACKGROUND_COLOR
    },
    '&:hover .typography': {
      color: BACKGROUND_COLOR
    },
    maxWidth: AppConfig.STANDARD_REPORT_SIDE_NAV_WIDTH
  },
  menuItem: {
    justifyContent: 'start',
    alignContent: 'start',
    alignItems: 'start',
    margin: 0,
  },
  icon: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    paddingTop: 3
  },
  count: {
    justifyContent: 'end',
    alignText: 'right',
  },
  listItemText: {
    whiteSpace: 'nowrap',
    maxWidth: AppConfig.STANDARD_REPORT_MENU_ITEM_TEXT_WIDTH,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuItemsWrapper: {
    alignItems: 'center'
  }
}));

const ReportNavItem = ({ item, index, filter }) => {
  const classes = useStyles();
  const currentMenu = useSelector((state) => state.customReport.currentMenu);
  const workbooks = useSelector((state) => state.customReport.workbooks);
  const customs = useSelector((state) => state.fo.customs);
  const [total, updateTotal] = useState('');

  useEffect(() => {
    // length of subset of reports in project with matching project id and exist in customs
    const count = workbooks.filter((wb) => wb.projectId == item.id
      && customs.some((report) => report.reptSource == wb.reptSource)).length;
    updateTotal(count);
  }, [item, workbooks]);

  return (
    <Box className={classes.sideNav}>
      <MenuItem
        disableGutters={true}
        className={classes.menuItem}
        key={`report-nav-item-${index}`}
        onClick={() => {
          store.dispatch(Action.setCurrentMenuItem({ name: item?.name, key: item?.id }));
          filter(item.id);
        }}
        selected={currentMenu?.name === item?.name}>
        <Grid container spacing={2} className={classes.menuItemsWrapper}>
          <Grid item xs={2} className={classes.icon}>{/* placeholder for future icon enhancement*/}</Grid>
          <Grid item xs={8}>
            <Tooltip title={item?.name}>
              <div className={classes.listItemText}>{item?.name}</div>
            </Tooltip>
          </Grid>
          <Grid item xs={2} className={classes.count}>
            <ListItemText>{total}</ListItemText>
          </Grid>
        </Grid>
      </MenuItem>
    </Box>
  );
};

export default ReportNavItem;
