import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormHelperText,
  Grid,
  TextField,
  IconButton,
  Typography
} from '@material-ui/core';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import * as ValidationUtil from 'utils/ValidationUtil';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const TagManagementDialog = (props) => {
  const { onClose, open, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const {
    reset,
    control,
    getValues,
    setValue,
    register,
    formState: { errors }
  } = methods;
  const [selectedImage, setSelectedImage] = useState(null);

  const deleteImage = () => {
    setValue('logoFile', null);
    setSelectedImage(null);
  };

  const handleClose = (data) => {
    setSelectedImage(null);
    onClose(data);
  };

  const onSubmit = (data) => {
    setSelectedImage(null);
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Tag Details
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} bgcolor="primary.main">
              <Grid
                item
                xs={12}
                style={{ marginBottom: '10px', marginTop: '20px' }}>
                <Controller
                  name="code"
                  control={control}
                  rules={{
                    required: "Tag code can't be empty.",
                    validate: async (value) => {
                      if (value) {
                        if (!/^[A-Za-z0-9_]*$/.test(value))
                          return 'Only letters, numbers and underscore allowed.';
                        const found = await axios.get(
                          AppConfig.FO_INITIAL_URL +
                            `/whitelabel-tag/tag-code?code=${value}`
                        );
                        return ValidationUtil.VALIDATION_DUPLICATION(
                          found,
                          parseInt(getValues('id')),
                          'Duplicated Tag Code is Found.'
                        );
                      }
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      value={field.value ?? ''}
                      error={error}
                      helperText={error ? error.message : null}
                      id={'input-tag-code'}
                      label="Tag Code"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginBottom: '10px', marginTop: '20px' }}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: "Description can't be empty."
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      value={field.value ?? ''}
                      error={error}
                      helperText={error ? error.message : null}
                      id={'input-description-code'}
                      label="Description"
                    />
                  )}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ minWidth: '300px', padding: '10px' }}>
                <Grid item>
                  <Typography color="primary">
                    Upload default image for this Tag
                  </Typography>
                </Grid>

                <Grid item container spacing={2}>
                  {selectedImage && (
                    <>
                      <Grid item xs={10}>
                        <Grid
                          container
                          spacing={0}
                          alignItems="center"
                          justify="center">
                          <img
                            alt="logo-preview"
                            width="180"
                            height="38"
                            src={URL.createObjectURL(selectedImage)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => deleteImage()}
                          component="span">
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <input
                      type="file"
                      name="input-image"
                      accept="image/png"
                      {...register('logoFile', {
                        validate: {
                          lessThan1m: (value) => {
                            if ('size' in value) {
                              if (value[0].size > 1024000) {
                                return 'Max allowed file size is 1 mb.';
                              }
                            }
                          }
                        }
                      })}
                      onChange={(event) => {
                        setSelectedImage(event.target.files[0]);
                      }}
                    />
                    <FormHelperText error>
                      {errors?.logoFile && errors?.logoFile?.message}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary"
              disabled={!methods.formState.isDirty}>
              Save
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default TagManagementDialog;
