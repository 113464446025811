import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';
import { downloadFile } from '../../../util/CommonUtil';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const deleteById = (id) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `v2/discretionary/id/${id}`)
      .then((m) => {
        dispatch({
          type: Types.DELETE_SUCCESS
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.DELETE_SUCCESS
        });
      });
  };
};

export const save = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/discretionary`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const getCoverageCategories = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + `v2/coverage`)
      .then((m) => {
        dispatch({
          type: Types.GET_COVERAGE_CATEGORIES,
          payload: m.data ? m.data : []
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const getCoverageNamesById = (id) => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + `v2/coverage-name/category/id/${id}`)
      .then((m) => {
        dispatch({
          type: Types.GET_COVERAGE_NAMES,
          payload: m.data ? m.data : []
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const cloneByYear = (data) => {
  const { ahpmIdFrom, ahpmIdTo } = data;

  return (dispatch) => {
    axios
      .post(
        AppConfig.API_BASE_URL +
        `v2/discretionary/clone/from/${ahpmIdFrom}/to/${ahpmIdTo}`
      )
      .then((m) => {
        const msg = `${m.data.createdRecords} Records Created`;
        dispatch({
          type: AppActionTypes.APP_REST_CALL_SUCESSED,
          payload: msg
        });
      })
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const validateFile = (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return (dispatch) => {
    axios
      .post(
        AppConfig.API_BASE_URL +
        `v2/discretionary/load/validate?generateErrorLog=false`,
        formData
      )
      .then((res) => {
        dispatch({
          type: Types.VALIDATE_SUCCESS,
          payload: res.data
        });
      });
  };
};

export const loadFile = (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/discretionary/load/file`, formData)
      .then((res) => {
        dispatch({
          type: Types.LOAD_SUCCESS
        });
      });
  };
};

export const downloadErrorLogs = (file) => {
  const payload = new FormData();
  payload.append('file', file);
  return (dispatch) => {
    axios({
      url: AppConfig.API_BASE_URL + `v2/discretionary/load/validate/file`,
      method: 'POST',
      data: payload,
      responseType: 'blob'
    }).then((res) => {
      const url = URL.createObjectURL(res.data);
      downloadFile(url, 'ErrorLogs.txt');
      dispatch({
        type: Types.DOWNLOAD_ERROR_LOGS,
        payload: url
      });
    });
  };
};
