import * as Types from '../actions/types';
import _ from 'lodash';

let initialState = {
  defaultSettings: [],
  featureReports: []
};

export default function defaultSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_FEATURE_REPORTS:
      return {
        ...state,
        featureReports: _.orderBy(action.payload, ['feature'], ['asc'])
      };
    case Types.FETCH_REPORT_DEFAULT_SETTINGS:
        return {
          ...state,
          defaultSettings: action.payload
        }
    default:
      return {
        ...state
      };
  }
}
