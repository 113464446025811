import { Model, attr, fk } from 'redux-orm';

const defaultAttributes = {
  tabDesc: 'Default menu'
};

export default class Menu extends Model {
  static modelName = 'Menu';

  static fields = {
    id: attr(),
    category: attr(),
    parentName: attr(),
    reptSource: attr(),
    helpVideoId: attr(),
    displayText: attr(),
    description: attr()
  };

  static parse(data) {
    return this.upsert(data);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherView) {
    this.update(otherView.ref);
  }
}
