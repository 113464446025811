import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import create from 'zustand';

const useConfirmDialogStore = create((set) => ({
  title: 'Confirmation',
  message: '',
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
  onClose: undefined,
  cancelText: '',
  confirmText: ''
}));

export const confirmDialog = (
  onSubmit,
  message = 'Do you really want to delete all the data?',
  title = 'Confirmation',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  onClose
) => {
  useConfirmDialogStore.setState({
    message,
    onSubmit,
    title,
    cancelText,
    confirmText,
    onClose
  });
};

const ConfirmDialog = () => {
  const { title, message, onSubmit, close, cancelText, confirmText, onClose } =
    useConfirmDialogStore();
  return (
    <Dialog open={Boolean(onSubmit)} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            if (onClose) {
              onClose();
            }
            close();
          }}>
          {cancelText}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
