import * as Constant from 'common/Constant';
import moment from 'moment-timezone';

export const DUPLICATION_ERROR_MSG = 'Same plan name for plan year is found.';

export const YEAR_REGEXP = /^\d{4}(-\d{4}){0,1}$/;

export const START_DATE = (
  planyearName,
  sDate,
  eDate,
  skipEmptyAndFormat = false
) => {
  if (!skipEmptyAndFormat && !sDate) {
    return 'Start date cannot be empty';
  } else {
    let startDate = moment(sDate);
    if (!skipEmptyAndFormat) {
      startDate = moment(sDate, Constant.FORMAT_DATE, true);
    }
    if (!skipEmptyAndFormat && !startDate.isValid()) {
      return `invalid Date format. please enter start date in (${Constant.FORMAT_DATE})`;
    } else {
      if (planyearName) {
        const [planStartYear, planEndYear] = planyearName.split('-');
        const startYear = startDate.year();

        if (planEndYear) {
          if (startYear < planStartYear || startYear > planEndYear) {
            return 'The start date must be within the plan year specified';
          }
        } else if (planStartYear != startYear) {
          return 'The start date must be within the plan year specified';
        }
      }

      if (eDate) {
        let end = moment(eDate);
        if (!skipEmptyAndFormat) {
          end = moment(eDate, Constant.FORMAT_DATE);
        }
        if (startDate.isAfter(end)) {
          return 'Start date cannot be greater than end date';
        }
      }
    }
  }
  return null;
};

export const END_DATE = (
  planyearName,
  sDate,
  eDate,
  skipEmptyAndFormat = false
) => {
  if (!skipEmptyAndFormat && !eDate) {
    return 'End date cannot be empty';
  } else {
    let endDate = moment(eDate);
    if (!skipEmptyAndFormat) {
      endDate = moment(eDate, Constant.FORMAT_DATE);
    }
    if (!skipEmptyAndFormat && !endDate.isValid()) {
      return `invalid Date format. please enter date in (${Constant.FORMAT_DATE})`;
    } else {
      const endYear = endDate.year();
      if (planyearName) {
        const [planStartYear, planEndYear] = planyearName.split('-');
        if (planEndYear) {
          if (endYear > planEndYear || endYear < planStartYear) {
            return 'The end date must be within the plan year specified';
          }
        } else {
          if (planStartYear != endYear) {
            return 'The end date must be within the plan year specified';
          }
        }
      }
      let start = moment(sDate);
      if (!skipEmptyAndFormat) {
        start = moment(sDate, Constant.FORMAT_DATE);
      }
      if (endDate.isBefore(start)) {
        return 'End Date cannot be less than start date';
      }
    }
  }
};

export const IS_WHOLE_NUMBER = (input) => {
  if (IS_NUMBER(input)) {
    let parsedNum = parseFloat(input);

    if (parsedNum || parsedNum === 0) {
      return parsedNum >= 0 && parsedNum % 1 === 0 ? true : false;
    }
  }

  return false;
};

export const IS_NUMBER = (input) => {
  let parsed = parseFloat(input);
  return !isNaN(parsed) && typeof parsed === 'number' && input == parsed;
};

export const validateTheStringOrNumberInput = (input) => {
  if (IS_NUMBER(input)) {
    if (IS_WHOLE_NUMBER(input) !== true) {
      return `Should be either String or Whole Number`;
    }
  }
};

export const validateDedIn = (input, dedFam, maxOutOfPcketIn, validation) => {
  let validationMsg = validateTheStringOrNumberInput(input);

  if (validationMsg) {
    return validationMsg;
  }

  if (
    validation &&
    !isNaN(dedFam) &&
    !isNaN(input) &&
    input &&
    input !== '' &&
    dedFam &&
    dedFam !== ''
  ) {
    if (parseFloat(input) > parseFloat(dedFam)) {
      return 'Cannot be greater than Deductible-Family';
    } else if (
      maxOutOfPcketIn &&
      parseFloat(input) > parseFloat(maxOutOfPcketIn)
    ) {
      return 'Cannot be greater than Max Out Of Pocket Inividual';
    }
  }
};

export const validateDedFam = (input, dedFam, validation) => {
  let validationMsg = validateTheStringOrNumberInput(dedFam);

  if (validationMsg) {
    return validationMsg;
  }

  if (
    validation &&
    !isNaN(dedFam) &&
    !isNaN(input) &&
    input &&
    input !== '' &&
    dedFam &&
    dedFam !== ''
  ) {
    if (parseFloat(dedFam) < parseFloat(input)) {
      return 'Cannot be less than Deductible Individual';
    }
  }
};

export const validateMaxOutOfPocketIn = (
  input,
  pocketFam,
  dedIn,
  validation
) => {
  let validationMsg = validateTheStringOrNumberInput(input);

  if (validationMsg) {
    return validationMsg;
  }

  if (
    validation &&
    !isNaN(input) &&
    !isNaN(pocketFam) &&
    input &&
    input !== '' &&
    pocketFam &&
    pocketFam !== ''
  ) {
    if (parseFloat(input) > parseFloat(pocketFam)) {
      return 'Cannot be greater than Max Out Of Pocket Family';
    } else if (parseFloat(input) < parseFloat(dedIn)) {
      return 'Cannot be less than Deductible individual';
    }
  }
};

export const validateMaxOutOfPocketFam = (input, pocketFam, validation) => {
  let validationMsg = validateTheStringOrNumberInput(pocketFam);

  if (validationMsg) {
    return validationMsg;
  }

  if (
    validation &&
    !isNaN(input) &&
    !isNaN(pocketFam) &&
    input &&
    input !== '' &&
    pocketFam &&
    pocketFam !== ''
  ) {
    if (parseFloat(pocketFam) < parseFloat(input)) {
      return 'Cannot be less than Max Out Of Pocket Individual';
    }
  }
};

export const YEAR_INPUT = (data) => {
  if (!data.match(YEAR_REGEXP)) {
    return 'Invalid plan year name specified';
  }
  const [planStartYear, planEndYear] = data.split('-');
  if (planEndYear) {
    if (planStartYear >= planEndYear) {
      return 'Invalid plan year ! plan start year must be less than plan end year';
    }
  }
};

export const VALIDATION_DUPLICATION = (
  existingRecord,
  targetId,
  DUPLICATION_ERROR_MSG = 'Duplicated Record is Found',
  targetProperty = 'id'
) => {
  if (
    existingRecord &&
    existingRecord.data &&
    targetId > 0 &&
    targetId === existingRecord.data.id
  ) {
    return null;
  }
  return existingRecord.data && existingRecord.data[targetProperty]
    ? DUPLICATION_ERROR_MSG
    : null;
};

export const IN_RANGE = (
  sDate,
  eDate,
  target,
  errorMsg = 'Date is not in range'
) => {
  let startDate = moment(sDate);
  let end = moment(eDate);
  let targetDate = moment(target);

  if (startDate.isValid && end.isValid && targetDate.isValid) {
    if (!targetDate.isBetween(startDate, end, null, '[]')) {
      return errorMsg;
    }
  }
  return null;
};

export const IS_INT = (value) => {
  return (
    !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10))
  );
};

export const VALIDATE_START_END_DATE = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  if (start.isValid && end.isValid) {
    if (end.isBefore(start)) {
      return 'Start Date must come before End Date';
    }
  }
};

export const VALIDATE_DATE = (input, DEFAULT_ERROR = 'Invalid Date') => {
  return moment(input).isValid() ? null : DEFAULT_ERROR;
};
