export const PREFIX = 'MD-PLAN-RATE_';

export const DELETE_PLAN_RATE_SUCCESS =
  PREFIX + 'SAVE_PLAN_DESIGN _DATA_SUCCESS';

export const SAVE_PLAN_RATE_SUCCESS = PREFIX + 'SAVE_PLAN_RATE_SUCCESS';

export const BULK_MODIFY_RATE_SUCCESS = PREFIX + 'BULK_MODIFY_RATE_SUCCESS';

export const SEARCH_REFRESH = PREFIX + 'SEARCH_REFRESH';

export const FETCH_ALL_UNIQUE_NAME_PLAN_DESIGN_BY_TENANT_ID_SUCCESS =
  PREFIX + 'FETCH_ALL_UNIQUE_NAME_PLAN_DESIGN_BY_TENANT_ID_SUCCESS';
