import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const fetchRoleList = (id) => {
  return (dispatch) => {
    axios.get(AppConfig.IAM_BACKEND_URL + `/role/list`).then((m) => {
      dispatch({
        type: Types.FETCH_ROLE_LIST,
        data: m.data
      });
    });
  };
};

export const fetchPermissionList = (id) => {
  return (dispatch) => {
    axios.get(AppConfig.IAM_BACKEND_URL + `/permission/list`).then((m) => {
      dispatch({
        type: Types.FETCH_PERMISSION_LIST,
        data: m.data
      });
    });
  };
};

export const saveRolePermissionComb = (dto) => {
  return (dispatch) => {
    axios.post(AppConfig.IAM_BACKEND_URL + `/role/all`, dto).then((m) => {
      dispatch({
        type: Types.SEARCH_REFRESH
      });
    });
  };
};

export const deleteRolePermissionComb = (roleId) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.IAM_BACKEND_URL + `/role/role/${roleId}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteSingleRolePermission = (roleId, permissionId) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.IAM_BACKEND_URL +
        `/role-permission/role/${roleId}/permission/${permissionId}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};
