import * as Types from '../actions/types';
import * as Actions from '../actions/action';

let initialState = {
  shouldRefresh: Actions.SHOULD_REFRESH_INIT_VALUE
};

export default function annualPlanReducer(state = initialState, action) {
  switch (action.type) {
    case Types.REFRESH_CONTENT:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    default:
      return state;
  }
}
