import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import EmployeClassDetail from './detail';
import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from 'common/SingleClickButton';

const ElectionTierDetailDialog = React.memo((props) => {
  const { onClose, open, tenantId, target, existingData } = props;

  const methods = useForm({
    defaultValues: {
      et: [{ ...target }]
    },
    mode: 'onSubmit'
  });
  const { reset } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset({ et: [{ ...target, toAdd: true }] });
  }, [tenantId, open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleClose();
          }}
          maxWidth="xl">
          <DialogTitle id="dialog-planDesignDataDetail">
            {target && target.id > 0 ? 'Edit' : 'New'}
          </DialogTitle>
          <DialogContent dividers>
            <EmployeClassDetail
              tenantId={tenantId}
              existingData={existingData}
              target={target}
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
});

export default ElectionTierDetailDialog;
