import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import { v4 as uuid } from 'uuid';

const UserCreationDialogDetail = ({ applications }) => {
  const { control, setValue, watch } = useFormContext();

  const appRoleList = useSelector((state) => state.tenantAdmin.roleList);
  const tenantList = useSelector((state) => state.tenantAdmin.tenantList);
  const loggedInUser = useSelector((state) => state.user);

  const [defaultTenantList, setDefaultTenantList] = useState([]);

  const [filteredRoleList, setFilteredRoleList] = useState([]);
  const [applicationRoleMap, setApplicationRoleMap] = useState([]);
  const watchApplicationId = watch('applicationId', '');

  const externalUserMessage = 'User without Site Administrator role';

  /**
   * Initialize application - assignable roles map.
   * Assignable role means intersection of roles of application and roles of user
   * can assign.
   *
   * @returns
   */
  const initializeAppToRolesMap = () => {
    let tempMap = {};
    // Application obj with list of all roles for application
    appRoleList.forEach((app) => {
      // All Role Ids which user can assign (across all applications)
      tempMap[app.id] = app.roles;
    });
    return tempMap;
  };

  const setTenantList = () => {
    return tenantList
      .filter((a) => a.name)
      .map((a) => ({ id: a.id, name: a.name, label: a.name }))
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0));
  };

  useEffect(() => {
    setApplicationRoleMap(initializeAppToRolesMap());
  }, [appRoleList]);

  useEffect(() => {
    setDefaultTenantList(setTenantList());
  }, []);

  useEffect(() => {
    setValue('role', null);
    // set which roles are visible to user based on application and user group
    if (watchApplicationId)
      setFilteredRoleList(applicationRoleMap[watchApplicationId]);
  }, [watchApplicationId]);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-firstName"
                required
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="First Name"
              />
            )}
            name="firstName"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-lastName"
                required
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="Last Name"
              />
            )}
            name="lastName"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  if (value.includes(' ')) {
                    return 'Username cannot have white space!';
                  }
                },
                rule2: async (value) => {
                  const found = await axios.get(
                    AppConfig.IAM_BACKEND_URL + `/user/username/${value}/`
                  );
                  if (found && found.data && found.data.userId) {
                    return 'Already exists!';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-username"
                required
                fullWidth
                inputProps={{ maxLength: 20 }}
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="Username"
              />
            )}
            name="username"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  var email = value;
                  const regEx = /^\S+@\S+\.\S+$/;
                  if (!regEx.test(email)) {
                    return 'Invalid email address';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-emailAddress"
                required
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="Email Address"
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="applicationId"
            control={control}
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-application-input">
                    Application
                  </InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    name="applicationId"
                    labelId="label-applicationId"
                    defaultValue=""
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    id="input-applicationId">
                    {applications &&
                      Array.isArray(applications) &&
                      applications.map((item) => {
                        return (
                          <MenuItem key={'key-' + uuid()} value={item?.id}>
                            {item.description}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-role"
                options={filteredRoleList}
                getOptionLabel={(option) => option.description}
                renderOption={(option) => (
                  <React.Fragment>{option.description}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Role"
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
                value={
                  filteredRoleList.find(
                    (option) => option.id === field.value
                  ) || null
                }
                onChange={(_, data) => field.onChange(data?.id)}
              />
            )}
            name="role"
            control={control}
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                multiple
                id="input-company"
                options={defaultTenantList}
                getOptionLabel={(option) => option.label}
                renderOption={(option) => (
                  <React.Fragment>{option.label}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tenant(s)"
                    inputProps={{ ...params.inputProps }}
                    error={error}
                    helperText={error ? error.message : null}
                  />
                )}
                onChange={(_, data) => field.onChange(data)}
              />
            )}
            name="company"
            control={control}
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserCreationDialogDetail;
