import * as Types from '../action/types';

let initialState = {
  applicationList: [],
  companyList: [],
  shouldRefresh: false,
  tenantUserSelectedBrokerIds: [],
  tenantUserSelectedCompanyIds: [],
  tenantAdminUserGroupId: null
};

export default function adminUserAccessReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_APPLICATION_LIST:
      return {
        ...state,
        applicationList: action.data
      };

    case Types.FETCH_TENANT_LIST:
      return {
        ...state,
        tenantList: action.data
      };

    case Types.FETCH_USER_TENANT_ROLE_DATA:
      return {
        ...state,
        userTenantRoleData: action.data
      };

    case Types.USER_ACCESS_CREATE_SUCCESS:
    case Types.USER_UPDATE_SUCCESS:
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.FETCH_TENANT_USER_PERMISSION:
      let brokerIds = [];
      let companyIds = [];
      let tenantAdminUserGroupId = null;

      if (action.data) {
        let userBrokerAdminAccess = action.data.userBrokerAdminAccess;
        tenantAdminUserGroupId = action.data.tenantAdminUserGroupId;

        if (userBrokerAdminAccess && Array.isArray(userBrokerAdminAccess)) {
          userBrokerAdminAccess.forEach((item) => {
            if (item.brokerId) {
              brokerIds.push(item.brokerId);
            }

            if (item.companyId) {
              companyIds.push(item.companyId);
            }
          });
        }
      }
      return {
        ...state,
        tenantUserSelectedBrokerIds: brokerIds,
        tenantUserSelectedCompanyIds: companyIds,
        tenantAdminUserGroupId: tenantAdminUserGroupId
      };
    default:
      return state;
  }
}
