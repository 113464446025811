import IframeComponent from '../features/landing/navigation/externalIframe';
import Login from '../features/login/login';
import Master from '../features/masterData/master';
import Ranger from '../features/ranger/ranger';
import Logout from '../features/logout/logout';
import WhiteLabelingManagement from '../features/admin/white-labeling/index';
import VendorLabelingTable from '../features/admin/vendor-labeling/index';
import NewHome from 'features/landing/home/newhome';
import AdminNavigator from '../features/admin/navigation/adminNavigator';
import TenantTable from '../features/user/components/tenantTable';
import FrontOffice from 'features/frontoffice/index';
import LookupTableNav from '../features/masterData/components/lookup/lookupTableNav';
import TenantAdminTable from '../features/admin/tenantAdmin/tenantAdminTable';
import UserProfile from '../features/userProfile/userProfile';
import ReportDefaultSetting from '../features/frontoffice/defaultSetting';
import TenantManagement from '../features/admin/tenantManagement/tenantManagement';
import ClientPortalNavigator from '../features/clientPortal/clientPortalNavigator';
import ClientPortalQuestionary from '../features/clientPortal/components/clientPortalQuestionary'
import ForgotPassword from '../features/login/forgot';
import SSO from '../features/sso/ssoRedirect';
import { ResetPassword } from '../features/login/reset';

export default {
  externalIframe: {
    title: 'reports',
    path: '/reports/report',
    component: IframeComponent
  },
  newHome: {
    title: 'Home',
    path: '/',
    component: NewHome
  },
  frontOffice: {
    title: 'Home',
    path: '/bi',
    component: FrontOffice
  },
  login: {
    title: 'Login',
    path: '/login',
    component: Login
  },
  master: {
    title: 'Master Portal',
    path: '/masterData/main',
    component: Master
  },
  lookup: {
    title: 'Lookup Tables',
    path: '/masterData/lookup',
    component: LookupTableNav
  },
  ranger: {
    title: 'Ranger',
    path: '/ranger/ranger',
    component: Ranger
  },
  reset: {
    title: 'Password Reset',
    path: '/reset',
    component: ResetPassword
  },
  forgot: {
    title: 'Forgot Password',
    path: '/forgot',
    component: ForgotPassword
  },
  adminPage: {
    title: 'Admin',
    path: '/admin',
    component: AdminNavigator
  },
  clientTable: {
    title: 'Client Table',
    path: '/clients',
    component: TenantTable
  },
  logout: {
    title: 'Logout',
    path: '/logout',
    component: Logout
  },
  whiteLabeling: {
    title: 'Logo Management',
    path: '/admin/white-labeling',
    component: WhiteLabelingManagement
  },
  vendorLabeling: {
    title: 'Vendor Logo Management',
    path: '/admin/vendor-labeling',
    component: VendorLabelingTable
  },
  tenantAdmin: {
    title: 'Tenant Admin',
    path: '/admin/tenant-admin',
    component: TenantAdminTable
  },
  tenantManagement: {
    title: 'Tenant Management',
    path: '/admin/management',
    component: TenantManagement
  },
  clientPortal: {
    title: 'Client Portal',
    path: '/client-portal/onboarding',
    component: ClientPortalNavigator
  },
  clientPortalQuestionary: {
    title: 'Client Portal Questionary',
    path: '/client-portal/questionary',
    component: ClientPortalQuestionary
  },
  userProfile: {
    title: 'User Profile',
    path: '/profile',
    component: UserProfile
  },
  reportDefaultSetting: {
    title: 'Home',
    path: '/report/default-setting',
    component: ReportDefaultSetting
  },
  oktaSso: {
    title: 'SSO',
    path: '/sso',
    component: SSO
  }
};
