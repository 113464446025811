import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import PlanRateDetail from './detail';
import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from 'common/SingleClickButton';

const PlanRateDetailDialog = React.memo((props) => {
  const { onClose, open, tenantId, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleClose();
          }}
          maxWidth="lg">
          <DialogTitle id="dialog-planRateDetail">
            {target && target.id > 0 ? 'Edit' : 'New'}
          </DialogTitle>
          <DialogContent dividers>
            <PlanRateDetail
              tenantId={tenantId}
              isNew={!(target && target.id > 0)}
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
});

export default PlanRateDetailDialog;
