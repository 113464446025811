import * as Types from '../actions/types';
import * as Actions from '../actions/action';

let initialState = {
  shouldRefresh: Actions.SHOULD_REFRESH_INIT_VALUE,
  data: []
};

export default function divisionReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ALL_DATA_BY_TENANT_ID_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    default:
      return state;
  }
}
