import * as Types from '../actions/types';
import * as Constant from 'common/Constant';

let initialState = {
  data: [],
  currentMenu: {name: Constant.ALL, key: Constant.ALL},
  currentTags: [],
  workbooks: [],
  projects: []
};

export default function customReportReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_CURRENT_SELECTED_MENU:
      return {
        ...state,
        currentMenu: action.data
      };
    case Types.SET_CURRENT_TAGS:
      return {
        ...state,
        currentTags: action.data
      };
    case Types.SET_CUSTOM_WORKBOOKS:
      return {
        ...state,
        workbooks: action.data
      };
    case Types.SET_CUSTOM_PROJECTS:
      return {
        ...state,
        projects: action.data
      };
    case Types.SET_DATA:
      if (Array.isArray(action.data)) {
        return {
          ...state,
          data: action.data
        }
      } else {
        const newData = [];
        newData.push(action.data);
        return {
          ...state,
          data: newData
        }
      }
    default:
      return {
        ...state
      };
  }
}
