import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Grid, Switch, FormControlLabel, FormControl, InputLabel, Select, MenuItem, FormHelperText, Typography, makeStyles, Button } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment-timezone';
import * as Constant from 'common/Constant';
import { store } from 'store/store';
import * as MDActions from '../../../common/actions/CommonActions';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as ValidationUtil from 'utils/ValidationUtil';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import MonthlyAgg from './monthlyAgg';
import * as CommonUtil from 'features/masterData/util/CommonUtil';
import PolicyExclusionListDialog from './temp/policyExclusionListDialog';
import LaserListDialog from './temp/laserListDialog';
import AggListDialog from './temp/aggListDialog';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(() => ({
  panel: {
    borderLeft: '1px lightgrey solid'
  },
  button: {
    marginTop: '.5rem'
  }
}));

const StopLossContractDetail = (props) => {
  const DEFAULT_CONTRACT_BASIS = '12/12';

  useEffect(() => {
    getPlanNames();
    initDatePickers();
    initDefaults();
  }, []);

  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanYears(tenantId));
  }, [tenantId]);

  const domesticReImbursementOptions = [];
  for (let i = 0; i <= 100; i += 5) {
    let obj = {
      label: i,
      id: i
    };
    domesticReImbursementOptions.push(obj);
  }

  const { tenantId, isModified, targetId } = props;

  const { control, watch, formState, setValue, getValues } = useFormContext();

  const watchSlcEndDate = watch('endDate', null);
  const watchSlcStartDate = watch('startDate', null);
  const watchIslAppliedToAll = watch('islAppliedToAll', false);
  const watchAslAppliedToAll = watch('aslAppliedToAll', false);
  const watchPlanYearId = watch('apdId', '');
  const watchShareExpectedClaims = watch('shareExpectedClaims', true);

  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );

  const [totalPlanName, setTotalPlanNames] = useState([]);
  const [currentPlanNames, setCurrentPlanNames] = useState([]);
  const [currentSelectedIsl, setCurrentSelectedIsl] = useState([]);
  const [currentSelectedAsl, setCurrentSelectedAsl] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [policyExclusionOpen, setPolicyExclusionOpen] = useState(false);
  const [laserOpen, setLaserOpen] = useState(false);
  const [aslOpen, setAslOpen] = useState(false);
  const [showAsl, setShowAsl] = useState(false);
  const [showIsl, setShowIsl] = useState(true);
  const [totalPlanYearsInMap, setTotalPlanYearsInMap] = useState({});
  const [annualPlanId, setAnnualPlanId] = useState(null);

  const styles = useStyles();

  const calculateContractBasisIsl = (start_date, end_date, contract_basis) => {
    const map = CommonUtil.CALCULATE_CONTRACT_BASIS_DATE_PERIOD(
      start_date,
      end_date,
      contract_basis
    );

    setValue(
      'runInStartDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_IN_START_DATE]
    );
    setValue('runInEndDate', map[CommonUtil.CONTRACT_BASIS_RUN_IN_END_DATE]);
    setValue(
      'runOutStartDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_OUT_START_DATE]
    );
    setValue('runOutEndDate', map[CommonUtil.CONTRACT_BASIS_RUN_OUT_END_DATE]);
  };

  const calculateContractBasisAsl = (start_date, end_date, contract_basis) => {
    const map = CommonUtil.CALCULATE_CONTRACT_BASIS_DATE_PERIOD(
      start_date,
      end_date,
      contract_basis
    );

    setValue(
      'aslStartDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_IN_START_DATE] ||
        map[CommonUtil.CONTRACT_BASIS_RUN_OUT_START_DATE]
    );
    setValue(
      'aslEndDate',
      map[CommonUtil.CONTRACT_BASIS_RUN_IN_END_DATE] ||
        map[CommonUtil.CONTRACT_BASIS_RUN_OUT_END_DATE]
    );
  };

  const getPlanNames = async () => {
    const planSearch = await axios.get(AppConfig.API_BASE_URL + `v2/plan/med`);
    setTotalPlanNames(planSearch.data);
    setIsLoading(false);
  };

  const handlePlanYearChange = (value) => {
    let found = totalPlanYearsInMap[value];
    if (found) {
      setValue('_planYearStartDate', found.startDate);
      setValue('_planYearEndDate', found.endDate);
      setValue('startDate', found.startDate);
      setValue('endDate', found.endDate);
      const targetYear = found.planYearName;

      setValue('stopLossYear', targetYear);
      filterPlans(targetYear);
      if (!getValues('islContractBasis')) {
        setValue('islContractBasis', DEFAULT_CONTRACT_BASIS);
      }
    } else {
      setValue('_planYearStartDate', null);
      setValue('_planYearEndDate', null);
    }
    setValue('aggRates', []);
  };

  const initDatePickers = () => {
    setValue('runOutStartDate', null);
    setValue('runOutEndDate', null);
    setValue('runInStartDate', null);
    setValue('runInEndDate', null);
    setValue('aslStartDate', null);
    setValue('aslEndDate', null);
  };

  const initDefaults = () => {
    setValue('islAppliedToAll', true);
    setValue('aslAppliedToAll', true);
  };

  const handleIslPlanSelect = (event) => {
    const selected = event.target.value;
    setCurrentSelectedIsl(selected);
    if (selected && selected.length !== currentPlanNames.length) {
      setValue('islAppliedToAll', false);

      const planIds = [];

      if (selected && Array.isArray(selected)) {
        selected.map((item) => {
          planIds.push({
            planId: item
          });
        });
      }
      setValue('islPlans', planIds);
    } else {
      setValue('islAppliedToAll', true);
      setCurrentSelectedIsl(currentPlanNames.map((plan) => plan.id));
    }
  };

  const handleIslApplyToAll = (checked) => {
    if (checked) {
      setCurrentSelectedIsl(currentPlanNames.map((pln) => pln.id));
    } else {
      setCurrentSelectedIsl([]);
    }
  };

  const handleAslPlanSelect = (event) => {
    setCurrentSelectedAsl(event.target.value);
    if (event.target.value.length !== currentPlanNames.length) {
      setValue('aslAppliedToAll', false);
    } else {
      setValue('aslAppliedToAll', true);
    }
  };

  const handleAslApplyToAll = (checked) => {
    if (checked) {
      setValue(
        'aslPlanName',
        currentPlanNames.map((pln) => pln.id)
      );
      setCurrentSelectedAsl(currentPlanNames.map((pln) => pln.id));
    } else {
      setValue('aslPlanName', []);
      setCurrentSelectedAsl([]);
    }
  };

  const handleAslShared = (checked) => {
    if (checked && watchAslAppliedToAll) {
      setValue(
        'aslPlanName',
        currentPlanNames.map((pln) => pln.id)
      );
    } else {
      setValue('aslPlanName', []);
    }
  };

  const handleShowAsl = (checked) => {
    setShowAsl(checked);
    if (checked) {
      if (!getValues('aslContractBasis')) {
        setValue('aslContractBasis', DEFAULT_CONTRACT_BASIS);
      }
    } else {
      setValue('aslPlanName', []);
      setValue('aslContractBasis', null);
      setValue('aggRates', []);
    }
  };

  const handleShowIsl = (checked) => {
    setShowIsl(checked);
    if (checked) {
      setValue(
        'islPlanName',
        currentPlanNames.map((pln) => pln.id)
      );
      setValue('islAppliedToAll', true);
    } else {
      setValue('islPlanName', []);
      setValue('islAppliedToAll', false);
    }
  };

  const filterPlans = (year) => {
    if (!year) {
      return;
    }

    const [startYear, endYear] = year.split('-');

    let targetEndYear = endYear;

    if (!endYear) {
      targetEndYear = startYear;
    }

    let filteredList = [];
    if (totalPlanName && Array.isArray(totalPlanName)) {
      filteredList = totalPlanName.filter((plan) => {
        const startDate = moment(plan.planStartDate);
        const endDate = moment(plan.planEndDate);
        return startDate.year() == startYear || endDate.year() == targetEndYear;
      });
    }

    setCurrentPlanNames(filteredList);
    const idList = filteredList.map((pln) => pln.id);
    setCurrentSelectedIsl(idList);
    setCurrentSelectedAsl(idList);
    if (watchIslAppliedToAll) {
      setValue('islPlanName', idList);
    }
    if (watchAslAppliedToAll) {
      setValue('aslPlanName', idList);
    }
  };

  const handlePolicyExclusionClose = (data) => {
    if (data && data.policyExclusionDTOs) {
      setValue('policyExclusions', data.policyExclusionDTOs);
    }
    setPolicyExclusionOpen(false);
  };

  const handleLaserClose = (data) => {
    if (data && data.laserDTOs) {
      setValue('lasers', data.laserDTOs);
    }
    setLaserOpen(false);
  };

  const handleAslClose = (data) => {
    if (data && data.aslDTOs) {
      setValue('aggRates', data.aslDTOs);
    }
    setAslOpen(false);
  };

  useEffect(() => {
    if (!isLoading && getValues('apdId')) {
      const planYr = totalPlanYears.find(
        (pln) => pln.id === getValues('apdId')
      );
      filterPlans(parseInt(planYr.planYearName));
    }

    let inMap = {};

    if (totalPlanYears && Array.isArray(totalPlanYears)) {
      totalPlanYears.forEach((item) => {
        inMap[item.id] = item;
      });
    }

    setTotalPlanYearsInMap(inMap);
  }, [totalPlanYears, isLoading]);

  useEffect(() => {
    calculateContractBasisIsl(
      watchSlcStartDate,
      watchSlcEndDate,
      getValues('islContractBasis')
    );
    calculateContractBasisAsl(
      watchSlcStartDate,
      watchSlcEndDate,
      getValues('aslContractBasis')
    );

    let lasers = getValues('lasers');
    if (lasers && Array.isArray(lasers)) {
      lasers.map((item) => {
        const map = CommonUtil.CALCULATE_CONTRACT_BASIS_DATE_PERIOD(
          watchSlcStartDate,
          watchSlcEndDate,
          item.contractBasis
        );
        item.runInStartDate = map[CommonUtil.CONTRACT_BASIS_RUN_IN_START_DATE];
        item.runInEndDate = map[CommonUtil.CONTRACT_BASIS_RUN_IN_END_DATE];
        item.runOutStartDate =
          map[CommonUtil.CONTRACT_BASIS_RUN_OUT_START_DATE];
        item.runOutEndDate = map[CommonUtil.CONTRACT_BASIS_RUN_OUT_END_DATE];
      });
      setValue('lasers', lasers);
    }
  }, [watchSlcEndDate, watchSlcStartDate]);

  useEffect(() => {
    if (currentPlanNames && Array.isArray(currentPlanNames)) {
      setCurrentSelectedIsl(
        currentPlanNames.map((pln) => [pln.id, pln.planName])
      );
      setCurrentSelectedAsl(currentPlanNames.map((pln) => pln.id));
    }
  }, []);

  if (isLoading) {
    return <div />;
  }

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-planYears">Plan Year</InputLabel>
                  <Select
                    {...field}
                    defaultValue=""
                    onChange={(e) => {
                      setAnnualPlanId(e.target.value);
                      field.onChange(e);
                      handlePlanYearChange(e.target.value);
                    }}
                    id="input_planYear"
                    key="input_planYear">
                    {totalPlanYears &&
                      Array.isArray(totalPlanYears) &&
                      totalPlanYears.map((item) => (
                        <MenuItem key={uuid()} value={item.id}>
                          {item.planYearName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              name="apdId"
              control={control}
              defaultValue={''}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              rules={{
                required: Constant.ERROR_MSG_REQUIRED_FIELD
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="input-stopLossYear"
                  {...field}
                  fullWidth
                  required
                  error={error}
                  helperText={error ? error.message : null}
                  label="Stop Loss Year"
                />
              )}
              name="stopLossYear"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  control={<Switch />}
                  label="Rx Covered"
                />
              )}
              name="rxCovered"
              control={control}
              defaultValue={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  control={<Switch />}
                  label="Med Covered"
                />
              )}
              name="medCovered"
              control={control}
              defaultValue={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-domesticReImbursement">
                    Domestic Reimbursement
                  </InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    labelId="label-domesticReImbursement"
                    id="input-domesticReImbursement"
                    defaultValue="">
                    {domesticReImbursementOptions &&
                      domesticReImbursementOptions.map((item) => {
                        return (
                          <MenuItem key={uuid()} value={item.id}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              )}
              name="domesticReImbursement"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              rules={{
                required: Constant.ERROR_MSG_REQUIRED_FIELD,
                validate: (value) => {
                  if (value) {
                    return ValidationUtil.VALIDATE_DATE(value);
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    clearable
                    {...field}
                    required
                    maxDate={watchSlcEndDate ? watchSlcEndDate : undefined}
                    error={error}
                    onChange={(value) => {
                      field.onChange(value);
                      calculateContractBasisIsl(value, getValues('endDate'), getValues('islContractBasis'));
                      calculateContractBasisAsl(value, getValues('endDate'), getValues('aslContractBasis'));
                    }}
                    helperText={error ? error.message : null}
                    format={Constant.FORMAT_DATE}
                    value={getValues('startDate') ? moment.utc(getValues('startDate')) : null}
                    id="input-startDate"
                    label="Start Date"
                    fullWidth
                    defaultValue=""
                  />
                </MuiPickersUtilsProvider>
              )}
              name="startDate"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              rules={{
                required: Constant.ERROR_MSG_REQUIRED_FIELD,
                validate: (value) => {
                  if (value) {
                    return ValidationUtil.VALIDATE_DATE(value);
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    clearable
                    {...field}
                    required
                    minDate={watchSlcStartDate ? watchSlcStartDate : undefined}
                    onChange={(value) => {
                      field.onChange(value);
                      calculateContractBasisIsl(getValues('startDate'), value, getValues('islContractBasis'));
                      calculateContractBasisAsl(getValues('startDate'), value, getValues('aslContractBasis'));
                    }}
                    format={Constant.FORMAT_DATE}
                    value={getValues('endDate') ? moment.utc(getValues('endDate')) : null}
                    error={error}
                    helperText={error ? error.message : null}
                    id="input-endDate"
                    label="End Date"
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              )}
              name="endDate"
              control={control}
            />
          </Grid>
          {!isModified && (
            <>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setLaserOpen(true);
                  }}
                  fullWidth
                  className={styles.button}
                  disabled={!(watchSlcStartDate && watchSlcEndDate)}>
                  Lasers (
                  {getValues('lasers') && Array.isArray(getValues('lasers'))
                    ? getValues('lasers').length
                    : 0}
                  )
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setPolicyExclusionOpen(true);
                  }}
                  fullWidth
                  className={styles.button}>
                  Policy Exclusions (
                  {getValues('policyExclusions') &&
                  Array.isArray(getValues('policyExclusions'))
                    ? getValues('policyExclusions').length
                    : 0}
                  )
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={4} className={styles.panel}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography align="center">ISL</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              checked={showIsl}
              onChange={(e) => {
                handleShowIsl(e.target.checked);
              }}
              control={<Switch />}
              label="Enable ISL"
            />
          </Grid>
          {showIsl && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                      control={<Switch />}
                      label="Apply to All Plans"
                    />
                  )}
                  name="islAppliedToAll"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={error}>
                      <InputLabel id="label-planNamesIsl">
                        Plan(s) covered by ISL
                      </InputLabel>
                      <Select
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          handleIslPlanSelect(e);
                        }}
                        multiple
                        id="input_planNameIsl"
                        key="input_planNameIsl"
                        disabled={!watchPlanYearId}
                        renderValue={() =>
                          currentPlanNames
                            .filter((plan) =>
                              currentSelectedIsl.includes(plan.id)
                            )
                            .map((plan) => {
                              return plan.planName;
                            })
                            .join(', ')
                        }>
                        {currentPlanNames &&
                          Array.isArray(currentPlanNames) &&
                          currentPlanNames.map((item) => (
                            <MenuItem key={uuid()} value={item.id}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={currentSelectedIsl.includes(item.id)}
                                />
                              </ListItemIcon>
                              <ListItemText primary={item.planName} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                  name="islPlanName"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  rules={{ required: Constant.ERROR_MSG_REQUIRED_FIELD }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="input-islContractBasis"
                      label="Contract Basis"
                      fullWidth
                      {...field}
                      required
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        calculateContractBasisIsl(
                          getValues('startDate'),
                          getValues('endDate'),
                          e.target.value
                        );
                      }}
                      error={error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  name="islContractBasis"
                  control={control}
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  rules={{
                    validate: (value) => {
                      if (value && !ValidationUtil.IS_INT(value))
                        return 'Only numbers are allowed.';
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="input-islDeductible"
                      label="ISL Deductible"
                      fullWidth
                      {...field}
                      error={error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  name="islDeductible"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="input-aggSpecDeductible"
                      label="Agg Spec Deductible"
                      fullWidth
                      {...field}
                      error={error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  name="aggSpecDeductible"
                  control={control}
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        clearable
                        {...field}
                        disabled
                        format={Constant.FORMAT_DATE}
                        value={getValues('runInStartDate') ? moment.utc(getValues('runInStartDate')) : null}
                        error={error}
                        helperText={error ? error.message : null}
                        id="input-runInStartDate"
                        label="Run In Start Date"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name="runInStartDate"
                  control={control}
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        clearable
                        {...field}
                        disabled
                        format={Constant.FORMAT_DATE}
                        value={getValues('runInEndDate') ? moment.utc(getValues('runInEndDate')) : null}
                        error={error}
                        helperText={error ? error.message : null}
                        id="input-runInEndDate"
                        label="Run In End Date"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name="runInEndDate"
                  control={control}
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        clearable
                        {...field}
                        disabled
                        format={Constant.FORMAT_DATE}
                        value={getValues('runOutStartDate') ? moment.utc(getValues('runOutStartDate')) : null}
                        error={error}
                        helperText={error ? error.message : null}
                        id="input-runOutStartDate"
                        label="Run Out Start Date"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name="runOutStartDate"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        clearable
                        {...field}
                        disabled
                        format={Constant.FORMAT_DATE}
                        value={getValues('runOutEndDate') ? moment.utc(getValues('runOutEndDate')) : null}
                        error={error}
                        helperText={error ? error.message : null}
                        id="input-runOutEndDate"
                        label="Run Out End Date"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name="runOutEndDate"
                  control={control}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={4} className={styles.panel}>
          <Grid container>
            <Grid item xs={12}>
              <Typography align="center">ASL</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                checked={showAsl}
                onChange={(e) => {
                  handleShowAsl(e.target.checked);
                }}
                control={<Switch />}
                label="Enable ASL"
              />
            </Grid>
            {showAsl && (
              <>
                {watchShareExpectedClaims !== false && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <FormControlLabel
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              handleAslApplyToAll(e.target.checked);
                            }}
                            control={<Switch />}
                            label="Apply to All Existing Plans"
                          />
                        )}
                        name="aslAppliedToAll"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <FormControl fullWidth error={error}>
                            <InputLabel id="label-planNamesAsl">
                              Plan(s) covered by ASL
                            </InputLabel>
                            <Select
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handleAslPlanSelect(e);
                              }}
                              multiple
                              id="input_planNameAsl"
                              key="input_planNameAsl"
                              disabled={!watchPlanYearId}
                              renderValue={() =>
                                currentPlanNames
                                  .filter((plan) =>
                                    currentSelectedAsl.includes(plan.id)
                                  )
                                  .map((plan) => {
                                    return plan.planName;
                                  })
                                  .join(', ')
                              }
                              fullWidth>
                              {currentPlanNames &&
                                Array.isArray(currentPlanNames) &&
                                currentPlanNames.map((item) => (
                                  <MenuItem key={uuid()} value={item.id}>
                                    <ListItemIcon>
                                      <Checkbox
                                        checked={currentSelectedAsl.includes(
                                          item.id
                                        )}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={item.planName} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                        name="aslPlanName"
                        control={control}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Controller
                    rules={{ required: Constant.ERROR_MSG_REQUIRED_FIELD }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="input-aslContractBasis"
                        label="Contract Basis"
                        fullWidth
                        onBlur={(e) => {
                          field.onBlur(e.target.value);
                          calculateContractBasisAsl(
                            getValues('startDate'),
                            getValues('endDate'),
                            e.target.value
                          );
                        }}
                        required
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    name="aslContractBasis"
                    control={control}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                          clearable
                          {...field}
                          disabled
                          format={Constant.FORMAT_DATE}
                          value={getValues('aslStartDate') ? moment.utc(getValues('aslStartDate')) : null}
                          error={error}
                          helperText={error ? error.message : null}
                          id="input-aslStartDate"
                          label="Start Date"
                          fullWidth
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="aslStartDate"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                          clearable
                          {...field}
                          disabled
                          format={Constant.FORMAT_DATE}
                          value={getValues('aslEndDate') ? moment.utc(getValues('aslEndDate')) : null}
                          error={error}
                          helperText={error ? error.message : null}
                          id="input-aslEndDate"
                          label="End Date"
                          fullWidth
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    name="aslEndDate"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    rules={{
                      required: Constant.ERROR_MSG_REQUIRED_FIELD,
                      validate: {
                        numberValidation: (value) => {
                          if (
                            value &&
                            value !== '' &&
                            !ValidationUtil.IS_NUMBER(value)
                          ) {
                            return 'Input must be a number';
                          }
                        }
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        {...field}
                        id="input-corridor"
                        label="Corridor in % (enter as integer, e.g. 120)"
                        defaultValue=""
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    name="corridor"
                    control={control}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControlLabel
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          handleAslShared(e.target.checked);
                        }}
                        control={<Switch />}
                        label="All Plans Share the below Agg Factor"
                      />
                    )}
                    name="shareExpectedClaims"
                    control={control}
                    defaultValue={true}
                  />
                </Grid>
                {!isModified && watchShareExpectedClaims !== false && (
                  <MonthlyAgg
                    tenantId={tenantId}
                    annualPlanId={annualPlanId}
                    showPlanSelection={false}
                  />
                )}
                {!isModified && watchShareExpectedClaims == false && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAslOpen(true);
                    }}
                    fullWidth
                    className={styles.button}
                    disabled={!watchPlanYearId}>
                    Agg Factor (
                    {getValues('aggRates') &&
                    Array.isArray(getValues('aggRates'))
                      ? getValues('aggRates').length
                      : 0}
                    )
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <PolicyExclusionListDialog
          open={policyExclusionOpen}
          target={{ policyExclusionDTOs: getValues('policyExclusions') }}
          onClose={handlePolicyExclusionClose}
          onCancel={() => setPolicyExclusionOpen(false)}
        />
        <LaserListDialog
          open={laserOpen}
          target={{ laserDTOs: getValues('lasers') }}
          slcStartDate={watchSlcStartDate}
          slcEndDate={watchSlcEndDate}
          onClose={handleLaserClose}
          onCancel={() => setLaserOpen(false)}
        />
        <AggListDialog
          open={aslOpen}
          target={{ aslDTOs: getValues('aggRates') }}
          onClose={handleAslClose}
          onCancel={() => setAslOpen(false)}
          tenantId={tenantId}
          annualPlanId={annualPlanId}
          currentPlanNames={currentPlanNames}
        />
      </Grid>
    </>
  );
};

export default StopLossContractDetail;
