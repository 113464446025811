import React, { useState } from 'react';
import { Box, Typography, IconButton, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { store } from 'store/store';
import axios from 'axios';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from 'common/BstAccordion';
import ManageReportDetail from './detail';
import * as ReportTypes from 'features/frontoffice/actions/types';
import * as AppActionTypes from 'store/actions/appstate';
import { AppConfig } from 'AppConfig';
import { formatReptSource } from 'utils/formatReptSource';
import { useSelector } from 'react-redux';

const GreenTypography = withStyles({
  root: {
    color: '#46C646'
  }
})(Typography);

const ManageReportAccordion = (props) => {
  const { views, onSubmit } = props;
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);
  const tableauServiceUrl = useSelector((state) => state.user.tableauServiceUrl);

  const [expandBitmap, setExpandBitmap] = useState(
    new Array(views.length).fill(false)
  );

  const updateBitmap = (i) => {
    let newExpanded = [...expandBitmap];
    newExpanded[i] = !expandBitmap[i];
    setExpandBitmap(newExpanded);
  };

  const handleExpand = (i) => (_e, _isExtended) => {
    updateBitmap(i);
  };

  const handleCancel = (i) => {
    updateBitmap(i);
  };

  const handleSubmit = (data, i) => {
    axios
      .post(AppConfig.FO_INITIAL_URL + '/init/workbook-meta-save', [data])
      .then((_res) => {
        updateBitmap(i);
        onSubmit();
      });
  };

  const renderView = (view, i) => {
    return (
      <Accordion
        key={`view-${view.viewName}-${i}`}
        expanded={expandBitmap[i]}
        onChange={handleExpand(i)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="none"
            flexGrow={1}
            alignItems="center"
            justifyContent="start">
            <IconButton
              className="p-1"
              onClick={(e) => {
                e.stopPropagation();
                store.dispatch({
                  type: ReportTypes.OPEN_REPORT_VIEWER_REQUEST,
                  reportUrl: formatReptSource(tableauServiceUrl, currentTenantCd, view.reptSource),
                  category: view.category,
                  title: view.displayText
                });
              }}>
              <VisibilityIcon />
            </IconButton>
            <Box className="pl-1">
              {view.inFo ? (
                <GreenTypography>{view.viewName}</GreenTypography>
              ) : (
                <Typography>{view.viewName}</Typography>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ManageReportDetail
            report={view}
            onCancel={() => handleCancel(i)}
            onSubmit={(data) => handleSubmit(data, i)}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  return <>{views.map((v, i) => renderView(v, i))}</>;
};

export default ManageReportAccordion;
