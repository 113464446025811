import { Model, attr } from 'redux-orm';

const defaultAttributes = {
  roleName: 'Default role name'
};

export default class SysRole extends Model {
  static modelName = 'SysRole';

  static fields = {
    roleName: attr()
  };

  static parse(data) {
    return this.upsert(data);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherView) {
    this.update(otherView.ref);
  }
}
