import { Tab, Tabs } from '@material-ui/core';
import { AppConfig } from 'AppConfig';
import * as UserTypes from 'features/user/userTypes';
import React, { useEffect, useState } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import { store } from 'store/store';
import { hasPermission } from 'utils/common';
import AnnualHealthPlan from './components/annualPlan/annualHealthPlan';
import DiscretionaryBenefits from './components/discretionaryBenefits/discretionaryBenefits';
import Division from './components/division/divsion';
import ElectionTier from './components/electionTier/electionTier';
import EmployeeClass from './components/employeeClass/employeClass';
import DentalPlan from './components/planDesignData/dentalPlan';
import PlanDesignData from './components/planDesignData/planDesignData';
import VisionPlan from './components/planDesignData/visionPlan';
import PlanRate from './components/planRate/planRate';
import { MastersTable } from './MastersTable';

import PlanExpense from './components/planExpense/PlanExpense';

import { Alert } from '@material-ui/lab';
import StopLossContract from './components/stopLossContract/stopLossContract';

const MasterNav = (props) => {
  const [state, updateState] = useState({
    onInitialLoad: true,
    refreshKey: 0,
    currentMDItem: null
  });
  const currentTenant = useSelector((state) => state.user.currentTenantCd);
  const permissions = useSelector((state) => state.user.permissions);
  const planSponsor = useSelector((state) => state.masterTable.planSponsor);
  const planSponsorLoading = useSelector(
    (state) => state.masterTable.planSponsorLoading
  );

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.STANDARD
    });
  }, []);

  const renderMasterContent = () => {
    const pathEnd = '/' + window.location.pathname.split('/').slice(-1);

    switch (pathEnd) {
      case AppConfig.MASTER_DATA_SUB_URL.MASTER_TABLE:
        return <MastersTable />;
      case AppConfig.MASTER_DATA_SUB_URL.ET_SCHEMA:
        return <ElectionTier tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.EMPLOYEE_CLASS:
        return <EmployeeClass tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.ANNUAL_HEALTH_PLAN:
        return <AnnualHealthPlan tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.PLAN_DESIGN_DATA:
        return <PlanDesignData tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.DISCRETIONARY_BENEFITS:
        return <DiscretionaryBenefits tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.DENTAL_PLAN:
        return <DentalPlan tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.VISION_PLAN:
        return <VisionPlan tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.PLAN_EXPENSE:
        return <PlanExpense />;
      case AppConfig.MASTER_DATA_SUB_URL.PLAN_RATES:
        return <PlanRate tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.DIVISION:
        return <Division tenantId={currentTenant} />;
      case AppConfig.MASTER_DATA_SUB_URL.STOP_LOSS_CONTRACT:
        return <StopLossContract />;
      default:
        props.history.push(
          RouteConfig.master.path + AppConfig.MASTER_DATA_SUB_URL.MASTER_TABLE
        );
    }
  };

  return (
    <>
      <Beforeunload
        onBeforeunload={(event) => {
          if (
            window.location.pathname &&
            window.location.pathname === RouteConfig.master.path
          ) {
            if (state.onInitialLoad === true) {
              updateState({ ...state, onInitialLoad: false });
            } else {
              updateState({
                ...state,
                refreshKey: state.refreshKey + 1
              });
            }
          }
        }}></Beforeunload>
      {!planSponsorLoading && !planSponsor?.name && (
        <Alert severity="error" variant="filled">
          Plan Sponsor is not provided
        </Alert>
      )}
      <Tabs
        value={'/' + window.location.pathname.split('/').slice(-1)[0]}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable">
        <Tab
          label="Master"
          value={AppConfig.MASTER_DATA_SUB_URL.MASTER_TABLE}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.MASTER_TABLE}
        />
        <Tab
          label="Election Tier Schema"
          value={AppConfig.MASTER_DATA_SUB_URL.ET_SCHEMA}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.ET_SCHEMA}
        />
        <Tab
          label="Employee Class"
          value={AppConfig.MASTER_DATA_SUB_URL.EMPLOYEE_CLASS}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.EMPLOYEE_CLASS}
        />
        <Tab
          label="Annual Health Plan"
          value={AppConfig.MASTER_DATA_SUB_URL.ANNUAL_HEALTH_PLAN}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.ANNUAL_HEALTH_PLAN}
          disabled={!planSponsor?.name}
        />
        <Tab
          label="Plan Design Data"
          value={AppConfig.MASTER_DATA_SUB_URL.PLAN_DESIGN_DATA}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.PLAN_DESIGN_DATA}
        />
        <Tab
          label="Dental Plan"
          value={AppConfig.MASTER_DATA_SUB_URL.DENTAL_PLAN}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.DENTAL_PLAN}
        />
        {hasPermission(
          AppConfig.PERMISSION.MD_DISCRECTIONARY_BENEFITS,
          permissions
        ) && (
          <Tab
            label="Discretionary Benefits"
            value={AppConfig.MASTER_DATA_SUB_URL.DISCRETIONARY_BENEFITS}
            component={Link}
            to={'.' + AppConfig.MASTER_DATA_SUB_URL.DISCRETIONARY_BENEFITS}
          />
        )}
        <Tab
          label="Vision Plan"
          value={AppConfig.MASTER_DATA_SUB_URL.VISION_PLAN}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.VISION_PLAN}
        />
        <Tab
          label="Plan Expense"
          value={AppConfig.MASTER_DATA_SUB_URL.PLAN_EXPENSE}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.PLAN_EXPENSE}
        />
        <Tab
          label="Plan Rates"
          value={AppConfig.MASTER_DATA_SUB_URL.PLAN_RATES}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.PLAN_RATES}
        />
        <Tab
          label="Stop Loss Contract"
          value={AppConfig.MASTER_DATA_SUB_URL.STOP_LOSS_CONTRACT}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.STOP_LOSS_CONTRACT}
        />
        <Tab
          label="Division"
          value={AppConfig.MASTER_DATA_SUB_URL.DIVISION}
          component={Link}
          to={'.' + AppConfig.MASTER_DATA_SUB_URL.DIVISION}
        />
      </Tabs>
      {state.currentMDItem !== 'lookup' &&
        state.currentMDItem !== 'dijob' &&
        renderMasterContent()}
    </>
  );
};

export default MasterNav;
