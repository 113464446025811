import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as MDActions from '../../../common/actions/CommonActions';
import { store } from 'store/store';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const deleteById = (id) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `v2/annual-health-plan/id/${id}`)
      .then((m) => {
        dispatch({
          type: Types.REFRESH_CONTENT
        });
        store.dispatch(MDActions.fetchAllPlanYears());
      })

  };
};

export const save = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + 'v2/annual-health-plan', data)
      .then((m) => {
        dispatch({
          type: Types.REFRESH_CONTENT
        });

      })

  };
};

export const clone = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + 'v2/annual-health-plan/deep-clone', data)
      .then((m) => {
        dispatch({
          type: Types.REFRESH_CONTENT
        });
      })

  };
};
