import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import UserCreationDialogDetail from './userCreationDialogDetail';
import * as Action from '../action/action';
import { store } from 'store/store';

const UserCreationDialog = (props) => {
  const { onClose, open, target, tenantId, appList } = props;
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = async (data) => {
    let dto = {
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      email: data.email,
      roleId: data.role,
      companyIds: []
    };
    
    if (data.company) {
      data.company.map((item) => {
        return dto.companyIds.push(item.id);
      });
    }
    store.dispatch(Action.saveCreatedNewUser(dto));
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
          }}>
          <DialogTitle>{target && target.id > 0 ? 'Edit' : 'New'}</DialogTitle>
          <DialogContent dividers>
            <UserCreationDialogDetail applications={appList} />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary">
              Save
            </Button>
            <Button autoFocus onClick={() => handleClose()} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default UserCreationDialog;
