import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { store } from 'store/store';
import * as MDActions from '../../../common/actions/CommonActions';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import * as Constant from 'common/Constant';
import _ from 'lodash';
import * as ValidationUtil from 'utils/ValidationUtil';

const DentalPlanNetworkDetail = (props) => {
  const { tenantId, isNew, existingNetworks } = props;

  const {
    register,
    control,
    watch,
    formState: { errors },
    getValues
  } = useFormContext();
  const { fields } = useFieldArray({
    control,
    keyName: Constant.DEFAULT_REACT_HOOK_FIELD_ID,
    name: 'hsaTiers'
  });

  const watchHsaType = watch('hsaType', Constant.KEY_HSA_TYPE_IN_FAMILY);

  const allNetworkTypes = useSelector(
    (state) => state.MD.mdCommon.allNetworkTypes
  );

  const allElectionTiers = useSelector(
    (state) => state.MD.mdCommon.allElectionTiers
  );

  const [filteredNetworkTypes, setFilteredNetworkTypes] =
    useState(allNetworkTypes);

  useEffect(() => {
    store.dispatch(MDActions.getAllElectionTiers());
  }, [tenantId]);

  useEffect(() => {
    if (
      isNew &&
      allNetworkTypes &&
      existingNetworks &&
      Array.isArray(allNetworkTypes) &&
      Array.isArray(existingNetworks) &&
      existingNetworks.length > 0
    ) {
      let filtered = allNetworkTypes.filter(function (item) {
        return (
          _.findIndex(existingNetworks, function (o) {
            return o.networkType === item;
          }) < 0
        );
      });
      setFilteredNetworkTypes(filtered);
    }
  }, [existingNetworks, allNetworkTypes]);

  return (
    <>
      <Grid container bgcolor="primary.main" spacing={5}>
        <Grid item xs={3}>
          <Controller
            name="networkType"
            rules={{ required: 'This field is required' }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                id="input-allNetworkTypes"
                required
                selectOnFocus
                clearOnBlur
                autoSelect
                options={filteredNetworkTypes}
                disabled={!isNew}
                autoFocus
                onChange={(_, data) => {
                  field.onChange(data);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={error}
                    helperText={error ? error.message : null}
                    label="Network Type"
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name="annualMaxBenefits"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-annualMaxBenefits"
                label="Annual Max.Benefits"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name="orthoEligibility"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-orthoEligibility"
                label="Ortho Eligibility"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="dedInd"
            control={control}
            rules={{
              validate: {
                deductibleIn: (value) =>
                  ValidationUtil.validateDedIn(
                    value,
                    getValues('dedFamily'),
                    null
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-dedInd"
                label="Ded. Individual"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="dedFamily"
            control={control}
            rules={{
              validate: {
                deductibleIn: (value) =>
                  ValidationUtil.validateDedFam(getValues('dedInd'), value)
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-dedFamily"
                label="Ded. Family"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{
              validate: {
                coInsurance: (value) =>
                  ValidationUtil.validateTheStringOrNumberInput(value)
              }
            }}
            name="orthoDedInd"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-orthoDedInd"
                label="Ortho Ded. Individual"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="orthoDedFamily"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-orthoDedFamily"
                label="Ortho Ded. Family"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="orthoMax"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-orthoMax"
                label="Ortho Max"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{
              validate: {
                pcpCopay: (value) =>
                  ValidationUtil.validateTheStringOrNumberInput(value)
              }
            }}
            name="preventiveCoInsurance"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-preventiveCoInsurance"
                label="Preventive CoInsurance"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{
              validate: {
                rule1: (value) =>
                  ValidationUtil.validateTheStringOrNumberInput(value)
              }
            }}
            name="basicDentalCoInsurance"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-basicDentalCoInsurance"
                label="Basic Dental CoInsurance"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            rules={{
              validate: {
                urgentCareCopay: (value) =>
                  ValidationUtil.validateTheStringOrNumberInput(value)
              }
            }}
            name="majorDentalCoInsurance"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-majorDentalCoInsurance"
                label="Major Dental CoInsurance"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="orthoCoInsurance"
            rules={{
              validate: {
                emergencyRoomCopay: (value) =>
                  ValidationUtil.validateTheStringOrNumberInput(value)
              }
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-orthoCoInsurance"
                label="Ortho CoInsurancey"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DentalPlanNetworkDetail;
