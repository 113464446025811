import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  MenuItem,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  InputLabel
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as ValidationUtil from 'utils/ValidationUtil';
import { useSelector } from 'react-redux';
import * as Actions from '../actions/action';
import { store } from 'store/store';
import {v4 as uuid} from 'uuid';

const ElectionTierDetail = ({ tenantId, target, existingData }) => {
  const DUPLICATION_ERROR_MSG = 'Duplicated Name is Found.';
  const targetCode = target.electionTier;

  const { control, getValues, setValue, reset } = useFormContext();

  const [filteredEt, setFilteredEt] = useState([]);

  const electionTierCategories = useSelector(
    (state) => state.MD.electionTier.tiers
  );
  const [existingTiers, setExistingTiers] = useState(new Map());

  useEffect(() => {
    const tierMap = new Map();
    const filterT = [];

    const existingMap = new Map();

    existingData.forEach((item) => {
      existingMap.set(item.electionTier, item);
    });
    if (electionTierCategories && Array.isArray(electionTierCategories)) {
      electionTierCategories.map((existing) => {
        tierMap.set(existing.code, existing.name);
        if (targetCode === existing.code || !existingMap.get(existing.code)) {
          filterT.push(existing);
        }
      });
    }
    setExistingTiers(tierMap);

    if (!target?.id) {
      reset({
        et: filterT.map((tier) => {
          return { etName: tier.name, electionTier: tier.code };
        })
      });
    }
    setFilteredEt(filterT);
  }, [existingData, electionTierCategories, target]);

  useEffect(() => {
    store.dispatch(Actions.getAllElectionTiers());
  }, [tenantId]);

  const renderRow = (i) => {
    return (
      <>
        <Grid container spacing={4} bgcolor="primary.main">
          {!target?.id && (
            <Grid item xs={2}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControlLabel
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    control={<Switch />}
                    label="Add"
                  />
                )}
                name={`et[${i}].toAdd`}
                control={control}
                defaultValue={false}
              />
            </Grid>
          )}
          <Grid item xs={!target?.id ? 3 : 4}>
            <Controller
              rules={{
                validate: async (value) => {
                  if (
                    getValues('et').some((tier, j) => {
                      return (
                        tier.tierOrder && tier.tierOrder === value && j !== i
                      );
                    })
                  ) {
                    return 'Duplicated Order Number is Found.';
                  }
                  try {
                    if (value && !ValidationUtil.IS_INT(value))
                      return 'Only integer is allowed.';

                    if (value && value !== target.tierOrder) {
                      const found = await axios.get(
                        AppConfig.API_BASE_URL +
                        `electiontiers/tier-order/${value}`
                      );
                      return ValidationUtil.VALIDATION_DUPLICATION(
                        found,
                        parseInt(getValues('id')),
                        'Duplicated Order Number is Found.'
                      );
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    id="input-tierOrder"
                    fullWidth
                    {...field}
                    label="Tier Order"
                    error={error}
                    helperText={error ? error.message : null}
                    labelId="label-tierorder"
                  />
                </>
              )}
              name={`et[${i}].tierOrder`}
              control={control}
            />
          </Grid>
          <Grid item xs={!target?.id ? 3 : 4}>
            <Controller
              rules={{
                required: Constant.ERROR_MSG_REQUIRED_FIELD,
                validate: async (value) => {
                  try {
                    const found = await axios.get(
                      AppConfig.API_BASE_URL + `electiontiers/et-name/${value}`
                    );

                    return ValidationUtil.VALIDATION_DUPLICATION(
                      found,
                      parseInt(getValues('id')),
                      DUPLICATION_ERROR_MSG
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-electionTier">Election Tier</InputLabel>
                  <Select
                    {...field}
                    disabled={!target?.id}
                    id="input_electionTier"
                    key="input_electionTier"
                    onChange={(e) => {
                      field.onChange(e);
                      setValue(
                        `et[${i}].etName`,
                        existingTiers.get(e.target.value)
                      );
                    }}>
                    {filteredEt &&
                      Array.isArray(filteredEt) &&
                      filteredEt.map((item) => (
                        <MenuItem key={uuid()} value={item.code}>
                          {item.code}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              name={`et[${i}].electionTier`}
              control={control}
              defaultValue={''}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    id="input-electionName"
                    fullWidth
                    {...field}
                    label=" "
                    labelId="label-electionName"
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    defaultValue=""
                  />
                </>
              )}
              name={`et[${i}].etName`}
              control={control}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return target?.id ? renderRow(0) : filteredEt.map((_et, i) => renderRow(i));
};
export default ElectionTierDetail;
