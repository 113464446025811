import React, { useState, useEffect, useRef } from 'react';
import { TableCell, TableRow, Chip } from '@material-ui/core';
import { LibraryAdd } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as Constant from 'common/Constant';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import {v4 as uuid} from 'uuid';
import * as AppActionTypes from 'store/actions/appstate';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Action from './action/action';
import { useSelector } from 'react-redux';
import * as UserTypes from 'features/user/userTypes';
import RoleManagementDialog from './components/roleManagementDialog';
import MaterialTable from '@material-table/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const RoleManagementTable = () => {
  const tableRef = useRef();
  const classes = useStyles();

  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [creationOpen, setCreationOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});

  const roleList = useSelector((state) => state.adminRoleManagement.roleList);
  const permissionList = useSelector(
    (state) => state.adminRoleManagement.permissionList
  );
  const shouldRefresh = useSelector(
    (state) => state.adminRoleManagement.shouldRefresh
  );

  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange({ page: 0 });
  };
  useEffect(() => {
    refresh();
  }, [shouldRefresh]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    store.dispatch(Action.fetchRoleList());
    store.dispatch(Action.fetchPermissionList());
  }, []);

  const handleSingleDelete = (roleId, permissionId) => {
    confirmDialog(
      () =>
        store.dispatch(Action.deleteSingleRolePermission(roleId, permissionId)),
      'Do you really want to remove this permission under this role?'
    );
  };

  const handleDelete = (roleId) => {
    confirmDialog(
      () => store.dispatch(Action.deleteRolePermissionComb(roleId)),
      'Do you really want to delete this role?'
    );
  };

  const handleCreationOpen = (data) => {
    setCloneData(data);
    setCreationOpen(true);
  };

  const handleCreationClose = (data) => {
    //convert data to backend expected object
    if (data && data.code && data.description) {
      let dto = {
        roleId: data.id ? data.id : null,
        code: data.code,
        description: data.description,
        permissionIds: Array.isArray(data.permissions)
          ? data.permissions.map((a) => a.id)
          : [],
        applicationCode: data.applicationCode ?? null
      };
      store.dispatch(Action.saveRolePermissionComb(dto));
    }

    setCreationOpen(false);
  };

  return (
    <>
      <RoleManagementDialog
        open={creationOpen}
        onClose={handleCreationClose}
        target={cloneData}
        roleList={roleList}
        permissionList={permissionList}
      />
      <MaterialTable
        title=""
        style={{ width: '100%' }}
        tableRef={tableRef}
        columns={[
          {
            title: 'Role Code',
            field: 'code',
            editable: 'never',
            width: '15%'
          },
          {
            title: 'Description',
            field: 'description',
            editable: 'never',
            width: '15%'
          },
          {
            title: 'Permissions',
            field: 'permissions',
            render: (rowData) => (
              <div className={classes.root}>
                {rowData.permissions.map((data) => (
                  <Chip
                    key={'key-' + uuid()}
                    label={data.code}
                    onDelete={
                      !rowData.isDefault
                        ? () => {
                            handleSingleDelete(rowData.id, data.id);
                          }
                        : null
                    }
                  />
                ))}
              </div>
            ),
            sorting: false
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.IAM_BACKEND_URL + `/role/search`, {
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'id',
                searchTerm: query.search
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                store.dispatch({
                  type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                  payload: 'Could not retrieve the chart.'
                });
              });
          })
        }
        actions={[
          {
            icon: () => <LibraryAdd />,
            tooltip: 'Clone',
            onClick: (event, rowData) => {
              handleCreationOpen({ ...rowData, id: null });
            }
          },
          (data) => ({
            icon: 'edit',
            tooltip: data.isDefault ? "Can't Edit Default Role" : 'Edit',
            onClick: (event, rowData) => {
              handleCreationOpen(rowData);
            },
            disabled: data.isDefault
          }),
          (data) => ({
            icon: 'delete',
            tooltip: data.isDefault ? "Can't Delete Default Role" : 'Delete',
            onClick: (event, rowData) => {
              handleDelete(rowData.id);
            },
            disabled: data.isDefault
          }),
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Create A New Role',
            onClick: () => {
              handleCreationOpen({});
            },
            isFreeAction: true
          }
        ]}
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
        components={{ OverlayLoading: () => <div /> }}
      />
    </>
  );
};

export default RoleManagementTable;
