import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import * as Actions from '../actions/Actions';
import { AppConfig } from 'AppConfig';
import PBMSelection from './PBMSelection';
import { FoMaterialTable } from 'common/FoMaterialTable';

const PBM = ({ tenantId }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.masterTable.tenantPBMs);
  const isLoading = useSelector((state) => state.masterTable.pbmLoading);

  const [open, setOpen] = useState(false);

  const handleOpenSelection = () => {
    setOpen(true);
  };

  const handleSaveSelection = (rows) => {
    if (rows && Array.isArray(rows) && rows.length > 0) {
      let tpas = [];

      rows.map((item) => {
        tpas.push({
          name: item.name,
          tenantId: tenantId
        });
      });

      axios
        .post(
          AppConfig.API_BASE_URL + 'pbm/addlist',
          tpas,
          AppConfig.POST_HEADERS
        )
        .then((m) => {
          refresh();
          setOpen(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteSelected = (row) => {
    let ids = [];

    row.map((item) => {
      ids.push(item.id);
    });
    if (ids && Array.isArray(ids) && ids.length > 0) {
      axios
        .delete(AppConfig.API_BASE_URL + `pbm/mult`, { data: ids })
        .then((m) => {
          refresh();
        })
        .catch((err) => console.log(err));
    }
  };

  const refresh = () => {
    dispatch({
      type: Actions.MASTER_TABLE_GET_PBM_FOR_TENENT
    });
    axios
      .get(AppConfig.API_BASE_URL + `pbm`)
      .then((m) => {
        dispatch({
          type: Actions.MASTER_TABLE_GET_PBM_FOR_TENENT_SUCCESS,
          data: m.data
        });
      })
      .catch((err) =>
        dispatch({
          type: Actions.MASTER_TABLE_GET_PBM_FOR_TENENT_FAILURE
        })
      );
  };

  useEffect(() => {
    refresh();
  }, [tenantId]);

  return (
    <>
      <FoMaterialTable
        title="PBM"
        isLoading={isLoading}
        columns={[{ title: 'Name', field: 'name' }]}
        data={data}
        options={{
          selection: true
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add PBM',
            isFreeAction: true,
            onClick: (event) => handleOpenSelection()
          },
          {
            tooltip: 'Remove All Selected PBM(s)',
            icon: 'delete',
            onClick: (evt, data) => deleteSelected(data)
          }
        ]}
      />

      <PBMSelection
        open={open}
        onSave={handleSaveSelection}
        onClose={handleClose}
        tenantId={tenantId}
      />
    </>
  );
};

export default PBM;
