import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Grid, InputLabel, MenuItem, Select, FormControl, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import * as Constant from 'common/Constant';
import { store } from 'store/store';
import { Controller, useFormContext } from 'react-hook-form';
import * as ValidationUtil from 'utils/ValidationUtil';
import moment from 'moment-timezone';
import * as MDActions from '../../../common/actions/CommonActions';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import debounce from 'lodash.debounce';
import MomentUtils from '@date-io/moment';

const AnnualPlanDetail = (props) => {
  const { tenantId, targetId, isClone, original } = props;
  const { control, watch, setValue, getValues, trigger } =
    useFormContext();

  //back the original start month and date for clone
  const originalStartDate = original.startDate;
  const originalEndDate = original.endDate;

  const originalDedStartDate = original.dedStartDate;
  const originalDedEndDate = original.dedEndDate;

  const watchPlanEndDate = watch('endDate', null);
  const watchPlanStartDate = watch('startDate', null);

  const watchDedEndDate = watch('dedEndDate', null);
  const watchDedStartDate = watch('dedStartDate', null);

  const tpaOptions = useSelector((state) => state.MD.mdCommon.tpas);
  const pbmOptions = useSelector((state) => state.MD.mdCommon.pbms);
  const slcOptions = useSelector((state) => state.MD.mdCommon.slcs);
  const slmOptions = useSelector((state) => state.MD.mdCommon.slms);

  const [disableDateInput, setDisableDateInput] = useState(false);

  const handleYearChange = async () => {
    setDisableDateInput(true);
    const yearToCreate = getValues('planYearName');

    if (yearToCreate && yearToCreate.match(ValidationUtil.YEAR_REGEXP)) {
      let [planStartYear, planEndYear] = yearToCreate.split('-');
      planEndYear = planEndYear ? planEndYear : '';

      let startYear = moment([planStartYear]);
      let endYear = moment([planEndYear || planStartYear]);
      
      startYear.startOf('year');
      endYear.endOf('year').startOf('day');
      let newEndCalenderYear = endYear;

      try {
        const response = await axios.get(
          AppConfig.API_BASE_URL +
          `v2/annual-health-plan/partial-year/${planStartYear}`
        );

        const existingDate = response.data;
        if (existingDate.endDate) {
          newEndCalenderYear = moment(existingDate.endDate)
            .add(1, 'y')
            .startOf('day');
        }
        if (existingDate.startDate) {
          startYear = moment(existingDate.startDate).add(1, 'y').startOf('day');
        }
      } catch {
        //do nothing
      }

      if (endYear.isAfter(newEndCalenderYear)) endYear = newEndCalenderYear;

      if (isClone && originalStartDate && originalEndDate) {       
        // set plan start and end dates
        setValue('startDate', passMonthAndDay(originalStartDate, startYear));
        setValue('endDate', passMonthAndDay(originalEndDate, endYear));
        // set deductable start and end dates
        setValue('dedStartDate', passMonthAndDay(originalDedStartDate, startYear));
        setValue('dedEndDate', passMonthAndDay(originalDedEndDate, endYear));
      } else {
        setValue('dedStartDate', startYear);
        setValue('dedEndDate', endYear);
        setValue('startDate', startYear);
        setValue('endDate', endYear);
      }
      setDisableDateInput(false);
    }
  };

  /**
   * Copies the first date's month and the day information to the second date object.
   * Since all date-time objects stored as UTC conversions could return local dates sometimes.
   * Uses UTC timezone all the time.
   * 
   * @param {Pass} sourceDateAsMs 
   * @param {*} targetDate 
   * @returns 
   */
  const passMonthAndDay = (sourceDateAsMs, targetDate) => {
    let sourceDateObj = moment(sourceDateAsMs);
    let targetDateObj = moment(targetDate);

    // Check if source date is Feb 29 in a leap year, and target year is not a leap year
    let targetDay = sourceDateObj.date();
    if (sourceDateObj.isLeapYear() && sourceDateObj.month() === 1 && sourceDateObj.date() === 29 && !targetDateObj.isLeapYear()) {
      targetDay = 28;
    }
    return targetDateObj.month(sourceDateObj.month()).date(targetDay);
  };

  useEffect(() => {
    store.dispatch(MDActions.getAllAPPredefinedValueByTenantId());
  }, [tenantId]);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: async (value) => {
                const errorMsg = ValidationUtil.YEAR_INPUT(value);

                if (!errorMsg) {
                  try {
                    const isFound = await axios.get(
                      AppConfig.API_BASE_URL +
                      `v2/annual-health-plan/year/${value}`
                    );
                    return ValidationUtil.VALIDATION_DUPLICATION(
                      isFound,
                      targetId,
                      'Same Plan Year Is Found.'
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }

                return errorMsg;
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-planYear"
                fullWidth
                {...field}
                required
                onChange={(event) => {
                  field.onChange(event.target.value);
                  setTimeout(
                    debounce(() => {
                      trigger('planYearName');
                      handleYearChange();
                    }, 500)
                  );
                }}
                error={error}
                helperText={error ? error.message : null}
                label="Plan Year"
              />
            )}
            name="planYearName"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-tpa">TPA</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    labelId="label-tpa"
                    onBlur={() => trigger('tpaId')}
                    id="input-tpa">
                    {tpaOptions &&
                      tpaOptions.map((item) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                  </Select>

                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="tpaId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-pbm">PBM</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    onBlur={() => trigger('pbmId')}
                    labelId="label-pbm"
                    id="input-pbmName">
                    {pbmOptions &&
                      pbmOptions.map((item) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                  </Select>

                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="pbmId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-slmName">Stop Loss MGU</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    onBlur={() => trigger('slmId')}
                    labelId="label-slmName"
                    id="input-slmName">
                    {slmOptions &&
                      slmOptions.map((item) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="slmId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-slcName">Stop Loss Carrier</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    labelId="label-slcName"
                    id="input-slcName">
                    {slcOptions &&
                      slcOptions.map((item) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="slcId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) =>
                  ValidationUtil.START_DATE(
                    getValues('planYearName'),
                    value,
                    getValues('endDate'),
                    true
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  disabled={disableDateInput}
                  maxDate={watchPlanEndDate ? watchPlanEndDate : undefined}
                  format={Constant.FORMAT_DATE}
                  value={getValues('startDate') ? moment.utc(getValues('startDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-planStartDate"
                  label="Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="startDate"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) =>
                  ValidationUtil.END_DATE(
                    getValues('planYearName'),
                    getValues('startDate'),
                    value,
                    true
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  disabled={disableDateInput}
                  minDate={watchPlanStartDate ? watchPlanStartDate : undefined}
                  format={Constant.FORMAT_DATE}
                  value={getValues('endDate') ? moment.utc(getValues('endDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-planEndDate"
                  label="End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="endDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) =>
                  ValidationUtil.START_DATE(
                    getValues('planYearName'),
                    value,
                    getValues('dedEndDate'),
                    true
                  ),
                rule2: (value) =>
                  ValidationUtil.IN_RANGE(
                    getValues('startDate'),
                    getValues('endDate'),
                    value,
                    'The Date should be within contract period'
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  disabled={disableDateInput}
                  maxDate={watchDedEndDate ? watchDedEndDate : undefined}
                  format={Constant.FORMAT_DATE}
                  value={getValues('dedStartDate') ? moment.utc(getValues('dedStartDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-dedStartDate"
                  label="Deductible Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="dedStartDate"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) =>
                  ValidationUtil.END_DATE(
                    getValues('planYearName'),
                    getValues('dedStartDate'),
                    value,
                    true
                  ),
                rule2: (value) =>
                  ValidationUtil.IN_RANGE(
                    getValues('startDate'),
                    getValues('endDate'),
                    value,
                    'The Date should be within contract period'
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <KeyboardDatePicker
                clearable
                {...field}
                required
                disabled={disableDateInput}
                minDate={watchDedStartDate ? watchDedStartDate : undefined}
                format={Constant.FORMAT_DATE}
                value={
                  getValues('dedEndDate')
                    ? moment.utc(getValues('dedEndDate'))
                    : null
                }
                error={error}
                helperText={error ? error.message : null}
                id="input-dedEndDate"
                label="Deductible End Date"
                fullWidth
              />
            )}
            name="dedEndDate"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AnnualPlanDetail;
