import * as Actions from '../actions/Actions';

let initialState = {
  tpaLoading: false,
  tpaSelectionLoading: false,
  pbmLoading: false,
  pbmSelectionLoading: false,
  slcLoading: false,
  slcSelectionLoading: false,
  slmguLoading: false,
  slmguSelectionLoading: false,
  planSponsor: undefined,
  planSponsorLoading: true,
  tenantTpas: [],
  systemTpas: [],
  tenantPBMs: [],
  systemPBMs: [],
  tenantSLCs: [],
  systemSLCs: [],
  tenantSLMGUs: [],
  systemSLMGUs: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.MASTER_TABLE_SAVE_TPA_FOR_TENENT:
    case Actions.MASTER_TABLE_SAVE_TPA:
      return {
        ...state,
        tpaSelectionLoading: true
      };
    case Actions.MASTER_TABLE_SAVE_TPA_FOR_TENENT_SUCCESS:
    case Actions.MASTER_TABLE_SAVE_TPA_FOR_TENENT_FAILURE:
    case Actions.MASTER_TABLE_SAVE_TPA_SUCCESS:
    case Actions.MASTER_TABLE_SAVE_TPA_FAILURE:
      return {
        ...state,
        tpaSelectionLoading: false
      };
    case Actions.MASTER_TABLE_GET_TPA_FOR_TENENT:
      return {
        ...state,
        tenantTpas: [],
        tpaLoading: true
      };
    case Actions.MASTER_TABLE_GET_TPA_FOR_TENENT_SUCCESS:
      return {
        ...state,
        tenantTpas: action.data,
        tpaLoading: false
      };
    case Actions.MASTER_TABLE_GET_TPA_FOR_TENENT_FAILURE:
      return {
        ...state,
        tpaLoading: false
      };
    case Actions.MASTER_TABLE_GET_ALL_TPA:
      return {
        ...state,
        systemTpas: [],
        tpaSelectionLoading: true
      };
    case Actions.MASTER_TABLE_GET_ALL_TPA_SUCCESS:
      return {
        ...state,
        systemTpas: action.data,
        tpaSelectionLoading: false
      };
    case Actions.MASTER_TABLE_GET_ALL_TPA_FAILURE:
      return {
        ...state,
        tpaSelectionLoading: false
      };
    case Actions.MASTER_TABLE_SAVE_PBM:
      return {
        ...state,
        pbmSelectionLoading: true
      };
    case Actions.MASTER_TABLE_SAVE_PBM_SUCCESS:
    case Actions.MASTER_TABLE_SAVE_PBM_FAILURE:
      return {
        ...state,
        pbmSelectionLoading: false
      };
    case Actions.MASTER_TABLE_GET_PBM_FOR_TENENT:
      return {
        ...state,
        tenantPBMs: [],
        pbmLoading: true
      };
    case Actions.MASTER_TABLE_GET_PBM_FOR_TENENT_SUCCESS:
      return {
        ...state,
        tenantPBMs: action.data,
        pbmLoading: false
      };
    case Actions.MASTER_TABLE_GET_PBM_FOR_TENENT_FAILURE:
      return {
        ...state,
        pbmLoading: false
      };
    case Actions.MASTER_TABLE_GET_ALL_PBM:
      return {
        ...state,
        systemPBMs: [],
        pbmSelectionLoading: true
      };
    case Actions.MASTER_TABLE_GET_ALL_PBM_SUCCESS:
      return {
        ...state,
        systemPBMs: action.data,
        pbmSelectionLoading: false
      };
    case Actions.MASTER_TABLE_GET_ALL_PBM_FAILURE:
      return {
        ...state,
        pbmSelectionLoading: false
      };
    case Actions.MASTER_TABLE_SAVE_SLC:
      return {
        ...state,
        pbmSelectionLoading: true
      };
    case Actions.MASTER_TABLE_SAVE_SLC_SUCCESS:
    case Actions.MASTER_TABLE_SAVE_SLC_FAILURE:
      return {
        ...state,
        pbmSelectionLoading: false
      };
    case Actions.MASTER_TABLE_GET_SLC_FOR_TENENT:
      return {
        ...state,
        tenantSLCs: [],
        slcLoading: true
      };
    case Actions.MASTER_TABLE_GET_SLC_FOR_TENENT_SUCCESS:
      return {
        ...state,
        tenantSLCs: action.data,
        slcLoading: false
      };
    case Actions.MASTER_TABLE_GET_SLC_FOR_TENENT_FAILURE:
      return {
        ...state,
        slcLoading: false
      };
    case Actions.MASTER_TABLE_GET_ALL_SLC:
      return {
        ...state,
        systemSLCs: [],
        slcSelectionLoading: true
      };
    case Actions.MASTER_TABLE_GET_ALL_SLC_SUCCESS:
      return {
        ...state,
        systemSLCs: action.data,
        slcSelectionLoading: false
      };
    case Actions.MASTER_TABLE_GET_ALL_SLC_FAILURE:
      return {
        ...state,
        slcSelectionLoading: false
      };
    case Actions.MASTER_TABLE_GET_SLMGU_FOR_TENENT:
      return {
        ...state,
        tenantSLMGUs: [],
        slmguLoading: true
      };
    case Actions.MASTER_TABLE_GET_SLMGU_FOR_TENENT_SUCCESS:
      return {
        ...state,
        tenantSLMGUs: action.data,
        slmguLoading: false
      };
    case Actions.MASTER_TABLE_GET_SLMGU_FOR_TENENT_FAILURE:
      return {
        ...state,
        slmguLoading: false
      };
    case Actions.MASTER_TABLE_GET_ALL_SLMGU:
      return {
        ...state,
        systemSLMGUs: [],
        slmguSelectionLoading: true
      };
    case Actions.MASTER_TABLE_GET_ALL_SLMGU_SUCCESS:
      return {
        ...state,
        systemSLMGUs: action.data,
        slmguSelectionLoading: false
      };
    case Actions.MASTER_TABLE_GET_ALL_SLMGU_FAILURE:
      return {
        ...state,
        slmguSelectionLoading: false
      };
    case Actions.MASTER_TABLE_SAVE_PLAN_SPONSOR:
    case Actions.MASTER_TABLE_FETCH_PLAN_SPONSOR:
      return {
        ...state,
        planSponsorLoading: true
      };
    case Actions.MASTER_TABLE_SAVE_PLAN_SPONSOR_SUCCESS:
    case Actions.MASTER_TABLE_FETCH_PLAN_SPONSOR_SUCCESS:
      return {
        ...state,
        planSponsor: action.data,
        planSponsorLoading: false
      };
    case Actions.MASTER_TABLE_SAVE_PLAN_SPONSOR_FAILURE:
    case Actions.MASTER_TABLE_FETCH_PLAN_SPONSOR_FAILURE:
      return {
        ...state,
        planSponsorLoading: false
      };
    default:
      return state;
  }
};
