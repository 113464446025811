import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import MaterialTable from '@material-table/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';

const materialTableTheme = createTheme({
  palette: {
    secondary: {
      main: '#3F51B5'
    }
  },
  header: {
    backgroundColor: '#01579b'
  }
});

export const FoMaterialTable = (props) => (
  <ThemeProvider theme={materialTableTheme}>
    <MaterialTable
      {...props}
      options={{
        ...props.options,
        addRowPosition: 'first',
        headerStyle: props?.options?.headerStyle
          ? props.options.headerStyle
          : {
              textTransform: 'uppercase'
            }
      }}
      icons={{
        Search: () => <SearchIcon color="primary"/>,
        Add: () => <AddIcon color="gray"/>
      }}
    />
  </ThemeProvider>
);
