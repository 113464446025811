import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  Grid,
  Typography,
  AccordionActions,
  IconButton,
  FormControl,
  FormLabel
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { store } from 'store/store';
import * as Actions from './actions/PlanDesignDataAction';
import * as Constant from 'common/Constant';
import { confirmDialog } from 'common/ComfirmationDialog';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from 'common/BstAccordion';

const PlanDesignDataNetwork = ({ item, handleEdit, handleExpend }) => {
  const allElectionTiers = useSelector(
    (state) => state.MD.mdCommon.allElectionTiers
  );

  const [electionTierMap, setElectionTierMap] = useState({});
  const [electionTierHraMap, setElectionTierHraMap] = useState({});

  const [cloned, setCloned] = useState(item);

  useEffect(() => {
    if (item && item.hsaTiers && Array.isArray(item.hsaTiers)) {
      let tierMap = {};

      item.hsaTiers.map((item) => {
        tierMap[item.tierId] = item.amount;
      });
      setElectionTierMap(tierMap);
    } else {
      setElectionTierMap({});
    }

    if (item && item.hraTiers && Array.isArray(item.hraTiers)) {
      let tierMap = {};

      item.hraTiers.map((item) => {
        tierMap[item.tierId] = item.amount;
      });
      setElectionTierHraMap(tierMap);
    } else {
      setElectionTierHraMap({});
    }
  }, [item]);

  const edit = () => {
    if (handleEdit) handleEdit(item);
  };

  const deleteTarget = () => {
    if (item.id && item.id > 0) {
      confirmDialog(() => store.dispatch(Actions.deleteNetwork(item.id)));
    }
  };

  const handleStateChange = (expend) => {
    if (handleExpend) {
      handleExpend(item, expend);
    }
    setCloned({ ...item, default_open: expend });
  };

  return (
    <>
      <Accordion
        defaultExpanded={cloned ? cloned.default_open : false}
        onChange={(event, expend) => handleStateChange(expend)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id={'network-header-' + item.id}
        >
          <Typography variant="button" display="block" gutterBottom>
            {item.networkType}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container bgcolor="primary.main">
            <Grid item xs={5}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-type' + item.id}
                    label="Type"
                    value={item.networkType}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-deductibleIn' + item.id}
                    label="Deductible IND"
                    value={item.deductibleIn}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-deductibleFAM' + item.id}
                    label="Deductible FAM"
                    value={item.deductibleFAM}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-maxOutOfPocketIn' + item.id}
                    label="Max OOP IND"
                    value={item.maxOutOfPocketIn}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-maxOutOfPocketFAM' + item.id}
                    label="Max OOP FAM"
                    value={item.maxOutOfPocketFAM}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-coInsurance' + item.id}
                    label="Co-Insurance"
                    value={item.coInsurance}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-pcpCopay' + item.id}
                    label="PCP Copay"
                    value={item.pcpCopay}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-scpCopay' + item.id}
                    label="SPC Copay"
                    value={item.scpCopay}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-urgentCareCopay' + item.id}
                    label="Urgent Care Copay"
                    value={item.urgentCareCopay}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-emergencyRoomCopay' + item.id}
                    label="ER Copay"
                    value={item.emergencyRoomCopay}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-rxCopay1' + item.id}
                    label="RX Copay 1"
                    value={item.rxCopay1}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-rxCopay2' + item.id}
                    label="RX Copay 2"
                    value={item.rxCopay2}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-rxCopay3' + item.id}
                    label="RX Copay 3"
                    value={item.rxCopay3}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-rxCopay4' + item.id}
                    label="RX Copay 4"
                    value={item.rxCopay4}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={'readonly-teleMedCoPay' + item.id}
                    label="Telemedicine Copay"
                    value={item.teleMedCoPay}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          HSA Contribution Basis
                        </FormLabel>
                        <TextField
                          key={'readonly-hsaType' + item.id}
                          value={Constant.KEY_HSA_TYPE_MAP[item.hsaType]}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="row">
                    {item.hsaType === Constant.KEY_HSA_TYPE_IN_FAMILY && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            key={'readonly-hsaIn' + item.id}
                            label="HSA IND"
                            value={item.hsaIn}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            key={'readonly-hsaFam' + item.id}
                            label="HSA FAM"
                            value={item.hsaFam}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </>
                    )}
                    {item.hsaType === Constant.KEY_HSA_TYPE_ELECTION_TIER && (
                      <>
                        {allElectionTiers.map((e, index) => (
                          <Fragment key={`key-hsaTiers-tier-amount-${index}`}>
                            <Grid item xs={12}>
                              <TextField
                                id={'readonly-hsaTiers-tier-amount' + e.id}
                                key={'key-' + item.id + '-'}
                                label={e.electionTier}
                                InputProps={{ readOnly: true }}
                                value={electionTierMap[e.id]}
                              />
                            </Grid>
                          </Fragment>
                        ))}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          HRA Contribution Basis
                        </FormLabel>
                        <TextField
                          key={'readonly-hraType' + item.id}
                          value={Constant.KEY_HSA_TYPE_MAP[item.hraType]}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="row">
                    {item.hraType === Constant.KEY_HSA_TYPE_IN_FAMILY && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            key={'readonly-hraIn' + item.id}
                            label="HRA IND"
                            value={item.hraIn}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            key={'readonly-hraFam' + item.id}
                            label="HRA FAM"
                            value={item.hraFam}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </>
                    )}
                    {item.hraType === Constant.KEY_HSA_TYPE_ELECTION_TIER && (
                      <>
                        {allElectionTiers.map((e, index) => (
                          <Fragment key={`key-hraTiers-tier-amount-${index}`}>
                            <Grid item xs={12}>
                              <TextField
                                id={'readonly-hraTiers-tier-amount' + e.id}
                                key={'key-' + item.id + '-'}
                                label={e.electionTier}
                                InputProps={{ readOnly: true }}
                                value={electionTierHraMap[e.id]}
                              />
                            </Grid>
                          </Fragment>
                        ))}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <IconButton size="small" onClick={edit}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={deleteTarget}>
            <Delete />
          </IconButton>
        </AccordionActions>
      </Accordion>
    </>
  );
};

export default PlanDesignDataNetwork;
