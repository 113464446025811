import React, { useState, useEffect, useRef } from 'react';
import * as Constant from 'common/Constant';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as UserTypes from 'features/user/userTypes';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import MaterialTable from '@material-table/core';
import { getAllBrokers } from 'features/user/userActions';
import { fetchBrokerCompanyMapping } from '../vendor-labeling/actions/action';
import * as Action from './action/action';
import { Checkbox } from '@material-ui/core';
import { confirmDialog } from 'common/ComfirmationDialog';
import BrokerCreationDialog from './components/BrokerCreationDailog';
import SearchIcon from '@material-ui/icons/Search';

const BrokerManagement = () => {
  const tableRef = useRef({});

  const shouldRefresh = useSelector(
    (state) => state.brokerManagement.shouldRefresh
  );
  const [creationOpen, setCreationOpen] = useState(false);

  const tenantData = useRef({});

  const handleCreationOpen = (rowData) => {
    tenantData.current = rowData;
    setCreationOpen(true);
  };

  const handleCreationClose = () => {
    setCreationOpen(false);
  };

  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);

  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleBrokerActiveCheckboxChange = (row) => {
    const payload = {
      code: row.code,
      name: row.name,
      active: row.active
    };
    confirmDialog(
      () => store.dispatch(Action.updateBroker(payload, row.id)),
      `Are you sure you want to change the status of "${payload.name}" to ${payload.active ? 'active' : 'inactive'
      }?`
    );
  };

  useEffect(() => {
    refresh();
  }, [shouldRefresh]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    store.dispatch(fetchBrokerCompanyMapping());
  }, []);

  useEffect(() => {
    store.dispatch(getAllBrokers());
  }, [shouldRefresh]);

  return (
    <>
      <MaterialTable
        title=""
        style={{ width: '100%' }}
        tableRef={tableRef}
        columns={[
          { title: 'Code', field: 'code', editable: 'never' },
          { title: 'Name', field: 'name', editable: 'never' },
          {
            title: 'Active',
            field: 'active',
            type: 'boolean',
            cellStyle: { textAlign: 'start' },
            headerStyle: { textAlign: 'center' },
            render: (rowdata) => (
              <Checkbox
                checked={rowdata.active}
                onChange={(_, checked) =>
                  handleBrokerActiveCheckboxChange({
                    ...rowdata,
                    active: checked
                  })
                }
                inputProps={{ 'aria-label': 'broker active checkbox' }}
                id={`input-activeCheckbox-broker-active${rowdata.id}`}
                color="primary"
              />
            )
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.FO_INITIAL_URL + `/broker/search`, {
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'code',
                searchTerm: query.search
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((_) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize,
          debounceInterval: 400
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          (data) => ({
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleCreationOpen(rowData);
            },
            disabled: data.isDefault
          }),
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            onClick: () => {
              handleCreationOpen({});
            },
            isFreeAction: true
          }
        ]}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
      />
      <BrokerCreationDialog
        open={creationOpen}
        target={tenantData.current}
        onClose={handleCreationClose}
      />
    </>
  );
};

export default BrokerManagement;
