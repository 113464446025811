import LocalStore from 'utils/LocalStore';
import * as UserTypes from 'features/user/userTypes';
import { store } from 'store/store';
import Auth from 'auth/Auth';

export const checkAuth = () => {
  const jwt = LocalStore.getAccessToken();

  if (!jwt) {
    return false;
  } else if (Auth.getLoginMode() === Auth.LOGIN_MODE_HST && Auth.isJwtExpired(jwt)) {
    return false;
  }

  return jwt;
};

export const dispatchJwt = (jwt) => {
  store.dispatch({
    type: UserTypes.JWT,
    payload: jwt
  });
};
