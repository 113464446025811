import { AppConfig } from 'AppConfig';
import { MDBBtn, MDBContainer, MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LocalStore from 'utils/LocalStore';
import { DIJobsTable } from './DIJobsTable';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';
class DIJobsComponent extends Component {
  state = {
    isLoading: false,
    statusData: []
  };
  componentWillUnmount = () => {
    clearInterval(this.diInterval);
  };

  onGetDIJobStatusClick = async () => {
    // 1. Get DI Job ID
    const latestJobId = await this.getDIJObId();

    // 2. If there's ID, call with setInterval
    if (latestJobId) {
      // If there;s already an interval, clear it.
      if (this.diInterval) {
        clearInterval(this.diInterval);
      }
      // Create new interval instance
      this.diInterval = setInterval(() => {
        const hasJobCompleted = this.state.statusData.find(
          ({ process, status }) =>
            process.toLowerCase() === 'Masterdata Loading Job'.toLowerCase() &&
            status.toLowerCase() === 'success'
        );
        console.log('hasJobCompleted', hasJobCompleted);
        if (hasJobCompleted) {
          clearInterval(this.diInterval);
          return;
        }
        this.fetchDIJobStatus(latestJobId);
      }, 5000);
    }
  };

  fetchDIJobStatus = async (jobId) => {
    this.setState({ isLoading: true });
    try {
      const statusResponse = await fetch(
        AppConfig.API_BASE_URL + `dijob/getStatusById?jobId=${jobId}`,
        {
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + LocalStore.getAccessToken()
          }
        }
      );
      if (!statusResponse.ok) {
        throw new Error('Network Error');
      }
      const diJobStatusData = await statusResponse.json();
      if (diJobStatusData) {
        const modifiedData = diJobStatusData
          .sort((a, b) => new Date(b.createdDttm) - new Date(a.createdDttm))
          .map((row) => {
            return {
              jobId: row.jobId,
              jobName: row.jobName,
              process: row.process,
              status: row.status
            };
          });
        this.setState({ statusData: modifiedData, isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      store.dispatch({
        type: AppActionTypes.APPSTATE_SET_APP_ERROR,
        payload: error.message
      });
    }
  };

  getDIJObId = async () => {
    try {
      this.setState({ isLoading: true });
      const apiResponse = await fetch(
        AppConfig.API_BASE_URL + `dijob/getDIJobId`,
        {
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + LocalStore.getAccessToken()
          }
        }
      );
      if (!apiResponse.ok) {
        throw new Error('Cannot get DI Job Id');
      }
      const id = await apiResponse.text();
      if (!id) {
        store.dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'No DI Jobs status found!.'
        });
      }

      this.setState({ isLoading: false });
      return id;
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error.message);
      return undefined;
    }
  };
  onExecute = async () => {
    try {
      const response = await fetch(AppConfig.API_BASE_URL + `dijob`, {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + LocalStore.getAccessToken()
        }
      });
      if (!response.ok) {
        throw new Error('FMR/MPR job execution failed.');
      }
      this.setState({ isLoading: false });
      store.dispatch({
        type: AppActionTypes.APP_REST_CALL_SUCESSED,
        payload: 'FMR/MPR job execution success.'
      });
    } catch (error) {
      this.setState({ isLoading: false });
      store.dispatch({
        type: AppActionTypes.APPSTATE_SET_APP_ERROR,
        payload: error.message
      });
    }
  };
  renderDIJobs = () => {
    const { statusData } = this.state;
    return (
      <MDBContainer fluid>
        <div className="clearfix border border-primary p-3 m-2">
          <p className="float-left ml-3">FMR/ MPR Job</p>
          <MDBBtn
            rounded
            color="primary float-right"
            onClick={() => {
              this.onGetDIJobStatusClick();
            }}>
            <MDBIcon far className="mr-2" />
            Get DI Jobs Status
          </MDBBtn>
          <MDBBtn
            rounded
            color="primary float-right"
            onClick={() => {
              this.setState({ isLoading: true });
              this.onExecute();
            }}>
            <MDBIcon far icon="check-circle" className="mr-2" />
            Execute
          </MDBBtn>
        </div>
        {statusData.length > 0 ? (
          <div className="clearfix  p-3 m-2">
            <h4 style={{ textAlign: 'center' }}>DI Jobs Status</h4>
            <DIJobsTable rows={statusData} />
          </div>
        ) : null}
      </MDBContainer>
    );
  };
  render = () => {
    return <>{this.renderDIJobs()}</>;
  };
}
const mapStateToPropsSelector = createStructuredSelector({});

export const DiJobs = connect(mapStateToPropsSelector)(DIJobsComponent);
