import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import ManageReportDetail from '../manageReports/detail';

const EditDialog = (props) => {
  const { onClose, open, target } = props;

  const handleCancel = () => {
    onClose(null);
  };

  const handleSubmit = (data) => {
    onClose(data);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCancel} maxWidth="lg">
        <DialogTitle id="dialog-edit-existing-report" onClose={handleCancel}>
          Edit
        </DialogTitle>
        <DialogContent dividers>
          <ManageReportDetail
            isEdit
            report={target}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditDialog;
