import React, { useState, useEffect, useRef } from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as Constant from 'common/Constant';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import {v4 as uuid} from 'uuid';
import * as AppActionTypes from 'store/actions/appstate';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Action from './action/action';
import * as RoleAction from '../roleManagement/action/action';
import { useSelector } from 'react-redux';
import * as UserTypes from 'features/user/userTypes';
import RoleManagementDialog from './components/groupManagementDialog';
import MaterialTable from '@material-table/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const GroupManagementTable = () => {
  const tableRef = useRef();
  const classes = useStyles();

  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [creationOpen, setCreationOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});

  const roleList = useSelector((state) => state.adminRoleManagement.roleList);
  const shouldRefresh = useSelector(
    (state) => state.adminGroupManagement.shouldRefresh
  );

  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange({ page: 0 });
  };
  useEffect(() => {
    refresh();
  }, [shouldRefresh]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    store.dispatch(RoleAction.fetchRoleList());
  }, []);

  const handleDelete = (groupId) => {
    confirmDialog(
      () => store.dispatch(Action.deleteUserGroup(groupId)),
      'Do you really want to delete this group?'
    );
  };

  const handleCreationOpen = (data) => {
    setCloneData(data);
    setCreationOpen(true);
  };

  const handleCreationClose = (data) => {
    if (data) {
      let dto = {
        ...data,
        rolesId: []
      };
      if (data.rolesId) {
        data.rolesId.forEach((item) => {
          dto.rolesId.push(item.id);
        });
      }
      store.dispatch(Action.manageUserGroup(dto));
    }
    setCreationOpen(false);
  };

  return (
    <>
      <RoleManagementDialog
        open={creationOpen}
        onClose={handleCreationClose}
        target={cloneData}
        roleList={roleList}
      />
      <MaterialTable
        title=""
        style={{ width: '100%' }}
        tableRef={tableRef}
        columns={[
          {
            title: 'Group Name',
            field: 'name',
            editable: 'never',
            width: '15%'
          },
          {
            title: 'Group Code',
            field: 'code',
            editable: 'never',
            width: '15%'
          },
          {
            title: 'Roles',
            field: 'roles',
            render: (rowData) => (
              <div className={classes.root}>
                {rowData.rolesResponse.map((data) => (
                  <Chip key={'key-' + uuid()} label={data.description} />
                ))}
              </div>
            ),
            sorting: false
          },
          {
            title: 'Permissions',
            field: 'permissions',
            render: (rowData) => (
              <div className={classes.root}>
                {rowData.rolesResponse.map((data) =>
                  data.permissions.map((permission) => (
                    <Chip key={'key-' + uuid()} label={permission.code} />
                  ))
                )}
              </div>
            ),
            sorting: false
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.IAM_BACKEND_URL + `/user-group/search`, {
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'id',
                searchTerm: query.search
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                store.dispatch({
                  type: AppActionTypes.APPSTATE_SET_APP_ERROR,
                  payload: 'Could not retrieve the chart.'
                });
              });
          })
        }
        actions={[
          (data) => ({
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (event, rowData) => {
              const data = {
                ...rowData,
                rolesId: rowData.rolesResponse.map((role) => ({
                  id: role.id,
                  code: role.description,
                  label: role.description
                }))
              };
              handleCreationOpen(data);
            },
            disabled: data.isDefault
          }),
          (data) => ({
            icon: 'delete',
            tooltip: !data.deletable ? "Can't Delete Default Group" : 'Delete',
            onClick: (event, rowData) => {
              handleDelete(rowData.id);
            },
            disabled: !data.deletable
          }),
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Create A New Role',
            onClick: () => {
              handleCreationOpen({});
            },
            isFreeAction: true
          }
        ]}
        onChangeRowsPerPage={setPageSize}
        options={{
          actionsColumnIndex: -1,
          search: true,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
        components={{ OverlayLoading: () => <div /> }}
      />
    </>
  );
};

export default GroupManagementTable;
