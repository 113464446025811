import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import MonthlyAggList from './aggList';
import { useForm, FormProvider } from 'react-hook-form';

const AggListDialog = (props) => {
  const {
    onClose,
    open,
    target,
    onCancel,
    tenantId,
    annualPlanId,
    currentPlanNames
  } = props;
  const methods = useForm({ defaultValues: target });
  const { reset } = methods;

  const handleClose = (data) => {
    onCancel(data);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog maxWidth="lg" open={open} onClose={handleClose}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Agg Factor
          </DialogTitle>
          <DialogContent dividers>
            <MonthlyAggList
              tenantId={tenantId}
              annualPlanId={annualPlanId}
              currentPlanNames={currentPlanNames}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary">
              OK
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AggListDialog;
