import React, { useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  TextField,
  Tooltip,
  Button,
  Link,
  makeStyles
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { Help } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';

import backgroundImage from '../../assets/img/log_bg.jpg';
import bstLogo from '../../assets/img/BST_logo.png';
import { AppConfig } from '../../AppConfig';

const useStyles = makeStyles(() => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw'
  },
  centerCard: {
    backgroundColor: 'transparent',
    maxWidth: '700px',
    minWidth: '400px',
    border: 'solid 1px rgb(201, 205, 209)',
    borderRadius: '4px',
    marginTop: '10rem',
    color: 'white',
    paddingBottom: '2rem'
  },
  header: {
    backgroundColor: 'rgb(201, 205, 209)',
    width: '100%',
    padding: '2rem 0'
  },
  body: {
    padding: '1rem'
  },
  divider: {
    width: '100%',
    backgroundColor: 'rgb(201, 205, 209)',
    margin: '1rem 0'
  },
  formInput: {
    backgroundColor: 'white'
  },
  formRow: {
    width: '80%',
    marginBottom: '2rem'
  },
  tooltip: {
    marginLeft: '1rem'
  },
  returnLink: {
    paddingTop: '1rem',
    color: 'yellow',
    textDecoration: 'underline'
  }
}));

export const ForgotPassword = () => {
  const styles = useStyles();
  const { control, handleSubmit } = useForm();
  const history = useHistory();

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data) => {
    console.log(`Submitting with ${JSON.stringify(data)}`);
    axios.post(`${AppConfig.IAM_BACKEND_URL}/auth/reset`, data).then((res) => {
        setSubmitted(true);
    }).catch((err) => {
        alert('Error contacting reset API. Please check your inputs and try again later.')
    })
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={styles.background}>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="center"
        alignItems="center"
        className={styles.centerCard}>
        <Box display="flex" justifyContent="center" className={styles.header}>
          <Box component="img" src={bstLogo} />
        </Box>
        {!submitted ? (
          <>
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="nowrap"
              alignItems="center"
              className={styles.body}>
              <Typography variant="h4">Forgot Your Password</Typography>
              <Divider variant="middle" className={styles.divider} />
              <Typography variant="body1">
                Enter your username and email and we will send you a link to
                reset your password
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              className={styles.formRow}
              alignItems="center">
              <Controller
                name="username"
                rules={{
                  required: 'This field is required.'
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="username-input"
                    label="Username"
                    className={styles.formInput}
                    variant="filled"
                    {...field}
                    fullWidth
                    error={error}
                    helperText={error?.message ?? null}
                  />
                )}
              />
              <Tooltip title="If you have forgotten your username, contact your account manager for assistance.">
                <Help className={styles.tooltip} />
              </Tooltip>
            </Box>
            <Controller
              name="email"
              rules={{
                required: 'This field is required.'
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="email-input"
                  label="Email"
                  className={`${styles.formInput} ${styles.formRow}`}
                  variant="filled"
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message ?? null}
                />
              )}
            />
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleSubmit(onSubmit)}>
              Reset Password
            </Button>
          </>
        ) : (
          <Typography variant="body1" className={styles.body}>
            If that username/email is in our system, you will receive an email
            to complete the reset process.
          </Typography>
        )}
        <Link
          className={styles.returnLink}
          href=""
          variant="body1"
          onClick={() => history.push('/login')}>
          Return to Login
        </Link>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
