import {
  Box, Grid,
  IconButton, TextField, Tooltip
} from '@material-ui/core';
import { Add, Delete, Edit, Link } from '@material-ui/icons';
import { AppConfig } from 'AppConfig';
import { confirmDialog } from 'common/ComfirmationDialog';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { store } from 'store/store';
import {v4 as uuid} from 'uuid';
import RouteConfig from '../../../../routes/RouteConfig';
import * as Action from '../actions/action';
import TagLabelingDialog from './tagLabelingDialog';


const TagLabelingListBrokerView = ({ data, handleSave }) => {

  const tagList = useSelector((state) => state.vendorLabeling.tagList);
  const brokerList = useSelector((state) => state.vendorLabeling.brokerList);

  const [creationOpen, setCreationOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});

  const history = useHistory();

  const handleCreationOpen = (inputData) => {
    setCloneData({
      selectedCompanyList: [],
      selectedBrokerList: brokerList.filter((item) => item.id === data.id),
      selectedTagList: [inputData]
    });
    setCreationOpen(true);
  }

  const handleCreationClose = (data) => {
    handleSave(data, tagList);
    setCreationOpen(false);
  }

  const handleDelete = (tagId, brokerId) => {
    confirmDialog(
      () => store.dispatch(Action.deleteLogoByTagIdAndBrokerId(tagId, brokerId)),
      'Are you sure you wish to remove this logo? It will be replaced with the default logo.'
    );
  };

  const getImgUrl = (item) => {
    const str = AppConfig.FO_INITIAL_URL +
      '/open-api/vendor-logo/tag/' + item.code;
    return data.code ? str + '/broker/' + data.code + '?' + item.updatedDttm : str;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        style={{ minWidth: '500px', padding: '20px' }}
      >
        <TagLabelingDialog
          open={creationOpen}
          onClose={handleCreationClose}
          target={cloneData}
        />
        {tagList && Array.isArray(tagList) && tagList.length > 0 ? (
          tagList.map((item, index) => (
            <Fragment key={'key-' + uuid()}>
              <Grid key={'key-' + uuid()} item xs={2}>
                <TextField
                  id={'readonly-tagCode-' + index}
                  key={'key-' + uuid()}
                  label="Tag Code"
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                  fullWidth
                  defaultValue={item.code}
                />
              </Grid>

              <Grid key={'key-' + uuid()} item xs={4}>
                <TextField
                  id={'readonly-tagDescription-' + index}
                  key={'key-' + uuid()}
                  label="Description"
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                  fullWidth
                  defaultValue={item.description}
                />
              </Grid>

              <Grid key={'key-' + uuid()} item xs={4}>
                <img
                  id={uuid()}
                  key={uuid()}
                  src={getImgUrl(item)}
                  alt="logo"
                  width="180"
                  height="38"
                />
              </Grid>

              <Grid key={'key-' + uuid()} item xs={2}>
                <Box
                  key={'key-' + uuid()}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <IconButton
                    id={'action-link-' + index}
                    key={'key-' + uuid()}
                    onClick={() => navigator.clipboard.writeText(getImgUrl(item))}>
                    <Tooltip title="Copy to clipboard">
                      <Link key={'key-' + uuid()} />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    id={'action-edit-' + index}
                    key={'key-' + uuid()}
                    onClick={() => handleCreationOpen(item)}>
                    <Edit key={'key-' + uuid()} />
                  </IconButton>
                  <IconButton
                    id={'action-delete-' + index}
                    key={'key-' + uuid()}
                    onClick={() => handleDelete(item.id, data.id)}>
                    <Delete key={'key-' + uuid()} />
                  </IconButton>
                </Box>
              </Grid>
            </Fragment>
          )
          )) : (
          <i>
            There is no tag in the system! Please click&nbsp;
            <IconButton
              onClick={() => history.push(RouteConfig.whiteLabeling.path + AppConfig.WHITE_LABEL_SUB_URL.TAG_MANAGEMENT)}
              key={'key-' + uuid()}
            >
              <Add key={'key' + uuid()} />
            </IconButton>
            &nbsp;to the tag management page to create one.
          </i>
        )}
      </Grid>
    </>
  )
}

export default TagLabelingListBrokerView;