import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const manageUserGroup = (data) => {
  return (dispatch) => {
    axios.post(AppConfig.IAM_BACKEND_URL + `/user-group`, data).then((m) => {
      dispatch({
        type: Types.SEARCH_REFRESH,
        data: m.data
      });
    });
  };
};

export const deleteUserGroup = (groupId) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.IAM_BACKEND_URL + `/user-group/id/${groupId}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};
