import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import EmployeClassDetail from './detail';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import SubmitButton from 'common/SingleClickButton';

const EmployeClassDetailDialog = React.memo((props) => {
  const { onClose, open, tenantId, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset, setError } = methods;

  const validate = async (data) => {
    if (!data.startDate || !data.endDate) {
      return;
    }
    return await axios.post(AppConfig.API_BASE_URL + 'v2/division/search', {
      tenantId: tenantId,
      divisionInfo: {
        startDate: data.startDate,
        endDate: data.endDate,
        divisionNumber: data.divisionNumber,
        excludeId: data.id
      }
    });
  };

  const handleClose = async (data) => {
    onClose(data);
  };

  const onSubmit = async (data) => {
    const conflictSearch = await validate(data);
    if (conflictSearch && conflictSearch.data.content.length) {
      return setError('startDate', {
        message: `The given start/end dates overlap with division: ${conflictSearch.data.content[0].name}`
      });
    }
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleClose();
          }}
          maxWidth="lg">
          <DialogTitle id="dialog-planDesignDataDetail">
            {target && target.id > 0 ? 'Edit' : 'New'}
          </DialogTitle>
          <DialogContent dividers>
            <EmployeClassDetail
              tenantId={tenantId}
              isNew={!(target && target.id > 0)}
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
});

export default EmployeClassDetailDialog;
