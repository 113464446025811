import * as Types from '../actions/types';
import * as Actions from '../actions/action';

let initialState = {
  shouldRefresh: Actions.SHOULD_REFRESH_INIT_VALUE,
  states: [],
  tagList: {},
  companyList: {},
  brokerList: {},
  brokerCompanyMapping: {}
};

export default function vendorLabelingReducer(state = initialState, action) {
  switch (action.type) {
    case Types.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        tagList: action.data ? action.data : state.tagList,
        shouldRefresh: !state.shouldRefresh
      };
    case Types.FETCH_TAG_LIST:
      return {
        ...state,
        tagList: action.data.sort((a, b) => a.code.localeCompare(b.code))
      };
    case Types.FETCH_BROKER_LIST:
      return {
        ...state,
        brokerList: action.data
      };
    case Types.FETCH_COMPANY_LIST:
      return {
        ...state,
        companyList: action.data
      };
    case Types.FETCH_BROKER_COMPANY_MAPPING:
      return {
        ...state,
        brokerCompanyMapping: action.data
      };
    default:
      return state;
  }
}
