import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Grid, InputLabel, MenuItem, Select, FormControl, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import * as Constant from 'common/Constant';
import { Controller, useFormContext } from 'react-hook-form';
import * as ValidationUtil from 'utils/ValidationUtil';
import * as MDActions from '../../../common/actions/CommonActions';
import { PLAN_RATE_STATUS_OPTIONS } from '../../../util/CommonUtil';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';

const PlanRateDetail = (props) => {
  const DUPLICATION_ERROR_MSG =
    'Duplicated plan rate found for same Plan, Election Tier and Status.';

  const { tenantId, isNew } = props;

  const { control, watch, setValue, getValues } = useFormContext();

  const watchPlanEndDate = watch('endDate', null);
  const watchPlanStartDate = watch('startDate', null);
  const annualPlanId = watch('annualPlanId', null);

  const employeClasses = useSelector(
    (state) => state.MD.mdCommon.employeClasses
  );
  const plansForYear = useSelector((state) => state.MD.mdCommon.plansForYear);
  const electionTiers = useSelector((state) => state.MD.mdCommon.electionTiers);

  useEffect(() => {
    store.dispatch(MDActions.getEmployeeClassTenantId());
    store.dispatch(MDActions.getElectionTiersTenantId());
  }, [tenantId]);

  useEffect(() => {
    store.dispatch(MDActions.getPlanByAnnualPlanId(annualPlanId));
  }, [annualPlanId]);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={4}>
          <Controller
            name="planYearName"
            rules={{ required: 'This field is required' }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="input-planYearName"
                fullWidth
                required
                disabled={!isNew}
                autoFocus
                helperText={error ? error.message : null}
                label="Plan Year"
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                planStartDate: (value) =>
                  ValidationUtil.START_DATE(
                    null,
                    value,
                    getValues('endDate'),
                    true
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  maxDate={watchPlanEndDate ? watchPlanEndDate : undefined}
                  error={error}
                  helperText={error ? error.message : null}
                  format={Constant.FORMAT_DATE}
                  value={getValues('startDate') ? moment.utc(getValues('startDate')) : null}
                  id="input-startDate"
                  label="Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="startDate"
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            rules={{
              required: 'This field is required',
              validate: async (value) => {
                const electionId = getValues('electionTierSchemaId');
                const planId = getValues('planId');
                const classSchemaId = getValues('classSchemaId');
                try {
                  const isFound = await axios.get(
                    AppConfig.API_BASE_URL +
                      `v2/plan-rate/plan-id/${planId}/election-id/${electionId}/status/${value}/class-id/${classSchemaId}`
                  );

                  if (
                    isFound &&
                    isFound.data &&
                    isFound.data.id !== getValues('id')
                  ) {
                    return DUPLICATION_ERROR_MSG;
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-status">Status</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    labelId="label-status"
                    id="input-status">
                    {PLAN_RATE_STATUS_OPTIONS.map((item) => {
                      return <MenuItem value={item.id}>{item.label}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="status"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="planId"
            rules={{
              required: 'This field is required',
              validate: async (value) => {
                const electionId = getValues('electionTierSchemaId');
                const status = getValues('status');
                const classSchemaId = getValues('classSchemaId');
                try {
                  const isFound = await axios.get(
                    AppConfig.API_BASE_URL +
                      `v2/plan-rate/plan-id/${value}/election-id/${electionId}/status/${status}/class-id/${classSchemaId}`
                  );

                  if (
                    isFound &&
                    isFound.data &&
                    isFound.data.id !== getValues('id')
                  ) {
                    return DUPLICATION_ERROR_MSG;
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-planName">Plan Name</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    labelId="label-planName"
                    id="input-planName">
                    {plansForYear &&
                      plansForYear.map((item) => {
                        return (
                          <MenuItem value={item.id}>{item.planName}</MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                planEndDate: (value) =>
                  ValidationUtil.END_DATE(
                    null,
                    getValues('startDate'),
                    value,
                    true
                  )
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  required
                  minDate={watchPlanStartDate ? watchPlanStartDate : undefined}
                  format={Constant.FORMAT_DATE}
                  value={getValues('endDate') ? moment.utc(getValues('endDate')) : null}
                  error={error}
                  helperText={error ? error.message : null}
                  id="input-endDate"
                  label="End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="endDate"
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          Rate Detail:
        </Grid>
        <Grid item xs={2}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: async (value) => {
                const planId = getValues('planId');
                const status = getValues('status');
                const classSchemaId = getValues('classSchemaId');
                try {
                  const isFound = await axios.get(
                    AppConfig.API_BASE_URL +
                      `v2/plan-rate/plan-id/${planId}/election-id/${value}/status/${status}/class-id/${classSchemaId}`
                  );

                  if (
                    isFound &&
                    isFound.data &&
                    isFound.data.id !== getValues('id')
                  ) {
                    return DUPLICATION_ERROR_MSG;
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-electionTiers">
                    Election Tier Schema
                  </InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    labelId="label-electionTiers"
                    id="input-electionTiers">
                    {electionTiers &&
                      electionTiers.map((item) => {
                        return (
                          <MenuItem value={item.id}>{item.etName}</MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="electionTierSchemaId"
            control={control}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            rules={{ required: Constant.ERROR_MSG_REQUIRED_FIELD }}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-classSchemaName">
                    Class Schema
                  </InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    labelId="label-classSchemaName"
                    id="input-classSchemaName">
                    {employeClasses &&
                      employeClasses.map((item) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                  </Select>
                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="classSchemaId"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  if (!ValidationUtil.IS_NUMBER(value)) {
                    return 'Input must be a number';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-rate"
                label="Total Monthly Rate ($)"
                fullWidth
                {...field}
                required
                error={error}
                type="number"
                onChange={(event) => {
                  let data = event.target.value;
                  field.onChange(data);
                  let employershare = getValues('employerShare');
                  let employeeShare = getValues('employeeShare');
                  let parsedValue = parseFloat(data);

                  if (employershare) {
                    setValue(`employeeShare`, parsedValue - employershare);
                  } else if (employeeShare) {
                    setValue(`employerShare`, parsedValue - employeeShare);
                  } else {
                    const half = parsedValue / 2;
                    setValue(`employerShare`, half);
                    setValue(`employeeShare`, half);
                  }
                }}
                helperText={error ? error.message : null}
              />
            )}
            name="rate"
            control={control}
            defaultValue=""
          />
        </Grid>

        <Grid item xs={2}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  if (!ValidationUtil.IS_NUMBER(value)) {
                    return 'Input must be a number';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-employerShare"
                label="Employer Share"
                fullWidth
                {...field}
                required
                error={error}
                type="number"
                onChange={(event) => {
                  let data = event.target.value;
                  field.onChange(data);
                  let parsedValue = parseFloat(data);
                  let total = parseFloat(getValues(`rate`));

                  let employeeShare = parseFloat(getValues[`employeeShare`]);

                  if (total) {
                    let c = total - parsedValue;

                    setValue(`employeeShare`, c);
                  } else if (employeeShare) {
                    setValue(`rate`, employeeShare + parsedValue);
                  }
                }}
                helperText={error ? error.message : null}
              />
            )}
            name="employerShare"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: {
                rule1: (value) => {
                  if (!ValidationUtil.IS_NUMBER(value)) {
                    return 'Input must be a number';
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-employeeShare"
                label="Employee Share"
                fullWidth
                {...field}
                required
                error={error}
                type="number"
                onChange={(event) => {
                  let data = event.target.value;
                  field.onChange(data);

                  let parsedValue = parseFloat(data);
                  let total = parseFloat(getValues(`rate`));

                  let employerShare = parseFloat(getValues(`employerShare`));

                  if (total) {
                    let c = total - parsedValue;

                    setValue(`employerShare`, c);
                  } else if (employerShare) {
                    setValue(`rate`, employerShare + parsedValue);
                  }
                }}
                helperText={error ? error.message : null}
              />
            )}
            name="employeeShare"
            control={control}
            defaultValue=""
          />
        </Grid>

        <Grid item xs={2}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-cobra"
                label="Rate %"
                fullWidth
                type="number"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="cobra"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-tobaccoSurcharge"
                label="Tobacco Surcharge"
                fullWidth
                type="number"
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="tobaccoSurcharge"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-wellnessDiscount"
                label="Wellness Discount"
                type="number"
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="wellnessDiscount"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-spousalSurcharge"
                label="Spousal Surcharge"
                type="number"
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="spousalSurcharge"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-ssdisabilitySurcharge"
                label="SS Disability Surcharge"
                type="number"
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="ssdisabilitySurcharge"
            control={control}
            defaultValue=""
          />
        </Grid>
      </Grid>
    </>
  );
};
export default PlanRateDetail;
