import axios from 'axios';

import { AppConfig } from 'AppConfig';
import { setAppError } from 'store/actions/appstate';

export const MASTER_TABLE_GET_TPA_FOR_TENENT =
  'MASTER_TABLE_GET_TPA_FOR_TENENT';
export const MASTER_TABLE_GET_TPA_FOR_TENENT_SUCCESS =
  'MASTER_TABLE_GET_TPA_FOR_TENENT_SUCCESS';
export const MASTER_TABLE_GET_TPA_FOR_TENENT_FAILURE =
  'MASTER_TABLE_GET_TPA_FOR_TENENT_FAILURE';

export const MASTER_TABLE_DELETE_TPA_FOR_TENENT =
  'MASTER_TABLE_DELETE_TPA_FOR_TENENT';
export const MASTER_TABLE_DELETE_TPA_FOR_TENENT_SUCCESS =
  'MASTER_TABLE_DELETE_TPA_FOR_TENENT_SUCCESS';
export const MASTER_TABLE_DELETE_TPA_FOR_TENENT_FAILURE =
  'MASTER_TABLE_DELETE_TPA_FOR_TENENT_FAILURE';

export const MASTER_TABLE_SAVE_TPA_FOR_TENENT =
  'MASTER_TABLE_SAVE_TPA_FOR_TENENT';
export const MASTER_TABLE_SAVE_TPA_FOR_TENENT_SUCCESS =
  'MASTER_TABLE_SAVE_TPA_FOR_TENENT_SUCCESS';
export const MASTER_TABLE_SAVE_TPA_FOR_TENENT_FAILURE =
  'MASTER_TABLE_SAVE_TPA_FOR_TENENT_FAILURE';

export const MASTER_TABLE_SAVE_TPA = 'MASTER_TABLE_SAVE_TPA';
export const MASTER_TABLE_SAVE_TPA_SUCCESS = 'MASTER_TABLE_SAVE_TPA_SUCCESS';
export const MASTER_TABLE_SAVE_TPA_FAILURE = 'MASTER_TABLE_SAVE_TPA_FAILURE';

export const MASTER_TABLE_DELETE_TPA = 'MASTER_TABLE_DELETE_TPA';
export const MASTER_TABLE_DELETE_TPA_SUCCESS =
  'MASTER_TABLE_DELETE_TPA_SUCCESS';
export const MASTER_TABLE_DELETE_TPA_FAILURE =
  'MASTER_TABLE_DELETE_TPA_FAILURE';

export const MASTER_TABLE_GET_ALL_TPA = 'MASTER_TABLE_GET_ALL_TPA';
export const MASTER_TABLE_GET_ALL_TPA_SUCCESS =
  'MASTER_TABLE_GET_ALL_TPA_SUCCESS';
export const MASTER_TABLE_GET_ALL_TPA_FAILURE =
  'MASTER_TABLE_GET_ALL_TPA_FAILURE';

export const MASTER_TABLE_SAVE_PBM = 'MASTER_TABLE_SAVE_PBM';
export const MASTER_TABLE_SAVE_PBM_SUCCESS = 'MASTER_TABLE_SAVE_PBM_SUCCESS';
export const MASTER_TABLE_SAVE_PBM_FAILURE = 'MASTER_TABLE_SAVE_PBM_FAILURE';

export const MASTER_TABLE_DELETE_PBM = 'MASTER_TABLE_DELETE_PBM';
export const MASTER_TABLE_DELETE_PBM_SUCCESS =
  'MASTER_TABLE_DELETE_PBM_SUCCESS';
export const MASTER_TABLE_DELETE_PBM_FAILURE =
  'MASTER_TABLE_DELETE_PBM_FAILURE';

export const MASTER_TABLE_GET_PBM_FOR_TENENT =
  'MASTER_TABLE_GET_PBM_FOR_TENENT';
export const MASTER_TABLE_GET_PBM_FOR_TENENT_SUCCESS =
  'MASTER_TABLE_GET_PBM_FOR_TENENT_SUCCESS';
export const MASTER_TABLE_GET_PBM_FOR_TENENT_FAILURE =
  'MASTER_TABLE_GET_PBM_FOR_TENENT_FAILURE';

export const MASTER_TABLE_GET_ALL_PBM = 'MASTER_TABLE_GET_ALL_PBM';
export const MASTER_TABLE_GET_ALL_PBM_SUCCESS =
  'MASTER_TABLE_GET_ALL_PBM_SUCCESS';
export const MASTER_TABLE_GET_ALL_PBM_FAILURE =
  'MASTER_TABLE_GET_ALL_PBM_FAILURE';

export const MASTER_TABLE_SAVE_SLC = 'MASTER_TABLE_SAVE_SLC';
export const MASTER_TABLE_SAVE_SLC_SUCCESS = 'MASTER_TABLE_SAVE_SLC_SUCCESS';
export const MASTER_TABLE_SAVE_SLC_FAILURE = 'MASTER_TABLE_SAVE_SLC_FAILURE';

export const MASTER_TABLE_DELETE_SLC = 'MASTER_TABLE_DELETE_SLC';
export const MASTER_TABLE_DELETE_SLC_SUCCESS =
  'MASTER_TABLE_DELETE_SLC_SUCCESS';
export const MASTER_TABLE_DELETE_SLC_FAILURE =
  'MASTER_TABLE_DELETE_SLC_FAILURE';

export const MASTER_TABLE_GET_SLC_FOR_TENENT =
  'MASTER_TABLE_GET_SLC_FOR_TENENT';
export const MASTER_TABLE_GET_SLC_FOR_TENENT_SUCCESS =
  'MASTER_TABLE_GET_SLC_FOR_TENENT_SUCCESS';
export const MASTER_TABLE_GET_SLC_FOR_TENENT_FAILURE =
  'MASTER_TABLE_GET_SLC_FOR_TENENT_FAILURE';

export const MASTER_TABLE_GET_ALL_SLC = 'MASTER_TABLE_GET_ALL_SLC';
export const MASTER_TABLE_GET_ALL_SLC_SUCCESS =
  'MASTER_TABLE_GET_ALL_SLC_SUCCESS';
export const MASTER_TABLE_GET_ALL_SLC_FAILURE =
  'MASTER_TABLE_GET_ALL_SLC_FAILURE';

export const MASTER_TABLE_GET_SLMGU_FOR_TENENT =
  'MASTER_TABLE_GET_SLMGU_FOR_TENENT';
export const MASTER_TABLE_GET_SLMGU_FOR_TENENT_SUCCESS =
  'MASTER_TABLE_GET_SLMGU_FOR_TENENT_SUCCESS';
export const MASTER_TABLE_GET_SLMGU_FOR_TENENT_FAILURE =
  'MASTER_TABLE_GET_SLMGU_FOR_TENENT_FAILURE';

export const MASTER_TABLE_GET_ALL_SLMGU = 'MASTER_TABLE_GET_ALL_SLMGU';
export const MASTER_TABLE_GET_ALL_SLMGU_SUCCESS =
  'MASTER_TABLE_GET_ALL_SLMGU_SUCCESS';
export const MASTER_TABLE_GET_ALL_SLMGU_FAILURE =
  'MASTER_TABLE_GET_ALL_SLMGU_FAILURE';

export const MASTER_TABLE_SAVE_SLMGU = 'MASTER_TABLE_SAVE_SLMGU';
export const MASTER_TABLE_SAVE_SLMGU_SUCCESS =
  'MASTER_TABLE_SAVE_SLMGU_SUCCESS';
export const MASTER_TABLE_SAVE_SLMGU_FAILURE =
  'MASTER_TABLE_SAVE_SLMGU_FAILURE';

export const MASTER_TABLE_DELETE_SLMGU = 'MASTER_TABLE_DELETE_SLMGU';
export const MASTER_TABLE_DELETE_SLMGU_SUCCESS =
  'MASTER_TABLE_DELETE_SLMGU_SUCCESS';
export const MASTER_TABLE_DELETE_SLMGU_FAILURE =
  'MASTER_TABLE_DELETE_SLMGU_FAILURE';

export const MASTER_TABLE_FETCH_PLAN_SPONSOR =
  'MASTER_TABLE_FETCH_PLAN_SPONSOR';
export const MASTER_TABLE_FETCH_PLAN_SPONSOR_SUCCESS =
  'MASTER_TABLE_FETCH_PLAN_SPONSOR_SUCCESS';
export const MASTER_TABLE_FETCH_PLAN_SPONSOR_FAILURE =
  'MASTER_TABLE_FETCH_PLAN_SPONSOR_FAILUTRE';

export const MASTER_TABLE_SAVE_PLAN_SPONSOR = 'MASTER_TABLE_SAVE_PLAN_SPONSOR';
export const MASTER_TABLE_SAVE_PLAN_SPONSOR_SUCCESS =
  'MASTER_TABLE_SAVE_PLAN_SPONSOR_SUCCESS';
export const MASTER_TABLE_SAVE_PLAN_SPONSOR_FAILURE =
  'MASTER_TABLE_SAVE_PLAN_SPONSOR_FAILURE';

export const fetchPlanSponsor = () => {
  return (dispatch) => {
    axios.get(AppConfig.API_BASE_URL + 'plansponsor').then((res) => {
      dispatch({
        type: MASTER_TABLE_FETCH_PLAN_SPONSOR_SUCCESS,
        data: res.data
      });
    });
  };
};

export const savePlanSponsor = (data) => {
  return (dispatch) => {
    dispatch({ type: MASTER_TABLE_SAVE_PLAN_SPONSOR });
    axios.post(AppConfig.API_BASE_URL + 'plansponsor', data).then((res) => {
      dispatch({
        type: MASTER_TABLE_SAVE_PLAN_SPONSOR_SUCCESS,
        data: res.data
      });
    });
  };
};
