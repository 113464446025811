import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import AddPoCDialogDetail from './AddPoCDialogDetail';

const AddPoCDialog = ({ open, onClose }) => {
  const methods = useForm({ mode: 'onBlur' });
  const { handleSubmit, reset } = methods;

  const handleClose = () => {
    onClose(null);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="add-poc-dialog-title">{'Add Point of Contact'}</DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <AddPoCDialogDetail />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmit(onSubmit)} color="primary" disabled={!methods.formState.isDirty}>
            Submit
          </Button>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPoCDialog;
