import { Model, attr } from 'redux-orm';

const defaultAttributes = {
  subgroup1: 'Default subgroup1'
};

export default class Trait extends Model {
  static modelName = 'Trait';

  static fields = {
    id: attr(),
    subgroup1: attr(),
    subgroup2: attr(),
    subgroup3: attr(),
    tpaName: attr(),
    claimType: attr(),
    roleName: attr(),
    tableauRole: attr()
  };

  static parse(data) {
    const parsedData = {
      ...data
    };

    return this.upsert(parsedData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherView) {
    this.update(otherView.ref);
  }
}
