import React, { useEffect, useState } from 'react';
import {
  TextField,
  Grid,
  Tooltip,
  Typography,
  List,
  ListSubheader,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {v4 as uuid} from 'uuid';
import * as Constant from 'common/Constant';
import { useSelector } from 'react-redux';
import { confirmDialog } from 'common/ComfirmationDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    marginTop: '-10px',
    marginLeft: '20px',
    backgroundColor: 'inherit',
    padding: 0
  }
}));

const BulkDeletionDialogDetail = (props) => {
  const { showReportList = false } = props;

  const classes = useStyles();
  const [reportOptions, setReportOptions] = useState([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [selectedBrokers, setSelectedBrokers] = useState([]);

  const { control, getValues, watch, setValue } = useFormContext();

  const brokerList = useSelector((state) => state.user.allBrokers);
  const brokerCompanyMapping = useSelector(
    (state) => state.vendorLabeling.brokerCompanyMapping
  );

  const watchTenant = watch('tenant');
  const displayTextList = useSelector((state) => state.fo.displayTexts);

  const tenantList = useSelector((state) => state.adminUserAccess.tenantList);

  const tenantFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.displayText}`
  });

  const brokerFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.name}`
  });

  useEffect(() => {
    if (displayTextList && Array.isArray(displayTextList)) {
      setReportOptions(
        displayTextList.sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 0
        )
      );
    }

    if (tenantList && Array.isArray(tenantList)) {
      setTenantOptions(
        tenantList.sort((a, b) =>
          a.displayText?.toLowerCase() < b.displayText?.toLowerCase() ? -1 : 0
        )
      );
    }

    if (brokerList && Array.isArray(brokerList)) {
      setBrokerOptions(
        brokerList.sort((a, b) =>
          a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 0
        )
      );
    }
  }, [displayTextList, brokerList, tenantList]);

  const onChangeBroker = () => {
    const brokers = getValues('broker');
    const brokersToAdd = brokers?.filter(
      (b) => !selectedBrokers.some((item) => item.code === b.code)
    );
    const brokersToRemove = selectedBrokers?.filter(
      (s) => !brokers.some((item) => item.code === s.code)
    );
    const currentSelectedTenenats = getValues('tenant');
    if (brokersToAdd && Array.isArray(brokersToAdd) && brokersToAdd.length) {
      brokersToAdd.forEach((broker) => {
        brokerCompanyMapping[broker.code].forEach((e) => {
          if (currentSelectedTenenats.some((item) => item.code === e.code)) {
            // tenant is already selected
            return;
          } else currentSelectedTenenats.push(e);
        });
      });
      setValue('tenant', currentSelectedTenenats);
    }

    if (
      brokersToRemove &&
      Array.isArray(brokersToRemove) &&
      brokersToRemove.length
    ) {
      confirmDialog(
        () => onRemoveBroker(brokersToRemove),
        'Do you want to remove all the tenants under the broker in this application?'
      );
    }
  };

  const onRemoveBroker = (brokersToRemove) => {
    const currentSelectedTenenats = getValues('tenant');
    brokersToRemove.forEach((r) => {
      brokerCompanyMapping[r.code].forEach((e) => {
        const itemToRemove = currentSelectedTenenats.findIndex(
          (item) => item.code === e.code
        );

        if (itemToRemove !== -1)
          currentSelectedTenenats.splice(itemToRemove, 1);
      });
    });
    setValue('tenant', currentSelectedTenenats);
  };

  return (
    <>
      <Grid container spacing={2} bgcolor="primary.main">
        <Grid item xs={showReportList === true ? 6 : 12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="broker"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    multiple
                    id="broker"
                    value={getValues('broker')}
                    options={brokerOptions}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ''
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={brokerFilterOptions}
                    onChange={(_, data) => {
                      field.onChange(data);
                      onChangeBroker();
                      setSelectedBrokers(data);
                    }}
                    filterSelectedOptions
                    renderTags={(value, getTagProps) =>
                      value.map((data, index) => (
                        <Chip
                          key={'key-' + uuid()}
                          {...getTagProps({ index })}
                          label={data.name}
                          variant={data?.active ? 'default' : 'outlined'}
                        />
                      ))
                    }
                    renderInput={(field) => (
                      <TextField
                        {...field}
                        id={'input-tenant'}
                        label="Broker"
                        placeholder="Chosen Broker"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="tenant"
                control={control}
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    fullWidth
                    id="tenants"
                    options={tenantOptions}
                    getOptionLabel={(option) =>
                      option.displayText ? option.displayText : ''
                    }
                    value={watchTenant}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={tenantFilterOptions}
                    onChange={(_, data) => field.onChange(data)}
                    filterSelectedOptions
                    defaultValue={getValues('tenant')}
                    renderTags={(value, getTagProps) =>
                      value.map((data, index) => {
                        return (
                          <Chip
                            key={'key-' + uuid()}
                            {...getTagProps({ index })}
                            label={data.displayText || data.name}
                            variant={data?.active ? 'default' : 'outlined'}
                          />
                        );
                      })
                    }
                    renderInput={(field) => (
                      <TextField
                        {...field}
                        id={'input-tenant'}
                        label="Tenants"
                        placeholder="Chosen Tenants"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="displayTextList"
                control={control}
                rules={{
                  required: Constant.ERROR_MSG_REQUIRED_FIELD
                }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    fullWidth
                    multiple
                    id="input-report-display-text"
                    options={reportOptions}
                    getOptionSelected={(option, value) => option === value}
                    onChange={(_, data) => field.onChange(data)}
                    filterSelectedOptions
                    defaultValue={getValues('displayTextList')}
                    renderTags={(value, getTagProps) =>
                      value.map((data, index) => (
                        <Chip
                          key={'key-' + uuid()}
                          {...getTagProps({ index })}
                          label={data}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={'input-report-display-text'}
                        label="Report Display Text"
                        placeholder="Chosen Reports"
                        required
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BulkDeletionDialogDetail;
