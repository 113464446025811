export const PREFIX = 'MD-DISCRETIONARY-BENEFITS';

export const DELETE_SUCCESS = PREFIX + 'DELETE_SUCCESS';
export const SEARCH_REFRESH = PREFIX + 'SEARCH_REFRESH';
export const GET_COVERAGE_CATEGORIES = PREFIX + 'GET_COVERAGE_CATEGORIES';
export const GET_COVERAGE_NAMES = PREFIX + 'GET_COVERAGE_NAMES';
export const CLEAR_COVERAGE_NAMES = PREFIX + 'CLEAR_COVERAGE_NAMES';
export const CLONE_BY_YEAR = PREFIX + 'CLONE_BY_YEAR';
export const VALIDATE_SUCCESS = PREFIX + 'VALIDATE_SUCCESS';
export const LOAD_SUCCESS = PREFIX + 'LOAD_SUCCESS';
export const DOWNLOAD_ERROR_LOGS = PREFIX + 'DOWNLOAD_ERROR_LOGS';
