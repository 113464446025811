import React, { useEffect } from 'react';
import { TextField, Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import * as Constant from 'common/Constant';
import * as ValidationUtil from 'utils/ValidationUtil';
import MomentUtils from "@date-io/moment";

const DivisionDetail = (props) => {
  const DUPLICATION_ERROR_MSG = 'Duplicated Name is Found.';

  const { tenantId, isNew, targetId } = props;

  const { control, watch, formState, setValue, getValues } = useFormContext();

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-name"
                fullWidth
                {...field}
                required
                error={error}
                helperText={error ? error.message : null}
                label="Name"
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="divisionNumber"
            rules={{ required: Constant.ERROR_MSG_REQUIRED_FIELD }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                {...field}
                required
                multiline
                error={error}
                helperText={error ? error.message : null}
                label="Division Number"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              validate: (value) => {
                return ValidationUtil.VALIDATE_START_END_DATE(
                  getValues('startDate'),
                  getValues('endDate')
                );
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  error={error}
                  helperText={error ? error.message : null}
                  format={Constant.FORMAT_DATE}
                  value={getValues('startDate') ? moment.utc(getValues('startDate')) : null}
                  id="input-startDate"
                  label="Start Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="startDate"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              validate: (value) => {
                return ValidationUtil.VALIDATE_START_END_DATE(
                  getValues('startDate'),
                  getValues('endDate')
                );
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  clearable
                  {...field}
                  error={error}
                  format={Constant.FORMAT_DATE}
                  value={getValues('endDate') ? moment.utc(getValues('endDate')) : null}
                  helperText={error ? error.message : null}
                  id="input-endDate"
                  label="End Date"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
            name="endDate"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default DivisionDetail;
