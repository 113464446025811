import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import LocalStore from 'utils/LocalStore';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const save = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `employeeclass/save`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const deleteByIds = (ids) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `employeeclass/delete`, ids)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => { });
  };
};

export const getAllByTenantId = (tenantId) => {
  return (dispatch) => {
    axios
      .get(
        AppConfig.API_BASE_URL +
        `employeeclass/findEmployeeClassWithPaging?from=0&limit=30&responseType=text`
      )
      .then((m) => {
        dispatch({
          type: Types.GET_ALL_DATA_BY_TENANT_ID_SUCCESS,
          data: m.data ? m.data.data : []
        });
      })
      .catch((err) => { });
  };
};
