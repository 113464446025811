import { Box, Checkbox, Chip, FormControl, Grid, InputLabel, Link, ListItemIcon, ListItemText, makeStyles, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from 'common/BstAccordion';
import { ALL_YEARS, ANNUAL_PLAN_RENEWAL_CASE_STATUS_LIST, ANNUAL_PLAN_RENEWAL_STAGES, CASE_STATUS, OPEN_CASES, RENEWAL_ACCOUNT_TYPE } from 'common/Constant';
import { INVALID_ANNUAL_PLAN_RENEWAL_CASE_STATUS } from 'common/error';
import { fetchAllPlanYears } from 'features/admin/mdRenewalManagement/action/action';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import * as AppActionTypes from 'store/actions/appstate';
import { store } from 'store/store';
import { v4 as uuid } from 'uuid';
import { getAnnualPlanRenewalData } from '../modules/action';
import AnnualPlanRenewalDetailPanel from './detail/AnnualPlanRenewalDetailPanel';

const AnnualPlanRenewal = () => {
  const dispatch = useDispatch();
  const { annualPlanRenewalList } = useSelector((state) => state.clientPortal);
  const { planYears: totalCaseYears } = useSelector((state) => state.mdRenewalManagement);

  const refreshCaseYears = () => {
    store.dispatch(fetchAllPlanYears());
  };

  const [caseYears, setCaseYears] = useState([ALL_YEARS]);
  const [caseStatuses, setCaseStatuses] = useState([OPEN_CASES]);

  const useStyles = makeStyles(() => ({
    select: {
      minWidth: 150
    },
    pending: {
      backgroundColor: alpha(red[500], 0.7),
      width: '160px'
    },
    done: {
      backgroundColor: alpha(green[500], 0.7),
      width: '160px'
    },
    notRequired: {
      backgroundColor: grey[200],
      width: '160px'
    },
    processing: {
      backgroundColor: alpha(blue[800], 0.7),
      width: '160px'
    },
    todo: {
      backgroundColor: alpha(grey[500], 0.7),
      width: '160px'
    },
    accordionSummary: {
      flexDirection: 'row-reverse'
    }
  }));
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    refreshCaseYears();
    dispatch(getAnnualPlanRenewalData([ALL_YEARS], [OPEN_CASES]));
    store.dispatch({
      type: AppActionTypes.SHOW_BST_BAR_TENANT,
      payload: false
    });
    return () => {
      store.dispatch({
        type: AppActionTypes.SHOW_BST_BAR_TENANT,
        payload: true
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelection = (event, defaultValue, setState) => {
    const inputVal = event.target.value;
    const selectedValue = inputVal.length === 0 || inputVal[inputVal.length - 1] === defaultValue 
      ? [defaultValue] : inputVal.filter((value) => value !== defaultValue);
    setState(selectedValue);
  }

  const handleClose = () => {
    dispatch(getAnnualPlanRenewalData(caseYears, caseStatuses));
  }

  const isAllSelected = caseYears.length === 1 && caseYears[0] === ALL_YEARS;
  const isOpenCasesSelected = caseStatuses.length === 1 && caseStatuses[0] === OPEN_CASES;

  const routeToRenewalQuestionnaire = (rowData) => {
    if (Array.isArray(annualPlanRenewalList) && annualPlanRenewalList.length > 0) {
      history.push({
        pathname: RouteConfig.clientPortalQuestionary.path,
        state: {
          caseId: rowData?.caseId,
          newPlanStartYear: rowData?.caseDueDate,
          tenantCode: rowData?.tenantCode,
          tenantName: rowData?.tenantName,
          renewalAccountType: rowData?.renewalAccountType
        }
      });
    }
  };
  const getComponent = (rowData, stage) => {
    const { renewalAccountType, status } = rowData || {};
    
    const renderChipWithLabel = (label, tooltip, className, onClick = null, dueDate = null) => (
        <>
            <Tooltip title={tooltip}>
              <Chip key={'key-' + uuid()} label={label} className={className} onClick={onClick} />
            </Tooltip>
            <br />
            <Typography variant="caption" style={{paddingLeft: '2.5rem'}}>{dueDate ? `Due ${dueDate}` : '\u00A0'}</Typography>
        </>
    );
    
    const renderChip = (label, tooltip, className, icon = null) => (
        <>
            {tooltip ?
              (<Tooltip title={tooltip}>
                <Chip key={'key-' + uuid()} label={label} className={className} icon={icon} />
              </Tooltip>) :
              <Chip key={'key-' + uuid()} label={label} className={className} icon={icon} />
            }
            {(status === CASE_STATUS.PENDING_CHANGE_FORM || status === CASE_STATUS.PENDING_MD_FILE) ? <><br /><Typography variant="caption">&nbsp;</Typography></>  : null}
        </>
    );
    
    switch (stage) {
        case ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.value:
            switch (true) {
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_CHANGE_FORM:
                    return renderChipWithLabel(
                        <u>{ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.label}</u>,
                        ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.tooltip,
                        classes.pending,
                        () => routeToRenewalQuestionnaire(rowData),
                        rowData?.surveyDueDate
                    );
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(null, ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.tooltip, classes.done, <DoneIcon fontSize="medium" />);
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.notRequired, ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.tooltip, classes.notRequired);
    
                default:
                    // If the renewal case data is invalid, display the error message in the second column.
                    return <>{INVALID_ANNUAL_PLAN_RENEWAL_CASE_STATUS}</>;
            };
        case ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.value:
            switch (true) {
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.notRequired, ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.tooltip, classes.notRequired);
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_MD_FILE:
                    return renderChipWithLabel(
                        <u>{ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.label}</u>,
                        ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.tooltip,
                        classes.pending,
                        null,
                        rowData?.mdFileDueDate
                    );
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(null, ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.tooltip, classes.done, <DoneIcon fontSize="medium" />);
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_CHANGE_FORM:
                    return renderChipWithLabel(
                        ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.label,
                        ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.tooltip,
                        classes.todo,
                        null,
                        rowData?.mdFileDueDate
                    );
    
                default:
                    return <></>;
            }
        case ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.value:
            switch (true) {
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_MD_FILE:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.label, ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.tooltip, classes.todo);
    
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.label, ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.tooltip, classes.processing);
    
                default:
                    return <></>;
            }
        case ANNUAL_PLAN_RENEWAL_STAGES.COMPLETED.value:
            switch (true) {
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.CORE && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.MD_ONLY && status === CASE_STATUS.READY_TO_RENEW:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_CHANGE_FORM:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.PENDING_MD_FILE:
                case renewalAccountType === RENEWAL_ACCOUNT_TYPE.ENHANCED && status === CASE_STATUS.READY_TO_RENEW:
                    return renderChip(ANNUAL_PLAN_RENEWAL_STAGES.COMPLETED.label, ANNUAL_PLAN_RENEWAL_STAGES.COMPLETED.tooltip, classes.todo);
    
                default:
                    return <></>;
            }
        default:
            return <></>;

        
    }
  };

  return (
    <>
        <Box sx={{ mt: 2, mb: 2 }}>
          <FormControl className={classes.select} size="medium">
            <InputLabel id="label_caseYear">Case Year:</InputLabel>
            <Select 
              multiple 
              id="input_caseYear" 
              key="input_caseYear" 
              onChange={(event) => handleSelection(event, ALL_YEARS, setCaseYears)}
              onClose={handleClose}
              value={caseYears} 
              renderValue={(caseYears) => caseYears.join(', ')}
            >
              <MenuItem value={ALL_YEARS}>
                <ListItemIcon>
                  <Checkbox checked={isAllSelected} />
                </ListItemIcon>
                <ListItemText primary={ALL_YEARS} />
              </MenuItem>
              {totalCaseYears &&
                Array.isArray(totalCaseYears) &&
                totalCaseYears.map((item) => (
                  <MenuItem value={item} key={item}>
                    <ListItemIcon>
                      <Checkbox checked={caseYears.indexOf(item) > -1 || isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={classes.select} size="medium">
            <InputLabel id="label_status">Case Status:</InputLabel>
            <Select
              multiple
              id="input_status"
              key="input_status"
              onChange={(event) => handleSelection(event, OPEN_CASES, setCaseStatuses)}
              onClose={handleClose}
              value={caseStatuses}
              renderValue={(selected) => 
                selected?.map(key => [[OPEN_CASES, OPEN_CASES], ...ANNUAL_PLAN_RENEWAL_CASE_STATUS_LIST]
                  ?.find(option => option[0] === key)[1])?.join(', ')}
            >
              <MenuItem value={OPEN_CASES}>
                <ListItemIcon>
                  <Checkbox checked={isOpenCasesSelected} />
                </ListItemIcon>
                <ListItemText primary={OPEN_CASES} />
              </MenuItem>
              {ANNUAL_PLAN_RENEWAL_CASE_STATUS_LIST &&
                Array.isArray(ANNUAL_PLAN_RENEWAL_CASE_STATUS_LIST) &&
                ANNUAL_PLAN_RENEWAL_CASE_STATUS_LIST.map((key, value) => (
                  <MenuItem value={key[0]} key={key}>
                    <ListItemIcon>
                      <Checkbox checked={caseStatuses.indexOf(key[0]) > -1 || (isOpenCasesSelected && key[0] !== 'COMPLETED')} />
                    </ListItemIcon>
                    <ListItemText primary={key[1]} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        {annualPlanRenewalList?.length > 0 ? annualPlanRenewalList?.map((rowData, i) => 
          <Accordion key={'key-' + rowData.caseId + i}>
              <AccordionSummary
                  id='annual-plan-renewal-list-header'
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
              >
                  <Grid container spacing={5} justifyContent='center' alignItems='center'>
                    <Grid item xs={3}>
                      <Typography>{rowData?.tenantName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {getComponent(rowData, ANNUAL_PLAN_RENEWAL_STAGES.RENEWAL_QUESTIONNAIRE.value)}
                    </Grid>
                    <Grid item xs={2}>
                      {getComponent(rowData, ANNUAL_PLAN_RENEWAL_STAGES.ANNUAL_RENEWAL_UPLOAD.value)}
                    </Grid>
                    <Grid item xs={2}>
                      {getComponent(rowData, ANNUAL_PLAN_RENEWAL_STAGES.PROCESSING.value)}
                    </Grid>
                    <Grid item xs={2}>
                      {getComponent(rowData, ANNUAL_PLAN_RENEWAL_STAGES.COMPLETED.value)}
                    </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                  <Box m={2} width={"100%"}>
                    <AnnualPlanRenewalDetailPanel rowData={rowData}/>
                  </Box>
              </AccordionDetails>
          </Accordion>
        ) : (
          <Typography align='center'>
            {`There are no cases available to view, for more questions please contact `}
            <Link href="mailto: insights-renewals@multiplan.com" underline="none">Insights Renewals</Link>.
          </Typography>
        )}
    </>
  );
};

export default AnnualPlanRenewal;
