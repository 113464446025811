import * as Types from '../actions/types';
import { store } from 'store/store';
import * as Actions from '../actions/action';
import * as Constant from 'common/Constant';

let initialState = {
  favoriteReports: [],
  currentMenu: { name: Constant.ALL }
};

export default function favoriteReportReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_REPORT_BY_USER_ID_SUCCESS:
      return {
        ...state,
        favoriteReports: action.data
      };
    case Types.GET_FAVORITE_REPORT:
      store.dispatch(Actions.getFavoriteReport());
      return {
        ...state
      };
    case Types.SET_CURRENT_SELECTED_MENU:
      return {
        ...state,
        currentMenu: action.data
      };
    default:
      return {
        ...state
      };
  }
}
