const USER_PREFIX = 'USER_';

export const FETCH_DATA = USER_PREFIX + 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = USER_PREFIX + 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = USER_PREFIX + 'FETCH_DATA_FAILURE';

export const LOGIN = USER_PREFIX + 'LOGIN';
export const LOGIN_SUCCESS = USER_PREFIX + 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = USER_PREFIX + 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const BROKER_SET = USER_PREFIX + 'BROKER_SET';

export const APP_SWITCHED = USER_PREFIX + 'APP_SWITCHED';

export const JWT = USER_PREFIX + 'JWT';

export const FETCH_ALL_BROKERS = USER_PREFIX + 'FETCH_ALL_BROKERS';

export const RESET_APPS_TENANT = USER_PREFIX + 'RESET_APPS_TENANT';

export const RESET_APP = USER_PREFIX + 'RESET_APP';
