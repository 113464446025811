import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import CloneDetail from './cloneDetail';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import SubmitButton from 'common/SingleClickButton';

const ClonePlanRateDialog = React.memo((props) => {
  const { onClose, open, tenantId, target, planYears } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset, setError } = methods;

  const validate = async (data) => {
    if (!data.targetYear || !data.sourceYear) {
      return;
    }
    return await axios.post(
      AppConfig.API_BASE_URL + 'v2/plan-rate/is-cloneable',
      data
    );
  };

  const handleClose = (data) => {
    onClose(data);
  };

  const onSubmit = async (data) => {
    const validationRes = await validate(data);
    if (validationRes.data) {
      switch (validationRes.data) {
        case 'PREEXISTING_PLAN_RATE':
          setError('targetYear', {
            type: 'api',
            message: 'The target year already has an existing plan rate set.'
          });
          return;
        case 'VALID_CLONE_TARGET':
          onClose(data);
          return;
        default:
          setError('targetYear', {
            type: 'api',
            message: `An unknown error: ${validationRes.data}} occured. Check the data and try again.`
          });
          return;
      }
    }
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleClose();
          }}
          maxWidth="lg">
          <DialogTitle id="dialog-clonePlanRatesDialog">
            Clone Rates
          </DialogTitle>
          <DialogContent dividers>
            <CloneDetail planYears={planYears} tenantId={tenantId} />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
});

export default ClonePlanRateDialog;
