import { Box, Grid, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { AppConfig } from 'AppConfig';
import * as Constant from 'common/Constant';
import Carousel from 'common/bstCarousel';
import * as UserTypes from 'features/user/userTypes';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as Action from './actions/action';
import * as Actions from './actions/action';
import ReportNav from './reportNav';

const carouselStyle = { zIndex: 1000 };

const CustomReport = () => {
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);
  const data = useSelector((state) => state.customReport.data); // subset of filtered reports from 'customs'
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.FO
    });
    store.dispatch(Actions.fetchCustomReports());
    store.dispatch(Actions.fetchCustomReportProjects(currentTenantCd));
    store.dispatch(Actions.fetchCustomReportWorkbooks(currentTenantCd));
    store.dispatch(Action.setCurrentMenuItem({ name: Constant.ALL, key: Constant.ALL }));
  }, [currentTenantCd]);


  return (
    <>
      <Grid container spacing={2} justifyContent='flex-end' alignItems='flex-end'>
        <Grid item>
          <TextField
            onChange={(event) => setSearchKey(event.target.value)}
            placeholder="Search for report"
            value={searchKey}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color='primary'/>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={2} lg={2} sm={4}>
              <ReportNav searchKey={searchKey} />
            </Grid>
            <Grid item xs={10} lg={10} sm={8} style={carouselStyle}>
              <Carousel type={AppConfig.CUSTOM_CAROUSEL} items={data}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomReport;
