import { Model, fk, attr } from 'redux-orm';

const defaultAttributes = {
  id: 'Default site id',
  name: 'Dummy Site'
};

export default class Site extends Model {
  static modelName = 'Site';
  static get fields() {
    return {
      id: attr(),
      code: attr(),
      name: attr(),
      subgroup1: attr(),
      subgroup2: attr(),
      subgroup3: attr(),
      traits: fk('Trait'),
      roles: fk('Role'),
      menus: fk('Menu'),
      views: fk('View')
    };
  }

  static parse(data) {
    const { Trait, Role, Menu, View } = { ...data };
    const parsedData = {
      ...data,
      traits:
        Trait && data.traits && data.traits.length > 0
          ? data.traits.map((t) => Trait.parse(t))
          : [{}],
      roles:
        Role && data.roles && data.roles.length > 0
          ? data.roles.map((r) => Role.parse(r))
          : [{}],
      menus:
        Menu && data.menus && data.menus.length > 0
          ? data.menus.map((r) => Menu.parse(r))
          : [{}],
      views:
        View && data.views && data.views.length > 0
          ? data.views.map((r) => View.parse(r))
          : [{}]
    };
    return this.upsert(parsedData);
  }

  static generate(newAttributes = {}) {
    const combinedAttributes = {
      ...defaultAttributes,
      ...newAttributes
    };

    return this.create(combinedAttributes);
  }

  toJSON() {
    return { ...this.ref };
  }

  updateFrom(otherView) {
    this.update(otherView.ref);
  }
}
