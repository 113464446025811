import React, { useEffect,useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Grid,
  Avatar,
  IconButton,
  Popper
} from '@material-ui/core';
import { useClickAwayListener } from 'common/useClickAwayListener';
import RouteConfig from '../../../../routes/RouteConfig';
import { useHistory } from 'react-router-dom';
import { deepPurple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { store } from 'store/store';
import * as UserTypes from 'features/user/userTypes';
import * as Actions from 'features/frontoffice/actions/actions';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  }
}));

const UserMenu = () => {
  const history = useHistory();
  const classes = useStyles();
  const menuIconRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;

  const firstName = useSelector((state) => state.user.firstName);
  const lastName = useSelector((state) => state.user.lastName);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useClickAwayListener(menuIconRef, handleClose);

  const handleLogout = () => {
    history.push(RouteConfig.logout.path);
  };

  const handleProfile = () => {
    store.dispatch({
      type: UserTypes.RESET_APPS_TENANT
    });
    history.push(RouteConfig.userProfile.path);
    setAnchorEl(null);
  };

  useEffect( () => {
    store.dispatch( Actions.globalConfig() );
  }, [] );
  
  return (
    <Box marginRight="60px">
      <IconButton onClick={handleClick} ref={menuIconRef}>
        <Avatar className={classes.purple}>
          {firstName && firstName.charAt(0).toUpperCase()}
          {lastName && lastName.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{
          zIndex: 100000000
        }}>
        <Box
          component={Container}
          maxWidth="xs"
          boxShadow={3}
          style={{
            background: 'white'
          }}>
          <Grid>
            <Button onClick={handleProfile}>Profile</Button>
          </Grid>
          <Grid>
            <Button onClick={handleLogout}>Logout</Button>
          </Grid>
        </Box>
      </Popper>
    </Box>
  );
};

export default UserMenu;
