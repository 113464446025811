import React, { useEffect, useState } from 'react';
import { Box, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useWindowDimensions from 'common/hooks/useWindowDimensions';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as UserTypes from 'features/user/userTypes';
import { AppConfig } from 'AppConfig';

import Carousel from 'common/bstCarousel';

import * as Actions from './actions/actions';
import _ from 'lodash';

const ExclusiveReports = () => {
  const { width } = useWindowDimensions();
  const exclusiveData = useSelector((state) => state.fo.exclusive);
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);

  const [data, setData] = useState(exclusiveData);

  const filterList = (value) => {
    if (Array.isArray(exclusiveData)) {
      const filteredData = _.filter(exclusiveData, function (report) {
        return report.displayText
          ? report.displayText.toLowerCase().includes(value?.toLowerCase())
          : false;
      });
      setData(filteredData);
    }
  };

  useEffect(() => {
    setData(exclusiveData);
  }, [exclusiveData]);

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.FO
    });
    store.dispatch(Actions.fetchExclusiveReports());
  }, [currentTenantCd]);

  return (
    <>
      <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
        <TextField
          onChange={(event) => filterList(event.target.value)}
          placeholder="Search for report"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color='primary'/>
              </InputAdornment>
            )
          }}
        />
      </Box>
      {Array.isArray(data) && data.length > 0 && (
        <>
          <Box className="has-carousel frequently-run-carousel">
            <Carousel
              type={AppConfig.EXCLUSIVE_CAROUSEL}
              items={data}
              viewWidth={width}
              cardWidth={AppConfig.HOME_CUSTOM_CARD_WIDTH}
              cardHeight={AppConfig.HOME_CUSTOM_CARD_HEIGHT}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default ExclusiveReports;
