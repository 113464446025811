import * as Types from 'features/frontoffice/actions/types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const fetchExclusiveReports = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + `/report/exclusive`).then((m) => {
      const response = m.data;
      dispatch({
        type: Types.FETCH_REPORT_EXCLUSIVE_SUCCESS,
        payload: response || null
      });
    });
  };
};
