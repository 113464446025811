import { AppConfig } from 'AppConfig';
import React, { useState, useEffect, useRef } from 'react';
import { MTableToolbar } from '@material-table/core';
import {
  Box,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import * as Constant from 'common/Constant';
import * as Actions from './actions/action';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import * as DateUtil from '../../util/Dateutil';
import { PLAN_RATE_STATUS_MAP } from '../../util/CommonUtil';
import { confirmDialog } from 'common/ComfirmationDialog';
import PlanRateDetailDialog from './component/detailDialog';
import ClonePlanRateDialog from './component/cloneDialog';
import BulkModificationDialog from './component/bulkModificationDetailDialog';
import * as MDActions from '../../common/actions/CommonActions';
import PlanRateCreationDialog from './component/creationDialog';
import { FoMaterialTable } from 'common/FoMaterialTable';

const useStyles = makeStyles(() => ({
  planYearSelect: {
    marginLeft: '1rem',
    minWidth: 150
  }
}));

const PlanRate = ({ tenantId }) => {
  const ALL_YEARS = 'All';

  const styles = useStyles();

  const tableRef = useRef();

  const [cloneOpen, setCloneOpen] = useState(false);
  const [cloneData, setCloneData] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
  const [bulkModificationOpen, setBulkModificationOpen] = useState(false);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [planYears, setPlanYears] = useState([ALL_YEARS]);
  const [selectedPlanDesign, setSelectedPlanDesign] = useState([ALL_YEARS]);
  const [planDesignOptions, setPlanDesignOptions] = useState([]);
  const [openNew, setOpenNew] = useState(false);

  const shouldRefresh = useSelector((state) => state.MD.planRate.shouldRefresh);
  const permissions = useSelector((state) => state.user.permissions);
  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );
  const allPlanDesignDatasInMap = useSelector(
    (state) => state.MD.planRate.allPlanDesignDatasInMap
  );
  const allPlanDesignDatas = useSelector(
    (state) => state.MD.planRate.allPlanDesignDatas
  );

  const isAllSelected = planYears.length === 1 && planYears[0] == ALL_YEARS;

  const handleCloneOpen = () => {
    setCloneOpen(true);
  };

  const handleCloneClose = (data) => {
    if (data) {
      store.dispatch(Actions.clone(data));
    }
    setCloneOpen(false);
  };

  const handleDetailOpen = (rowData) => {
    setCloneData(rowData);
    setDetailOpen(true);
  };

  const handleDetailClose = (data) => {
    if (data) {
      store.dispatch(Actions.update(data));
    }
    setDetailOpen(false);
  };

  const handleBulkModifyClose = (data) => {
    setBulkModificationOpen(false);
    if (data) {
      Actions.bulkModify(data, refresh);
    }
  };

  const deletePlan = (rowData) => {
    const ids = rowData.map((item) => item.id);
    confirmDialog(() => store.dispatch(Actions.deleteByIds(ids)));
  };

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
      refreshBase();
    }
  };

  const handlePlanYearSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_YEARS) {
      setPlanYears([ALL_YEARS]);
    } else {
      setPlanYears(inputVal.filter((yr) => yr !== ALL_YEARS));
    }
  };

  const handleDesignPlanDataSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_YEARS) {
      setSelectedPlanDesign([ALL_YEARS]);
    } else {
      setSelectedPlanDesign(inputVal.filter((yr) => yr !== ALL_YEARS));
    }
  };

  const refreshBase = () => {
    store.dispatch(MDActions.fetchAllPlanYears());
    store.dispatch(Actions.fetchAllUniqueNamePlanDesignData());
  };

  useEffect(() => {
    if (ALL_YEARS === planYears[0]) {
      setPlanDesignOptions(allPlanDesignDatas);
    } else {
      let selectedPlanDesigns = [];
      planYears.forEach((item) => {
        let found = allPlanDesignDatasInMap[item];
        if (found && Array.isArray(found)) {
          selectedPlanDesigns = selectedPlanDesigns.concat(found);
        }
      });

      setPlanDesignOptions(selectedPlanDesigns);
    }
  }, [planYears, allPlanDesignDatasInMap]);

  useEffect(() => {
    refreshBase();
  }, [tenantId]);

  useEffect(() => {
    refresh();
  }, [tenantId, planYears, selectedPlanDesign]);

  useEffect(() => {
    if (
      tableRef.current &&
      shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE
    ) {
      tableRef.current.onQueryChange();
      refreshBase();
    }
  }, [shouldRefresh]);

  return (
    <>
      <FoMaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Plan Year', field: 'planYearName' },
          { title: 'Plan', field: 'planName' },
          {
            title: 'Start Date',
            field: 'startDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.startDate)
          },
          {
            title: 'End Date',
            field: 'endDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.endDate)
          },
          { title: 'Election Tier Schema', field: 'electionTierSchemaName' },
          { title: 'Class Schema', field: 'classSchemaName' },
          {
            title: 'Status',
            field: 'status',
            render: (rowData) => PLAN_RATE_STATUS_MAP[rowData.status]
          },
          { title: 'Total Monthly Rate', field: 'rate' },
          { title: 'Employer Share', field: 'employerShare' },
          { title: 'Employee Share', field: 'employeeShare' }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.API_BASE_URL + `v2/plan-rate/search`, {
                tenantId: tenantId,
                years: ALL_YEARS === planYears[0] ? [] : planYears,
                selectedPlanDesign:
                  ALL_YEARS === selectedPlanDesign[0] ? [] : selectedPlanDesign,
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_DESC,
                sortProperty: query.orderBy
                  ? query.orderBy.field
                  : 'planYearName'
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                console.log('error' + err);
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          selection: true,
          actionsColumnIndex: -1,
          exportButton: {
            csv: true
          },
          exportAllData: true,
          exportCsv: () => {
            store.dispatch(
              Actions.exportCSV({
                tenantId: tenantId,
                years: ALL_YEARS === planYears[0] ? [] : planYears,
                selectedPlanDesign:
                  ALL_YEARS === selectedPlanDesign[0] ? [] : selectedPlanDesign,
                direction: Constant.DIRECTION_DESC,
                size: AppConfig.DOWNLOAD_MAX_ROW_COUNT,
                sortProperty: 'planYearName'
              })
            );
          },
          search: false,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add New',
            isFreeAction: true,
            onClick: () => {
              setOpenNew(true);
            }
          },
          {
            position: 'row',
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleDetailOpen(rowData);
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (evt, data) => deletePlan(data)
          },
          {
            position: 'row',
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (_, rowData) => deletePlan([rowData])
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'library_add',
            tooltip: 'Clone',
            isFreeAction: true,
            onClick: () => {
              handleCloneOpen();
            }
          },
          {
            icon: 'control_point_duplicate_icon',
            tooltip: 'Bulk Modification',
            isFreeAction: true,
            onClick: () => {
              setBulkModificationOpen(true);
            },
            hidden: !(
              permissions &&
              Array.isArray(permissions) &&
              permissions.includes(
                AppConfig.PERMISSION.MD_PLAN_RATE_BULK_MODIFICATION
              )
            )
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={(rowData) => {
          return (
            <>
              <Box m={8}>
                <Grid container spacing={2} bgcolor="primary.main">
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      key={'readonly-rate' + rowData.rowData.id}
                      label="Rate %"
                      defaultValue={rowData.rowData.cobra}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      key={'readonly-tobaccoSurcharge' + rowData.rowData.id}
                      label="Tobacco Surcharge"
                      defaultValue={rowData.rowData.tobaccoSurcharge}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      key={'readonly-wellnessDiscount' + rowData.rowData.id}
                      label="Wellness Discount"
                      defaultValue={rowData.rowData.wellnessDiscount}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      key={'readonly-spousalSurcharge' + rowData.rowData.id}
                      label="Spousal Surcharge"
                      defaultValue={rowData.rowData.spousalSurcharge}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      key={'readonly-ssdisabilitySurcharge' + rowData.rowData.id}
                      label="SS Disability Surcharge"
                      defaultValue={rowData.rowData.ssdisabilitySurcharge}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        }}
        components={{
          OverlayLoading: () => <div />,
          Toolbar: (props) => (
            <Box>
              <MTableToolbar {...props} />
              <FormControl className={styles.planYearSelect} size="medium">
                <InputLabel id="label_planYear">Plan Year:</InputLabel>
                <Select
                  multiple
                  id="input_planYear"
                  key="input_planYear"
                  onChange={handlePlanYearSelection}
                  value={planYears}
                  renderValue={(planYears) => planYears.join(', ')}>
                  <MenuItem value={ALL_YEARS}>
                    <ListItemIcon>
                      <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary={ALL_YEARS} />
                  </MenuItem>
                  {totalPlanYears &&
                    Array.isArray(totalPlanYears) &&
                    totalPlanYears.map((item) => (
                      <MenuItem value={item.planYearName}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              planYears.indexOf(item.planYearName) > -1 ||
                              isAllSelected
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.planYearName} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl className={styles.planYearSelect} size="medium">
                <InputLabel id="label_planYear">Plan:</InputLabel>
                <Select
                  multiple
                  id="input_planDesignDataFilter"
                  key="input_planDesignDataFilter"
                  onChange={handleDesignPlanDataSelection}
                  value={selectedPlanDesign}>
                  <MenuItem value={ALL_YEARS}>{ALL_YEARS}</MenuItem>
                  {planDesignOptions.map((item) => (
                    <MenuItem value={item.planName}>{item.planName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )
        }}
      />

      <PlanRateDetailDialog
        open={detailOpen}
        onClose={handleDetailClose}
        tenantId={tenantId}
        target={cloneData}
      />
      <ClonePlanRateDialog
        open={cloneOpen}
        onClose={handleCloneClose}
        planYears={totalPlanYears}
        tenantId={tenantId}
      />
      <PlanRateCreationDialog
        open={openNew}
        onClose={() => setOpenNew(false)}
      />
      {permissions &&
        Array.isArray(permissions) &&
        permissions.includes(
          AppConfig.PERMISSION.MD_PLAN_RATE_BULK_MODIFICATION
        ) && (
          <BulkModificationDialog
            open={bulkModificationOpen}
            onClose={handleBulkModifyClose}
          />
        )}
    </>
  );
};

export default PlanRate;
