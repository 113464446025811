import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const SHOULD_REFRESH_INIT_VALUE = 0;

export const save = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/stop-loss-contract/all`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const update = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + 'v2/stop-loss-contract/', data)
      .then((res) => {
        dispatch({ type: Types.SEARCH_REFRESH });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: Types.SEARCH_REFRESH });
      });
  };
};

export const deleteByIds = (ids) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/stop-loss-contract/delete`, ids)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const savePolicyExclusion = (data) => {
  return (dispatch) => {
    axios
      .post(
        AppConfig.API_BASE_URL + `v2/stop-loss-contract/policy/exclusion`,
        data
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deletePolicyExclusionById = (id) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.API_BASE_URL +
        `v2/stop-loss-contract/policy/exclusion/id/${id}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const saveLaser = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/stop-loss-contract/laser`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteLaserById = (id) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `v2/stop-loss-contract/laser/id/${id}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteMonthlyAggByPlanAndStopLossContractId = (planId, id) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.API_BASE_URL +
        `v2/stop-loss-contract/id/${id}/monthly-agg/plan/id/${planId}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const saveMonthlyAgg = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + `v2/stop-loss-contract/monthly-agg`, data)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const saveMonthlyAggForElectionTiers = (data) => {
  return (dispatch) => {
    axios
      .post(
        AppConfig.API_BASE_URL +
        `v2/stop-loss-contract/monthly-agg/election-tier`,
        data
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteById = (id) => {
  return (dispatch) => {
    axios
      .delete(AppConfig.API_BASE_URL + `v2/stop-loss-contract/id/${id}`)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteIslPlanById = (id) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.API_BASE_URL + `v2/stop-loss-contract-isl-plan/id/${id}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const saveIslPlans = (data) => {
  return (dispatch) => {
    axios
      .post(
        AppConfig.API_BASE_URL + `v2/stop-loss-contract-isl-plan/multiples`,
        data
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteAllIslPlanBySlcId = (slcId) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.API_BASE_URL +
        `v2/stop-loss-contract-isl-plan/id/${slcId}/all`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const applyAllIslPlanToSlc = (slcId) => {
  return (dispatch) => {
    axios
      .post(
        AppConfig.API_BASE_URL +
        `v2/stop-loss-contract/apply/all/isl/plan/${slcId}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};
