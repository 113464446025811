const PREFIX = "CUSTOM_REPORT_"

export const SET_CURRENT_SELECTED_MENU = PREFIX + 'SET_CURRENT_SELECTED_MENU';

export const SET_CURRENT_TAGS = PREFIX + "SET_CURRENT_TAGS";

export const SET_CUSTOM_WORKBOOKS = PREFIX + "SET_CUSTOM_WORKBOOKS";

export const SET_CUSTOM_PROJECTS = PREFIX + "SET_CUSTOM_PROJECTS";

export const SET_DATA = PREFIX + "SET_DATA";