import React, { useState } from 'react';
import { TextField, Grid, IconButton, Box } from '@material-ui/core';
import { Delete, Add, Edit } from '@material-ui/icons';
import {v4 as uuid} from 'uuid';
import { useFormContext, useFieldArray } from 'react-hook-form';
import PolicyExclusionDialog from './policyExclusionDialog';
import * as Actions from '../actions/actions';
import { store } from 'store/store';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Constant from 'common/Constant';

const PolicyExclusionList = (props) => {
  const [creationOpen, setCreationOpen] = useState(false);

  const { control, getValues } = useFormContext();
  const { fields } = useFieldArray({
    control,
    keyName: Constant.DEFAULT_REACT_HOOK_FIELD_ID,
    name: 'policyExclusions'
  });

  const [cloneData, setCloneData] = useState({});

  const handleCreationClose = (data) => {
    if (data) {
      store.dispatch(Actions.savePolicyExclusion(data));
    }
    setCreationOpen(false);
  };

  const handleDelete = (id) => {
    confirmDialog(() => store.dispatch(Actions.deletePolicyExclusionById(id)));
  };

  const handleCreationOpen = (data) => {
    setCloneData(data);
    setCreationOpen(true);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        style={{ minWidth: '500px' }}>
        <PolicyExclusionDialog
          open={creationOpen}
          onClose={handleCreationClose}
          target={cloneData}
        />

        {fields && Array.isArray(fields) && fields.length > 0 ? (
          fields.map((item, index) => (
            <>
              <Grid item xs={2}>
                <TextField
                  id={'readonly-policyExclusionPersonId-' + index}
                  key={'key-' + uuid()}
                  label="Person Id"
                  InputProps={{ readOnly: true }}
                  defaultValue={item.personId}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id={'readonly-policyExclusionFirstName-' + index}
                  key={'key-' + uuid()}
                  label="First Name"
                  InputProps={{ readOnly: true }}
                  defaultValue={item.firstName}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id={'readonly-policyExclusionLastName-' + index}
                  key={'key-' + uuid()}
                  label="Last Name"
                  InputProps={{ readOnly: true }}
                  defaultValue={item.lastName}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id={'readonly-policyExclusionMedId-' + index}
                  key={'key-' + uuid()}
                  label="Medical Id"
                  InputProps={{ readOnly: true }}
                  defaultValue={item.medId}
                />
              </Grid>
              <Grid item xs={2} wrap="nowrap">
                <Box display="flex" justifyContent="flex-end">
                  {index === fields.length - 1 ? (
                    <IconButton
                      id={'action-add'}
                      key={'key-' + uuid()}
                      onClick={() =>
                        handleCreationOpen({
                          stopLossContractId: getValues('id')
                        })
                      }
                      title="Add New">
                      <Add />
                    </IconButton>
                  ) : null}
                  <IconButton
                    id={'action-edit-' + index}
                    key={'key-' + uuid()}
                    onClick={() => handleCreationOpen(item)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    id={'action-delete-' + index}
                    key={'key-' + uuid()}
                    onClick={() => handleDelete(item.id)}>
                    <Delete />
                  </IconButton>
                </Box>
              </Grid>
            </>
          ))
        ) : (
          <>
            <Grid container justify="center" alignItems="center">
              <i>No data was found. Please click </i>
              <IconButton
                id={'action-add-no-data'}
                key={'key-' + uuid()}
                onClick={() =>
                  handleCreationOpen({ stopLossContractId: getValues('id') })
                }
                title="Add New">
                <Add />
              </IconButton>
              <i> to create a new one</i>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PolicyExclusionList;
