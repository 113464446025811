import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from 'common/SingleClickButton';
import * as MDActions from '../../../common/actions/CommonActions';
import { store } from 'store/store';
import PlanExpenseDetail from './detailForm';
import * as Actions from '../actions/action';
import { useSelector } from 'react-redux';
import * as Constant from 'common/Constant';
import moment from 'moment-timezone';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import * as AppActionTypes from 'store/actions/appstate';
import _ from 'lodash';

const PlanExpenseDetailDialog = (props) => {
  const DUPLICATION_ERROR_MSG = 'Duplicated entry found for the same plan';
  const { onClose, open, tenantId, target } = props;
  const methods = useForm({
    defaultValues: {
      ...target,
      planExpenseRates: [],
      specialExpense: []
    },
    mode: 'onBlur'
  });
  const { reset, register } = methods;
  const [duplicateErrorMessage, setDuplicateErrorMessage] = useState('');

  // register conditional rendering fields
  register('applicableToAllPlans', { value: false, shouldUnregister: true });
  register('medCovered', { value: false, shouldUnregister: true });
  register('rxCovered', { value: true, shouldUnregister: true });

  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );
  const simplePlans = useSelector((state) => state.MD.planExpense.simplePlans);

  const handleClose = (data) => {
    onClose(data);
  };

  const resetDuplicateErrorMessage = () => {
    setDuplicateErrorMessage('');
  };

  const filterDuplicatePlans = (payload) => {
    return _.uniqWith(payload, (planA, planB) => 
    planA.planId === planB.planId && planA.expenseName === planB.expenseName && planA.startDate === planB.startDate && planA.endDate === planB.endDate);
  };

  const onSubmit = async (data) => {
    const payload = [];

    if (data) {
      // build payload as backend expected format

      const planExpenseRates =
        data.rateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL &&
          data.planYears.length === 1
          ? data.specialExpense
            .filter((ex) => ex.rate)
            .map((sp) => {
              sp.startDate = moment(sp.startDate).format(
                Constant.FORMAT_DATE
              );
              sp.endDate = moment(sp.endDate).format(Constant.FORMAT_DATE);
              return sp;
            })
          : data.planExpenseRates.filter((expense) => expense.rate);

      data.planYears.forEach((planyear) => {
        data.planName.forEach((plan) => {
          const foundPlan = simplePlans.find(
            (sp) => sp.ahpmId === planyear.id && sp.planName === plan.planName
          );

          if (!foundPlan) {
            return;
          }

          const payloadObject = {
            ...data,
            planId: foundPlan?.id,
            startDate:
              data.planYears.length > 1
                ? totalPlanYears.find((y) => y.id === planyear.id)?.startDate
                : moment(data.startDate).format(Constant.FORMAT_DATE),
            endDate:
              data.planYears.length > 1
                ? totalPlanYears.find((y) => y.id === planyear.id)?.endDate
                : moment(data.endDate).format(Constant.FORMAT_DATE),
            planExpenseRates: planExpenseRates,
            rxCovered:
              data.rateBasis === Constant.PLAN_EXPENSE_RATE_BASIS_PER_CLAIM
                ? data.rxCovered
                : false
          };
          if (
            !payload.some(
              (plan) =>
                plan.planId === payloadObject.planId &&
                plan.startDate === payloadObject.startDate &&
                plan.endDate === payloadObject.endDate &&
                plan.expenseName === payloadObject.expenseName
            )
          ) {
            payload.push(payloadObject);
          }

        });
      });

      // cleanup payload by removing unwanted properties
      const payloadMap = payload.map(
        ({ planYears, planName, rate, specialExpense, ...rest }) => rest
      );
      if (
        data.rateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL &&
        data.planYears.length === 1
      ) {
        const validationResult = await validateSpecialExpense(payloadMap); //validate special expense data
        if (validationResult.length) {
          store.dispatch({
            type: AppActionTypes.APPSTATE_SET_APP_ERROR,
            payload: `${DUPLICATION_ERROR_MSG} special expense, ${JSON.stringify(
              validationResult.map(({ startDate, endDate, rate }) => {
                return { startDate, endDate, rate };
              })
            )}`
          });
        } else {
          checkDuplicateAndSubmit(data, payloadMap);
        }
      } else {
        checkDuplicateAndSubmit(data, payloadMap);
      }
    } else onClose(null);
  };

  const checkDuplicateAndSubmit = async (formData, payload) => {
    const uniquePayload = filterDuplicatePlans(payload);
    const duplicateResponse = await checkDuplicate(uniquePayload);
    if (!duplicateResponse.length) {
      onClose(uniquePayload);
    } else {
      const duplicatePlanNames = formData.planName.filter((name) =>
        duplicateResponse.some((duplicate) => duplicate.planId === name.id)
      );
      const planNames = formData.planYears.map((year) => {
        const name = duplicatePlanNames.filter(
          (duplicate) => duplicate.ahpmId === year.id
        );
        return `${name[0].planName} (${year.label})`;
      });

      const name = planNames.length > 1 ? planNames.join(', ') : planNames[0];

      const errorMessage = `${DUPLICATION_ERROR_MSG} for Plan (${name})
      with Start Date(${moment(formData.startDate).format(
        Constant.FORMAT_DATE
      )}) and End Date(${moment(formData.endDate).format(
        Constant.FORMAT_DATE
      )}),
       Expense Catogory (${formData.expenseCategory}), Expense Name (${formData.expenseName
        }) and Rate Basis (${formData.rateBasis}) and Rate (${formData.rate}).`;

      setDuplicateErrorMessage(errorMessage);
    }
  };
  const checkDuplicate = async (data) => {
    try {
      const response = await axios.post(
        AppConfig.API_BASE_URL + `planexpense/checkDuplicate`,
        data,
        AppConfig.POST_HEADERS
      );

      if (response.data && response.data.length) {
        return response.data;
      }
      return [];
    } catch (error) {
      return [];
    }
  };
  const validateSpecialExpense = async (data) => {
    try {
      const result = await Promise.all(
        data.map(async (row) => {
          const found = await axios.post(
            AppConfig.API_BASE_URL + 'planexpense/special-rate/verify',
            row
          );
          return found.data;
        })
      );
      return result.flat();
    } catch (error) {
      console.warn('validation failed : ', error);
    }
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  useEffect(() => {
    store.dispatch(MDActions.getAllElectionTiers());
    store.dispatch(Actions.fetchPlans());
    store.dispatch(Actions.fetchAllPlansExpenses());
  }, [tenantId]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') handleClose();
          }}
          maxWidth="lg"
          fullWidth>
          <DialogTitle id="dialog-planRateDetail">
            {target && target.id > 0 ? 'Edit' : 'New'}
          </DialogTitle>
          <DialogContent dividers>
            <PlanExpenseDetail
              tenantId={tenantId}
              isNew={!(target && target.id > 0)}
              duplicateError={duplicateErrorMessage}
              resetDuplicateError={resetDuplicateErrorMessage}
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default React.memo(PlanExpenseDetailDialog);
