import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';

const LookupDetail = () => {

  const { control } = useFormContext();

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-name"
                fullWidth
                {...field}
                required
                error={error}
                helperText={error ? error.message : null}
                label="Name"
              />
            )}
            name="name"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="code"
            rules={{ required: Constant.ERROR_MSG_REQUIRED_FIELD }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                {...field}
                required
                error={error}
                helperText={error ? error.message : null}
                label="Code"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default LookupDetail;
