import * as Types from '../action/types';

let initialState = {
  shouldRefresh: false,
  tenantList: [],
  roleList: []
};

export default function tenantAdminReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };

    case Types.FETCH_ADMIN_TENANT_LIST:
      return {
        ...state,
        tenantList: action.data
      };

    case Types.FETCH_ADMIN_ROLE_LIST:
      return {
        ...state,
        roleList: action.data
      };

    default:
      return state;
  }
}
