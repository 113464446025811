import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as Types from './userTypes';
import * as AppActionTypes from 'store/actions/appstate';
import { setAxiosHeaderToken } from 'networking/NetworkManger';
import LocalStore from 'utils/LocalStore';

export const fetchData = (companyCode, appCode) => {
  return async (dispatch) => {
    await axios
      .post(AppConfig.IAM_BACKEND_URL + AppConfig.APP_TENANT_SWITCHING_URL, {
        companyCode: companyCode,
        appCode: appCode
      })
      .then((res) => {
        if (res) {
          setAxiosHeaderToken(res.data.jwt);
          LocalStore.saveAccessToken(res.data.jwt);
          dispatch({
            type: Types.FETCH_DATA_SUCCESS,
            payload: res.data
          });
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        dispatch({
          type: Types.FETCH_DATA_FAILURE
        });
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: err ? err.message : err
        });
      });
  };
};

export const getAllBrokers = () => {
  return (dispatch) => {
    axios.get(AppConfig.FO_INITIAL_URL + `/broker/all`).then((m) => {
      if (m != null && m.data) {
        dispatch({
          type: Types.FETCH_ALL_BROKERS,
          payload: m.data
        });
      }
    });
  };
};
