import { AppConfig } from 'AppConfig';
import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from '@material-table/core';
import axios from 'axios';
import * as Constant from 'common/Constant';
import * as Actions from './actions/action';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { confirmDialog } from 'common/ComfirmationDialog';
import LogoUploaderDialog from './components/logoUploaderDialog';
import * as UserTypes from 'features/user/userTypes';
import LogoImg from './components/logoImg';
import SearchIcon from '@material-ui/icons/Search';

const WhiteLabelingManagement = () => {
  const tableRef = useRef();

  const [open, setOpen] = useState(false);
  const [targetData, setTargetData] = useState(false);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);

  const shouldRefresh = useSelector((state) => state.whiteLabeling.shouldRefresh);

  const handleLogoUploaderOpen = (rowData) => {
    setTargetData(rowData);
    setOpen(true);
  };

  const handleSave = (data) => {
    if (data && data.logoFile instanceof File) {
      const file = data.logoFile;

      if (data.TYPE === Constant.LOGO_TENANT_LOGO) {
        store.dispatch(Actions.uploadTenantLogo(data.code, file));
      } else {
        store.dispatch(Actions.uploadDefaultLogo(file));
      }
    }
  };

  const handleLogoUploaderClose = (data) => {
    handleSave(data);
    setOpen(false);
  };

  const deleteLogo = (tenantCode) => {
    confirmDialog(
      () => store.dispatch(Actions.deleteLogo(tenantCode)),
      'Are you sure you want to remove the logo (System will use default logo instead)?'
    );
  };

  const refresh = () => {
    tableRef.current?.onQueryChange();
  };

  useEffect(() => {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.NONE
    });
    refresh();
  }, []);

  useEffect(() => {
    if (shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE) {
      refresh();
    }
  }, [shouldRefresh]);

  return (
    <>
      <MaterialTable
        title="Logo Management"
        tableRef={tableRef}
        localization={{
          toolbar: { searchPlaceholder: 'Search for Name or Code' }
        }}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Code', field: 'code' },
          { title: 'Broker Name', field: 'brokerName' },
          {
            title: 'Logo (180px * 38px)',
            field: 'brokerName',
            render: (rowData) => <LogoImg data={rowData} url={AppConfig.FO_INITIAL_URL + '/open-api/logo/tenant/'}/>
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(AppConfig.FO_INITIAL_URL + '/company-admin/v2/search', {
                search: query.search,
                page: query.page,
                size: query.pageSize,
                direction: query.orderDirection
                  ? query.orderDirection.toUpperCase()
                  : Constant.DIRECTION_ASC,
                sortProperty: query.orderBy ? query.orderBy.field : 'name'
              })
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.szie,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          selection: false,
          actionsColumnIndex: -1,
          search: true,
          exportButton: false,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          debounceInterval: 400,
          pageSize
        }}
        components={{ OverlayLoading: () => <div /> }}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            position: 'row',
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleLogoUploaderOpen({
                ...rowData,
                TYPE: Constant.LOGO_TENANT_LOGO
              });
            }
          },
          {
            icon: 'delete',
            tooltip: 'Remove Logo',
            onClick: (_, rowData) => deleteLogo(rowData.code)
          },
          {
            position: 'row',
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleLogoUploaderOpen({ TYPE: Constant.LOGO_DEFAULT_TYPE });
            }
          }
        ]}
        icons={{
          Search: () => <SearchIcon color="primary"/>,
        }}
      />
      <LogoUploaderDialog
        open={open}
        onClose={handleLogoUploaderClose}
        target={targetData}
      />
    </>
  );
};

export default WhiteLabelingManagement;
