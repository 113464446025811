import clientPortalReducer from 'features/clientPortal/modules/reducer';
import customReportReducer from 'features/frontoffice/customReport/reducer/index';
import defaultSettingsReducer from 'features/frontoffice/defaultSetting/reducer';
import favoriteReportReducer from 'features/frontoffice/favoriteReport/reducer/index';
import foReducer from 'features/frontoffice/reducer/index';
import userReducer from 'features/user/userReducer';
import { orm } from 'model/schema';
import { combineReducers } from 'redux';
import { createReducer } from 'redux-orm';
import brokerManagementReducer from '../features/admin/brokerManagement/reducer';
import adminGroupManagementReducer from '../features/admin/groupManagement/reducer/index.js';
import mdRenewalManagementReducer from '../features/admin/mdRenewalManagement/reducer';
import adminRoleManagementReducer from '../features/admin/roleManagement/reducer/index.js';
import tagManagementReducer from '../features/admin/tagManagement/reducer';
import tenantAdminReducer from '../features/admin/tenantAdmin/reducer';
import tenantManagementReducer from '../features/admin/tenantManagement/reducer';
import adminUserAccessReducer from '../features/admin/userAccess/reducer/index.js';
import vendorLabelingReducer from '../features/admin/vendor-labeling/reducer/reducer';
import whiteLabelingReducer from '../features/admin/white-labeling/reducer/reducer';
import masterTableReducer from '../features/masterData/MastersTable/reducer/MastersTableReducer';
import MD from '../features/masterData/reducer/index';
import { reduceReducers } from '../utils/reducerUtils';
import appState from './reducers/appstate';

const combinedReducer = combineReducers({
  entities: createReducer(orm),
  appState,
  masterTable: masterTableReducer,
  MD: MD,
  whiteLabeling: whiteLabelingReducer,
  favoriteReport: favoriteReportReducer,
  customReport: customReportReducer,
  user: userReducer,
  fo: foReducer,
  adminUserAccess: adminUserAccessReducer,
  adminRoleManagement: adminRoleManagementReducer,
  tenantAdmin: tenantAdminReducer,
  tagManagement: tagManagementReducer,
  vendorLabeling: vendorLabelingReducer,
  adminGroupManagement: adminGroupManagementReducer,
  tenantManagement: tenantManagementReducer,
  brokerManagement: brokerManagementReducer,
  defaultSettings: defaultSettingsReducer,
  mdRenewalManagement: mdRenewalManagementReducer,
  clientPortal: clientPortalReducer,
});

const rootReducer = reduceReducers(combinedReducer);

export default rootReducer;
