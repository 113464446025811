import {
  Box,
  Grid,
  ListItemText,
  MenuItem,
  Tooltip,
  ListItemIcon
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import BuildIcon from '@material-ui/icons/Build';
import WarningIcon from '@material-ui/icons/Warning';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import LinkOutlined from '@material-ui/icons/LinkOutlined';
import PolicyIcon from '@material-ui/icons/Policy';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import * as Action from '../favoriteReport/actions/action';
import { useSelector } from 'react-redux';

const BACKGROUND_COLOR = '#4051B5';

const useStyles = makeStyles((theme) => ({
  sideNav: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    '&:hover .menuItem': {
      color: BACKGROUND_COLOR
    },
    '&:hover .icon': {
      color: BACKGROUND_COLOR
    },
    '&:hover .typography': {
      color: BACKGROUND_COLOR
    },
    maxWidth: AppConfig.STANDARD_REPORT_SIDE_NAV_WIDTH
  },
  menuItem: {
    justifyContent: 'start',
    alignContent: 'start',
    alignItems: 'start',
    margin: 0
  },
  icon: {
    '&:hover': {
      color: BACKGROUND_COLOR
    },
    paddingTop: 3
  },
  count: {
    justifyContent: 'end',
    alignText: 'right'
  },
  listItemText: {
    whiteSpace: 'nowrap',
    maxWidth: AppConfig.STANDARD_REPORT_MENU_ITEM_TEXT_WIDTH,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const REPORT_SUBCATEGORY = AppConfig.REPORT_SUBCATEGORY;

const ReportNavItem = ({ item, filter, total }) => {
  const classes = useStyles();
  const currentMenu = useSelector((state) => state.favoriteReport.currentMenu);

  return (
    <>
      <Box className={classes.sideNav}>
        <MenuItem
          disableGutters={true}
          className={classes.menuItem}
          key={`standard-report-nav-item-${item.id}`}
          onClick={() => {
            store.dispatch(Action.setCurrentMenuItem(item));
            filter(item.name);
          }}
          selected={currentMenu?.name === item.name}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <ListItemIcon className={classes.icon}>
                {(() => {
                  switch (item.name) {
                    case REPORT_SUBCATEGORY.PLAN_PERFORMANCE:
                      return <TimelineOutlinedIcon />;
                    case REPORT_SUBCATEGORY.DRUGS:
                      return <LinkOutlined />;
                    case REPORT_SUBCATEGORY.QUALITY_GAPS_IN_CARE:
                      return <BuildIcon />;
                    case REPORT_SUBCATEGORY.LCARS:
                      return <WarningIcon />;
                    case REPORT_SUBCATEGORY.BENCHMARKS:
                      return <AssessmentIcon />;
                    case REPORT_SUBCATEGORY.PROCEDURES_AND_PROVDIERS:
                      return <LocalHospitalIcon />;
                    case REPORT_SUBCATEGORY.CLINICAL_MEASURMENTS:
                      return <PolicyIcon />;
                    default:
                      return <StarBorderIcon />;
                  }
                })()}
              </ListItemIcon>
            </Grid>
            <Grid item xs={8}>
              <Tooltip title={item.name}>
                <div className={classes.listItemText}>{item.name}</div>
              </Tooltip>
            </Grid>
            <Grid item xs={2} className={classes.count}>
              <ListItemText>{total}</ListItemText>
            </Grid>
          </Grid>
        </MenuItem>
      </Box>
    </>
  );
};

export default ReportNavItem;
