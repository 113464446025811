import axios from 'axios';
import { AppConfig } from 'AppConfig';

export const trackingTypes = {
  APPLICATION: 'APPLICATION',
  REPORT: 'REPORT',
  TENANT: 'TENANT'
};

export const trackingActions = {
  READ: 'READ',
  SWITCH: 'SWITCH',
  CLICK: 'CLICK'
};

export const trackClick = function (type, action, target, detail) {
  axios
    .post(AppConfig.FO_INITIAL_URL + '/click/track', {
      type: type,
      action: action,
      target: target,
      detail: detail ? detail : null
    })
    .catch((error) => {
      console.error('click call issue:' + error);
    });
};
