import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { store } from 'store/store';
import LocalStore from 'utils/LocalStore';
import {
  Button,
  Card,
  CardContent,
  Grid,
  CardActions
} from '@material-ui/core';
import { LOAD_DATA } from 'reducers/reducersConstants';
import 'common/bstCard/bstCard.css';
import { AppConfig } from 'AppConfig';
import ReportCard from './reportCard';
import { hasPermission } from 'utils/common';

class BstCard extends Component {
  constructor(props) {
    super(props);
    this.BstGrid = React.createRef();
  }

  renderItem(props) {
    const reportType = props?.type;
    switch (reportType) {
      case AppConfig.OFTENTIMES_CAROUSEL:
        return (
          <ReportCard
            item={props.item}
            showFavorite={false}
            showDismiss={false}
            reportType={reportType}
          />
        );
      case AppConfig.LEGACY_CAROUSEL:
        return (
          <ReportCard
            item={props.item}
            showFavorite={true}
            showDismiss={false}
            reportType={reportType}
          />
        );
      case AppConfig.FAVORITE_CAROUSEL:
        return (
          <ReportCard
            item={props.item}
            showFavorite={true}
            showDismiss={false}
            reportType={reportType}
            showThumbnail={false}
          />
        );

      case AppConfig.SMART_CAROUSEL:
        return (
          <ReportCard
            item={props.item}
            showFavorite={true}
            showDismiss={
              true &&
              hasPermission(
                AppConfig.PERMISSION.FO_SMARTCARD_DISMISS,
                this.props.permissions
              )
            }
            showThumbnail={true}
            showTitle={false}
            reportType={reportType}
          />
        );

      case AppConfig.SHARE_CAROUSEL:
        return (
          <Card style={{ height: props.h }}>
            <CardContent>
              <div width={props.width} height={props.height}>
                <Grid>
                  <Grid center>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '12px',
                        marginBottom: '5px'
                      }}>
                      {props.item.displayText}

                      <Button
                        onClick={() => {
                          axios
                            .post(
                              AppConfig.FO_INITIAL_URL + '/init/shareReject',
                              {
                                reptSource: this.props.item.reptSource,
                                shareBy: this.props.item.sharedBy
                              },
                              {
                                headers: {
                                  Authorization:
                                    'Bearer ' + LocalStore.getAccessToken()
                                }
                              }
                            )
                            .then((res) => {
                              axios
                                .post(
                                  AppConfig.FO_INITIAL_URL +
                                  AppConfig.USER_PROFILE_PATH,
                                  {
                                    username: this.props.username,
                                    app: AppConfig.APP_FO,
                                    updateCache: 'true'
                                  }
                                )
                                .then(
                                  (res) => {
                                    if (res && res.data) {
                                      store.dispatch({
                                        type: LOAD_DATA,
                                        payload: res.data
                                      });
                                    }
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}>
                        Reject
                      </Button>
                    </span>
                  </Grid>
                  <Grid>By: {props.item.sharedBy}</Grid>
                </Grid>
                <Grid>
                  <span
                    style={{
                      marginTop: '5px',
                      marginBottom: '5px',
                      fontFamily: 'OpenSans-Regular',
                      fontSize: '12px',
                      padding: '10px'
                    }}>
                    {props.item.description}
                  </span>
                </Grid>
                <Grid
                  center
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    marginBottom: '10px'
                  }}></Grid>
              </div>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.props.history.push('/report/chart', {
                    path: window.location.pathname,
                    src: props.item,
                    state: { category: props.item.category }
                  });
                }}>
                View Report
              </Button>
            </CardActions>
          </Card>
        );

      case AppConfig.EXPLORE_CAROUSEL:
        return (
          <ReportCard
            item={props.item}
            showFavorite={true}
            reportType={reportType}
          />
        );
      case AppConfig.EXCLUSIVE_CAROUSEL:
      case AppConfig.CUSTOM_CAROUSEL:
      case AppConfig.AD_HOC_CAROUSEL:
        return <ReportCard item={props.item} reportType={reportType} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="carousel-cell__inner" style={{ width: this.props.w }}>
        {this.renderItem(this.props)}
      </div>
    );
  }
}

const mapStateToPropsSelector = (state) => ({
  username: state.user.username,
  currentTenantCd: state.user.currentTenantCd,
  permissions: state.user.permissions
});

export default connect(mapStateToPropsSelector)(withRouter(BstCard));
