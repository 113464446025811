import { AppConfig } from 'AppConfig';
import React, { useState, useEffect, useRef } from 'react';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import {
  Box,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import * as Constant from 'common/Constant';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import * as DateUtil from '../../util/Dateutil';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as Actions from './actions/action';
import AnnualPlanDetailDialog from './component/detailDialog';
import * as MDActions from '../../common/actions/CommonActions';

const useStyles = makeStyles(() => ({
  planYearSelect: {
    marginLeft: '1rem',
    minWidth: 150
  }
}));

const AnnualHealthPlan = ({ tenantId }) => {
  const ALL_YEARS = 'All';

  const styles = useStyles();

  const tableRef = useRef();

  const [cloneData, setCloneData] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
  const [pageSize, setPageSize] = useState(Constant.DEFAULT_SELECT_PAGE_SIZE);
  const [planYears, setPlanYears] = useState([ALL_YEARS]);

  const shouldRefresh = useSelector(
    (state) => state.MD.annualPlanData.shouldRefresh
  );
  const totalPlanYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );
  const [isClone, setIsClone] = useState(false);

  const isAllSelected = planYears.length === 1 && planYears[0] == ALL_YEARS;

  const handleDetailOpen = (rowData, isCloneAction = false) => {
    setIsClone(isCloneAction);
    setCloneData(rowData);
    setDetailOpen(true);
  };

  const handleDetailClose = (rowData, cloneEveryThing) => {
    if (rowData) {
      if (cloneEveryThing) {
        store.dispatch(Actions.clone({ ...rowData, tenantId: tenantId }));
      } else {
        store.dispatch(Actions.save({ ...rowData, tenantId: tenantId }));
      }
    }
    setDetailOpen(false);
  };

  const deletePlan = (rowData) => {
    confirmDialog(() => store.dispatch(Actions.deleteById(rowData.id)));
  };

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const refreshPlanYears = () => {
    store.dispatch(MDActions.fetchAllPlanYears());
  };

  useEffect(() => {
    refresh();
  }, [planYears]);

  useEffect(() => {
    refreshPlanYears();
    refresh();
  }, [tenantId]);

  useEffect(() => {
    if (
      tableRef.current &&
      shouldRefresh !== Actions.SHOULD_REFRESH_INIT_VALUE
    ) {
      refresh();
    }
  }, [shouldRefresh]);

  const handlePlanYearSelection = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length === 0 || inputVal[inputVal.length - 1] === ALL_YEARS) {
      setPlanYears([ALL_YEARS]);
    } else {
      setPlanYears(inputVal.filter((yr) => yr !== ALL_YEARS));
    }
  };

  return (
    <>
      <MaterialTable
        title=""
        tableRef={tableRef}
        columns={[
          { title: 'Plan Year', field: 'planYearName' },
          {
            title: 'Start Date',
            field: 'startDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.startDate)
          },
          {
            title: 'End Date',
            field: 'endDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.endDate)
          },
          { title: 'TPA', field: 'tpaName' },
          { title: 'PBM', field: 'pbmName' },
          { title: 'Stop Loss MGU', field: 'slmName' },
          { title: 'Stop Loss Carrier', field: 'slcName' },
          {
            title: 'Deductible Start Date',
            field: 'dedStartDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.dedStartDate)
          },
          {
            title: 'Deductible End Date',
            field: 'dedEndDate',
            render: (rowData) => DateUtil.DEFAULT_FORMAT(rowData.dedEndDate)
          }
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .post(
                AppConfig.API_BASE_URL + 'v2/annual-health-plan/search',
                {
                  years: ALL_YEARS === planYears[0] ? [] : planYears,
                  page: query.page,
                  size: query.pageSize,
                  direction: query.orderDirection
                    ? query.orderDirection.toUpperCase()
                    : Constant.DIRECTION_DESC,
                  sortProperty: query.orderBy
                    ? query.orderBy.field
                    : 'planYearName'
                },
                AppConfig.POST_HEADERS
              )
              .then((m) => {
                let data = m.data;
                resolve({
                  data: data.content,
                  page: data.number,
                  size: data.size,
                  totalCount: data.totalElements
                });
              })
              .catch((err) => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
                console.error('error: ' + err);
              });
          })
        }
        onChangeRowsPerPage={setPageSize}
        options={{
          selection: false,
          actionsColumnIndex: -1,
          search: false,
          pageSizeOptions: Constant.DEFAULT_SEARCH_PAGE_SIZE,
          pageSize
        }}
        actions={[
          {
            icon: 'mode_edit',
            tooltip: 'Edit',
            onClick: (_, rowData) => {
              handleDetailOpen(rowData);
            }
          },
          {
            icon: 'library_add',
            tooltip: 'Clone',
            onClick: (event, rowData) => {
              handleDetailOpen(
                {
                  ...rowData,
                  sourceAhpmId: rowData.id,
                  id: null,
                  planYearName: null
                },
                true
              );
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete Plan',
            onClick: (_, rowData) => deletePlan(rowData)
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            onClick: () => {
              refresh();
            },
            isFreeAction: true
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              handleDetailOpen({
                startDate: null,
                endDate: null,
                dedEndDate: null,
                dedStartDate: null
              });
            }
          }
        ]}
        components={{
          OverlayLoading: () => <div />,
          Toolbar: (props) => (
            <Box>
              <MTableToolbar {...props} />
              <FormControl className={styles.planYearSelect} size="medium">
                <InputLabel id="label_planYear">Plan Year:</InputLabel>
                <Select
                  multiple
                  id="input_planYear"
                  key="input_planYear"
                  onChange={handlePlanYearSelection}
                  value={planYears}
                  renderValue={(planYears) => planYears.join(', ')}>
                  <MenuItem value={ALL_YEARS}>
                    <ListItemIcon>
                      <Checkbox checked={isAllSelected} />
                    </ListItemIcon>
                    <ListItemText primary={ALL_YEARS} />
                  </MenuItem>
                  {totalPlanYears &&
                    Array.isArray(totalPlanYears) &&
                    totalPlanYears.map((item) => (
                      <MenuItem
                        value={item.planYearName}
                        key={item.planYearName}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              planYears.indexOf(item.planYearName) > -1 ||
                              isAllSelected
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.planYearName} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )
        }}
      />
      {detailOpen ? (
        <AnnualPlanDetailDialog
          open={detailOpen}
          onClose={handleDetailClose}
          tenantId={tenantId}
          target={cloneData}
          isClone={isClone}
        />
      ) : null}
    </>
  );
};

export default AnnualHealthPlan;
