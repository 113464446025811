import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';

export const fetchExpenseCategories = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + 'planexpenselookup?type=ExpenseCategory')
      .then((res) => {
        dispatch({
          type: Types.FETCH_EXPENSE_CATEGORY_SUCCESS,
          payload: res.data
        });
      });
  };
};

export const fetchExpenseNames = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + 'planexpenselookup?type=ExpenseName')
      .then((res) => {
        dispatch({
          type: Types.FETCH_EXPENSE_NAMES_SUCCESS,
          payload: res.data
        });
      });
  };
};

export const fetchRateBasis = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + 'planexpenselookup?type=RateBasis')
      .then((res) => {
        dispatch({
          type: Types.FETCH_RATE_BASIS_SUCCESS,
          payload: res.data
        });
      });
  };
};

export const fetchAllRateBasis = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.API_BASE_URL + 'ratebasis/findAllRateBasis')
      .then((res) => {
        dispatch({
          type: Types.FETCH_ALL_RATE_BASIS_SUCCESS,
          payload: res.data
        });
      });
  };
};

export const fetchPlans = () => {
  return (dispatch) => {
    axios.get(AppConfig.API_BASE_URL + `plan/simple`).then((res) => {
      dispatch({
        type: Types.FETCH_SIMPLE_PLANS_SUCCESS,
        payload: res.data
      });
    });
  };
};

export const createPlanExpense = (data) => {
  return (dispatch) => {
    axios.post(AppConfig.API_BASE_URL + 'planexpense', data).then((_) => {
      dispatch({
        type: AppActionTypes.APP_REST_CALL_SUCESSED,
        payload: 'Data Added Successfully'
      });
    });
  };
};

export const fetchAllPlansExpenses = () => {
  return (dispatch) => {
    axios.get(AppConfig.API_BASE_URL + `planexpense/simple`).then((res) => {
      dispatch({
        type: Types.FETCH_SIMPLE_PLANS_EXPENSE_SUCCESS,
        payload: res.data
      });
    });
  };
};

export const deleteRecord = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + 'planexpense/deletePlanExpenseRates', data)
      .then((_) => {
        dispatch({
          type: Types.DELETE_RECORD_SUCCESS
        });
      });
  };
};

export const createNewRecord = (data) => {
  return (dispatch) => {
    axios.post(AppConfig.API_BASE_URL + 'planexpense/', data).then((_) => {
      dispatch({
        type: Types.CREATION_SUCCESS
      });
    });
  };
};

export const updateRecord = (data) => {
  return (dispatch) => {
    axios.post(AppConfig.API_BASE_URL + 'planexpense/save', data).then((_) => {
      dispatch({
        type: Types.CREATION_SUCCESS
      });
    });
  };
};

export const cloneRecord = (data) => {
  return (dispatch) => {
    axios
      .post(AppConfig.API_BASE_URL + 'v2/plan-expense/clone', data)
      .then((_) => {
        dispatch({
          type: Types.CLONE_SUCCESS
        });
      });
  };
};
