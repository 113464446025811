import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { AppConfig } from 'AppConfig';
import axios from 'axios';
import * as Constant from 'common/Constant';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import * as ValidationUtil from 'utils/ValidationUtil';
import * as MDActions from '../../../common/actions/CommonActions';
import * as DiscretionaryActions from '../actions/action';
import * as Types from '../actions/types';
import {v4 as uuid} from 'uuid';

const DiscretionaryBenefitsDetail = (props) => {
  const { tenantId, targetId } = props;

  const { control, watch } = useFormContext();

  const years = useSelector((state) => state.MD.mdCommon.allPlanYearsForTenant);
  const plansForYear = useSelector((state) => state.MD.mdCommon.plansForYear);

  const coverageCategories = useSelector(
    (state) => state.MD.dicretionaryBenefits.coverageCategories
  );

  const coverageNames = useSelector(
    (state) => state.MD.dicretionaryBenefits.coverageNames
  );

  const annualPlanId = watch('ahpmId', '');
  const coverageCategory = watch('coverageCategoryId', '');
  const coverageIndicator = watch('coverageIndicator', false);
  const differentialsIndicator = watch('differentialsIndicator', false);

  useEffect(() => {
    store.dispatch(MDActions.fetchAllPlanYears(tenantId));
  }, [tenantId]);

  useEffect(() => {
    store.dispatch(MDActions.getPlanByAnnualPlanId(annualPlanId));
  }, [annualPlanId]);

  useEffect(() => {
    if (coverageCategory) {
      store.dispatch(
        DiscretionaryActions.getCoverageNamesById(coverageCategory)
      );
    }
    store.dispatch({ type: Types.CLEAR_COVERAGE_NAMES });
  }, [coverageCategory]);

  useEffect(() => {
    store.dispatch(DiscretionaryActions.getCoverageCategories());
  }, [tenantId]);

  return (
    <>
      <Grid container spacing={4} bgcolor="primary.main">
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-ahpmId">Plan Year</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    labelId="label-ahpmId"
                    id="input-ahpmId">
                    {years &&
                      years.map((item) => {
                        return (
                          <MenuItem key={'key-' + uuid()} value={item.id}>
                            {item.planYearName}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="ahpmId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD,
              validate: async (value) => {
                if (value) {
                  try {
                    const isFound = await axios.get(
                      AppConfig.API_BASE_URL +
                      `v2/discretionary/plan/id/${value}`
                    );
                    return ValidationUtil.VALIDATION_DUPLICATION(
                      isFound,
                      targetId,
                      'Same Plan is Found.'
                    );
                  } catch (error) {
                    console.log(error);
                  }
                }
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControl fullWidth error={error}>
                  <InputLabel id="label-planId">Plan</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    required
                    labelId="label-planId"
                    id="input-planId">
                    {plansForYear &&
                      plansForYear.map((item) => {
                        return (
                          <MenuItem key={'key-' + uuid()} value={item.id}>
                            {item.planName}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <FormHelperText>
                    {error ? error.message : null}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            name="planId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <InputLabel id="label-coverageCategory">
                  Coverage Category
                </InputLabel>
                <Select
                  fullWidth
                  {...field}
                  required
                  labelId="label-coverageCategory"
                  id="input-coverageCategory">
                  {coverageCategories &&
                    Array.isArray(coverageCategories) &&
                    coverageCategories.map((item) => {
                      return (
                        <MenuItem key={'key-' + uuid()} value={item.id}>
                          {item.displayText}
                        </MenuItem>
                      );
                    })}
                </Select>

                <FormHelperText>{error ? error.message : null}</FormHelperText>
              </FormControl>
            )}
            name="coverageCategoryId"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            rules={{
              required: Constant.ERROR_MSG_REQUIRED_FIELD
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <InputLabel id="label-coverageCategory">
                  Coverage Name
                </InputLabel>
                <Select
                  id="input-coverageName"
                  fullWidth
                  {...field}
                  required
                  error={error}
                  helperText={error ? error.message : null}
                  label="Coverage Name">
                  {coverageNames &&
                    Array.isArray(coverageNames) &&
                    coverageNames.map((item) => {
                      return (
                        <MenuItem key={'key-' + uuid()} value={item.id}>
                          {item.code}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>{error ? error.message : null}</FormHelperText>
              </FormControl>
            )}
            name="coverageNameId"
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                id="input-inNetworkCoinsurance"
                {...field}
                label="In Network Coinsurance (%)"
                type="number"
                error={error}
                helperText={error ? error.message : null}
              />
            )}
            name="inNetworkCoinsurance"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-inNetworkCopay"
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="In-Network Copay"
              />
            )}
            name="inNetworkCopay"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <TextField
                  id="input-coverageSpecification"
                  fullWidth
                  {...field}
                  error={error}
                  helperText={error ? error.message : null}
                  label="Coverage Specification"
                />
                <FormHelperText>{error ? error.message : null}</FormHelperText>
              </FormControl>
            )}
            name="coverageSpecification"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-coverageLimit"
                fullWidth
                {...field}
                error={error}
                helperText={error ? error.message : null}
                label="Coverage Limit"
              />
            )}
            name="coverageLimit"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    id="input-coverageIndicator"
                    checked={coverageIndicator}
                    fullWidth
                    {...field}
                  />
                }
                label="Coverage Indicator"
              />
            )}
            name="coverageIndicator"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    id="input-differentialsIndicator"
                    checked={differentialsIndicator}
                    fullWidth
                    {...field}
                  />
                }
                label="BDC Benefit Differentials Indicator"
              />
            )}
            name="differentialsIndicator"
            control={control}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DiscretionaryBenefitsDetail;
