import React, { useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid
} from '@material-ui/core';
import * as Actions from '../action/action';
import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { APPSTATE_SET_APP_ERROR } from 'store/actions/appstate';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import { useDispatch } from 'react-redux';
import SystemUpdateAltRoundedIcon from '@material-ui/icons/SystemUpdateAltRounded';

const MdFileDowloadDialog = ({ onClose, open, target }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5)
      }
    },
    noBorderBottom: {
      borderBottom: 'none'
    }
  }));

  const fileList = useSelector((state) => state.mdRenewalManagement.fileList);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (open && target?.rowData?.id) {
      store.dispatch(Actions.clearFileList());
      store.dispatch(Actions.fetchFilesByCaseId(target?.rowData?.id));
    }
  }, [open, target]);

  const downloadMdFile = (file) => {
    axios.post(AppConfig.CLIENT_PORTAL_URL + `/annual-renewal/file/download`, { caseId: target?.rowData?.id, fileName: file }, { responseType: 'blob' }).then(
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        dispatch({ type: APPSTATE_SET_APP_ERROR, payload: error });
      }
    );
  };

  const handleClose = () => {};

  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogTitle id="dialog-fileDownloadingDetail">{'Download MD files'}</DialogTitle>
        <DialogContent dividers>
          <Grid item xs={12}>
            {fileList?.length > 0 ? (
              <TableContainer>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.noBorderBottom} align="left">
                        File Name
                      </TableCell>
                      <TableCell className={classes.noBorderBottom} align="right">
                        &nbsp;&nbsp;&nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fileList?.map((row) => (
                      <TableRow key={row.uploadedDttm}>
                        <TableCell className={classes.noBorderBottom} align="left">
                          {row?.replace(/^.*[\\/]/, '')}
                        </TableCell>
                        <TableCell
                          className={classes.noBorderBottom}
                          align="right"
                          onClick={() => {
                            downloadMdFile(row);
                          }}>
                          <SystemUpdateAltRoundedIcon fontSize="small" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="caption">{'No file has been uploaded'}</Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(handleClose)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MdFileDowloadDialog;
