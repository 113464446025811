const { Button } = require('@material-ui/core');
const { useState } = require('react');

const SubmitButton = ({ handleSubmit, isDirty, ...props }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleClick = async () => {
    setSubmitting(true);
    try {
      await handleSubmit();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Button
      autoFocus
      onClick={handleClick}
      color="primary"
      disabled={!isDirty || isSubmitting}
      {...props}>
      Save
    </Button>
  );
};

export default SubmitButton;
