import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  IconButton,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as Constant from 'common/Constant';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const TagLabelingDialog = (props) => {
  const { onClose, open, target } = props;

  const methods = useForm({ defaultValues: target, mode: 'onBlur' });
  const { reset, control, getValues, setValue, register } = methods;

  const [selectedImage, setSelectedImage] = useState(null);

  const brokerList = useSelector((state) => state.vendorLabeling.brokerList);
  const companyList = useSelector((state) => state.vendorLabeling.companyList);
  const tagList = useSelector((state) => state.vendorLabeling.tagList);

  const brokerFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.name}`
  });

  const companyFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.displayText}`
  });

  const tagFilterOptions = createFilterOptions({
    stringify: (option) => `${option.code} ${option.description}`
  });

  const deleteImage = () => {
    setValue('logoFile', null);
    setSelectedImage(null);
  };

  const handleClose = (data) => {
    setSelectedImage(null);
    onClose(data);
  };

  const onSubmit = (data) => {
    setSelectedImage(null);
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Edit
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} bgcolor="primary.main">
              <Grid
                item
                xs={12}
                style={{ marginBottom: '10px', marginTop: '20px' }}>
                <Typography>
                  Please upload logo here. You can choose multiple brokers,
                  companies and tags.
                  <br />
                  The logo will apply to all the brokers and companies you
                  choose for each tag you selected.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginBottom: '10px', marginTop: '20px' }}>
                <Controller
                  name="selectedBrokerList"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      fullWidth
                      id="input-brokerList"
                      options={brokerList}
                      getOptionLabel={(option) =>
                        option.name ? option.name : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      filterOptions={brokerFilterOptions}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={'input-broker'}
                          label="Broker"
                          placeholder="Chosen Brokers"
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginBottom: '10px', marginTop: '20px' }}>
                <Controller
                  name="selectedCompanyList"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      fullWidth
                      id="input-companyList"
                      options={companyList}
                      getOptionLabel={(option) =>
                        option.displayText ? option.displayText : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      filterOptions={companyFilterOptions}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={'input-company'}
                          label="Company"
                          placeholder="Chosen Companies"
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginBottom: '10px', marginTop: '20px' }}>
                <Controller
                  name="selectedTagList"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      fullWidth
                      id="input-tagList"
                      options={tagList}
                      getOptionLabel={(option) =>
                        option.code ? option.code : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      filterOptions={tagFilterOptions}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={'input-tag'}
                          label="Tag"
                          placeholder="Chosen Tags"
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item container spacing={2}>
                {selectedImage && (
                  <>
                    <Grid item xs={10}>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justify="center">
                        <img
                          alt="logo-preview"
                          width="180"
                          height="38"
                          src={URL.createObjectURL(selectedImage)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() => deleteImage()}
                        component="span">
                        <HighlightOffIcon />
                      </IconButton>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <input
                    type="file"
                    name="input-image"
                    accept="image/png"
                    {...register('logoFile')}
                    onChange={(event) => {
                      setSelectedImage(event.target.files[0]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={methods.handleSubmit(onSubmit)}
              color="primary"
              disabled={!methods.formState.isDirty}>
              Save
            </Button>
            <Button onClick={() => handleClose(null)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default TagLabelingDialog;
