import * as Types from '../action/types';

const initialState = {
  shouldRefresh: false,
  stateList: []
};

export default function tenantManagementReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_STATE_LIST:
      return {
        ...state,
        stateList: action.data
      };
    case Types.SEARCH_REFRESH:
      return {
        ...state,
        shouldRefresh: !state.shouldRefresh
      };
    default:
      return state;
  }
}
