import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IconButton, Grid } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const LogoUploader = (props) => {
  const { setValue, control, watch } = useFormContext();
  const watchLogo = watch('logoFile', null);

  const deleteImage = () => {
    setValue('logoFile', null);
  };
  return (
    <Grid container spacing={2}>
      {watchLogo && (
        <>
          <Grid item xs={10}>
            <Grid container spacing={0} alignItems="center" justifyContent="center">
              <img
                alt="logo-preview"
                width="180"
                height="38"
                src={URL.createObjectURL(watchLogo)}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => deleteImage()} component="span">
              <HighlightOffIcon />
            </IconButton>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Controller
          render={({ field }) => (
            <input
              type="file"
              name="input-image"
              accept="image/png"
              {...field}
              value={''}
              onChange={(event) => {
                field.onChange(event.target.files[0]);
              }}
            />
          )}
          name="logoFile"
          control={control}
        />
      </Grid>
    </Grid>
  );
};

export default LogoUploader;
