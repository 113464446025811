import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';

const VisionPlanNetworkDetail = (props) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    getValues
  } = useFormContext();

  return (
    <>
      <Grid container bgcolor="primary.main" spacing={5}>
        <Grid item xs={3}>
          <Controller
            name="examCopayIn"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-examCopayIn"
                label="Exam Copay In"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name="materialsCopayIn"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-materialsCopayIn"
                label="Materials Copay In"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="retinalScreeningDiabeticsIn"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-retinalScreeningDiabeticsIn"
                label="Retinal Screening Diabetics In"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="framesAllowanceIn"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-framesAllowanceIn"
                label="Frames Allowance In"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="electiveContactLenseAllowanceIn"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-electiveContactLenseAllowanceIn"
                label="Elective Contact Lense Allowance In"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="electiveContactLenseFittingAllowanceIn"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-electiveContactLenseFittingAllowanceIn"
                label="Elective Contact Lense Fitting Allowance In"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="necessaryContactLenseAllowanceIn"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-necessaryContactLenseAllowanceIn"
                label="Necessary Contact Lense Allowance In"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="examReImbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-examReImbOut"
                label="Exam ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="framesReImbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-framesReImbOut"
                label="Frames ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="singleVisionLenseReImbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-singleVisionLenseReImbOut"
                label="Single Vision Lense ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="biFocalLenseReimbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-biFocalLenseReimbOut"
                label="Bi Focal Lense ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="triFocallenseReimbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-triFocallenseReimbOut"
                label="Tri Focal Lense ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="lenticularLenseReimbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-lenticularLenseReimbOut"
                label="Lenticular Lesnse ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="electiveContactReimbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-electiveContactReimbOut"
                label="Elective Contact ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="electiveContactFittingReimbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-electiveContactFittingReimbOut"
                label="Elective Contact Fitting ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="necessaryContactReimbOut"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="input-necessaryContactReimbOut"
                label="Necessary Contact ReImb Out"
                {...field}
                fullWidth
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VisionPlanNetworkDetail;
