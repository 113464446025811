import React, { Component } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import moment from 'moment-timezone';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import AccountTable from './account/accountTable';
import BrokerTable from './account/brokerTable';
import { significantUnexpectedIndicators } from 'utils/common';
import * as AppActionTypes from 'store/actions/appstate';
import { getAllBrokers, fetchData } from 'features/user/userActions';
import * as UserTypes from 'features/user/userTypes';

class Ranger extends Component {
  state = {
    brokerData: null,
    brokerOptions: null,
    homeData: null,
    onBroker: false,
    renderAccount: false,
    robotBrokerData: null,
    robotData: null,
    selectedBroker: null,
    selectedTenants: null,
    showDetailPage: false,
    showGrid: false,
    showHomeColor: null,
    showReport: false,
    showStatus: false,
    summary: null
  };

  constructor(props) {
    super(props);
    this.AccountTable = React.createRef();
  }

  tenantSelect = debounce((aTenant) => {
    let myTenant = aTenant.replace(/\s/g, '');
    axios
      .all([
        axios.get(
          AppConfig.FO_RANGER_URL + '/validation/inspections/' + myTenant
        ),
        axios.post(AppConfig.FO_RANGER_URL + '/logo/getbytenant', {
          tenantCode: myTenant
        })
      ])
      .then(
        axios.spread((...res) => {
          this.setState({
            ...this.state,
            renderAccount: true,
            selectedTenants: myTenant,
            showDetailPage: false,
            showStatus: true,
            homeData: res[0].data.length > 1 ? res[0].data[0] : res[0].data,
            robotData: res[1].data[0],
            onBroker: false,
            showHomeColor: this.hasError(res.data) === true ? 'red' : 'green'
          });
        })
      )
      .catch((err) => {
        console.log(err);
        store.dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Error: getting inspection data from server.'
        });
      });
  }, AppConfig.DEBOUNCE_WAIT_TIME);

  brokerSelect = debounce((selectedBroker) => {
    if (!selectedBroker) {
      return;
    }
    axios
      .all([
        axios.get(
          AppConfig.FO_RANGER_URL +
          '/validation/inspections?brokerCode=' +
          selectedBroker
        ),
        axios.post(AppConfig.FO_RANGER_URL + '/logo/getbybroker', {
          brokerCode: selectedBroker
        })
      ])
      .then(
        axios.spread((...res) => {
          this.setState({
            ...this.state,
            brokerData: res[0].data,
            robotBrokerData: res[1].data,
            onBroker: true,
            selectedBroker: selectedBroker,
            selectedTenants: null,
            showDetailPage: false,
            showStatus: false
          });
        })
      )
      .catch((err) => console.log(err));
  }, AppConfig.DEBOUNCE_WAIT_TIME);

  hasFieldError(data, field) {
    if (!data || !field) {
      return true;
    }
    if (
      (data[field] === null || data[field] === undefined) &&
      'unexpectedBrandGenericIndicators unexpectedFormularyIndicators unexpectedMailOrderIndicators'.indexOf(
        field
      ) < 0
    ) {
      return true;
    }
    switch (field) {
      case 'planLossPercent':
        return data[field] < 50 || data[field] > 150;

      case 'memberCount':
        return data[field] === 0;
      case 'percentInNetwork':
        return data[field] < 90 || data[field] > 100;
      case 'employeeEnrollment':
        return (
          !data['lastEmployeeEnrollment'] ||
          data['lastEmployeeEnrollment'] === 0 ||
          Math.abs(data[field] / data.lastEmployeeEnrollment - 1) > 0.05
        );

      case 'memberEnrollment':
        return (
          !data['lastMemberEnrollment'] ||
          data['lastMemberEnrollment'] === 0 ||
          Math.abs(data[field] / data.lastMemberEnrollment - 1) > 0.05
        );

      case 'rxClaims':
        return (
          !data['lastRxClaims'] ||
          data['lastRxClaims'] === 0 ||
          Math.abs(data[field] / data.lastRxClaims - 1) > 0.3
        );
      case 'medClaims':
        return (
          !data['lastMedClaims'] ||
          data['lastMedClaims'] === 0 ||
          Math.abs(data[field] / data.lastMedClaims - 1) > 0.3
        );

      case 'medCopay':
      case 'medDeductible':
      case 'medCoinsurance':
      case 'rxCopay':
      case 'rxDeductible':
      case 'rxCoinsurance':
      case 'lastMonthTotalPremium':
      case 'lastMonthTotalFixedCost':
        return data[field] !== null && data[field] === 0;
      case 'sumProjectedReimbursements':
        return data[field] !== data.islReimbursement;
      case 'icdCategoryNull':
        return data[field];
      case 'brandCodesNull':
      case 'brandNamesNull':
      case 'genericCodesNull':
      case 'genericNamesNull':
        return data[field] || !data['claimsDrugPopulated'];
      case 'highestVariation':
        return data[field] > 5;
      case 'riskscorePeriod':
        return (
          !data.dataThruDate ||
          moment(data[field])
            .startOf('day')
            .isSame(moment(data.dataThruDate).startOf('day')) === false
        );
      case 'unexpectedBrandGenericIndicators':
        return (
          data[field] == null ||
          significantUnexpectedIndicators(
            data.unexpectedBrandGenericIndicators,
            'B,G'
          ) === true
        );
      case 'unexpectedFormularyIndicators':
        return (
          data[field] == null ||
          significantUnexpectedIndicators(
            data.unexpectedFormularyIndicators,
            'Y,N'
          ) === true ||
          !data['claimsDrugPopulated']
        );
      case 'unexpectedMailOrderIndicators':
        return (
          data[field] == null ||
          significantUnexpectedIndicators(
            data.unexpectedMailOrderIndicators,
            'Y,N'
          ) === true ||
          !data['claimsDrugPopulated']
        );
      default:
        return true;
    }
  }

  hasError(data, fieldErrorFun, robotData) {
    let fieldFun = fieldErrorFun;
    if (!fieldErrorFun) {
      fieldFun = this.hasFieldError;
    }
    let myError =
      fieldFun(data, 'planLossPercent') ||
      fieldFun(data, 'memberCount') ||
      fieldFun(data, 'percentInNetwork') ||
      //mpr
      fieldFun(data, 'employeeEnrollment') ||
      fieldFun(data, 'memberEnrollment') ||
      fieldFun(data, 'rxClaims') ||
      fieldFun(data, 'medClaims') ||
      //financial
      fieldFun(data, 'medCopay') ||
      fieldFun(data, 'medDeductible') ||
      fieldFun(data, 'medCoinsurance') ||
      fieldFun(data, 'rxCopay') ||
      fieldFun(data, 'rxDeductible') ||
      fieldFun(data, 'rxCoinsurance') ||
      fieldFun(data, 'lastMonthTotalPremium') ||
      fieldFun(data, 'lastMonthTotalFixedCost') ||
      fieldFun(data, 'sumProjectedReimbursements') ||
      // diagnosis
      fieldFun(data, 'icdCategoryNull') ||
      // risk
      fieldFun(data, 'highestVariation') ||
      fieldFun(data, 'riskscorePeriod') ||
      // drug
      fieldFun(data, 'brandCodesNull') ||
      fieldFun(data, 'brandNamesNull') ||
      fieldFun(data, 'genericCodesNull') ||
      fieldFun(data, 'genericNamesNull') ||
      fieldFun(data, 'unexpectedBrandGenericIndicators') ||
      fieldFun(data, 'unexpectedFormularyIndicators') ||
      fieldFun(data, 'unexpectedMailOrderIndicators') ||
      (robotData && robotData.runningStatus === false) ||
      (robotData && robotData.dataPublishedStatus === false) ||
      (robotData && robotData.nameMatch === false) ||
      (robotData && robotData.isValidMenu === false);

    return myError;
  }

  getRecord = (data) => {
    if (data) {
      this.setState({ ...this.state, summary: data });
    }
  };

  componentDidMount() {
    store.dispatch({
      type: UserTypes.APP_SWITCHED,
      payload: AppConfig.HEADER_MENU_TYPE.RANGER
    });
    store.dispatch(getAllBrokers());
    store.dispatch(
      fetchData(this.props.currentTenant, AppConfig.APP_CODE_RANGER)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.currentBroker &&
      this.props.currentBroker !== prevProps.currentBroker
    ) {
      this.setState(
        { ...this.state, onBroker: false, renderAccount: false },
        () => {
          this.brokerSelect(this.props.currentBroker);
        }
      );
    }

    if (
      this.props.currentTenantCd &&
      this.props.currentTenantCd !== prevProps.currentTenantCd
    ) {
      this.setState(
        { ...this.state, renderAccount: false, onBroker: false },
        () => {
          this.tenantSelect(this.props.currentTenantCd);
        }
      );
    }
  }

  sumValues = (obj) => {
    if (!obj || !Object.keys(obj).length) {
      return 0;
    }
    return Object.values(obj).reduce((a, b) => a + b);
  };

  generateQualityScore = () => {
    if (!this.state.brokerData) {
      return null;
    }
    if (this.state.summary === null) {
      return (
        <MDBRow
          center
          style={{
            fontSize: 'large',
            fontWeight: 'bold',
            color: 'green'
          }}>
          Total Tenants: {this.state.brokerData.length}, &nbsp; Total Errors: 0,
          &nbsp; Quality Score : 100
        </MDBRow>
      );
    } else {
      let qualityScore =
        this.sumValues(this.state.summary) === 0
          ? 100
          : 100 -
          (this.sumValues(this.state.summary) * 100) /
          (this.state.brokerData.length *
            Object.keys(this.state.summary).length);
      return (
        <MDBRow
          center
          style={{
            fontSize: 'large',
            fontWeight: 'bold',
            color: 'green'
          }}>
          Total Tenants: {this.state.brokerData.length}, &nbsp; Total Errors:{' '}
          {this.sumValues(this.state.summary)}, &nbsp; Quality Score:
          {qualityScore.toFixed(2)}
        </MDBRow>
      );
    }
  };

  render() {
    return (
      <>
        <MDBRow className="d-flex justify-content-center">
          {this.state.homeData && this.state.selectedTenants && (
            <MDBCol size="2" style={{ marginTop: '1em', marginLeft: '10em' }}>
              <span
                className="font-weight-bold align-top mb-5 mr-3"
                style={{ fontSize: '2em' }}>
                {this.state.selectedTenants}
              </span>
              <MDBIcon
                size="3x"
                far
                icon={
                  this.state.showHomeColor === 'green'
                    ? 'check-circle'
                    : 'times-circle'
                }
                style={{ color: this.state.showHomeColor }}
                onClick={(e) =>
                  this.AccountTable.current.setState({
                    ...this.state,
                    showDetailPage:
                      !this.AccountTable.current.state.showDetailPage
                  })
                }
              />
            </MDBCol>
          )}
        </MDBRow>
        {this.state.onBroker === true && this.state.brokerData ? (
          <>
            {this.generateQualityScore()}
            <BrokerTable
              hasError={this.hasError}
              hasFieldError={this.hasFieldError}
              getStatus={this.getRecord}
              brokerData={this.state.brokerData}
              robotBrokerData={this.state.robotBrokerData}
            />
          </>
        ) : null}
        {this.state.homeData && this.state.renderAccount === true && (
          <AccountTable
            hasFieldError={this.hasFieldError}
            ref={this.AccountTable}
            key={this.props.currentTenantCd}
            values={{
              showReport: this.state.showReport,
              showDetailPage: this.state.showDetailPage,
              selectedTenants: this.state.selectedTenants,
              homeData: this.state.homeData,
              robotData: this.state.robotData
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToPropsSelector = createStructuredSelector({
  currentBroker: (state) => state.user.currentBroker,
  currentTenantCd: (state) => state.user.currentTenantCd
});

export default connect(mapStateToPropsSelector)(withRouter(Ranger));
