export const PREFIX = 'MD_';

export const FETCH_PLAN_TYPES_SUCCESS = PREFIX + 'FETCH_PLAN_TYPES';

export const GET_ALL_ELECTION_TIER_SUCCESS =
  PREFIX + 'GET_ALL_ELECTION_TIER_SUCCESS';

export const GET_ALL_NETWORK_TYPES = PREFIX + 'GET_ALL_NETWORK_TYPES';
export const GET_ALL_NETWORK_TYPES_SUCCESS =
  PREFIX + 'GET_ALL_NETWORK_TYPES_SUCCESS';
export const GET_ALL_NETWORK_TYPES_FAILURE =
  PREFIX + 'GET_ALL_NETWORK_TYPES_FAILURE';

export const GET_TPA_FOR_TENENT_SUCCESS = PREFIX + 'GET_TPA_FOR_TENENT_SUCCESS';
export const GET_PBM_FOR_TENENT_SUCCESS = PREFIX + 'GET_PBM_FOR_TENENT_SUCCESS';
export const GET_SLC_FOR_TENENT_SUCCESS = PREFIX + 'GET_SLC_FOR_TENENT_SUCCESS';
export const GET_SLM_FOR_TENENT_SUCCESS = PREFIX + 'GET_SLM_FOR_TENENT_SUCCESS';

export const GET_EMPLOYE_CLASS_SUCCESS = PREFIX + 'GET_EMPLOYE_CLASS_SUCCESS';
export const GET_ELECTION_TIER_BY_TENANT_ID_SUCCESS =
  PREFIX + 'GET_ELECTION_TIER_BY_TENANT_ID_SUCCESS';

export const FETCH_ALL_PLAN_YEARS_BY_TENANT_ID_SUCCESS =
  PREFIX + 'FETCH_ALL_PLAN_YEARS_BY_TENANT_ID_SUCCESS';

export const GET_PLANS_BY_ANNUAL_PLAN_ID_SUCCESS =
  PREFIX + 'GET_PLANS_BY_ANNUAL_PLAN_ID_SUCCESS';

export const GET_MED_PLANS_BY_ANNUAL_PLAN_ID_SUCCESS =
  PREFIX + 'GET_MED_PLANS_BY_ANNUAL_PLAN_ID_SUCCESS';

export const SET_MASTERDATA_PLAN_YEARS = PREFIX + 'SET_PLAN_YEARS';

export const FETCH_ALL_UNIQUE_NAME_PLAN_DESIGN_BY_TENANT_ID_SUCCESS =
  PREFIX + 'FETCH_ALL_UNIQUE_NAME_PLAN_DESIGN_BY_TENANT_ID_SUCCESS';

export const FETCH_ALL_PLAN_NAMES_SUCCESS =
  PREFIX + 'FETCH_ALL_PLAN_NAMES_SUCCESS';
