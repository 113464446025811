import {
  Box,
  Button,
  Container,
  Divider,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { fetchData } from 'features/user/userActions';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';
import React, { useState ,useEffect} from 'react';

import UpdateDialog from './component/updateDialog';
import Auth from 'auth/Auth';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: '3rem',
    paddingBottom: '3rem'
  },

  title: {
    textAlign: 'center',
    margin: 'auto',
    fontSize: 30,
    paddingBottom: '0.5rem',
  },

  titleContainer: {
    maxWidth: 400,
    margin: 'auto'
  },

  card: {
    maxWidth: 480,
    textAlign: 'center',
    boxShadow: "1px 2px 2px 1px #9E9E9E",
    paddingBottom: '1rem',
    margin: 'auto',
    borderRadius: 5
  },

  gridName: {
    display: 'flex',
    justifyContent: 'right',
  },

  gridContent: {
    display: 'flex',
    justifyContent: 'left'
  }

}));

const UserProfile = () => {
  const classes = useStyles();
  const {deafultUserState,currentTenantCd} = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);

  const [updateOpen, setUpdatOpen] = useState(false);

  const handleUpdateOpen = () => {
    setUpdatOpen(true);
  };

  const handleUpdateClose = () => {
    setUpdatOpen(false);
  };
  useEffect(() =>{
    if(deafultUserState){
      store.dispatch(
        fetchData(currentTenantCd, AppConfig.APP_CODE_FO)
      );
    }

  },[deafultUserState])

  return (
    <>
      <div className={classes.wrapper}>
        <Grid className={classes.card}>
          <Grid className={classes.titleContainer}>
            <Typography className={classes.title}>My Profile</Typography>
          </Grid>
          <Divider />
          <Grid style={{ paddingTop: '2rem' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} className={classes.gridName}>
                <Typography style={{ fontWeight: 'bold' }}>First Name:&nbsp;</Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridContent}>
                <Typography>{user.firstName}</Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridName}>
                <Typography style={{ fontWeight: 'bold' }}>Last Name:&nbsp;</Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridContent}>
                <Typography>{user.lastName}</Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridName}>
                <Typography style={{ fontWeight: 'bold' }}>Email:&nbsp;</Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridContent}>
                <Typography>{user.email}</Typography>
              </Grid>
            </Grid>
            { Auth.getLoginMode() === Auth.LOGIN_MODE_BST && 
              <Container style={{ paddingTop: '2rem' }}>
                <Button
                  autoFocus
                  variant='outlined'
                  onClick={handleUpdateOpen}
                  color='primary'
                >
                  Change Password
                </Button>
              </Container>
            }
          </Grid>
        </Grid>
      </div>
      <UpdateDialog
        open={updateOpen}
        onClose={handleUpdateClose}
      />
    </>
  );
};

export default UserProfile;
