import React, { useEffect, useState } from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PolicyExclusionList from './components/policyExclusionList';
import MonthlyAggList from './components/monthlyAggList';
import IslPlanList from './components/islPlanList';
import LaserList from './components/laserList';
import { useForm, FormProvider } from 'react-hook-form';
import * as DateUtil from '../../util/Dateutil';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from 'common/BstAccordion';

const StopLossContractDetailView = React.memo(
  ({ item, tenantId, handleEdit, handleExpend }) => {
    const DEFAULT_UNKNOWN_DATE = 'Unknown';

    const [cloned, setCloned] = useState(item);

    const methods = useForm({ defaultValues: item });
    const { reset } = methods;

    const handleStateChange = (expend, section = 'default_agg_open') => {
      if (handleExpend) {
        handleExpend(item, expend);
      }
      setCloned({ ...cloned, [section]: expend });
    };

    const getIslStartDate = () => {
      if (item.runInStartDate || item.runOutStartDate) {
        return item.runInStartDate
          ? DateUtil.DEFAULT_FORMAT(item.runInStartDate)
          : item.runOutStartDate
            ? DateUtil.DEFAULT_FORMAT(item.runOutStartDate)
            : DEFAULT_UNKNOWN_DATE;
      } else {
        return item.startDate
          ? DateUtil.DEFAULT_FORMAT(item.startDate)
          : DEFAULT_UNKNOWN_DATE;
      }
    };

    const getIslEndDate = () => {
      if (item.runInEndDate || item.runOutEndDate) {
        return item.runInEndDate
          ? DateUtil.DEFAULT_FORMAT(item.runInEndDate)
          : item.runOutEndDate
            ? DateUtil.DEFAULT_FORMAT(item.runOutEndDate)
            : DEFAULT_UNKNOWN_DATE;
      } else {
        return item.endDate
          ? DateUtil.DEFAULT_FORMAT(item.endDate)
          : DEFAULT_UNKNOWN_DATE;
      }
    };

    const getAslStartDate = () => {
      if (item.aslStartDate) {
        return DateUtil.DEFAULT_FORMAT(item.aslStartDate);
      } else {
        return item.startDate
          ? DateUtil.DEFAULT_FORMAT(item.startDate)
          : DEFAULT_UNKNOWN_DATE;
      }
    };

    const getAslEndDate = () => {
      if (item.aslEndDate) {
        return DateUtil.DEFAULT_FORMAT(item.aslEndDate);
      } else {
        return item.endDate
          ? DateUtil.DEFAULT_FORMAT(item.endDate)
          : DEFAULT_UNKNOWN_DATE;
      }
    };

    useEffect(() => {
      reset(item);
    }, [item]);

    return (
      <>
        ¸
        <FormProvider {...methods}>
          <Accordion
            defaultExpanded={cloned ? cloned.default_isl_open : false}
            onChange={(event, expend) =>
              handleStateChange(expend, 'default_isl_open')
            }
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="isl-plancontent"
              id="isl-plan-header">
              <Typography variant="button" display="block" gutterBottom>
                ISL Plans ({getIslStartDate()} - {getIslEndDate()}) -{' '}
                <i>
                  {item.islAppliedToAll === true
                    ? ' All'
                    : item.islPlans && Array.isArray(item.islPlans)
                      ? item.islPlans.length
                      : 0}{' '}
                  Plan(s){' '}
                </i>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <IslPlanList slcData={item} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={cloned ? cloned.default_agg_open : false}
            onChange={(event, expend) =>
              handleStateChange(expend, 'default_agg_open')
            }
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="monthly-aggreate-content"
              id="monthly-aggreate-header">
              <Typography variant="button" display="block" gutterBottom>
                ASL Agg Factor (Agg Factor = Expected Claims x Corridor) ({getAslStartDate()} -{' '}
                {getAslEndDate()}) - <i>{item.numOfAslPlans} Plan(s) </i>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MonthlyAggList
                data={item.aggRates}
                stopLossContractId={item.id}
                tenantId={tenantId}
                annualPlanId={item.ahpmId}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={cloned ? cloned.default_policy_open : false}
            onChange={(event, expend) =>
              handleStateChange(expend, 'default_policy_open')
            }
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="policy-exclusion-content"
              id="policy-exclusion-header">
              <Typography
                variant="button"
                display="block"
                gutterBottom
                color={
                  item && item.completedPolicyExclusion === false
                    ? 'error'
                    : 'initial'
                }>
                Policy Exclusion
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PolicyExclusionList />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={cloned ? cloned.default_laser_open : false}
            onChange={(event, expend) =>
              handleStateChange(expend, 'default_laser_open')
            }
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="laser-content"
              id="laser-header">
              <Typography
                variant="button"
                display="block"
                gutterBottom
                color={
                  item && item.completedLaser === false ? 'error' : 'initial'
                }>
                Laser
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LaserList
                slcStartDate={item.startDate}
                slcEndDate={item.endDate}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={cloned ? cloned.default_additional_open : false}
            onChange={(event, expend) =>
              handleStateChange(expend, 'default_additional_open')
            }
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="laser-content"
              id="laser-header">
              <Typography variant="button" display="block" gutterBottom>
                Additional info
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} bgcolor="primary.main">
                <Grid item xs={2}>
                  <TextField
                    key={'readonly-domesticReImbursement' + item.id}
                    label="Domestic Reimbursementidor"
                    defaultValue={item.domesticReImbursement}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    key={'readonly-runInStartDate' + item.id}
                    label="Run In Start Date"
                    defaultValue={DateUtil.DEFAULT_FORMAT(item.runInStartDate)}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    key={'readonly-runInEndDate' + item.id}
                    label="Run In End Date"
                    defaultValue={DateUtil.DEFAULT_FORMAT(item.runInEndDate)}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    key={'readonly-runOutStartDate' + item.id}
                    label="Run Out Start Date"
                    defaultValue={DateUtil.DEFAULT_FORMAT(item.runOutStartDate)}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    key={'readonly-runOutEndDate' + item.id}
                    label="Run Out End Date"
                    defaultValue={DateUtil.DEFAULT_FORMAT(item.runOutEndDate)}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    key={'readonly-aslContractBasis' + item.id}
                    label="ASL Contract Basis"
                    defaultValue={item.aslContractBasis}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    key={'readonly-aslStartDate' + item.id}
                    label="ASL Start Date"
                    defaultValue={DateUtil.DEFAULT_FORMAT(item.aslStartDate)}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    key={'readonly-aslEndDate' + item.id}
                    label="ASL End Date"
                    defaultValue={DateUtil.DEFAULT_FORMAT(item.aslEndDate)}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </FormProvider>
      </>
    );
  }
);

export default StopLossContractDetailView;
