import * as Types from './types';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import * as AppActionTypes from 'store/actions/appstate';

export const fetchFeatureReports = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.FO_INITIAL_URL + `/report-default-setting/feature-reports`)
      .then((res) => {
        dispatch({
          type: Types.FETCH_FEATURE_REPORTS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Failed to fetch feature reports.'
        });
        console.error('Error fetching feature reports:', err);
      });
  };
};

export const fetchDefaultSettings = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.FO_INITIAL_URL + `/report-default-setting`)
      .then((res) => {
        dispatch({
          type: Types.FETCH_REPORT_DEFAULT_SETTINGS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: AppActionTypes.APPSTATE_SET_APP_ERROR,
          payload: 'Failed to fetch feature reports.'
        });
        console.error('Error fetching feature reports:', err);
      });
  };
};
