import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Box
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { store } from 'store/store';
import {v4 as uuid} from 'uuid';
import * as MDActions from '../../../common/actions/CommonActions';
import * as Constant from 'common/Constant';

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: '400px'
  },
  box: {
    spacing: theme.spacing(2)
  }
}));

const ALL_PLANS = Constant.ALL;

const BulkModificationDetail = ({ tenantId }) => {
  const { control, setValue, getValues } = useFormContext();
  const [planNames, setPlanNames] = useState([]);

  const planYears = useSelector(
    (state) => state.MD.mdCommon.allPlanYearsForTenant
  );
  const allPlanDesignDatasInMap = useSelector(
    (state) => state.MD.planRate.allPlanDesignDatasInMap
  );

  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const styles = useStyles();

  const handleCheckboxClick = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    setIsLoading(true);
    store.dispatch(MDActions.fetchAllPlanYears());
    setIsLoading(false);
  }, [tenantId]);

  const onFieldChange = (name, field, event) => {
    const val = parseInt(event.target.value);
    field.onChange(val);
    if (checked) {
      setValue(name, val, {
        shouldTouch: true,
        shouldDirty: val !== 0
      });
    }
  };
  const onPlanYearChange = (year) => {
    if (
      allPlanDesignDatasInMap &&
      allPlanDesignDatasInMap[year] &&
      Array.isArray(allPlanDesignDatasInMap[year])
    ) {
      setPlanNames(allPlanDesignDatasInMap[year].map((item) => item.planName));
    }
  };

  if (isLoading) {
    return <div />;
  }
  return (
    <>
      <Grid
        container
        direction="row"
        bgcolor="primary.main"
        className={styles.dialog}>
        <Grid item xs={12}>
          <Controller
            rules={{ required: 'Source year is required' }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <InputLabel id="label-sourceYear">
                  Annual Health Plan Year
                </InputLabel>
                <Select
                  id="input-sourceYear"
                  {...field}
                  onChange={(_, v) => {
                    onPlanYearChange(parseInt(v.props.children));
                    field.onChange(_, v);
                  }}
                  error={error}
                  defaultValue=""
                  required>
                  {planYears &&
                    Array.isArray(planYears) &&
                    planYears.map((item) => (
                      <MenuItem key={uuid()} value={item.id}>
                        {item.planYearName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            name="ahpmId"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            rules={{ required: 'Plan Type is required' }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={error}>
                <InputLabel id="label-sourceYear">Plan Type</InputLabel>
                <Select {...field} id="input_planName">
                  <MenuItem value={ALL_PLANS}>{ALL_PLANS}</MenuItem>
                  {planNames.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="planName"
            control={control}
          />
        </Grid>

        <Box>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox checked={checked} onClick={handleCheckboxClick} />
              }
              label="Date Shift"
            />
          </Grid>
        </Box>
        <Grid container direction="row" justifyContent="space-evenly" fullWidth>
          <Box className={styles.box}>
            <Grid item xs={12}>
              <Controller
                rules={{ required: 'Month Change' }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={error}>
                    <TextField
                      id="plan-rate-start-date-month-change-input"
                      type="number"
                      label={'Start Date Month Change'}
                      value={getValues('startDateMonthChange')}
                      onChange={(e, v) =>
                        onFieldChange('endDateMonthChange', field, e)
                      }
                    />
                    {error && error.message && (
                      <FormHelperText>{error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
                name="startDateMonthChange"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                rules={{ required: 'Month Change' }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={error}>
                    <TextField
                      id="plan-rate-end-date-month-change-input"
                      type="number"
                      label="End Date Month Change"
                      value={getValues('endDateMonthChange')}
                      disabled={checked}
                      onChange={(e, v) =>
                        field.onChange(parseInt(e.target.value))
                      }
                    />
                    {error && error.message && (
                      <FormHelperText>{error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
                name="endDateMonthChange"
                control={control}
              />
            </Grid>
          </Box>
          <Box className={styles.box}>
            <Grid item xs={12}>
              <Controller
                rules={{ required: 'Day Change' }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={error}>
                    <TextField
                      id="plan-rate-start-date-day-change-input"
                      type="number"
                      label={'Start Date Day Change'}
                      value={getValues('startDateDayChange')}
                      onChange={(e, v) =>
                        onFieldChange('endDateDayChange', field, e)
                      }
                    />
                    {error && error.message && (
                      <FormHelperText>{error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
                name="startDateDayChange"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                rules={{ required: 'Day Change' }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={error}>
                    <TextField
                      id="plan-rate-end-date-day-change-input"
                      type="number"
                      label="End Date Day Change"
                      value={getValues('endDateDayChange')}
                      disabled={checked}
                      onChange={(e, v) =>
                        field.onChange(parseInt(e.target.value))
                      }
                    />
                    {error && error.message && (
                      <FormHelperText>{error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
                name="endDateDayChange"
                control={control}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default BulkModificationDetail;
