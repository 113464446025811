import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import DiscretionaryBenefitsDetail from './detail';
import { useForm, FormProvider } from 'react-hook-form';
import SubmitButton from 'common/SingleClickButton';

const DiscretionaryBenefitsDetailDialog = (props) => {
  const { onClose, open, tenantId, target } = props;
  const defaultValues = {
    planId: [],
    coverageCategoryId: null,
    coverageSpecification: null,
    coverageNameId: null,
    coverageIndicator: null,
    inNetworkCoinsurance: null,
    inNetworkCopay: null,
    coverageLimit: null,
    tenantId: null,
    differentialsIndicator: null
  };

  const methods = useForm({
    defaultValues: { ...defaultValues },
    mode: 'onBlur'
  });
  const { reset } = methods;

  const handleClose = () => {
    onClose(null);
  };

  const onSubmit = (data) => {
    onClose(data);
  };

  useEffect(() => {
    reset(target);
  }, [tenantId, open, target]);

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}>
          <DialogTitle id="customized-dialog-title">
            {target && target.id > 0
              ? 'Edit'
              : target && target.tenantId
              ? 'Clone'
              : 'New'}
          </DialogTitle>
          <DialogContent dividers>
            <DiscretionaryBenefitsDetail
              tenantId={tenantId}
              isModified={target && target.id > 0}
              targetId={target ? target.id : null}
            />
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={methods.handleSubmit(onSubmit)}
              isDirty={methods.formState.isDirty}
            />
            <Button onClick={() => handleClose()} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default DiscretionaryBenefitsDetailDialog;
