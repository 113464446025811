/* for User */
export const APP_SET = 'APP_SET';
export const BROKER_SET = 'BROKER_SET';
export const CLAIM_TYPE_SET = 'CLAIM_TYPE_SET';
export const LEAF_CLICK = 'LEAF_CLICK';
export const LEVEL_SET = 'LEVEL_SET';
export const LOAD_DATA = 'LOAD_DATA';
export const MESSAGE_OK_SET = 'MESSAGE_OK_SET';
export const PLAN_YEAR_SET = 'PLAN_YEAR_SET';
export const SHOW_TOP_BAR_BROKER_DROPDOWN = 'SHOW_TOP_BAR_BROKER_DROPDOWN';
export const SITE_SET = 'SITE_SET';
export const ROLE_SET = 'ROLE_SET';
export const SUBGROUP1_SET = 'SUBGROUP1_SET';
export const SUBGROUP2_SET = 'SUBGROUP2_SET';
export const SUBGROUP3_SET = 'SUBGROUP3_SET';
export const TPA_SET = 'TPA_SET';
export const USER_SET = 'USER_SET';
export const SYS_ADMIN_USER_SET = 'SYS_ADMIN_USER_SET';
export const MESSAGE_SET = 'MESSAGE_SET';
export const REC_DISMISSED = 'REC_DISMISSED';
export const REC_PINNED = 'REC_PINNED';
