import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { OPEN_ALERT_DIALOG } from 'store/actions/appstate';
import { store } from 'store/store';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AppConfig } from 'AppConfig';

const useStyles = makeStyles((theme) => ({
  copyRightText: {
    color: 'black',
    fontSize: '10px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '20px'
  },

  btnGrid: {
    padding: '10px'
  },
  cnfrmBtn: { textTransform: 'none' },
  closeBtn: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: '#ffffff'
    },
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff'
  }
}));

export const AlertDialog = ({ handleComfirm }) => {
  const styles = useStyles();
  const isOpenAlertDialog = useSelector(
    (state) => state.appState.isOpenAlertDialog
  );
  const [isLoading, setIsLoading] = useState(true);
  const [copyrightYear, updateCopyrightYear] = useState(null);

  const handleClose = () => {
    store.dispatch({
      type: OPEN_ALERT_DIALOG,
      payload: false
    });
  };
  const onConfirm = () => {
    handleComfirm();
    handleClose();
  };

  useEffect(() => {
    if (copyrightYear == null) {
      axios.get(AppConfig.FO_INITIAL_URL + `/copyright`)
        .then((m) => {
          updateCopyrightYear(m.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <Dialog
      open={isOpenAlertDialog && !isLoading}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <IconButton
              component="span"
              className={styles.closeBtn}
              onClick={handleClose}
              size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography className={styles.copyRightText}>
            CPT © {copyrightYear} American Medical Association. All
            rights reserved.
          </Typography>
          <Typography className={styles.copyRightText}>
            Fee schedules, relative value units, conversion factors and/or
            related components are not assigned by the AMA, are not part of CPT,
            and the AMA is not recommending their use. The AMA does not directly
            or indirectly practice medicine or dispense medical services. The
            AMA assumes no liability for data contained or not contained herein.
          </Typography>
          <Typography className={styles.copyRightText}>
            CPT is a registered trademark of the American Medical Association.
          </Typography>
          <Typography
            className={styles.copyRightText}
            style={{ paddingTop: '20px' }}>
            U.S. Government Rights
          </Typography>
          <Typography className={styles.copyRightText}>
            This product includes CPT and/or CPT® Changes which are commercial
            technical data, which was developed exclusively at private expense
            by the American Medical Association, 330 North Wabash Avenue,
            Chicago, Illinois 60611. The American Medical Association does not
            agree to license CPT to the Federal Government based on the license
            in FAR 52.227-14 (Data Rights - General) and DFARS 252.227-7015
            (Technical Data - Commercial Items) or any other license provision.
            The American Medical Association reserves all rights to approve any
            license with any Federal agency.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={styles.btnGrid}>
          <Grid item>
            <Button
              className={styles.cnfrmBtn}
              onClick={onConfirm}
              color="primary"
              autoFocus
              variant="contained">
              Proceed to Ad-hoc
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
