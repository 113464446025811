import * as Types from './types';
import { APPSTATE_SET_APP_ERROR } from 'store/actions/appstate';
import axios from 'axios';
import { AppConfig } from 'AppConfig';
import { trackingTypes, trackingActions, trackClick } from 'utils/tracking';

export const fetchApplicationList = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.IAM_BACKEND_URL + `/user-access/application-role-list`)
      .then((m) => {
        dispatch({
          type: Types.FETCH_APPLICATION_LIST,
          data: m.data
        });
      });
  };
};

export const fetchTenantList = () => {
  return (dispatch) => {
    axios
      .get(AppConfig.IAM_BACKEND_URL + `/user-access/company-list`)
      .then((m) => {
        dispatch({
          type: Types.FETCH_TENANT_LIST,
          data: m.data
        });
      });
  };
};

export const fetchUserTenantRoleByUserId = (userId) => {
  return (dispatch) => {
    axios
      .get(AppConfig.IAM_BACKEND_URL + `/user-tenant-role/user/` + userId)
      .then((m) => {
        dispatch({
          type: Types.FETCH_USER_TENANT_ROLE_DATA,
          data: m.data
        });
      });
  };
};

export const saveApplicationAccesses = (dto) => {
  return (dispatch) => {
    axios
      .post(AppConfig.IAM_BACKEND_URL + `/user-tenant-role/all`, dto)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const saveApplicationAdmin = (dto) => {
  return (dispatch) => {
    axios
      .post(AppConfig.IAM_BACKEND_URL + `/user/v2/role/admin`, dto)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const saveTenantAdminPermissions = (dto) => {
  return (dispatch) => {
    axios
      .post(AppConfig.IAM_BACKEND_URL + `/user-permission-admin`, dto)
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      })
      .catch((err) => {
        dispatch({
          type: APPSTATE_SET_APP_ERROR,
          payload: err
        });
      });
  };
};

export const deleteByUserApplicationAndRoleId = (
  userId,
  applicationId,
  roleId
) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.IAM_BACKEND_URL +
        `/user-tenant-role/user/v2/${userId}/role/${roleId}/application/${applicationId}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const deleteByUserApplicationAndCompanyIdAndRoleId = (
  userId,
  applicationId,
  companyId,
  roleId
) => {
  return (dispatch) => {
    axios
      .delete(
        AppConfig.IAM_BACKEND_URL +
        `/user-tenant-role/user/${userId}/application/${applicationId}/company/${companyId}/role/${roleId}`
      )
      .then((m) => {
        dispatch({
          type: Types.SEARCH_REFRESH
        });
      });
  };
};

export const syncAdminsWithTableau = () => {
  const target = "SYNC_ADMIN_TABLEAU";
  trackClick(
    trackingTypes.APPLICATION,
    trackingActions.CLICK,
    target
  );
  return (dispatch) => {
    axios.get(AppConfig.IAM_BACKEND_URL + `/user/v2/admin/tableau-sync`);
  };
};

export const syncExternalUsersWithTableau = () => {
  const target = "SYNC_EXTERNAL_TABLEAU";
  trackClick(
    trackingTypes.APPLICATION,
    trackingActions.CLICK,
    target
  );
  return (dispatch) => {
    axios.get(AppConfig.IAM_BACKEND_URL + `/user/v2/external-user/tableau-sync`);
  };
};

export const fetchTenantUserPermission = (userId) => {
  return (dispatch) => {
    axios
      .get(`${AppConfig.IAM_BACKEND_URL}/user-permission-admin/user/${userId}`)
      .then((m) => {
        dispatch({
          type: Types.FETCH_TENANT_USER_PERMISSION,
          data: m.data
        });
      });
  };
};

export const createNewUserAccess = (params) => {
  return (dispatch) => {
    axios.post(`${AppConfig.IAM_BACKEND_URL}/user/create`, params).then((m) => {
      dispatch({
        type: Types.USER_ACCESS_CREATE_SUCCESS
      });
    });
  };
};
export const updateUserDetails = (params) => {
  return (dispatch) => {
    axios.post(`${AppConfig.IAM_BACKEND_URL}/user/update`, params).then((m) => {
      dispatch({
        type: Types.USER_UPDATE_SUCCESS
      });
    });
  };
};
