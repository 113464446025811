import React, { useState, useEffect, Fragment } from 'react';
import { TextField, Grid, IconButton } from '@material-ui/core';
import { Delete, Add } from '@material-ui/icons';
import {v4 as uuid} from 'uuid';
import MonthlyAggDialog from './monthlyAggDialog';
import * as Constant from 'common/Constant';
import * as Actions from '../actions/actions';
import { store } from 'store/store';
import { confirmDialog } from 'common/ComfirmationDialog';

const MonthlyAggList = React.memo((props) => {
  const { data, stopLossContractId, tenantId, annualPlanId } = props;

  const DEAFULT_CRUD_OBJ = {
    stopLossContractId: stopLossContractId,
    sLCMonthlyAggType: Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE
  };

  const [dataGroupByPlanMap, setDataGroupByPlanMap] = useState({});
  const [planKeyValue, setPlanKeyValue] = useState({});

  const [creationOpen, setCreationOpen] = useState(false);

  const [crudObj, setCrudObj] = useState(DEAFULT_CRUD_OBJ);

  const handleCreationClose = (data) => {
    if (data) {
      if (
        data.sLCMonthlyAggType === Constant.SLC_MONTHLY_AGG_TYPE.ELECTION_TIER
      ) {
        store.dispatch(Actions.saveMonthlyAggForElectionTiers(data));
      } else {
        store.dispatch(Actions.saveMonthlyAgg(data));
      }
    }
    setCreationOpen(false);
  };

  const handleCreationOpen = (data) => {
    setCrudObj(data);
    setCreationOpen(true);
  };

  const handleDelete = (id) => {
    if (stopLossContractId && id) {
      confirmDialog(() =>
        store.dispatch(
          Actions.deleteMonthlyAggByPlanAndStopLossContractId(
            id,
            stopLossContractId
          )
        )
      );
    }
  };

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const newMap = {};
      const map = {};
      data.map((key) => {
        const keyId = key.planId;
        if (!newMap.hasOwnProperty(keyId)) {
          newMap[keyId] = [];
        }

        newMap[keyId].push(key);
        if (key.plan) map[keyId] = key.plan.planName;
      });
      setPlanKeyValue(map);
      setDataGroupByPlanMap(newMap);
    }
  }, [data]);

  return (
    <>
      <Grid
        container
        spacing={2}
        bgcolor="primary.main"
        style={{ minWidth: '500px' }}>
        <MonthlyAggDialog
          open={creationOpen}
          onClose={handleCreationClose}
          tenantId={tenantId}
          target={crudObj}
          annualPlanId={annualPlanId}
        />
        {data && Object.keys(dataGroupByPlanMap).length > 0 ? (
          Object.keys(dataGroupByPlanMap).map((key, index) => (
            <Fragment key={uuid()}>
              <Grid item xs={3}>
                <TextField
                  id={'readonly-planId-' + index}
                  key={'key-' + uuid()}
                  label="Plan"
                  InputProps={{ readOnly: true }}
                  multiline
                  defaultValue={planKeyValue[key]}
                />
              </Grid>
              {Array.isArray(dataGroupByPlanMap[key]) &&
                dataGroupByPlanMap[key].length > 0
                ? dataGroupByPlanMap[key].map((item, indx) => {
                  return item.slcmonthlyAggType ===
                    Constant.SLC_MONTHLY_AGG_TYPE.COMPOSITE ? (
                    <Fragment key={uuid()}>
                      {indx > 0 ? <Grid item xs={3}></Grid> : null}
                      <Grid item xs={3}>
                        <TextField
                          id={'readonly-aggDedComp-' + indx}
                          key={'key-' + uuid()}
                          label="Aggregate Factor Composite"
                          InputProps={{ readOnly: true }}
                          defaultValue={item.aggDedComp}
                        />
                      </Grid>
                      <Grid item xs={5}></Grid>
                    </Fragment>
                  ) : item.slcmonthlyAggType ===
                    Constant.SLC_MONTHLY_AGG_TYPE.ELECTION_TIER ? (
                    <Fragment key={uuid()}>
                      {indx > 0 ? <Grid item xs={3}></Grid> : null}
                      <Grid item xs={3}>
                        <TextField
                          id={'readonly-electiontierId-' + indx}
                          key={'key-' + uuid()}
                          label="Election Tier"
                          InputProps={{ readOnly: true }}
                          defaultValue={item.electionTierSchema.etName}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id={'readonly-electiontierRate-' + index}
                          key={'key-' + uuid()}
                          label="Rate"
                          InputProps={{ readOnly: true }}
                          defaultValue={item.electiontierRate}
                        />
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Fragment>
                  ) : item.slcmonthlyAggType ===
                    Constant.SLC_MONTHLY_AGG_TYPE.IND_FAM ? (
                    <Fragment key={uuid()}>
                      {indx > 0 ? <Grid item xs={3}></Grid> : null}
                      <Grid item xs={3}>
                        <TextField
                          id={'readonly-aggFactInd-' + indx}
                          key={'key-' + uuid()}
                          label="IND"
                          InputProps={{ readOnly: true }}
                          defaultValue={item.aggFactInd}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id={'readonly-aggFactFam-' + index}
                          key={'key-' + uuid()}
                          label="FAM"
                          InputProps={{ readOnly: true }}
                          defaultValue={item.aggFactFam}
                        />
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Fragment>
                  ) : null;
                })
                : null}
              <Grid item xs={1}>
                <IconButton
                  id={'action-delete-' + index}
                  onClick={() => handleDelete(key)}>
                  <Delete />
                </IconButton>
                {index === Object.keys(dataGroupByPlanMap).length - 1 ? (
                  <IconButton
                    id={'action-add'}
                    onClick={() => handleCreationOpen(DEAFULT_CRUD_OBJ)}
                    title="Add New">
                    <Add />
                  </IconButton>
                ) : null}
              </Grid>
            </Fragment>
          ))
        ) : (
          <>
            <Grid container justify="center" alignItems="center">
              <i>No data was found. Please click </i>
              <IconButton
                id={'action-add-no-data'}
                onClick={() => handleCreationOpen(DEAFULT_CRUD_OBJ)}
                title="Add New">
                <Add />
              </IconButton>
              <i> to create a new one</i>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
});

export default MonthlyAggList;
