import { Button, Grid, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import * as Constant from 'common/Constant';
import moment from 'moment-timezone';

const SpecialExpenseForm = ({ rateBasis, planYears, planStartDate, planEndDate }) => {
  const [rate, setRate] = useState(null);
  const { control, setValue, getValues, trigger } = useFormContext();
  const targetRateId = getValues('id');
  const { fields, remove } = useFieldArray({
    control,
    name: 'specialExpense',
    shouldUnregister: true
  });
  const getSpecialExpenseData = () => {
    // method to create one-time-special expense range from start of plan year to end of plan year
    // 1. one-time-special expense range shall be based on the plan year selected
    // 2. for plan year xxxx, the range shall be 01/01/xxxx to 12/31/xxxx

    const expenseDates = [];
    const numberOfMonths = moment(planEndDate).diff(planStartDate, 'months');

    const startDate = moment(planStartDate).toISOString();
    const endDate = moment(planEndDate).toISOString();

    const endOfStartDate = moment(planStartDate).endOf('month').toISOString();
    const startOfEndDate = moment(planEndDate).startOf('month').toISOString();

    if (numberOfMonths === 0 && moment(startDate).month() === 0 && moment(endDate).month() === 0) {
      expenseDates.push({
        startDate: moment(planStartDate).toString(),
        endDate: moment(planEndDate).toString()
      });
    } else {
      for (let i = 1; i < numberOfMonths; i++) {
        let month = moment(planStartDate).add(i, 'M');
        const sDate = moment(month).startOf('month');
        const eDate = moment(month).endOf('month');
        const expenseDateObject = {
          startDate: moment(sDate).toString(),
          endDate: moment(eDate).toString()
        };
        expenseDates.push(expenseDateObject);
      }
      expenseDates.unshift({
        startDate,
        endDate: endOfStartDate
      });
      expenseDates.push({
        startDate: startOfEndDate,
        endDate
      });
    }

    setValue('specialExpense', expenseDates);
  };

  useEffect(() => {
    remove();

    if (rateBasis === Constant.PLAN_EXPENSE_DATA_ONE_TIME_SPECIAL && planYears.length === 1 && targetRateId) {
      const specialExpenseObject = [
        {
          startDate: getValues('rateStartDate'),
          endDate: getValues('rateEndDate'),
          rate: getValues('rate')
        }
      ];
      setValue('specialExpense', specialExpenseObject);
    } else if (planStartDate && planEndDate) {
      getSpecialExpenseData();
    }
  }, [rateBasis, planYears, planStartDate, planEndDate, targetRateId]);

  const applyRateToAllPlans = () => {
    fields.forEach((_, i) => {
      setValue(`specialExpense[${i}].rate`, rate);
    });
    trigger();
  };

  return (
    Array.isArray(fields) &&
    fields.length > 0 && (
      <Grid container spacing={2}>
        {!targetRateId && (
          <Grid item lg={12}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <TextField id="input-rate" fullWidth label="Rate" onChange={(e) => setRate(e.target.value)} value={rate} />
              </Grid>
              <Grid item>
                <Button color="primary" variant="outlined" onClick={applyRateToAllPlans} disabled={!rate}>
                  Apply To All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {fields.map((ob, index) => (
          <Grid item lg={targetRateId ? 12 : 6} key={`specialExpense[${index}].startDate`}>
            <Grid container spacing={1}>
              <Grid item lg={3}>
                <Controller
                  defaultValue={ob.startDate}
                  render={({ field, fieldState: { error } }) => (
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        clearable
                        {...field}
                        maxDate={getValues(`specialExpense[${index}].endDate`)}
                        error={error}
                        helperText={error?.message ?? null}
                        format={Constant.FORMAT_DATE}
                        id={`specialExpense[${index}].startDate`}
                        label="Start Date"
                        fullWidth
                        key={`specialExpense[${index}].startDate`}
                        value={moment.utc(getValues(`specialExpense[${index}].startDate`))}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name={`specialExpense[${index}].startDate`}
                  control={control}
                />
              </Grid>
              <Grid item lg={3}>
                <Controller
                  defaultValue={ob.endDate}
                  render={({ field, fieldState: { error } }) => (
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        {...field}
                        minDate={getValues(`specialExpense[${index}].startDate`)}
                        error={error}
                        helperText={error?.message ?? null}
                        format={Constant.FORMAT_DATE}
                        id={`specialExpense[${index}].endDate`}
                        label="End Date"
                        fullWidth
                        key={`specialExpense[${index}].endDate`}
                        value={moment.utc(getValues(`specialExpense[${index}].endDate`))}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name={`specialExpense[${index}].endDate`}
                  control={control}
                />
              </Grid>
              <Grid item lg={3}>
                <Controller
                  rules={{
                    validate: (_) => {
                      if (!getValues('specialExpense').filter((exp) => exp.rate).length) {
                        return 'At least one rate for special expense is required.';
                      }
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id={`input-rate-sp-${index}`}
                      fullWidth
                      type="number"
                      {...field}
                      error={error}
                      helperText={error?.message ?? null}
                      label="Rate"
                      value={field.value || ''}
                      key={`input-rate-sp-${index}`}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger();
                      }}
                    />
                  )}
                  name={`specialExpense[${index}].rate`}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default SpecialExpenseForm;
