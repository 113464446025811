import React from 'react';
import { AppConfig } from 'AppConfig';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  CardHeader,
  Button,
  Tooltip,
  Box
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { store } from 'store/store';
import * as FavoriteReportActions from 'features/frontoffice/favoriteReport/actions/action';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import * as ReportActions from 'features/frontoffice/actions/actions';
import { confirmDialog } from 'common/ComfirmationDialog';
import * as ReportTypes from 'features/frontoffice/actions/types';
import { makeStyles } from '@material-ui/core/styles';
import { formatReptSource } from 'utils/formatReptSource';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/common';
import { trackingTypes, trackingActions, trackClick } from 'utils/tracking';

const useStyles = makeStyles((theme) => ({
  box: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    overflow: 'hidden'
  },
  cardHeader: {
    display: 'block',
    overflow: 'hidden'
  }
}));

const ReportCard = ({
  item,
  showFavorite = false,
  showDismiss = false,
  showTitle = true,
  showThumbnail = false,
  reportType
}) => {
  const classes = useStyles();
  const currentTenantCd = useSelector((state) => state.user.currentTenantCd);
  const permissions = useSelector((state) => state.user.permissions);
  const tableauServiceUrl = useSelector((state) => state.user.tableauServiceUrl);

  const handleReportClick = () => {
    if (item?.id) {
      trackClick(
        trackingTypes.REPORT,
        trackingActions.READ,
        item.id,
        item.category + ';' + item.displayText
      );
    }
    store.dispatch({
      type: ReportTypes.OPEN_REPORT_VIEWER_REQUEST,
      reportUrl: item.reportSource
        ? item.reportSource
        : formatReptSource(tableauServiceUrl, currentTenantCd, item.reptSource),
      category: item.category,
      title: item.displayText
    });
  };

  const addFavorite = (id) => {
    store.dispatch(FavoriteReportActions.addFavoriteReport(id, reportType));
  };

  const deleteFavorite = (id) => {
    store.dispatch(FavoriteReportActions.deleteFavoriteReport(id, reportType));
  };

  const handleFavorite = () => {
    item.favorited ? deleteFavorite(item.id) : addFavorite(item.id);
  };

  const dismiss = () => {
    confirmDialog(
      () => store.dispatch(ReportActions.dismissReport(item.id)),
      'Are you sure you want to dismiss this report?',
      'Dismiss Report'
    );
  };

  return (
    <>
      <Card style={{ width: AppConfig.HOME_SMART_CARD_WIDTH }}>
        {showTitle ? (
          <Tooltip title={<h6>{item.displayText}</h6>}>
            <div>
              <CardHeader
                className={classes.cardHeader}
                title={
                  <Typography noWrap gutterBottom variant="h6" component="h4">
                    {item.displayText}
                  </Typography>
                }
              />
            </div>
          </Tooltip>
        ) : (
          <CardHeader className={classes.cardHeader} />
        )}
        {showThumbnail && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <img
              height="150px"
              width="auto"
              src={item.s3Url}
              alt="No Thumbnail"
            />
          </Box>
        )}
        <CardContent style={{ height: '50px' }}>
          <Box className={classes.box}>
            {showTitle === true ? (
              <Tooltip title={<h6>{item.description}</h6>}>
                <Typography>{item.description}</Typography>
              </Tooltip>
            ) : (
              <Typography
                style={{
                  height: '50px',
                  fontSize: '14px',
                  fontWeight: 'bold'
                }}>
                {item.description}
              </Typography>
            )}
          </Box>
        </CardContent>
        <CardActions disableSpacing>
          {showFavorite === true && (
            <Tooltip
              title={
                item.favorited
                  ? 'Remove from my favorites'
                  : 'Mark as a favorite'
              }>
              <IconButton onClick={handleFavorite}>
                <FavoriteIcon
                  style={{ color: item.favorited ? '#ED2C2D' : null }}
                />
              </IconButton>
            </Tooltip>
          )}
          {showDismiss === true &&
            hasPermission(
              AppConfig.PERMISSION.FO_SMARTCARD_DISMISS,
              permissions
            ) && (
              <Tooltip title="Dismiss">
                <IconButton onClick={dismiss}>
                  <ThumbDownIcon />
                </IconButton>
              </Tooltip>
            )}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleReportClick}
            style={{ marginLeft: 'auto' }}>
            View Report
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default ReportCard;
