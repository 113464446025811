import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import RouteConfig from 'routes/RouteConfig';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchData } from 'features/user/userActions';
import { AppConfig } from 'AppConfig';
import { store } from 'store/store';

import UserPermissionTable from '../userAccess/userPermissionTable';
import RoleManagementTable from '../roleManagement/roleManagementTable';
import GroupManagementTable from '../groupManagement/groupManagementTable';

const AdminNavigator = ({ location }) => {
  const USER_ACCESS_URL = RouteConfig.adminPage.path;
  const ROLE_MANAGEMENT_URL =
    RouteConfig.adminPage.path + AppConfig.ADMIN_SUB_URL.ROLE_MANAGEMENT;
  const USER_GROUP_URL =
    RouteConfig.adminPage.path + AppConfig.ADMIN_SUB_URL.USER_GROUP;
  const { deafultUserState, currentTenantCd } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deafultUserState) {
        store.dispatch(fetchData(currentTenantCd, AppConfig.APP_CODE_FO));
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Tabs
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary">
        <Tab
          label="User Access"
          value={USER_ACCESS_URL}
          component={Link}
          to={USER_ACCESS_URL}
        />
        <Tab
          label="Role Management"
          value={ROLE_MANAGEMENT_URL}
          component={Link}
          to={ROLE_MANAGEMENT_URL}
        />
        <Tab
          label="User Group"
          value={USER_GROUP_URL}
          component={Link}
          to={USER_GROUP_URL}
        />
      </Tabs>
      {location.pathname === RouteConfig.adminPage.path && (
        <UserPermissionTable />
      )}
      {location.pathname === ROLE_MANAGEMENT_URL && <RoleManagementTable />}
      {location.pathname === USER_GROUP_URL && <GroupManagementTable />}
    </>
  );
};

export default AdminNavigator;
